import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import Select from 'react-select';
import ScrollspyNav from "react-scrollspy-nav";
import ReactTooltip from 'react-tooltip';
import Switch from "react-switch";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap/";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import { tfaFormsubmit } from "../actions/authActions";
import setAuthToken from "../utils/setAuthToken";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const url = keys.baseUrl;


const currencyType = [
  { value: 'NGN', label: 'NGN' },
  { value: 'BTC', label: 'BTC' },
  { value: 'USDT', label: 'USDT' },
  { value: 'BCH', label: 'BCH' },
  { value: 'XRP', label: 'XRP' },
  { value: 'TRON', label: 'TRON' },
  { value: 'DASH', label: 'DASH' },
  { value: 'LTC', label: 'LTC' },
];
const buySell = [
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];
const transferType = [
  { value: 'Bank Transfer', label: 'Bank Transfer' },
  { value: 'Fiat Transfer', label: 'Fiat Transfer' },
  { value: 'Instant Transfer', label: 'Instant Transfer' },
];

class FAQ extends Component {
  constructor() {
		super();
		this.state = { 
		  selectedPreferredTime: null,
		  selectedPreferredLanguage: null,
		  checked: false ,
		  selectedCoinPair: null,
		  selectedbankName: null,
		  oldpassword: "",
		  password: "",
		  password2: "",
		  loginpassword: "",
		  googleauth: "",
		  onecode: "",
		  tfacode: "",
		  applicationName: "",
		  readOnly: "",
		  twofactorkey: "",
		  notifications: {},
		  errors: {},
		  accepterror: "",
		  onecodeerror: "",
		  twofastatus:"",
		  accept: "false",
		  _csrf:'',
		  googlestatus:'',
		  isTop: true,
		  selectedBuySell: null,
		  selectedTransferType: null,
		  selectedCurrencyType: null,
		  faqDetails:[],
      cmsData:{}
		};
	  }
// state = {
//     isTop: true,
//   };
//  state = {
//     selectedBuySell: null,
//     selectedTransferType: null,
//     selectedCurrencyType: null,
//     };

 getFaqDetails =async () => {
  const data = { identifier: "cookies_policy" }
  const result = await axios.post(url + "users/getSingleCmsData", data);
  const cmsData=result.data.cmsResult
  this.setState({
    cmsData: cmsData
  })
  }

  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
	this.getFaqDetails();
  }

  handleBuySell = selectedBuySell => {
    this.setState({ selectedBuySell });
  };
  handleTransferType = selectedTransferType => {
    this.setState({ selectedTransferType });
  };
  handleCurrencyType = selectedCurrencyType => {
    this.setState({ selectedCurrencyType });
  };
	render() {
	const { cmsData } = this.state;
	const { selectedTransferType } = this.state;
	const { selectedCurrencyType } = this.state;
		return (
      <div>
        <Navbar />
        <section className="secFAQ cmsPages">
          <div className="container">
            <div className="cmsBoxWhiteBox pb-0">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-sm-12 ml-auto mr-auto">
                  <div className="faqCont">
                    <div className="homeAccordian wow fadeIn">
                        <h2>Cookies Policy</h2>
                        <div class="row">
                          <div class="col-md-12  terms">
                          {
                          cmsData && cmsData.content &&
                          ReactHtmlParser(
                            cmsData.content
                          )}
                          </div>
                          
                          
                          
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
	  </div>
    );
	}
}

FAQ.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	logoutUser: PropTypes.func.isRequired,
  
  };
  
  const mapStateToProps = (state) => ({
   auth: state.auth,
   errors: state.errors,
  });
  
export default connect(mapStateToProps,{tfaFormsubmit})(FAQ)