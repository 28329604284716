export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_ADD = "USER_ADD";
export const USER_REGISTER = "USER_REGISTER";
export const OTP_REGISTER = "OTP_REGISTER";
export const USER_UPDATE = "USER_UPDATE";
export const USER_FORGOT = "USER_FORGOT";
export const TRADE = "TRADE";
export const WALLET = "WALLET";
export const SUPPORT = "SUPPORT";
export const SUPPORT_REPLY = "SUPPORT_REPLY";
