import React, { Component, Fragment, setTimeOut } from "react";
import { NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import Select from "react-select";
import { Multiselect } from "multiselect-react-dropdown";
import axios from "axios";
import keys from "../actions/config";
import queryString from "query-string";
import { store } from "react-notifications-component";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TimePicker from "react-time-picker";
import ReactFlagsSelect from "react-flags-select";
import { getCode, getName } from "country-list";
import config from "../actions/config";

const url = keys.baseUrl;

const tradeHours = [
  {
    value: "24 Hours, 00.00AM to 24.00AM",
    label: "24 Hours, 00.00AM to 24.00AM",
  },
  {
    value: "12 Hours, 00.00AM to 12.00Noon",
    label: "12 Hours, 00.00AM to 12.00Noon",
  },
  {
    value: "12 Hours, 01.00PM to 12.00Mid Night",
    label: "12 Hours, 01.00PM to 12.00Mid Night",
  },
];
const TradeTime = [
  { value: "GMT +2.00 Time Zone", label: "GMT +2.00 Time Zone" },
  { value: "GMT +2.00 Time Zone", label: "GMT +2.00 Time Zone" },
  { value: "GMT +2.00 Time Zone", label: "GMT +2.00 Time Zone" },
];

const coinPair = [
  { value: "BTC", label: "BTC - Bitcoin" },
  { value: "ETH", label: "ETH - Ethereum" },
  { value: "USDT", label: "USDT- Tether" },
  { value: "LTC", label: "LTC - Litecoin" },
];
const fiatType = [
  { value: "US - Dollar", label: "US - Dollar" },
  { value: "INR - Rupees", label: "INR - Rupees" },
  { value: "AUD - Australian Dollar", label: "AUD - Australian Dollar" },
];

const startTimeOptions = [
  { value: "00:00", label: "00:00" },
  { value: "00:15", label: "00:15" },
  { value: "00:30", label: "00:30" },
  { value: "00:45", label: "00:45" },
  { value: "01:00", label: "01:00" },
  { value: "01:15", label: "01:15" },
  { value: "01:30", label: "01:30" },
  { value: "01:45", label: "01:45" },
  { value: "02:00", label: "02:00" },
  { value: "02:15", label: "02:15" },
  { value: "02:30", label: "02:30" },
  { value: "02:45", label: "02:45" },
  { value: "03:00", label: "03:00" },
  { value: "03:15", label: "03:15" },
  { value: "03:30", label: "03:30" },
  { value: "03:45", label: "03:45" },
  { value: "04:00", label: "04:00" },
  { value: "04:15", label: "04:15" },
  { value: "04:30", label: "04:30" },
  { value: "04:45", label: "04:45" },
  { value: "05:00", label: "05:00" },
  { value: "05:15", label: "05:15" },
  { value: "05:30", label: "05:30" },
  { value: "05:45", label: "05:45" },
  { value: "06:00", label: "06:00" },
  { value: "06:15", label: "06:15" },
  { value: "06:30", label: "06:30" },
  { value: "06:45", label: "06:45" },
  { value: "07:00", label: "07:00" },
  { value: "07:15", label: "07:15" },
  { value: "07:30", label: "07:30" },
  { value: "07:45", label: "07:45" },
  { value: "08:00", label: "08:00" },
  { value: "08:15", label: "08:15" },
  { value: "08:30", label: "08:30" },
  { value: "08:45", label: "08:45" },
  { value: "09:00", label: "09:00" },
  { value: "09:15", label: "09:15" },
  { value: "09:30", label: "09:30" },
  { value: "09:45", label: "09:45" },
  { value: "10:00", label: "10:00" },
  { value: "10:15", label: "10:15" },
  { value: "10:30", label: "10:30" },
  { value: "10:45", label: "10:45" },
  { value: "11:00", label: "11:00" },
  { value: "11:15", label: "11:15" },
  { value: "11:30", label: "11:30" },
  { value: "11:45", label: "11:45" },
  { value: "12:00", label: "12:00" },
  { value: "12:15", label: "12:15" },
  { value: "12:30", label: "12:30" },
  { value: "12:45", label: "12:45" },
  { value: "13:00", label: "13:00" },
  { value: "13:15", label: "13:15" },
  { value: "13:30", label: "13:30" },
  { value: "13:45", label: "13:45" },
  { value: "14:00", label: "14:00" },
  { value: "14:15", label: "14:15" },
  { value: "14:30", label: "14:30" },
  { value: "14:45", label: "14:45" },
  { value: "15:00", label: "15:00" },
  { value: "15:15", label: "15:15" },
  { value: "15:30", label: "15:30" },
  { value: "15:45", label: "15:45" },
  { value: "16:00", label: "16:00" },
  { value: "16:15", label: "16:15" },
  { value: "16:30", label: "16:30" },
  { value: "16:45", label: "16:45" },
  { value: "17:00", label: "17:00" },
  { value: "17:15", label: "17:15" },
  { value: "17:30", label: "17:30" },
  { value: "17:45", label: "17:45" },
  { value: "18:00", label: "18:00" },
  { value: "18:15", label: "18:15" },
  { value: "18:30", label: "18:30" },
  { value: "18:45", label: "18:45" },
  { value: "19:00", label: "19:00" },
  { value: "19:15", label: "19:15" },
  { value: "19:30", label: "19:30" },
  { value: "19:45", label: "19:45" },
  { value: "20:00", label: "20:00" },
  { value: "20:15", label: "20:15" },
  { value: "20:30", label: "20:30" },
  { value: "20:45", label: "20:45" },
  { value: "21:00", label: "21:00" },
  { value: "21:15", label: "21:15" },
  { value: "21:30", label: "21:30" },
  { value: "21:45", label: "21:45" },
  { value: "22:00", label: "22:00" },
  { value: "22:15", label: "22:15" },
  { value: "22:30", label: "22:30" },
  { value: "22:45", label: "22:45" },
  { value: "23:00", label: "23:00" },
  { value: "23:15", label: "23:15" },
  { value: "23:30", label: "23:30" },
  { value: "23:45", label: "23:45" },
];

const endTimeOptions = [
  { value: "00:15", label: "00:15" },
  { value: "00:30", label: "00:30" },
  { value: "00:45", label: "00:45" },
  { value: "01:00", label: "01:00" },
  { value: "01:15", label: "01:15" },
  { value: "01:30", label: "01:30" },
  { value: "01:45", label: "01:45" },
  { value: "02:00", label: "02:00" },
  { value: "02:15", label: "02:15" },
  { value: "02:30", label: "02:30" },
  { value: "02:45", label: "02:45" },
  { value: "03:00", label: "03:00" },
  { value: "03:15", label: "03:15" },
  { value: "03:30", label: "03:30" },
  { value: "03:45", label: "03:45" },
  { value: "04:00", label: "04:00" },
  { value: "04:15", label: "04:15" },
  { value: "04:30", label: "04:30" },
  { value: "04:45", label: "04:45" },
  { value: "05:00", label: "05:00" },
  { value: "05:15", label: "05:15" },
  { value: "05:30", label: "05:30" },
  { value: "05:45", label: "05:45" },
  { value: "06:00", label: "06:00" },
  { value: "06:15", label: "06:15" },
  { value: "06:30", label: "06:30" },
  { value: "06:45", label: "06:45" },
  { value: "07:00", label: "07:00" },
  { value: "07:15", label: "07:15" },
  { value: "07:30", label: "07:30" },
  { value: "07:45", label: "07:45" },
  { value: "08:00", label: "08:00" },
  { value: "08:15", label: "08:15" },
  { value: "08:30", label: "08:30" },
  { value: "08:45", label: "08:45" },
  { value: "09:00", label: "09:00" },
  { value: "09:15", label: "09:15" },
  { value: "09:30", label: "09:30" },
  { value: "09:45", label: "09:45" },
  { value: "10:00", label: "10:00" },
  { value: "10:15", label: "10:15" },
  { value: "10:30", label: "10:30" },
  { value: "10:45", label: "10:45" },
  { value: "11:00", label: "11:00" },
  { value: "11:15", label: "11:15" },
  { value: "11:30", label: "11:30" },
  { value: "11:45", label: "11:45" },
  { value: "12:00", label: "12:00" },
  { value: "12:15", label: "12:15" },
  { value: "12:30", label: "12:30" },
  { value: "12:45", label: "12:45" },
  { value: "13:00", label: "13:00" },
  { value: "13:15", label: "13:15" },
  { value: "13:30", label: "13:30" },
  { value: "13:45", label: "13:45" },
  { value: "14:00", label: "14:00" },
  { value: "14:15", label: "14:15" },
  { value: "14:30", label: "14:30" },
  { value: "14:45", label: "14:45" },
  { value: "15:00", label: "15:00" },
  { value: "15:15", label: "15:15" },
  { value: "15:30", label: "15:30" },
  { value: "15:45", label: "15:45" },
  { value: "16:00", label: "16:00" },
  { value: "16:15", label: "16:15" },
  { value: "16:30", label: "16:30" },
  { value: "16:45", label: "16:45" },
  { value: "17:00", label: "17:00" },
  { value: "17:15", label: "17:15" },
  { value: "17:30", label: "17:30" },
  { value: "17:45", label: "17:45" },
  { value: "18:00", label: "18:00" },
  { value: "18:15", label: "18:15" },
  { value: "18:30", label: "18:30" },
  { value: "18:45", label: "18:45" },
  { value: "19:00", label: "19:00" },
  { value: "19:15", label: "19:15" },
  { value: "19:30", label: "19:30" },
  { value: "19:45", label: "19:45" },
  { value: "20:00", label: "20:00" },
  { value: "20:15", label: "20:15" },
  { value: "20:30", label: "20:30" },
  { value: "20:45", label: "22:00" },
  { value: "22:15", label: "22:15" },
  { value: "22:30", label: "22:30" },
  { value: "22:45", label: "22:45" },
  { value: "23:00", label: "23:00" },
  { value: "23:15", label: "23:15" },
  { value: "23:30", label: "23:30" },
  { value: "23:45", label: "23:45" },
  { value: "24:00", label: "24:00" },
];

const tradeOpen = [
  { value: "Anybody", label: "Anybody" },
  { value: "Only Email Verified", label: "Only Email Verified" },
  // { value: "Only Phone Verified", label: "Only Phone Verified" },
];

class PostTrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMonEndTime: null,
      selectedMonStartTime: null,
      selectedTradeHours: null,
      selectedTradeTime: null,
      selectedTradewith: null,
      tradeDay: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      plainArray: [
        "New Delhi, India",
        "Tokyo, Japan",
        "Newyork, USA",
        "London, UK",
      ],
      transferType: [
        "Bank Transfer",
        "Paypal",
        "Crypto Wallet",
        "Gif Certificate",
      ],
      errors: {},
      buyads: [],
      sellads: [],
      buytab: "",
      selltab: "",
      findadstab: "active",
      buycheckbox: true,
      sellcheckbox: false,
      buytradecheckbox: true,
      selltradecheckbox: false,
      Skeletonbuy: 1,
      Skeletonsell: 0,
      // selectedCurrencyType: {
      //   label: "BTC",
      //   value: "BTC",
      // },

      // selectedtocpostcurrency: {
      //   label: "NGN",
      //   value: "NGN",
      // },
      selectedpreferred: {
        label: "Select",
        value: "",
      },
      selectedDepositFrom: {
        label: null,
        value: null,
      },
      preferredtransfer: {
        label: "Select",
        value: "",
      },
      curmarketprice: 0,
      BuyorSell: "Buy",
      BuyorSell_find: "Buy",
      tradetype: "Buy",
      balancedetails: [],
      IdentityVerification: "",
      email: "",
      username: "",
      currencydetails: [],
      userbalance: 0,
      currencyselected: "",
      posttocurrency: "",
      postprefcurrency: [],
      spotpairsdata: [],
      posttradeprice: 0,
      postminlimit: "",
      postmaxlimit: "",
      posttooptions: [],
      postcheckboxaccept: false,
      margin: 0,
      myadshistory: [],
      postDetails: false,
      postSummary: false,
      offerCategory: false,
      bankdetails: [],
      bankdboptions: "",
      bankselected: "",
      editadshow: false,
      editableboxshow: false,
      cancelpopupshow: false,
      myactivities: [],
      data: [],
      offset_myads: 0,
      offset_searchads: 0,
      activetab: "",
      myadname: "",
      myaddate: "",
      date: "",
      terms: "",
      editterms: "",
      kycstatus: "Not Verified",
      placorder_disable: false,
      percentagetype: "",
      Skeletonabove: 1,
      Skeletonbelow: 0,
      aboveCheckbox: false,
      belowCheckbox: false,
      marginOffer: 0,
      sunStartTime: "",
      sunEndTime: "",
      monStartTime: "",
      monEndTime: "",
      tueStartTime: "",
      tueEndTime: "",
      wedStartTime: "",
      wedEndTime: "",
      thuStartTime: "",
      thuEndTime: "",
      friStartTime: "",
      friEndTime: "",
      satStartTime: "",
      satEndTime: "",
      title: "",
      tradeWith: "",
      _csrf: "",
      country: "",
      aggreeterms: false,
      onecodeerror: "",
      transactionfee: "1",
      post_trade: false,
      tab0visible: "nav-link active",
      tab1visible: "nav-link",
      tab2visible: "nav-link",
      tab3visible: "nav-link",
      tab0content: "tab-pane container fade active show",
      tab1content: "tab-pane container fade",
      tab2content: "tab-pane container fade",
      tab3content: "tab-pane container fade",
    };
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    this.getCSRFtoken();
    this.getUserdata();
    this.getBalance();
    this.getcurrencydetails();

    this.getspotdata();
    // this.getbankdetails();
    // this.getmyads();
    // this.getmyactivity();
    this.getpreferred_transfer();
    // this.getbuyads();
    // this.searchfind_ads("Buy", 0);
    let params = queryString.parse(this.props.location.search);
    if (params.tab == "postads") {
      this.setState({ postadstab: "active" });
      this.setState({ findadstab: "" });
    }

    if (params.tab == "myads") {
      this.setState({ myadstab: "active" });
      this.setState({ postadstab: "" });
      this.setState({ findadstab: "" });
    }
    var ctab = this.props.match.params.id;

    if (ctab == "myads" || ctab == "myactivity") {
      let userid = this.props.auth.user.id;
      if (userid == "" || userid == undefined) {
        this.props.history.push("/login");
      }
    }

    this.setState({ activetab: ctab });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      //   store.addNotification({
      //     title: "Wonderful!",
      //     message: "Loggedin Successfully. Welcome to coinfriends",
      //     type: "success",
      //     insert: "top",
      //     container: "top-right",
      //     animationIn: ["animated", "fadeIn"],
      //     animationOut: ["animated", "fadeOut"],
      //     dismiss: {
      //       duration: 2000,
      //       onScreen: true,
      //     },
      //   });
      //   this.props.history.push("/Dashboard");
    }
  }

  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
      })
      .catch();
  };
  handleCoinPair = (selectedCoinPair) => {
    this.setState({ selectedCoinPair });
  };
  handleCoinPair = (selectedFiatType) => {
    this.setState({ selectedFiatType });
  };
  // // Handling time changes
  // handleSunStartTime = selectedSunStartTime =>{
  // 	this.setState({selectedSunStartTime, sunStartTime:selectedSunStartTime.value});
  // }
  // handleSunEndTime = selectedSunEndTime =>{
  // 	this.setState({selectedSunEndTime, sunEndTime:selectedSunEndTime.value});
  // }

  handleTimeChange = (id, e) => {
    this.setState({ [id]: e.value });
  };
  handleTradeWith = (e) => {
    this.setState({ tradeWith: e.value });
  };

  handleMonEndTime = (selectedMonEndTime) => {
    this.setState({ selectedMonEndTime, monEndTime: selectedMonEndTime.value });
  };

  handlepostpreferred = (selectedpreferred) => {
    const values =
      selectedpreferred && [...selectedpreferred].map((opt) => opt.value);
    this.setState({ exceptCountries: values });
    this.setState({ selectedpreferred });
    this.setState({ postprefcurrency: values });
  };

  getUserdata() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/userget/" + userid).then((res) => {
      this.setState({ kycstatus: res.data.kycstatus });
      if (res.data.kycstatus != "Approved") {
        store.addNotification({
          title: "Error!",
          message:
            "You need to update your verification status to continue using Coinbarter",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });

        this.props.history.push("/MyAccount/");
      }
    });
  }
  getBalance() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      this.setState({ balancedetails: res.data.data });
      var tempdata = res.data.data;
      var index = tempdata.findIndex(
        (x) => x.currencySymbol === this.state.currencyselected
      );
      if (index != -1) {
        this.setState({ userbalance: tempdata[index].balance });
      }
    });
  }
  getspotdata() {
    axios.get(url + "api/spotpair-data").then((res) => {
      if (res.data.status) {
        this.setState({ spotpairsdata: res.data.data });
        var tempdata = res.data.data;
        console.log("========================", res.data.data);
        var pair = this.state.currencyselected + this.state.posttocurrency;
        var indexonchcange = this.state.spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          var onchbaseprice =
            this.state.spotpairsdata[indexonchcange].index_price;
          let transactionfee =
            this.state.spotpairsdata[indexonchcange].transactionfee;

          this.setState({ curmarketprice: onchbaseprice });
          this.setState({ transactionfee: transactionfee });
        }
      }
    });
  }

  // Get currency details
  getcurrencydetails() {
    axios.get(url + "api/currency").then((res) => {
      this.setState({ currencydetails: res.data });
      var firstcur = res.data;
      var newcurar = [];
      firstcur.map((details, index) => {
        if (details.type == "Crypto") {
          if (details.p2p == "On") {
            newcurar.push({
              value: details.currencySymbol,
              label: details.currencySymbol,
            });
          }
        }
      });
      var fiatcur = [];
      firstcur.map((details, index) => {
        if (details.type == "Fiat") {
          if (details.p2p == "On") {
            fiatcur.push({
              value: details.currencySymbol,
              label: details.currencySymbol,
            });
          }
        }
      });
      var tocurrecur = [];
      firstcur.map((details, index) => {
        if (details.currencySymbol != this.state.currencyselected) {
          tocurrecur.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });
      this.setState({ posttooptions: tocurrecur });
      this.setState({ currencyoptions: newcurar, fiatcuroption: fiatcur });
    });
  }

  getpreferred_transfer() {
    axios.get(url + "tradeapi/transfer_options").then((res) => {
      if (res.data.status) {
        var pref = [];
        res.data.preferredtransfer.map((details, index) => {
          console.log(details.BankName, "------------>details123");
          pref.push({
            value: details.BankName,
            label: (
              <span>
                {" "}
                <img
                  width="20"
                  height="20"
                  src={config.imageUrl + "/banklogo/" + details.banklogo}
                  class="css-pjaw30"
                />{" "}
                {details.BankName}
              </span>
            ),
            banklogo: details.banklogo,
          });
        });

        this.setState({ preferredtransfer: pref });
      }
    });
  }

  //   getpreferred_transfer() {
  // 	axios.get(url + "tradeapi/preferred_transfer").then((res) => {
  // 	  if (res.data.status) {
  // 		var pref = [];
  // 		res.data.preferredtransfer.map((details, index) => {
  // 		  pref.push({
  // 			value: details.bank,
  // 			label: (
  // 			  <span>
  // 				{" "}
  // 				<img
  // 				  width="20"
  // 				  height="20"
  // 				  src={require("../images/payment/" +
  // 					details.bank.replace(/[ )(]/g, "") +
  // 					".png")}
  // 				  class="css-pjaw30"
  // 				/>{" "}
  // 				{details.bank}
  // 			  </span>
  // 			),
  // 		  });
  // 		});

  // 		this.setState({ preferredtransfer: pref });
  // 	  }
  // 	});
  //   }

  // Radio box change
  RadiobuttonChange_buy = (e) => {
    this.setState({
      tradetype: e.currentTarget.value,
    });
    this.setState({ buycheckbox: true });
    this.setState({ sellcheckbox: false });

    this.setState({ Skeletonbuy: 1, Skeletonsell: 0 });

    if (this.state.marginOffer != 0) {
      var margin = this.state.marginOffer / 100 - 1;
      var priceEquation = this.state.curmarketprice * margin;
      this.setState({ posttradeprice: Math.abs(priceEquation) });
    }
    // this.searchfind_ads(e.currentTarget.value, 0);
  };

  RadiobuttonChange_sell = (e) => {
    this.setState({
      tradetype: e.currentTarget.value,
    });
    this.setState({ buycheckbox: false });
    this.setState({ sellcheckbox: true });
    this.setState({ Skeletonbuy: 0, Skeletonsell: 1 });
    // this.searchfind_ads(e.currentTarget.value, 0);
    if (this.state.marginOffer != 0) {
      var margin = this.state.marginOffer / 100 + 1;
      var priceEquation = this.state.curmarketprice * margin;
      this.setState({ posttradeprice: Math.abs(priceEquation) });
    }
  };

  RadiobuttonChange_above = (e) => {
    this.setState({
      percentagetype: e.currentTarget.value,
    });
    this.setState({ aboveCheckbox: true });
    this.setState({ belowCheckbox: false });

    this.setState({ Skeletonabove: 1, Skeletonbelow: 0 });
  };

  RadiobuttonChange_below = (e) => {
    this.setState({
      percentagetype: e.currentTarget.value,
    });
    this.setState({ aboveCheckbox: false });
    this.setState({ belowCheckbox: true });
    this.setState({ Skeletonabove: 0, Skeletonbelow: 1 });
    // this.searchfind_ads(e.currentTarget.value, 0);
  };

  onChangeCheckbox = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
  };
  //   Handle currencty
  handleCurrencyType = (selectedCurrencyType) => {
    this.setState({ selectedCurrencyType });
    this.setState({ currencyselected: selectedCurrencyType.value });
    var pair = selectedCurrencyType.value + this.state.posttocurrency;
    var tempprice = 0;
    var curindex = this.state.spotpairsdata.findIndex(
      (x) => x.tiker_root === pair
    );
    if (curindex != -1) {
      tempprice = this.state.spotpairsdata[curindex].index_price;
      this.setState({
        curmarketprice: this.state.spotpairsdata[curindex].index_price,
      });

      this.setState({
        transactionfee: this.state.spotpairsdata[curindex].transactionfee,
      });
    }
    var index = this.state.balancedetails.findIndex(
      (x) => x.currencySymbol === selectedCurrencyType.value
    );
    if (index != -1) {
      this.setState({ userbalance: this.state.balancedetails[index].balance });
    }

    var fiatcur = [];
    this.state.currencydetails.map((details, index) => {
      if (details.currencySymbol != selectedCurrencyType.value) {
        fiatcur.push({
          value: details.currencySymbol,
          label: details.currencySymbol,
        });
      }
    });

    this.setState({ posttooptions: fiatcur });
  };

  handleposttocurrency = (selectedtocpostcurrency) => {
    this.setState({ selectedtocpostcurrency });
    this.setState({ posttocurrency: selectedtocpostcurrency.value });

    var pair = this.state.currencyselected + selectedtocpostcurrency.value;
    var tempprice = 0;
    var curindex = this.state.spotpairsdata.findIndex(
      (x) => x.tiker_root === pair
    );
    if (curindex != -1) {
      tempprice = this.state.spotpairsdata[curindex].index_price;
      this.setState({
        curmarketprice: this.state.spotpairsdata[curindex].index_price,
      });
      this.setState({
        transactionfee: this.state.spotpairsdata[curindex].transactionfee,
      });
    }
  };
  onSelectCountry = (countryCode) => {
    this.setState({ country: countryCode });
  };

  onChange = (e) => {
    if (e.target.id == "terms" || e.target.id == "editterms") {
      if (e.target.value.length > 1000) {
        store.addNotification({
          title: "Error!",
          message: "Only Allowed 1000 Character",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        return false;
      } else {
        this.setState({ [e.target.id]: e.target.value });
        if (e.target.id == "marginOffer") {
          if (this.state.tradetype == "Buy") {
            var margin = e.target.value / 100 - 1;
            var priceEquation = this.state.curmarketprice * margin;
            this.setState({ posttradeprice: Math.abs(priceEquation) });
          } else if (this.state.tradetype == "Sell") {
            var margin = e.target.value / 100 + 1;
            var priceEquation = this.state.curmarketprice * margin;
            this.setState({ posttradeprice: Math.abs(priceEquation) });
          }
        }
      }
    } else {
      this.setState({ [e.target.id]: e.target.value });
      if (e.target.id == "marginOffer") {
        if (this.state.tradetype == "Buy") {
          var margin = e.target.value / 100 - 1;
          var priceEquation = this.state.curmarketprice * margin;
          this.setState({ posttradeprice: Math.abs(priceEquation) });
        } else if (this.state.tradetype == "Sell") {
          var margin = e.target.value / 100 + 1;
          var priceEquation = this.state.curmarketprice * margin;
          this.setState({ posttradeprice: Math.abs(priceEquation) });
        }
      }
    }
  };

  // Post forward to next step
  PostTrade = () => {
    let userid = this.props.auth.user.id;
    var message = "";

    if (userid == "" || userid == undefined) {
      message = "Please Login to post ads";
    } else if (this.state.currencyselected == "") {
      message = "Please Select a Cyprocurreny would like to trade";
    } else if (this.state.posttocurrency == "") {
      message = "Please Select a local currency";
    } else if (this.state.postminlimit == "") {
      message = "Please Enter the Min Limit";
    } else if (this.state.postminlimit <= 0) {
      message = "Please Enter the Min Limit greater then Zero";
    } else if (this.state.postmaxlimit <= 0) {
      message = "Please Enter the Max Limit greater then Zero";
    } else if (isNaN(this.state.postminlimit) == true) {
      message = "Please Enter the Min Limit number only";
    } else if (this.state.postmaxlimit == "") {
      message = "Please Enter the Max Limit";
    } else if (isNaN(this.state.postmaxlimit) == true) {
      message = "Please Enter the Max Limit number only";
    } else if (this.state.marginOffer == "") {
      message = "Please Enter the Margin Limit";
    } else if (isNaN(this.state.marginOffer) == true) {
      message = "Please Enter the Margin Limit number only";
    } else if (this.state.postminlimit != "" && this.state.postmaxlimit != "") {
      if (
        parseFloat(this.state.postminlimit) >
        parseFloat(this.state.postmaxlimit)
      ) {
        message = "Please Enter the correct Minimum Value";
      }
    }
    if (this.state.selectedpreferred.length > 2) {
      message = "Please select maximum 2 Preferred Transfer";
    }
    if (message != "") {
      store.addNotification({
        title: "Error!",
        message: message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      return false;
    } else {
      this.setState({
        tab0visible: "nav-link",
        tab1visible: "nav-link",
        tab2visible: "nav-link active",
        tab3visible: "nav-link",
        tab0content: "tab-pane container fade ",
        tab1content: "tab-pane container fade ",
        tab2content: "tab-pane container fade active show",
        tab3content: "tab-pane container fade",
      });
      this.setState({ postDetails: true });
      const data = this.state;
    }
  };

  handleonchange = (e) => {
    this.setState({ aggreeterms: !this.state.aggreeterms });
    this.setState({ onetermerr: "" });
  };

  PostDetails = () => {
    let userid = this.props.auth.user.id;
    var message = "";
    if (userid == "" || userid == undefined) {
      message = "Please Login to post ads";
    } else if (this.state.title == "") {
      message = "Please enter the title of the post";
    } else if (this.state.terms == "") {
      message = "Please enter terms of the trade";
    }
    var flag = 0;
    if (!this.state.monEndTime != "" && this.state.monStartTime != "") {
      message = "Please select the proper start and end time for Monday";
    }
    if (!this.state.sunEndTime != "" && this.state.sunStartTime != "") {
      message = "Please select the proper start and end time for Sunday";
    }
    if (!this.state.wedEndTime != "" && this.state.wedStartTime != "") {
      message = "Please select the proper start and end time for Wednesday";
    }
    if (!this.state.thuEndTime != "" && this.state.thuStartTime != "") {
      message = "Please select the proper start and end time for Thursday";
    }
    if (!this.state.friEndTime != "" && this.state.friEndTime != "") {
      message = "Please select the proper start and end time for Friday";
    }
    if (!this.state.satEndTime != "" && this.state.satStartTime != "") {
      message = "Please select the proper start and end time for Saturday";
    }
    if (!this.state.tueEndTime != "" && this.state.tueStartTime != "") {
      message = "Please select the proper start and end time for Tuesday";
    }

    if (this.state.sunEndTime != "" && this.state.sunStartTime != "") {
      flag = 1;
    } else if (this.state.monEndTime != "" && this.state.monStartTime != "") {
      // message = "Please select the proper start and end time for Monday";
      flag = 1;
    } else if (this.state.tueEndTime != "" && this.state.tueStartTime != "") {
      // message = "Please select the proper start and end time for Tuesday";
      flag = 1;
    } else if (this.state.wedEndTime != "" && this.state.wedStartTime != "") {
      // message = "Please select the proper start and end time for Wednesday";
      flag = 1;
    } else if (this.state.thuEndTime != "" && this.state.thuStartTime != "") {
      // message = "Please select the proper start and end time for Thursday";
      flag = 1;
    } else if (this.state.friEndTime != "" && this.state.friStartTime != "") {
      // message = "Please select the proper start and end time for Friday";
      flag = 1;
    } else if (this.state.satEndTime != "" && this.state.satStartTime != "") {
      // message = "Please select the proper start and end time for Saturday";
      flag = 1;
    }
    if (!flag) {
      message =
        "Please select the proper start and end time for atleast one day";
    }
    if (this.state.tradeWith == "") {
      message = "Please select the open trade";
    }

    if (message != "") {
      store.addNotification({
        title: "Error!",
        message: message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      return false;
    } else {
      this.setState({ postSummary: true });
      this.setState({
        tab0visible: "nav-link",
        tab1visible: "nav-link",
        tab2visible: "nav-link",
        tab3visible: "nav-link active",
        tab0content: "tab-pane container fade",
        tab1content: "tab-pane container fade ",
        tab2content: "tab-pane container fade",
        tab3content: "tab-pane container fade active show",
      });
      const data = this.state;
    }
  };

  offerCategory = () => {
    this.setState({ offerCategory: true });
    this.setState({
      tab0visible: "nav-link",
      tab1visible: "nav-link active",
      tab2visible: "nav-link",
      tab3visible: "nav-link",
      tab0content: "tab-pane container fade",
      tab1content: "tab-pane container fade active show",
      tab2content: "tab-pane container fade",
      tab3content: "tab-pane container fade",
    });
  };
  changePage = () => {
    window.location.href = "/ProfileDetail";
  };

  //   Submit post
  SubmitPosttrade = () => {
    if (!this.state.aggreeterms) {
      this.setState({ onetermerr: "Please Aggree our Policy" });
      return;
    }
    //alert(this.state.BuyorSell);
    this.setState({ placorder_disable: true, post_trade: true });
    let userid = this.props.auth.user.id;
    var passData = {};
    passData.userId = userid;
    passData._csrf = this.state._csrf;
    passData.fromcurrency = this.state.currencyselected;
    passData.tocurrency = this.state.posttocurrency;
    passData.posttradeprice = this.state.posttradeprice;
    passData.type = this.state.tradetype;
    passData.minlimit = this.state.postminlimit;
    passData.maxlimit = this.state.postmaxlimit;
    passData.margin = this.state.margin;
    passData.postcheckboxaccept = this.state.postcheckboxaccept;
    passData.postprefcurrency = this.state.selectedpreferred;
    passData.bankselected = this.state.bankselected;
    passData.title = this.state.title;
    passData.terms = this.state.terms;
    passData.tradeWith = this.state.tradeWith;
    passData.percentagetype = this.state.percentagetype;
    passData.sunStartTime = this.state.sunStartTime;
    passData.sunEndTime = this.state.sunEndTime;
    passData.monStartTime = this.state.monStartTime;
    passData.monEndTime = this.state.monEndTime;
    passData.tueStartTime = this.state.tueStartTime;
    passData.tueEndTime = this.state.tueEndTime;
    passData.wedStartTime = this.state.wedStartTime;
    passData.wedEndTime = this.state.wedEndTime;
    passData.thuStartTime = this.state.thuStartTime;
    passData.thuEndTime = this.state.thuEndTime;
    passData.friStartTime = this.state.friStartTime;
    passData.friEndTime = this.state.friEndTime;
    passData.satStartTime = this.state.satStartTime;
    passData.satEndTime = this.state.satEndTime;
    passData.country = this.state.country;

    axios.post(url + "tradeapi/posttrade", passData).then((res) => {
      if (res.data.status) {
        store.addNotification({
          title: "Wonderful!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        this.getBalance();
        // this.getmyads();

        this.setState({ submitreview: !this.state.submitreview });
        setTimeout(() => {
          this.changePage();
        }, 1500);
      } else {
        store.addNotification({
          title: "Error!",
          message: res.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      }
      this.setState({ placorder_disable: false });
    });
  };
  // Reverse post
  backPostDetails = () => {
    this.setState({
      tab0visible: "nav-link",
      tab1visible: "nav-link",
      tab2visible: "nav-link active",
      tab3visible: "nav-link",
      tab0content: "tab-pane container fade",
      tab1content: "tab-pane container fade",
      tab2content: "tab-pane container fade active show",
      tab3content: "tab-pane container fade",
    });
  };
  backOfferCategory = () => {
    this.setState({
      tab0visible: "nav-link",
      tab1visible: "nav-link active",
      tab2visible: "nav-link",
      tab3visible: "nav-link",
      tab0content: "tab-pane container fade ",
      tab1content: "tab-pane container fade active show",
      tab2content: "tab-pane container fade ",
      tab3content: "tab-pane container fade",
    });
  };
  render() {
    const { selectedMonStartTime } = this.state;
    const { selectedMonEndTime } = this.state;
    const { selectedTradeHours } = this.state;
    const { selectedTradeTime } = this.state;
    const { tradeDay } = this.state;
    const { selectedCoinPair } = this.state;
    const { selectedFiatType } = this.state;
    const { plainArray, transferType, selectedValues } = this.state;
    const { errors } = this.state;

    const {
      selectedCurrencyType,
      selectedtocpostcurrency,
      selectedTradeWith,
      selectedpreferred,
      selectedDepositFrom,
      postprefoption,
      postprefoption_find,
    } = this.state;
    return (
      <div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <div className="userCommonCard">
              {/* <div className="tab-content px-3 px-sm-0 pt-3" id="main-nav-tabContent"> */}
              <div class="tab-content">
                <div className="breadCrumb">
                  <ul class="nav " role="tablist">
                    <li class="nav-item">
                      <span className="blueText">Create New Trade</span>
                    </li>
                    {this.state.offerCategory == true ? (
                      <>
                        <li className="bcArrow">
                          <i class="fas fa-angle-right"></i>
                        </li>
                        <li class="nav-item">
                          <span className="blueText">Offer Category</span>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.postDetails == true ? (
                      <>
                        <li className="bcArrow">
                          <i class="fas fa-angle-right"></i>
                        </li>
                        <li class="nav-item">
                          <span className="blueText">Post Details</span>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.postSummary == true ? (
                      <>
                        <li className="bcArrow">
                          <i class="fas fa-angle-right"></i>
                        </li>
                        <li class="nav-item">
                          <span className="blueText">Post Summary</span>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                <div class={this.state.tab0content} id="home">
                  <div className="userBoxWhiteBox securityBox">
                    <p className="postTrdeDetails">
                      Creating an offer will allow you to set your own rate and
                      terms, and enjoy low fee.
                    </p>
                    <div className="form-group text-left mt-4">
                      {/* <a className="btn btnBlue py-2" href="/OfferCategory">Start to Create New Post</a> */}
                      <ul class="nav " role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link btn btnBlue py-2"
                            data-toggle="tab"
                            onClick={this.offerCategory}
                          >
                            Start to Create New Post
                          </a>
                        </li>
                      </ul>
                      {/* <button type="button" className="btn btnBlue py-2">Start to Create New Post</button> */}
                    </div>
                  </div>
                </div>
                <div class={this.state.tab1content} id="menu1">
                  <div className="userBoxWhiteBox securityBox">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="ocTradeDetails">
                          <h3 class="userBoxUnderlineTitle">Trade Details</h3>
                          <div className="form-group">
                            <label>
                              What type of offer do you want to create?
                            </label>
                            <div className="dashRadio">
                              <div class="radio mr-4">
                                <label>
                                  <input
                                    type="radio"
                                    name="o2"
                                    value="Buy"
                                    checked={this.state.buycheckbox}
                                    id="BuyorSell_find"
                                    onChange={this.RadiobuttonChange_buy}
                                  />
                                  <span class="cr">
                                    <i class="cr-icon fa fa-circle"></i>
                                  </span>
                                  Buy Crypto
                                </label>
                              </div>
                              <div class="radio">
                                <label>
                                  <input
                                    type="radio"
                                    name="o2"
                                    value="Sell"
                                    checked={this.state.sellcheckbox}
                                    id="BuyorSell_find"
                                    onChange={this.RadiobuttonChange_sell}
                                  />
                                  <span class="cr">
                                    <i class="cr-icon fa fa-circle"></i>
                                  </span>
                                  Sell Crypto
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label>
                              What Cyprocurreny would like to trade?
                            </label>
                            <Select
                              options={this.state.currencyoptions}
                              value={selectedCurrencyType}
                              onChange={this.handleCurrencyType}
                            />
                          </div>
                          <div class="form-group">
                            <label>Location to display the post?</label>
                            <ReactFlagsSelect
                              id="country"
                              searchable
                              onSelect={this.onSelectCountry}
                              placeholder="Select a country"
                              selected={this.state.country}
                            />
                          </div>
                          <div class="form-group">
                            <label>Currency would like to trade?</label>
                            <Select
                              value={selectedtocpostcurrency}
                              onChange={this.handleposttocurrency}
                              options={this.state.fiatcuroption}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="ocPaymentDetails">
                          <h3 class="userBoxUnderlineTitle">Payment Details</h3>
                          <div class="row">
                            <div class="col-lg-6">
                              <div className="form-group">
                                <label>Minimum</label>
                                <div class="input-group">
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={this.onChange}
                                    value={this.state.postminlimit}
                                    error={errors.postminlimit}
                                    id="postminlimit"
                                  />
                                  <span className="text-danger">
                                    {errors.postminlimit}
                                  </span>
                                  <div class="input-group-append">
                                    <span class="input-group-text">
                                      {this.state.posttocurrency}
                                    </span>
                                  </div>
                                  {/* <input type="text" class="form-control" value="50.00" id="secondamount" />
												<div class="input-group-append"><span class="input-group-text">USD</span></div>
												*/}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div className="form-group">
                                <label>Maximum</label>
                                <div class="input-group">
                                  <input
                                    className="form-control"
                                    type="tex"
                                    onChange={this.onChange}
                                    value={this.state.postmaxlimit}
                                    error={errors.postmaxlimit}
                                    id="postmaxlimit"
                                  />
                                  <span className="text-danger">
                                    {errors.postmaxlimit}
                                  </span>
                                  <div class="input-group-append">
                                    <span class="input-group-text">
                                      {this.state.posttocurrency}
                                    </span>
                                  </div>
                                  {/* <input type="text" class="form-control" value="10000.00" id="secondamount" />
												<div class="input-group-append"><span class="input-group-text">USD</span></div>
												*/}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label>
                              Which payment methods do you want to accept?
                            </label>
                            <Select
                              isMulti
                              onChange={this.handlepostpreferred}
                              options={this.state.preferredtransfer}
                            />
                            {/* 
									<Multiselect options={transferType} isObject={false} />
									*/}
                          </div>
                          <div className="form-group">
                            <label>What margin do you want to offer?</label>
                            <div class="input-group">
                              <input
                                className="form-control"
                                type="number"
                                size="number"
                                onChange={this.onChange}
                                value={this.state.marginOffer}
                                error={errors.marginOffer}
                                id="marginOffer"
                              />
                              {/* <input type="text" class="form-control" value="2.5" id="secondamount" /> */}
                              <div class="input-group-append">
                                <span class="input-group-text">%</span>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Price equation</label>
                            <div class="input-group">
                              <input
                                className="form-control"
                                type="number"
                                size="number"
                                onChange={this.onChange}
                                value={this.state.posttradeprice}
                                error={errors.posttradeprice}
                                id="posttradeprice"
                                disabled
                              />
                              {/* <input type="text" class="form-control" value="2.5" id="secondamount" /> */}
                              {/* <div class="input-group-append"><span class="input-group-text">%</span></div> */}
                            </div>
                          </div>
                          {this.state.posttocurrency &&
                            this.state.currencyselected && (
                              <h5>
                                Current Market Price is{" "}
                                <span>
                                  {this.state.curmarketprice &&
                                    this.state.curmarketprice}
                                </span>{" "}
                                {this.state.posttocurrency} / per{" "}
                                {this.state.currencyselected}
                              </h5>
                            )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group text-center">
                          <label>
                            Transaction Fee for Seller :{" "}
                            {this.state.transactionfee}%
                          </label>
                          <br />
                          <button
                            type="button"
                            className="btn btnBlue py-2"
                            onClick={this.PostTrade}
                          >
                            Next <i class="fas fa-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={this.state.tab2content} id="menu2">
                  <div className="userBoxWhiteBox securityBox">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ocTradeDetails">
                          <h3 class="userBoxUnderlineTitle">Post Details</h3>
                          <div class="form-group">
                            <label>
                              What do you want the title of your offer to be?
                            </label>
                            {/* <input type="text" class="form-control" value="" /> */}
                            <input
                              className="form-control"
                              type="tex"
                              onChange={this.onChange}
                              value={this.state.title}
                              error={errors.title}
                              id="title"
                            />
                          </div>
                          <div class="form-group">
                            <label>
                              What do you want to publish in your terms of
                              trade?
                            </label>
                            {/* <textarea row="3" class="form-control"></textarea> */}
                            <textarea
                              rows="3"
                              id="terms"
                              name="terms"
                              className="form-control"
                              value={this.state.terms}
                              onChange={this.onChange}
                            ></textarea>
                          </div>
                          <div class="form-group">
                            <label>
                              Which hours of the day will you be available to
                              trade?
                            </label>
                            <div className="row mb-2">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="Sunday"
                                  disabled
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="your-custom-id"
                                  // value={selectedMonStartTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("sunStartTime", e)
                                  }
                                  options={startTimeOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="endMon"
                                  // value={selectedMonEndTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("sunEndTime", e)
                                  }
                                  options={endTimeOptions}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="Monday"
                                  disabled
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="your-custom-id"
                                  // value={selectedMonStartTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("monStartTime", e)
                                  }
                                  options={startTimeOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="endMon"
                                  // value={selectedMonEndTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("monEndTime", e)
                                  }
                                  options={endTimeOptions}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="Tuesday"
                                  disabled
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="your-custom-id"
                                  // value={selectedMonStartTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("tueStartTime", e)
                                  }
                                  options={startTimeOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="endMon"
                                  // value={selectedMonEndTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("tueEndTime", e)
                                  }
                                  options={endTimeOptions}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="Wednesday"
                                  disabled
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="your-custom-id"
                                  // value={selectedMonStartTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("wedStartTime", e)
                                  }
                                  options={startTimeOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="endMon"
                                  // value={selectedMonEndTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("wedEndTime", e)
                                  }
                                  options={endTimeOptions}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="Thursday"
                                  disabled
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="your-custom-id"
                                  // value={selectedMonStartTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("thuStartTime", e)
                                  }
                                  options={startTimeOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="endMon"
                                  // value={selectedMonEndTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("thuEndTime", e)
                                  }
                                  options={endTimeOptions}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="Friday"
                                  disabled
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="your-custom-id"
                                  // value={this.state.friStartTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("friStartTime", e)
                                  }
                                  options={startTimeOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="endMon"
                                  // value={selectedMonEndTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("friEndTime", e)
                                  }
                                  options={endTimeOptions}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  value="Saturday"
                                  disabled
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="your-custom-id"
                                  // value={selectedMonStartTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("satStartTime", e)
                                  }
                                  options={startTimeOptions}
                                />
                              </div>
                              <div className="col-md-4">
                                <Select
                                  id="endMon"
                                  // value={selectedMonEndTime}
                                  onChange={(e, index) =>
                                    this.handleTimeChange("satEndTime", e)
                                  }
                                  options={endTimeOptions}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label>Who can open a trade with you?</label>
                            {/* <Multiselect options={tradeOpen} isObject={false} /> */}
                            <Select
                              value={
                                tradeOpen &&
                                tradeOpen.filter(
                                  (option) =>
                                    option.label === this.state.tradeWith
                                )
                              }
                              // value={selectedTradeWith}
                              onChange={this.handleTradeWith}
                              options={tradeOpen}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group text-center">
                          <button
                            type="button"
                            className="btn btnBlueBordered py-2 mr-3"
                            onClick={this.backOfferCategory}
                          >
                            <i class="fas fa-chevron-left"></i> Back
                          </button>
                          <button
                            type="button"
                            className="btn btnBlue py-2"
                            onClick={this.PostDetails}
                          >
                            Next <i class="fas fa-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={this.state.tab3content} id="menu3">
                  <div className="userBoxWhiteBox securityBox">
                    <h3 class="userBoxUnderlineTitle">
                      Your Trade Post Summary
                    </h3>
                    <div className="dashAccountTrade">
                      <ul>
                        <li>
                          <label>Type</label>
                          <span>
                            {this.state.tradetype}{" "}
                            <span className="strong">
                              {this.state.currencyselected}
                            </span>
                          </span>
                        </li>
                        <li>
                          <label>Location</label>
                          <span>{getName(this.state.country)}</span>
                        </li>
                        <li>
                          <label>Payment Method</label>
                          {this.state.postprefcurrency &&
                          this.state.postprefcurrency.length
                            ? this.state.postprefcurrency.map(
                                (postprefcurrency) => (
                                  <span> {postprefcurrency}, </span>
                                )
                              )
                            : "-"}
                          {/* <span>{this.state.postprefcurrency}</span> */}
                        </li>
                        <li>
                          <label>Rate</label>
                          <span>
                            Current crypto market rate +{this.state.marginOffer}
                            % margin
                          </span>
                        </li>
                        <li>
                          <label>Post Title</label>
                          <span>
                            <span className="strong italics">
                              “{this.state.title}”
                            </span>
                          </span>
                        </li>
                        <li>
                          <label>Terms of the trade</label>
                          <span className="longPara">
                            <span className="mb-3 d-block">
                              {this.state.terms}
                            </span>
                            {/* <span className="d-block">If you have not done KYC with localbitcoins, I may ask you for the ID.</span> */}
                          </span>
                        </li>
                        <li>
                          <label>Limit Minimum & Maximum</label>
                          <span>
                            <span className="strong">
                              {this.state.posttocurrency}
                              {this.state.postminlimit} -{" "}
                              {this.state.posttocurrency}
                              {this.state.postmaxlimit}
                            </span>
                          </span>
                        </li>
                        <li>
                          <label>Time Duration</label>
                          <span>
                            {this.state.sunStartTime != "" &&
                            this.state.sunEndTime != "" ? (
                              <span>
                                Sunday -{this.state.sunStartTime}-
                                {this.state.sunEndTime}
                              </span>
                            ) : null}{" "}
                            {this.state.monStartTime != "" &&
                            this.state.monEndTime != "" ? (
                              <span>
                                Monday -{this.state.monStartTime}-
                                {this.state.monEndTime}
                              </span>
                            ) : null}{" "}
                            {this.state.tueStartTime != "" &&
                            this.state.tueEndTime != "" ? (
                              <span>
                                Tuesday -{this.state.tueStartTime}-
                                {this.state.tueEndTime}
                              </span>
                            ) : null}{" "}
                            {this.state.wedStartTime != "" &&
                            this.state.wedEndTime != "" ? (
                              <span>
                                Wednesday -{this.state.wedStartTime}-
                                {this.state.wedEndTime}
                              </span>
                            ) : null}{" "}
                            {this.state.thuStartTime != "" &&
                            this.state.thuEndTime != "" ? (
                              <span>
                                Thursday -{this.state.thuStartTime}-
                                {this.state.thuEndTime}
                              </span>
                            ) : null}{" "}
                            {this.state.friStartTime != "" &&
                            this.state.friEndTime != "" ? (
                              <span>
                                Friday -{this.state.friStartTime}-
                                {this.state.friEndTime}
                              </span>
                            ) : null}{" "}
                            {this.state.satStartTime != "" &&
                            this.state.satEndTime != "" ? (
                              <span>
                                Saturday -{this.state.satStartTime}-
                                {this.state.satEndTime}
                              </span>
                            ) : null}
                          </span>
                        </li>
                        <li>
                          <label>Trade View</label>
                          <span>{this.state.tradeWith}</span>
                        </li>
                        {this.state.tradetype == "Sell" && (
                          <li>
                            <label>Seller Fee </label>
                            <span>{this.state.transactionfee}%</span>
                          </li>
                        )}
                        <div className="form-group remForAction mt-4">
                          <div className="checkbox d-block w-100">
                            <label className="postADAgreement flexJustifyCenter">
                              <input
                                id="aggreeterms"
                                type="checkbox"
                                checked={this.state.aggreeterms}
                                onChange={this.handleonchange}
                              />
                              <span className="cr">
                                <i className="cr-icon fa fa-check"></i>
                              </span>
                              <span className="agreeContent">
                                I agree to Escrow my crypto funds and accept
                                their <a href="/terms">Terms</a> and{" "}
                                <a href="/privacy">Policy</a>
                              </span>
                              <br />{" "}
                              <span className="text-danger">
                                {this.state.onetermerr}
                              </span>
                            </label>
                          </div>
                        </div>
                      </ul>
                      <div className="form-group text-center mt-4">
                        <button
                          className="btn btnBlueBordered py-2 mr-3"
                          onClick={this.backPostDetails}
                          disabled={this.state.post_trade}
                        >
                          <i class="fas fa-chevron-left"></i> Edit
                        </button>
                        <button
                          className="btn btnBlue py-2"
                          onClick={this.SubmitPosttrade}
                          disabled={this.state.post_trade}
                        >
                          Submit My Post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

PostTrade.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(PostTrade));
