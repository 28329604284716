import React, { Component } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";
import * as moment from "moment";
import axios from "axios";
import { store } from "react-notifications-component";
import keys, { numberWithCommas } from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "jquery";
import ReactDatatable from "@ashvin27/react-datatable";
import jsPDF from "jspdf";
import "jspdf-autotable";

const url = keys.baseUrl;

const preferredCrypto = [
  { value: "BTC - Bitcoin", label: "BTC - Bitcoin" },
  { value: "ETH - Ethereum", label: "ETH - Ethereum" },
  { value: "USDT - Teather", label: "USDT - Teather" },
  { value: "XRP - Ripple", label: "XRP - Ripple" },
];
const preferredCryptoShort = [
  { value: "BTC", label: "BTC" },
  { value: "ETH", label: "ETH" },
  { value: "USDT", label: "USDT" },
  { value: "XRP", label: "XRP" },
];
const preferredRecords = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "All", label: "All" },
];

const preferredType = [
  { value: "Deposit", label: "Deposit" },
  { value: "Withdraw", label: "Withdraw" },
  // { value: 'All', label: 'All' },
];

class WalletDepositWithdraw extends Component {
  constructor() {
    super();
    this.depositColumns = [
      {
        key: "created",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{moment(record.created).format("DD-MM-YYYY  k:mm:s ")}</p>;
        },
      },
      {
        key: "depType",
        text: "Type",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          const transferType = record && record.transferType;
          if (transferType != "Withdraw") {
            var lockVal = "Deposit";
          } else if (transferType == "Withdraw") {
            var lockVal = "Withdraw";
          }
          return <p>{lockVal}</p>;
        },
      },
      {
        key: "currencySymbol",
        text: "Fiat currency",
        className: "currencySymbol",
        align: "left",
        sortable: true,
      },
      {
        key: "bankaccountno",
        text: "Bank account number",
        className: "Bankaccountnumber",
        align: "left",
        sortable: true,
      },
      {
        key: "amount",
        text: "Amount",
        className: "Amount",
        align: "left",
        sortable: true,
      },
      {
        key: "status",
        text: "Status",
        className: "Ip",
        align: "left",
        sortable: true,
      },
    ];
    this.depositConfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };
    this.withdrawColumns = [
      {
        key: "created",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>{moment(record.created_date).format("DD-MM-YYYY  k:mm:s ")}</p>
          );
        },
      },
      {
        key: "_id",
        text: "Transaction ID",
        className: "Transaction ID",
        align: "left",
        sortable: true,
      },

      {
        key: "Amount",
        text: "Amount",
        className: "Amount",
        align: "left",
        sortable: true,
      },
      {
        key: "transferType",
        text: "Type",
        className: "Ip",
        align: "left",
        sortable: true,
      },
      {
        key: "status",
        text: "Status",
        className: "Ip",
        align: "left",
        sortable: true,
      },
    ];
    this.withdrawConfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };
    this.state = {
      icon: "",
      selectedPhoneCode: null,
      selectedbankName: null,
      selectedDepositFrom: null,
      selectedWithdrawTo: null,
      selectedCurrencyType: null,
      selectedcountry: { label: "Nigeria", value: "Nigeria" },
      firstname: "",
      lastname: "",
      email: "",
      _id: "",
      emailotp: "",
      mobileotp: "",
      errors: {},
      phone: "",
      birthDate: "",
      address: "",
      city: "",
      postalcode: "",
      country: "",
      usernameold: "",
      totallikes: 0,
      kycstatus: "",
      createdDate: new Date(),
      ratings: 0,
      emailverified: false,
      mobilenumber: "",
      accountno: "",
      ifsccode: "",
      bankname: "",
      bankdetails: [],
      ViewBankAccount: false,
      currencydetails: [],
      bankdboptions: "",
      adminbankdetails: {},
      tocurrency: "",
      selectedadmin: [],
      toifsccode: "",
      tobankaccountno: "",
      tobankname: "",
      bankselected: "",
      depositamount: "",
      history: [],
      withdrawcurrency: "",
      walletbalance: 0,
      amount: 0,
      tfacode: "",
      balancedetails: [],
      googlestatus: "Disabled",
      withdrawtobank: "",
      withdrawHistory: [],
      depositHistory: [],
      activetab: "",
      Skeletonwith: 0,
      completed_trades: 0,
      kycstatus: "Not Verified",
      _csrf: "",
      selectedPreferredCrypto: null,
      selectedPreferredShort: null,
      selectedPreferredRecords: null,
      selectedPreferredType: null,
      Allhistory: [],
      withdrawShowing: false,
      depositShowing: true,
      preferredTypeVal: "Deposit",
    };
  }

  // Initial values getting
  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    this.getCSRFtoken();
    this.getData();
    // this.gettrade();
    this.getAdminbank();
    this.getbankdetails();
    this.getCurrency();
    this.getdeposithistory();
    this.getAssetData();
    this.getWithdrawhistory();
    // this.getdepositwithdrawhistory();
    var ctab = this.props.match.params.id;

    console.log("ctabctabctabctabctabctab", ctab);
    this.setState({ activetab: ctab });
  }
  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
      })
      .catch();
  };

  getData() {
    let userid = this.props.auth.user.id;

    var ctab = this.props.match.params.id;

    if (ctab == "deposit" || ctab == "withdraw") {
      let userid = this.props.auth.user.id;
      axios.get(url + "cryptoapi/userget/" + userid).then((res) => {
        if (res.data.kycstatus != "Approved") {
          this.setState({ kycstatus: res.data.kycstatus });
          store.addNotification({
            title: "Error!",
            message:
              "You need to update your verification status to continue using Aya",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });

          this.props.history.push("/TierVerification/");
        }
      });
    }

    axios
      .get(url + "cryptoapi/userget/" + userid)
      .then((res) => {
        if (res.status == 200) {
          var total_ratings =
            res.data.positive_ratings + res.data.negative_ratings;
          if (total_ratings > 0) {
            var avg_positive_ratings =
              (res.data.positive_ratings / total_ratings) * 100;
          } else {
            var avg_positive_ratings = 0;
          }
          var negative_ratings = 100 - avg_positive_ratings;
          this.setState({
            avg_positive_ratings: avg_positive_ratings,
            negative_ratings: negative_ratings,
          });

          this.setState({
            userid: res.data.userid,
            name: res.data.name,
            otp: res.data.otp,
            firstname: res.data.firstname,
            middlename: res.data.middlename,
            lastname: res.data.lastname,
            phone: res.data.phonenumber,
            email: res.data.email,
            emailverified: res.data.emailverified,
            username: res.data.username,
            usernameold: res.data.username,
            mobilenumber: res.data.phonenumber,
            createdDate: res.data.date,
            googlestatus: res.data.google,
            totallikes: res.data.totallikes,
            icon: res.data.icon,
            kycstatus: res.data.kycstatus,
            // IdentityVerification:res.data.kycstatus=="Approved"?"KYC Verified":res.data.kycstatus=="Rejected"?"Declined":"None"
          });

          //this.setState(res.data);

          this.props.auth.user.name = res.data.name;
        }
      })
      .catch();
  }

  getAssetData() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      this.setState({ balancedetails: res.data.data });
      var index = this.state.balancedetails.findIndex(
        (x) => x.currencySymbol === this.state.activetab
      );
      console.log(
        "res.datares.data",
        this.state.bankdetails && this.state.balancedetails
      );
      if (index != -1) {
        this.setState({
          walletbalance: this.state.balancedetails[index].balance,
        });
      }
    });
  }

  getAdminbank() {
    axios.post(url + "api/adminbank-data").then((res) => {
      console.log("adiminn bankdatail", res.data, this.state.activetab);
      if (res.data != undefined) {
        let result = res.data;
        result &&
          result.length > 0 &&
          result.map((details, index) => {
            console.log(
              "adiminn bankdatail",
              details.currencyId.currencySymbol
            );

            if (
              details &&
              details.currencyId &&
              details.currencyId.currencySymbol == this.state.activetab
            ) {
              this.setState({ adminbankdetails: details });
            }
          });
      }
    });
  }
  getCurrency() {
    const data = {
      _csrf: this.state._csrf,
    };
    axios.get(url + "api/currency").then((res) => {
      this.setState({ currencydetails: res.data });
      var firstcur = res.data;
      var newcurar = [];
      firstcur.map((details, index) => {
        if (details.type == "Fiat") {
          newcurar.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });

      this.setState({ currencyoptions: newcurar });
    });
  }
  getbankdetails() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getbankdetails/" + userid).then((res) => {
      this.setState({ bankdetails: res.data });
      var tempbank = res.data;
      var addressselect = [];

      for (var i = 0; i < tempbank.length; i++) {
        var from_val = {
          value:
            tempbank[i].Accountno +
            "-" +
            tempbank[i].country +
            "-" +
            tempbank[i]._id +
            "-" +
            tempbank[i].BankName,
          label: tempbank[i].Accountno + " - " + tempbank[i].BankName,
        };
        addressselect.push(from_val);
      }
      this.setState({ bankdboptions: addressselect });
    });
  }

  // History details
  getdeposithistory() {
    let userid = this.props.auth.user.id;
    var postdata = {
      userid: this.props.auth.user.id,
    };
    axios
      .get(url + "cryptoapi/getUserDepositData/" + userid)
      .then((res) => {
        if (typeof res.data.history != "undefined") {
          var history = res.data.history;
          this.setState({ depositHistory: history, duplicatehistory: history });
        }
      })
      .catch();
  }
  // transferType
  // depType
  getWithdrawhistory() {
    const id = this.props.auth.user.id;

    this.setState({ Skeletonwith: 1 });
    axios.get(url + "cryptoapi/fiatdeposithistory/" + id).then((res) => {
      this.setState({ withdrawHistory: res.data, duplicatehistory: res.data });
      this.setState({ Skeletonwith: 0 });
    });
  }

  // Deposit selection changes
  handleDepositFrom = (selectedDepositFrom) => {
    this.setState({ selectedDepositFrom });
    this.setState({ bankselected: selectedDepositFrom.value });
    var tempcountry = selectedDepositFrom.value;
    const totvalue = tempcountry.split("-");
    const country = totvalue[1];
    // adminbankdetails

    // var bankindex = this.state.adminbankdetails.findIndex(
    //   (x) => x.Country === country
    // );
    // if (bankindex != -1) {
    //   console.log("this.state.adminbankdetailsthis.state.adminbankdetails",this.state.adminbankdetails,bankindex,country)
    //   this.setState({
    //     tocurrency:
    //       this.state.adminbankdetails[bankindex].currencyId.currencySymbol,
    //     toifsccode: this.state.adminbankdetails[bankindex].IFSCcode,
    //     tobankaccountno: this.state.adminbankdetails[bankindex].Accountno,
    //     tobankname: this.state.adminbankdetails[bankindex].BankName,
    //   });
    // }
  };

  handleWithdrawTo = (selectedWithdrawTo) => {
    this.setState({ selectedWithdrawTo });
    this.setState({ withdrawtobank: selectedWithdrawTo.value });
  };
  handleCurrencyType = (selectedCurrencyType) => {
    this.setState({ selectedCurrencyType });
    this.setState({ withdrawcurrency: selectedCurrencyType.value });
    var withdrawcurrency = selectedCurrencyType.value;
    var index = this.state.balancedetails.findIndex(
      (x) => x.currencySymbol === this.state.activetab
    );
    if (index != -1) {
      this.setState({
        walletbalance: this.state.balancedetails[index].balance,
      });
    }
    // walletbalance
  };

  // Deposit form submit
  Depositnow = (e) => {
    const bankdata = {
      id: this.props.auth.user.id,
      bankselected: this.state.bankselected,
      depositamount: this.state.depositamount,
      tobankaccountno: this.state.adminbankdetails.Accountno,
      tocurrency: this.state.activetab,
      _csrf: this.state._csrf,
    };
    axios
      .post(url + "cryptoapi/fiatdeposit", bankdata)
      .then((res) => {
        if (res.data.status) {
          this.getData();
          this.getdeposithistory();
          this.setState({
            accountno: " ",
            depositamount: " ",
          });
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      })
      .catch();
  };

  // Withdraw form submit
  Withdraw() {
    if (this.state.googlestatus == "Enabled") {
      if (this.validateForm()) {
        // if (this.state.twofaactivated) {
        if (
          this.state.walletbalance >= this.state.amount &&
          this.state.amount > 0
        ) {
          // if (this.validateForm()) {

          let fields = {};
          fields["amount"] = "";
          fields["withdrawtobank"] = "";
          fields["tfacode"] = "";
          this.setState({ validation: fields });
          const withdrawdata = {
            id: this.props.auth.user.id,
            amount: this.state.amount,
            tfacode: this.state.tfacode,
            withdrawtobank: this.state.withdrawtobank,
            currency: this.state.activetab,
            _csrf: this.state._csrf,
          };
          axios
            .post(url + "cryptoapi/fiatwithdraw", withdrawdata)
            .then((res) => {
              if (res.data.status) {
                store.addNotification({
                  title: "Wonderful!",
                  message: res.data.message,
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
                this.getAssetData();
                this.getWithdrawhistory();
                this.setState({
                  selectedWithdrawTo: {},
                  selectedCurrencyType: null,
                  walletbalance: "",
                  amount: "",
                  tfacode: "",
                });
              } else {
                store.addNotification({
                  title: "Error!",
                  message: res.data.message,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 1500,
                    onScreen: true,
                  },
                });
              }
            })
            .catch();
        } else {
          store.addNotification({
            message: "Balance is low",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      }
    } else {
      store.addNotification({
        title: "Error!",
        message: "Please Enable 2FA for FIAT Withdraw",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["amount"] = "";
    errors["tfacode"] = "";
    errors["withdrawcurrency"] = "";
    let formIsValid = true;
    if (this.state.amount == "") {
      formIsValid = false;
      errors["amount"] = "*Please enter the  Amount";
    }
    if (this.state.amount <= 0) {
      formIsValid = false;
      errors["amount"] = "*Please enter the  Amount greater then zero";
    }

    if (this.state.activetab == "") {
      formIsValid = false;
      errors["withdrawcurrency"] = "*Please Select the  Currency";
    }

    if (this.state.withdrawtobank == "") {
      formIsValid = false;
      errors["withdrawtobank"] = "*Please select the  Bank";
    }

    if (this.state.tfacode == "") {
      formIsValid = false;
      errors["tfacode"] = "*Please enter the  2FA code";
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  // Pdf generation
  //  Export option functions:
  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    if (this.state.withdrawShowing == true) {
      const title = "Withdraw History";
      const headers = [["Date", "Address", "Amount", "Type", "status"]];
      const data = this.state.withdrawHistory.map((elt) => [
        moment(elt.created_date).format("DD-MM-YYYY  k:mm:s "),
        elt._id,
        elt.Amount,
        elt.transferType,
        elt.status,
      ]);
      let content = {
        startY: 50,
        head: headers,
        body: data,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
    } else if (this.state.depositShowing == true) {
      const title = "Deposit History";
      const headers = [
        [
          "Date",
          "Type",
          "Fiat currency",
          "Bank account number",
          "Amount",
          "status",
        ],
      ];
      const data = this.state.depositHistory.map((elt) => [
        moment(elt.created).format("DD-MM-YYYY  k:mm:s "),
        "Deposit",
        elt.currencySymbol,
        elt.bankaccountno,
        elt.amount,
        elt.status,
      ]);
      let content = {
        startY: 50,
        head: headers,
        body: data,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
    }

    if (this.state.withdrawShowing == true) {
      doc.save("Withdraw-details.pdf");
    } else if (this.state.depositShowing == true) {
      doc.save("Deposit-details.pdf");
    }
  };
  // Onchange for all text field
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  //  state = {
  //     selectedPreferredCrypto: null,
  //     selectedPreferredShort: null,
  //     selectedPreferredRecords: null,
  //     selectedPreferredType: null,
  //   };
  handlePreferredCrypto = (selectedPreferredCrypto) => {
    this.setState({ selectedPreferredCrypto });
  };
  handlePreferredCryptoShort = (selectedPreferredCryptoShort) => {
    this.setState({ selectedPreferredCryptoShort });
  };
  handlePreferredRecords = (selectedPreferredRecords) => {
    this.setState({ selectedPreferredRecords });
  };
  handlePreferredType = (selectedPreferredType) => {
    this.setState({
      selectedPreferredType,
      preferredTypeVal: selectedPreferredType.value,
    });
    if (selectedPreferredType && selectedPreferredType.value == "Deposit") {
      this.setState({ withdrawShowing: false, depositShowing: true });
    } else if (
      selectedPreferredType &&
      selectedPreferredType.value == "Withdraw"
    ) {
      this.setState({ depositShowing: false, withdrawShowing: true });
    }
  };
  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    const { selectedPreferredCrypto } = this.state;
    const { selectedPreferredCryptoShort } = this.state;
    const { selectedPreferredRecords } = this.state;
    const { selectedPreferredType } = this.state;
    const { selectedDepositFrom } = this.state;
    const { selectedWithdrawTo } = this.state;
    const { selectedCurrencyType } = this.state;
    const { errors } = this.state;
    return (
      <div>
        <div class="modal fade" id="withdrawModal">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Confirm Withdraw</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body withdrawConfirmDetails px-5 pb-4">
                <h3>
                  <small>Your withdraw is</small>
                  <span className="font-weight-bold">0.0025878787 BTC</span>
                </h3>
                <h3>
                  <small>Withdraw address is</small>
                  12d1iTgXzFjcSzow59E5adSKLS2KGpMdqv
                </h3>
                <div className="form-group">
                  <label>
                    Enter 6 digit 2FA code (<a href="#">Enable</a> if not enable
                    2FA)
                  </label>
                  <input type="text" class="form-control" />
                </div>
                <div class="form-group text-center">
                  <button class="btn btnBlue">Confirm Withdraw</button>
                </div>
                <div class="form-group text-center mb-0">
                  <button class="btn btnBlueBordered" data-dismiss="modal">
                    Cancel Withdraw
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="userCommonCard">
                  {/* <h2 className="userPageTitle">Deposit</h2>
                  <div className="userBoxWhiteBox depositBox">
                    <div className="form-group">
                      <label>Cyprocurreny</label>
                      <Select
                         value={selectedPreferredCrypto}
                         onChange={this.handlePreferredCrypto}
                         options={preferredCrypto}
                       />
                    </div>
                    <div className="form-group">
                      <label>Your Bitcoin Address</label>
                      <div class="input-group">
                        <input type="text" class="form-control" value="12d1iTgXzFjcSzow59E5adSKLS2KGpMdqv" disabled />
                        <div class="input-group-append">
                          <button className="btn btnType1"><i class="fas fa-copy"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className="qrCodePh text-center">
                      <img src={require("../images/qrCode.jpg")} alt="" className="img-fluid" />
                    </div>
                    <h6>Send only BTC to this deposit address.</h6>
                    <p>Sending coin or token other than BTC to this address may result in the loss of your deposit.</p>
                  </div> */}
                  <div
                    className={
                      this.state.activetab == "deposit"
                        ? "tab-pane fade show active"
                        : "tab-pane fade show"
                    }
                    id="nav-myDeposit"
                    role="tabpanel"
                    aria-labelledby="nav-myDeposit-tab"
                  >
                    <h2 className="userPageTitle">Deposit</h2>
                    <div className="userBoxWhiteBox depositBox">
                      <div className="userPageContent">
                        <div className="pageBox">
                          {/* <h3 className="subPageTitle">Fiat Deposit</h3> */}
                          <div className="accountForm border-top-zero">
                            <div className="form-group">
                              <label>Deposit From</label>
                              <Select
                                value={selectedDepositFrom}
                                onChange={this.handleDepositFrom}
                                options={this.state.bankdboptions}
                              />
                            </div>
                            <div className="form-group">
                              <label className="flexLabel">
                                <span>Amount</span>
                                <span className="labelBalance"></span>
                              </label>
                              <div class="input-group">
                                <input
                                  name="depositamount"
                                  id="depositamount"
                                  type="text"
                                  onChange={this.onChange}
                                  value={this.state.depositamount}
                                  error={errors.depositamount}
                                  className="form-control"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    {this.state.activetab}
                                  </span>
                                </div>
                              </div>
                              <span className="text-danger">
                                {errors.depositamount}
                              </span>
                            </div>
                            {/* <div className="form-group">
                                      <label>Amount</label>
                                      <div className="input-group amountInputGroup">
                                        <input
                                          name="depositamount"
                                          id="depositamount"
                                          type="text"
                                          onChange={this.onChange}
                                          value={this.state.depositamount}
                                          error={errors.depositamount}
                                          className="form-control"
                                        />
                                        <div class="input-group-append">
                                          {this.state.tocurrency}
                                        </div>
                                      </div>
                                      <span className="text-danger">
                                        {errors.depositamount}
                                      </span>
                                    </div> */}
                            <div className="form-group">
                              <label>Deposit To</label>
                              <br />
                              {console.log(
                                "adminbankdetails",
                                this.state.adminbankdetails
                              )}
                              Bank Name :{" "}
                              {this.state.adminbankdetails &&
                                this.state.adminbankdetails.BankName}{" "}
                              <br />
                              {/* {this.state.toifsccode} */}
                              IBAN No :{" "}
                              {this.state.adminbankdetails &&
                                this.state.adminbankdetails.Accountno}
                              <br />
                              Swift/BIC Code :{" "}
                              {this.state.adminbankdetails &&
                                this.state.adminbankdetails.IFSCcode}
                              {/* {this.state.tobankaccountno} */}
                              <br />
                              Country :{" "}
                              {this.state.adminbankdetails &&
                                this.state.adminbankdetails.Country}
                              <br />
                            </div>
                            <div className="buttonNote">
                              <button
                                className="btn btnDefaultRounded mt-2 mb-3"
                                onClick={this.Depositnow}
                              >
                                Deposit
                              </button>
                              <p>
                                {" "}
                                <span>Notes:</span> Fiat Deposit is totally Free{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="userCommonCard">
                  <h2 className="userPageTitle">Withdraw</h2>
                  {/* <div className="userBoxWhiteBox withdrawBox">
                    <div class="form-group">
                      <label className="flexLabel"><span>Cyprocurreny</span><span className="labelBalance"><small>Balance</small> 1.25478787 <small>BTC</small></span></label>
                      <Select
                         value={selectedPreferredCrypto}
                         onChange={this.handlePreferredCrypto}
                         options={preferredCrypto}
                       />
                     </div>
                     <div className="form-group">
                       <label className="flexLabel"><span>Amount to Withdraw</span><a href="#">Max</a></label>
                       <input type="text" name="" className="form-control" value="0.000587" />
                     </div>
                     <div className="form-group">
                       <label className="flexLabel"><span>Enter Withdraw Address</span></label>
                       <input type="text" name="" className="form-control" value="12d1iTgXzFjcSzow59E5adSKLS2KGpMdqv" />
                     </div>
                     <div className="form-group">
                       <label className="flexLabel"><span>Final Withdraw Amount</span><span className="labelBalance"><small>+ Fee 0.1%</small></span></label>
                       <div class="input-group">
                        <input type="text" class="form-control" id="secondamount" value="0.000581" />
                        <div class="input-group-append"><span class="input-group-text">BTC</span></div>
                      </div>
                     </div>
                     <div className="form-group text-center">
                      <button className="btn btnBlue px-4 py-2" data-toggle="modal" data-target="#withdrawModal">Withdraw</button>
                    </div>
                  </div> */}
                  <div className="userBoxWhiteBox depositBox">
                    <div
                      className={
                        this.state.activetab == "withdraw"
                          ? "tab-pane fade show active"
                          : "tab-pane fade show"
                      }
                      id="nav-myWithdraw"
                      role="tabpanel"
                      aria-labelledby="nav-myWithdraw-tab"
                    >
                      <div className="userPageContent">
                        <div className="pageBox">
                          {/* <h3 className="subPageTitle">Fiat Withdraw</h3> */}
                          <div className="accountForm border-top-zero">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Withdraw Fiat</label>
                                  <input
                                    value={this.state.activetab}
                                    // onChange={this.handleCurrencyType}
                                    // options={this.state.currencyoptions}
                                    disabled
                                  />
                                  <span className="text-danger">
                                    {errors.withdrawcurrency}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Total Wallet Amount</label>
                                  <input
                                    type="text"
                                    name=""
                                    value={this.state.walletbalance}
                                    className="form-control"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="d-block">
                                    Withdraw Amount
                                    {/* <a href="#" className="float-right">
                                          Max
                                        </a> */}
                                  </label>
                                  <div class="input-group">
                                    <input
                                      onChange={this.onChange}
                                      id="amount"
                                      type="number"
                                      value={this.state.amount}
                                      className="form-control"
                                    />
                                    <div class="input-group-append">
                                      <span class="input-group-text">
                                        {this.state.activetab}
                                      </span>
                                    </div>
                                  </div>
                                  <span className="text-danger">
                                    {errors.amount}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Enter 2 FA Code</label>
                                  <input
                                    className="form-control widthdrawtfacode"
                                    onChange={this.onChange}
                                    value={this.state.tfacode}
                                    error={errors.tfacode}
                                    id="tfacode"
                                    type="number"
                                    required
                                  />
                                  <span className="text-danger">
                                    {errors.tfacode}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Withdraw Account</label>
                              <Select
                                value={selectedWithdrawTo}
                                onChange={this.handleWithdrawTo}
                                options={this.state.bankdboptions}
                              />
                              <span className="text-danger">
                                {errors.withdrawtobank}
                              </span>
                            </div>
                            <div className="buttonNote">
                              <button
                                className="btn btnDefaultRounded mt-2 mb-3"
                                onClick={() => this.Withdraw()}
                              >
                                Withdraw
                              </button>
                              <p>
                                <span>Notes:</span> Fiat Withdraw is totally
                                Free
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="userCommonCard mt-5">
                  <div className="tableFilterHead">
                    {this.state.depositShowing == true ? (
                      <h2 className="userPageTitle mb-0">Deposit History</h2>
                    ) : this.state.withdrawShowing == true ? (
                      <h2 className="userPageTitle mb-0">Withdraw History</h2>
                    ) : null}
                    <div className="tableFilterCard">
                      {/* <div className="filterGroup">
                        <label>Show</label>
                        <Select
                           value={selectedPreferredRecords}
                           onChange={this.handlePreferredRecords}
                           options={preferredRecords}
                         />
                      </div> */}
                      <div className="filterGroup">
                        <label>Type</label>
                        <Select
                          //  value={selectedPreferredType}
                          value={
                            preferredType &&
                            preferredType.filter(
                              (option) =>
                                option.value === this.state.preferredTypeVal
                            )
                          }
                          onChange={this.handlePreferredType}
                          options={preferredType}
                        />
                      </div>
                      {/* <div className="filterGroup">
                        <label>Crypto</label>
                        <Select
                           value={selectedPreferredCryptoShort}
                           onChange={this.handlePreferredCryptoShort}
                           options={preferredCryptoShort}
                         />
                      </div> */}
                      {/* <div className="filterGroup">
                        <label>Search</label>
                        <div class="input-group">
                        <input type="text" class="form-control" id="secondamount" placeholder="Search" />
                        <div class="input-group-append"><span class="input-group-text"><i class="fas fa-search"></i></span></div>
                      </div>
                      </div> */}
                      <button
                        className="btnType1 downLoadBtn"
                        onClick={() => this.exportPDF()}
                      >
                        Download PDF
                      </button>
                    </div>
                  </div>
                  <div className="userBoxWhiteBox referralIncome">
                    {this.state.depositShowing == true ? (
                      <div className="table-responsive">
                        <ReactDatatable
                          responsive={this.state.responsive}
                          config={this.depositConfig}
                          records={this.state.depositHistory}
                          columns={this.depositColumns}
                          onPageChange={this.pageChange.bind(this)}
                        />
                      </div>
                    ) : null}
                    {this.state.withdrawShowing == true ? (
                      <div className="table-responsive">
                        <ReactDatatable
                          responsive={this.state.responsive}
                          config={this.withdrawConfig}
                          records={this.state.withdrawHistory}
                          columns={this.withdrawColumns}
                          onPageChange={this.pageChange.bind(this)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

WalletDepositWithdraw.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(WalletDepositWithdraw));
