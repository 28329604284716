import React, { Component } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { store } from "react-notifications-component";
import keys, { numberWithCommas } from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap/";
import * as moment from "moment";
import queryString from "query-string";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import { profileAdd, kycAdd } from "../actions/authActions";
import ReactFlagsSelect from "react-flags-select";
import { encrypt, decrypt } from "../actions/crypto";

const url = keys.baseUrl;

const idProofType = [
  { value: "Driving license", label: "Driving license" },
  { value: "National card", label: "National card" },
  { value: "Pancard", label: "Pancard" },
];

const countryType = [
  { value: "India", label: "India" },
  { value: "Australia", label: "Australia" },
  { value: "China", label: "China" },
];

class MyAccount extends Component {
  constructor() {
    super();
    this.state = {
      icon: "",
      selectedPhoneCode: null,
      selectedbankName: null,
      selectedDepositFrom: null,
      selectedWithdrawTo: null,
      selectedCurrencyType: null,
      selectedcountry: { label: "Nigeria", value: "Nigeria" },
      firstname: "",
      lastname: "",
      email: "",
      _id: "",
      emailotp: "",
      mobileotp: "",
      errors: {},
      phone: "",
      birthDate: "",
      address: "",
      city: "",
      postalcode: "",
      country: "",
      usernameold: "",
      totallikes: 0,
      kycstatus: "",
      createdDate: new Date(),
      ratings: 0,
      emailverified: false,
      mobilenumber: "",
      accountno: "",
      ifsccode: "",
      bankname: "",
      bankdetails: [],
      ViewBankAccount: false,
      currencydetails: [],
      bankdboptions: "",
      adminbankdetails: [],
      tocurrency: "",
      selectedadmin: [],
      toifsccode: "",
      tobankaccountno: "",
      tobankname: "",
      bankselected: "",
      depositamount: "",
      history: [],
      withdrawcurrency: "",
      walletbalance: 0,
      amount: 0,
      tfacode: "",
      balancedetails: [],
      googlestatus: "Disabled",
      withdrawtobank: "",
      history: [],
      deposithistory: [],
      activetab: "",
      Skeletonwith: 0,
      completed_trades: 0,
      // kycstatus: "Not Verified",
      last_seen: "",
      createdDate: "",
      selectedIdProofType: null,
      selectedCountry: null,
      mobilenumberShow: "",
      emailShow: "",
      profileError: "",
      profileSize: "",
      viewprofile: "",
      profile: "",
      preferredicon: "",
      currenticon: "",
      _csrf: "",
      kycdate: "",
      showprefer_currency: "btnCurrencyDropShow collapse hide",
      idProofType: "",
      idProoferror: "",
      idProoffilename: "",
      idProod: "",
      viewIdProof: "",
      Idtype: "",
      idProofSize: 0,
      kycsubmittedimage: "",
    };
  }

  handleprefered_sicon = (cur) => {
    this.setState({ showprefer_currency: "btnCurrencyDropShow collapse show" });
  };

  handleprefered_icon = (cur) => {
    this.setState({ preferredicon: cur });
    this.setState({ icon: cur });
    this.setState({ showprefer_currency: "btnCurrencyDropShow collapse hide" });
  };

  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
        var csrftoken = res.data._csrf;
      })
      .catch();
  };
  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    this.getCSRFtoken();
    let id = this.props.auth.user.id;
    this.getuserdetails(id);
    // this.getfeedback(id);
  }

  getuserdetails(id) {
    let userid = this.props.auth.user.id;

    var ctab = this.props.match.params.id;

    if (ctab == "deposit" || ctab == "withdraw") {
      let userid = this.props.auth.user.id;
      axios.get(url + "users/userget/" + userid).then((res) => {
        if (res.data.kycstatus != "Approved") {
          this.setState({ kycstatus: res.data.kycstatus });
          store.addNotification({
            title: "Error!",
            message:
              "You need to update your verification status to continue using Coinbarter",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });

          this.props.history.push("/TierVerification/");
        }
      });
    }

    axios
      .get(url + "users/userget/" + userid)
      .then((res) => {
        if (res.status == 200) {
          // .splice(-1)[0]
          var last_login = res.data.loginhistory.splice(-1)[0];
          if (last_login) {
            this.setState({ last_seen: last_login.createdDate });
          }
          var total_ratings =
            res.data.positive_ratings + res.data.negative_ratings;
          if (total_ratings > 0) {
            var avg_positive_ratings =
              (res.data.positive_ratings / total_ratings) * 100;
          } else {
            var avg_positive_ratings = 0;
          }
          var negative_ratings = 100 - avg_positive_ratings;
          this.setState({
            avg_positive_ratings: avg_positive_ratings,
            negative_ratings: negative_ratings,
          });
          var kyc = res.data.kyc;
          if (kyc.length == 0) {
            this.setState({ kycstatus: "Not updated" });
          } else {
            var updatedKyc = kyc[kyc.length - 1];
            if (updatedKyc.status == 0) {
              this.setState({ kycstatus: "Pending" });
            } else if (updatedKyc.status == 2) {
              this.setState({ kycstatus: "Approved" });
            } else {
              this.setState({ kycstatus: "Rejected" });
            }
          }

          this.setState({
            userid: res.data.userid,
            _id: res.data._id,
            name: res.data.name,
            otp: res.data.otp,
            firstname: res.data.firstname,
            middlename: res.data.middlename,
            lastname: res.data.lastname,
            phone: res.data.phonenumber,
            email: res.data.email,
            emailverified: res.data.emailverified,
            username: res.data.username,
            usernameold: res.data.username,
            mobilenumber: res.data.phonenumber,
            createdDate: res.data.date,
            googlestatus: res.data.google,
            totallikes: res.data.totallikes,
            icon: res.data.icon,
            currenticon: res.data.icon,
            // kycstatus: res.data.kycstatus,
            createdDate: res.data.date,
            address: res.data.address,
            city: res.data.city,
            postalcode: res.data.postalcode,
            country: res.data.country,
            kycdate: res.data.kycdate,
            idProofType:
              res.data.kyc.length &&
              res.data.kyc[res.data.kyc.length - 1].idproofType,
            kycsubmittedimage:
              res.data.kyc.length &&
              res.data.kyc[res.data.kyc.length - 1].idprooImage,

            // IdentityVerification:res.data.kycstatus=="Approved"?"KYC Verified":res.data.kycstatus=="Rejected"?"Declined":"None"
          });

          //this.setState(res.data);

          this.props.auth.user.name = res.data.name;
        }
      })
      .catch();
  }

  handleIdProofType = (selectedIdProofType) => {
    this.setState({ selectedIdProofType });
    this.setState({ idProofType: selectedIdProofType.value });
  };
  handleCountry = (selectedCountry) => {
    this.setState({ selectedCountry });
    this.setState({ country: selectedCountry.value });
  };
  onSelectCountry = (countryCode) => {
    this.setState({ country: countryCode });
  };

  // Profile photo selection
  handleChangeIdproof = (e) => {
    this.setState({ idProoferror: "" });
    if (e.target.files[0]) {
      if (e.target.files[0].type == "application/pdf") {
        this.setState({
          Idtype: "pdf",
        });
      } else {
        this.setState({
          Idtype: "img",
        });
      }

      var filesize = e.target.files[0].size;
      this.setState({ idProofSize: filesize });
      if (filesize > 3145728) {
        this.setState({ idProoferror: "*Image is Above 3MB" });
      } else {
        this.setState({
          idProoffilename: e.target.files[0].name,
          idProof: e.target.files[0],
          viewIdProof: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };

  onSubmitProfile = (e) => {
    e.preventDefault();
    var profiledata = {};
    profiledata = {
      id: this.props.auth.user.id,
      username: this.state.username,
      mobilenumber: this.state.mobilenumber,
      email: decrypt(this.state.email),
      icon: this.state.preferredicon
        ? this.state.preferredicon
        : this.state.icon,
      _csrf: this.state._csrf,
    };
    this.props.profileAdd(profiledata);
  };

  onSubmitKYC = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("address", this.state.address);
    formData.append("city", this.state.city);
    formData.append("postalcode", this.state.postalcode);
    formData.append("country", this.state.country);
    formData.append("idProofType", this.state.idProofType);
    formData.append("idProoffilename", this.state.idProoffilename);
    formData.append("idProofSize", this.state.idProofSize);
    formData.append("_id", this.state._id);
    formData.append("idProof", this.state.idProof);
    // formData.append("_csrf", this.state._csrf);

    // formData.append("frontSide", formProofFront);
    // formData.append("backSide", formProofBack);
    // formData.append("addressProof", formAddress);
    this.props.kycAdd(formData);

    // var profiledata = {}
    // profiledata = {
    //    id: this.props.auth.user.id,
    //    username : this.state.username,
    //    mobilenumber: this.state.mobilenumber,
    //    email:this.state.email,
    //    icon:this.state.preferredicon? this.state.preferredicon : this.state.icon,
    //    _csrf:this.state._csrf,
    // }
    // this.props.profileAdd(profiledata);
    this.setState({
      viewIdProof: "",
      idProoffilename: "",
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        disable: false,
      });
    }
    if (
      nextProps.auth.updateprofile !== undefined &&
      nextProps.auth.updateprofile.data !== undefined &&
      nextProps.auth.updateprofile.data.message !== undefined
    ) {
      store.addNotification({
        // title: "Wonderful!",
        message: nextProps.auth.updateprofile.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      nextProps.auth.updateprofile = undefined;
      this.getuserdetails();
      this.setState({
        errors: "",
        disable: false,
      });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  mobilenumberChange = () => {
    this.setState({ mobilenumberShow: "true" });
  };
  emailChange = () => {
    this.setState({ emailShow: "true" });
  };

  render() {
    const { selectedIdProofType } = this.state;
    const { selectedCountry } = this.state;
    const { showprefer_currency } = this.state;
    const { errors } = this.state;
    return (
      <div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="userCommonCard">
                  <h2 className="userPageTitle">My Profile</h2>
                  <div className="userBoxWhiteBox myProfileBox">
                    <form name="kycsubmit" onSubmit={this.onSubmitProfile}>
                      <div className="form-group">
                        <label>
                          Email Address{" "}
                          <span className="verifyLabel ml-3">
                            <i class="fas fa-check-circle greenText mr-2"></i>{" "}
                            Verified on{" "}
                            {moment(this.state.createdDate).format(
                              "DD MMMM YYYY"
                            )}
                          </span>
                        </label>
                        <div class="input-group">
                          {/* {this.state.emailShow =='true' &&  */}
                          <input
                            type="text"
                            class="form-control"
                            value={decrypt(this.state.email)}
                            id="email"
                            onChange={this.onChange}
                            disabled
                          />
                          {/* } */}
                          {/* {this.state.emailShow !='true' && 
                            <input type="text" class="form-control" value={this.state.email} disabled />
                          } */}
                          {/* <div class="input-group-append">
                            <span className="btn btnType1" onClick={this.emailChange}>Change</span>
                          </div> */}
                        </div>
                        <span className="text-danger">{errors.email}</span>
                      </div>
                      <div className="form-group">
                        {/* <label>Phone Number {this.state.mobilenumber=='' ?<span className="verifyLabel ml-3"><i class="fas fa-check-circle redText mr-2"></i>Pending</span> : <span className="verifyLabel ml-3"><i class="fas fa-check-circle greenText mr-2"></i>Verified on {moment(this.state.createdDate).format('DD MMMM YYYY')}</span>}</label> */}
                        <label>Phone Number </label>
                        <div class="input-group">
                          {/* {this.state.mobilenumberShow =='true' &&  */}
                          <input
                            type="number"
                            class="form-control"
                            value={this.state.mobilenumber}
                            id="mobilenumber"
                            onChange={this.onChange}
                          />
                          {/* } */}
                          {/* {this.state.mobilenumberShow !='true' && 
                            <input type="text" class="form-control" value={this.state.mobilenumber} disabled />
                          } */}
                          {/* <div class="input-group-append">
                            <span className="btn btnType1" onClick={this.mobilenumberChange}>Change</span>
                          </div> */}
                        </div>
                        <span className="text-danger">
                          {errors.mobilenumber}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Your Full Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="username"
                          onChange={this.onChange}
                          value={this.state.username}
                        />
                        <span className="text-danger">{errors.username}</span>
                      </div>
                      {/* <div class="form-group">
                        <div className="row">
                            <div className="col-md-4">
                              <label>Profile Icon</label>
                              <div class="dropdown profileIconDropdown">
                                <button type="button" class="btn selectButton dropdown-toggle" data-toggle="dropdown">
                                  <img src={require("../images/profileIconLarge.png")} alt="" className="img-fluid" />
                                </button>
                                <div class="dropdown-menu">
                                  <a class="dropdown-item" href="#">
                                    <img src={require("../images/profileIconLarge.png")} alt="" className="img-fluid" />
                                  </a>
                                  <a class="dropdown-item" href="#">
                                    <img src={require("../images/profileIconLarge.png")} alt="" className="img-fluid" />
                                  </a>
                                  <a class="dropdown-item" href="#">
                                    <img src={require("../images/profileIconLarge.png")} alt="" className="img-fluid" />
                                  </a>
                                </div>
                              </div> 
                          </div>
                            <div className="col-md-8">
                              <div className="selectedProfileThumb">
                                <img src={require("../images/profileIconLarge.png")} alt="" className="img-fluid" />
                              </div>
                            </div>
                        </div>
                      </div> */}
                      <div class="form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Profile Icon</label>
                            <div className="col-md-6">
                              <div className="selectedProfileThumb">
                                {this.state.currenticon == "BTC" ? (
                                  <img
                                    src={require("../images/dashTabIcon1.png")}
                                    class="img-fluid"
                                  />
                                ) : this.state.currenticon == "ETH" ? (
                                  <img
                                    src={require("../images/dashTabIcon2.png")}
                                    class="img-fluid"
                                  />
                                ) : this.state.currenticon == "USDT" ? (
                                  <img
                                    src={require("../images/dashTabIcon3.png")}
                                    class="img-fluid"
                                  />
                                ) : this.state.currenticon == "BCH" ? (
                                  <img
                                    src={require("../images/dashTabIcon4.png")}
                                    class="img-fluid"
                                  />
                                ) : this.state.currenticon == "XRP" ? (
                                  <img
                                    src={require("../images/dashTabIcon5.png")}
                                    class="img-fluid"
                                  />
                                ) : this.state.currenticon == "TRX" ? (
                                  <img
                                    src={require("../images/dashTabIcon6.png")}
                                    class="img-fluid"
                                  />
                                ) : this.state.currenticon == "DASH" ? (
                                  <img
                                    src={require("../images/dashTabIcon8.png")}
                                    class="img-fluid"
                                  />
                                ) : this.state.currenticon == "CELO" ? (
                                  <img
                                    src={require("../images/dashTabIcon9.png")}
                                    class="img-fluid"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* <img src={require("../images/dashTabIcon1.png")} class="img-fluid" /> */}
                            <br></br> Update Profile Icon <br></br>{" "}
                            <div className="select-group-transfer">
                              <div class="selectCoinType">
                                <div class="dropdown profileIconDropdow">
                                  <button
                                    onClick={() =>
                                      this.handleprefered_sicon("")
                                    }
                                    type="button"
                                    class="btn selectButton"
                                    data-toggle="collapse"
                                    data-target="#collapseExample"
                                    aria-expanded="false"
                                  >
                                    {this.state.preferredicon
                                      ? this.state.preferredicon
                                      : this.state.icon
                                      ? this.state.icon
                                      : "Select icon"}
                                    <i class="fas fa-caret-down"></i>
                                  </button>
                                  <div
                                    class={showprefer_currency}
                                    id="collapseExample"
                                  >
                                    {/* <div class="input-group py-2 px-2 mr-0 w-100">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-search"></i></span></div>
                                    <input type="text" class="form-control" placeholder="Search Icon" id="searchpair" value="" />
                                </div> */}
                                    <div class="currencyTab">
                                      <ul class="css-1yh09yi">
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("BTC")
                                          }
                                          role="option"
                                          id="btcIcon"
                                          title="BTC"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon1.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">BTC</div>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("ETH")
                                          }
                                          role="option"
                                          id="ethIcon"
                                          title="ETH"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon2.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">ETH</div>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("USDT")
                                          }
                                          role="option"
                                          id="usdtIcon"
                                          title="USDT"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon3.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">USDT</div>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("BCH")
                                          }
                                          role="option"
                                          id="bchIcon"
                                          title="BCH"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon4.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">BCH</div>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("XRP")
                                          }
                                          role="option"
                                          id="xrpIcon"
                                          title="XRP"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon5.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">XRP</div>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("TRX")
                                          }
                                          role="option"
                                          id="tronIcon"
                                          title="TRON"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon6.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">TRON</div>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("DASH")
                                          }
                                          role="option"
                                          id="dashIcon"
                                          title="DASH"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon8.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">DASH</div>
                                        </li>
                                        <li
                                          onClick={() =>
                                            this.handleprefered_icon("CELO")
                                          }
                                          role="option"
                                          id="celoIcon"
                                          title="CELO"
                                          class="bn-sdd-option css-tsqhhu"
                                        >
                                          <div class="css-1ii0qmr">
                                            <img
                                              src={require("../images/dashTabIcon9.png")}
                                              class="css-pjaw30"
                                            />
                                          </div>
                                          <div class="css-1pysja1">CELO</div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="selectedProfileThumb">
                              {this.state.icon == "BTC" ? (
                                <img
                                  src={require("../images/dashTabIcon1.png")}
                                  class="img-fluid"
                                />
                              ) : this.state.icon == "ETH" ? (
                                <img
                                  src={require("../images/dashTabIcon2.png")}
                                  class="img-fluid"
                                />
                              ) : this.state.icon == "USDT" ? (
                                <img
                                  src={require("../images/dashTabIcon3.png")}
                                  class="img-fluid"
                                />
                              ) : this.state.icon == "BCH" ? (
                                <img
                                  src={require("../images/dashTabIcon4.png")}
                                  class="img-fluid"
                                />
                              ) : this.state.icon == "XRP" ? (
                                <img
                                  src={require("../images/dashTabIcon5.png")}
                                  class="img-fluid"
                                />
                              ) : this.state.icon == "TRX" ? (
                                <img
                                  src={require("../images/dashTabIcon6.png")}
                                  class="img-fluid"
                                />
                              ) : this.state.icon == "DASH" ? (
                                <img
                                  src={require("../images/dashTabIcon8.png")}
                                  class="img-fluid"
                                />
                              ) : this.state.icon == "CELO" ? (
                                <img
                                  src={require("../images/dashTabIcon9.png")}
                                  class="img-fluid"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group text-center">
                        <input
                          type="submit"
                          className="roundedCorner"
                          value="Save"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="userCommonCard">
                  <h2 className="userPageTitle">KYC</h2>
                  <form name="kycsubmit" onSubmit={this.onSubmitKYC}>
                    <div className="userBoxWhiteBox generalSettingsBox">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          onChange={this.onChange}
                          value={this.state.address}
                        />
                        <span className="text-danger">{errors.address}</span>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              class="form-control"
                              id="city"
                              onChange={this.onChange}
                              value={this.state.city}
                            />
                            <span className="text-danger">{errors.city}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Postal Code</label>
                            <input
                              type="number"
                              class="form-control"
                              id="postalcode"
                              onChange={this.onChange}
                              value={this.state.postalcode}
                            />
                            <span className="text-danger">
                              {errors.postalcode}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Country</label>
                        <ReactFlagsSelect
                          id="country"
                          searchable
                          onSelect={this.onSelectCountry}
                          placeholder="Select a country"
                          selected={this.state.country}
                        />
                        {/* <Select
                          value={countryType && countryType.filter(option => option.label === this.state.country)}
                          // value={selectedCountry}
                          onChange={this.handleCountry}
                          options={countryType}
                        /> */}
                      </div>
                      <span className="text-danger">{errors.country}</span>
                      {console.log(
                        "kycstatuskycstatuskycstatuskycstatus",
                        this.state.kycstatus
                      )}
                      {/* {(this.state.kycstatus == "Not updated" || this.state.kycstatus == "Rejected") && */}
                      <div class="form-group">
                        <label>ID Proof Type</label>
                        <Select
                          value={
                            idProofType &&
                            idProofType.filter(
                              (option) =>
                                option.label === this.state.idProofType
                            )
                          }
                          // value={selectedIdProofType}
                          onChange={this.handleIdProofType}
                          options={idProofType}
                        />
                      </div>
                      {/* } */}
                      <span className="text-danger">{errors.idProofType}</span>
                      {this.state.kycstatus != "Approved" && (
                        <div className="form-group">
                          <label>
                            Upload Proof (name with photo and address page)
                          </label>
                          <div className="custom-file">
                            <input
                              type="file"
                              name="idProof"
                              onChange={this.handleChangeIdproof}
                              accept="image/x-png,image/jpeg,application/pdf,image/jpg"
                              className="custom-file-input"
                              aria-describedby="inputGroupFileAddon01"
                            />
                            <label>Max 3MB in png/jpeg/pdf/jpg</label>
                            <label className="custom-file-label">
                              {this.state.Idtype != "pdf" ||
                              this.state.Idtype == "pdf"
                                ? "Choose file"
                                : this.state.idProoffilename}
                            </label>
                            {this.state.viewIdProof ? (
                              <a
                                className="d-block"
                                style={{ marginTop: "15px" }}
                                href={this.state.viewIdProof}
                                target="_blank"
                              >
                                {" "}
                                {this.state.Idtype != "pdf" ? (
                                  <img
                                    style={{ width: "90%", margin: "25px 0" }}
                                    src={this.state.viewIdProof}
                                  ></img>
                                ) : null}
                                {this.state.idProoffilename}
                              </a>
                            ) : null}
                          </div>
                          <span className="text-danger">
                            {errors.idProoferror}
                          </span>
                          <span className="text-danger">
                            {this.state.idProoferror}
                          </span>
                        </div>
                      )}
                      <input
                        type="hidden"
                        name="_csrf"
                        value={this.state._csrf}
                      />

                      <div className="form-group text-center">
                        {this.state.kycstatus != "Approved" &&
                          this.state.kycstatus != "Pending" &&
                          this.state.kycstatus == "Not updated" && (
                            <input
                              type="submit"
                              className="roundedCorner"
                              value="Submit"
                            />
                          )}
                        {console.log(
                          "this.state.kycstatusthis.state.kycstatus",
                          this.state.kycstatus
                        )}
                        {this.state.kycstatus == "Rejected" && (
                          <div>
                            <input
                              type="submit"
                              className="roundedCorner"
                              value="Re Submit"
                            />
                          </div>
                        )}
                        {/* {this.state.kycstatus == "Pending" &&
                          <h5>Your Kyc details submitted successfully.. Wait untill admin confirmation</h5>
                        }
                        {this.state.kycstatus == "Approved" &&
                          <h5>Your Kyc details Accepted successfully.. Start your trade</h5>
                        } */}
                      </div>
                      <div class="alert alert-primary" role="alert">
                        <div className="aletBoxCard">
                          <div className="alertboxDescription">
                            {/* <h5>KYC {this.state.kycstatus}</h5> */}
                            <h5>
                              {this.state.kycstatus == "Approved" ? (
                                <p>
                                  KYC{" "}
                                  <i class="fas fa-check-circle greenText mr-2"></i>{" "}
                                  {this.state.kycstatus}
                                </p>
                              ) : this.state.kycstatus == "Pending" ? (
                                <p>
                                  KYC{" "}
                                  <i class="fas fa-times-circle yellowText mr-2"></i>{" "}
                                  {this.state.kycstatus}
                                </p>
                              ) : this.state.kycstatus == "Rejected" ? (
                                <p>
                                  KYC{" "}
                                  <i class="fas fa-times-circle redText mr-2"></i>{" "}
                                  {this.state.kycstatus}
                                </p>
                              ) : (
                                <p>
                                  KYC{" "}
                                  <i class="fas fa-times-circle redText mr-2"></i>{" "}
                                  {this.state.kycstatus}
                                </p>
                              )}
                            </h5>
                            <p>
                              Document submitted{" "}
                              <span className="blueText">
                                - {this.state.idProofType}
                              </span>
                            </p>
                            <p>
                              {this.state.kycsubmittedimage ? (
                                <a
                                  href={
                                    keys.baseUrl +
                                    "kyc/" +
                                    this.state.kycsubmittedimage
                                  }
                                  target="_blank"
                                >
                                  {" "}
                                  {this.state.kycsubmittedimage.slice(
                                    ((this.state.kycsubmittedimage.lastIndexOf(
                                      "."
                                    ) -
                                      1) >>>
                                      0) +
                                      2
                                  ) != "pdf" ? (
                                    <img
                                      style={{
                                        width: "100%",
                                        margin: "30px 0",
                                      }}
                                      src={
                                        keys.baseUrl +
                                        "kyc/" +
                                        this.state.kycsubmittedimage
                                      }
                                    ></img>
                                  ) : (
                                    <a
                                      href={
                                        keys.baseUrl +
                                        "kyc/" +
                                        this.state.kycsubmittedimage
                                      }
                                      target="_blank"
                                    >
                                      {this.state.kycsubmittedimage}
                                    </a>
                                  )}
                                </a>
                              ) : (
                                <p></p>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

MyAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  profileAdd: PropTypes.object.isRequired,
  kycAdd: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { profileAdd, kycAdd })(
  withRouter(MyAccount)
);
