import React, { Component } from 'react'

import {NavLink} from 'react-router-dom';
import ReactDOM from 'react-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import Select from 'react-select';
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import { Modal, Button } from "react-bootstrap/";
import keys from "../actions/config";
import { logoutUser } from "../actions/authActions";
import queryString from 'query-string';
import * as moment from "moment";
import $ from 'jquery';
import classNames from "classnames";
const url = keys.baseUrl;


class UserNavbar extends Component {


  constructor() {
    super();
    this.state = {
      errors: {},
      _csrf:'',
      notifications:[],
      isButtonActive: true,
      appliedtheme:'dark_theme'
    
    };
      this.onClick = this.onClick.bind(this)     
  }


  getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
          this.setState({_csrf:res.data._csrf});
        })
        .catch();
  }

  componentDidMount() {
    this.getCSRFtoken();
    this.getNavNotification();
    let theme=localStorage.getItem("xavitheme");
    if(theme==null){
      localStorage.setItem('xavitheme','dark_theme')
    }{
      if(theme=='dark_theme'){
        document.body.classList.remove('light_theme' );
        document.body.classList.add('dark_theme');
        this.setState({appliedtheme:'dark_theme',isButtonActive:true})
      }else{
        document.body.classList.remove('dark_theme' );
        document.body.classList.add('light_theme');
        this.setState({appliedtheme:'light_theme',isButtonActive:false})
      }
     
    }
  }
  onLogoutClick = (e) => {
    e.preventDefault();
    let userid = this.props.auth.user.id;
    let postdata={"userId":userid, "_csrf" :this.state._csrf}  
    axios.post(url + "users/logoutuser/",postdata).then((res) => {
      store.addNotification({
        // title: "Wonderful!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 15000,
          onScreen: true,
        },
      });
      this.props.logoutUser();
    });
   
  };

  getNavNotification = async () => {
    let userid = this.props.auth.user.id;
    await axios
    .get(url + "users/getNavNotification/"+userid)
    .then((res) => {
      if(res.data){
        this.setState({notifications:res.data});
      }
    })
    .catch();
  }

  onClick () {
    localStorage.setItem('xavitheme', this.state.isButtonActive ? 'light_theme' : 'dark_theme');
    this.setState({appliedtheme:this.state.isButtonActive ? 'light_theme' : 'dark_theme'});

    document.body.classList.remove(!this.state.isButtonActive ? 'light_theme' : 'dark_theme');
    this.setState({
      isButtonActive: !this.state.isButtonActive
    })

    document.body.classList.add(this.state.isButtonActive ? 'light_theme' : 'dark_theme');
    // this.props.openPanel(this.props.buttonNumber)
  }
  render() {
    return (<div>
     <nav className ="navbar navbar-expand-lg navbar-light bg-light fixed-top landingNav wow fadeInDown Scroll up">
        <div className="container">
          <a className="navbar-brand" href="/"><img src={require("../images/landingLogo.png")} alt="" className="img-fluid" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
          <div className="ml-auto d-flex">
            <ul className="navbar-nav mr-5 linkTabs padin_link mr-auto">
              <li className="nav-item">
                <a className="nav-link" href="/Buy">Buy Crypto</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Sell">Sell Crypto</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/PostTrade">Post a Trade</a>
              </li>
            </ul>
            <ul className="navbar-nav linkTabs">
            <li className="nav-item">
                <a className="nav-link" href="/Dashboard">Dashboard</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/Wallet">Wallet</a>
              </li> */}
              
        
              <li className="nav-item">
                <div className="dropdown show">
                  <a className="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Wallet
                  </a>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <NavLink to="/Wallet" className="dropdown-item">Crypto Wallet</NavLink>
                    <NavLink to="/FiatWallet" className="dropdown-item">Fiat Wallet</NavLink>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                 <div className="dropdown show">
                <a className="nav-link dropdown-toggle" href="#" role="button" id="notificationDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Notification</a>
                <div className="dropdown-menu py-0" aria-labelledby="notificationDropdown">
                    <ul className="notificationList">
                    {this.state.notifications&& this.state.notifications.length > 0 ?
                        this.state.notifications.map((item, i) => {
                          return(
                            <li>
                              <h6>{moment(item.created_date).format(
                                              "DD-MM-YYYY  k:mm"
                                            )}</h6>
                              <p>{item.Category} <span className="blueText">{item.message}</span></p>
                              {/* <p>{item.Category} <span className="blueText">#73243199</span> new message from <span className="blueText">CRYPTOKIN</span></p> */}
                            </li>
                            );
                        })
                        :<h6>No more notifications</h6>
                      }
                      <li>
                        <NavLink to="/Notification" className="dropdown-item">All Notification</NavLink>
                      </li>
                      
                      {/* <li>
                        <h6>5 min ago</h6>
                        <p>Trade <span className="blueText">#73243199</span> new message from <span className="blueText">CRYPTOKIN</span></p>
                      </li>
                      <li>
                        <h6>50 min ago</h6>
                        <p>Trade <span className="blueText">#73243199</span> new message from <span className="blueText">CRYPTOKIN</span></p>
                      </li>
                      <li>
                        <h6>1 day ago</h6>
                        <p>Check your account's verification status and update it accordingly.</p>
                      </li> */}
                    </ul>
                </div>
              </div>
              </li>
              <li className="nav-item">
                <div className="dropdown show">
                  <a className="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    My Account
                  </a>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <NavLink to="/ProfileDetail" className="dropdown-item">Profile</NavLink>
                    <NavLink to="/MyAccount" className="dropdown-item">My account</NavLink>
                    <NavLink to="/Myactivity" className="dropdown-item">My activity</NavLink>
                    <NavLink to="/Settings" className="dropdown-item">Settings</NavLink>
                    <NavLink to="/SupportTicket" className="dropdown-item">Support Ticket</NavLink>
                    <a className="dropdown-item" href="#" onClick={this.onLogoutClick}>Logout</a>
                  </div>
                </div>
              </li>
              <li>
                <div className="light_dark_sectoin">
                  <a  href="#" onClick={this.onClick}> <i className={this.state.isButtonActive ?  'fas fa-moon':'fas fa-sun' } ></i></a>
                      {/* <a><i class="fas fa-sun"></i></a>  
                      <i class="fas fa-moon d-none"></i> */}
                </div>
              
              </li>
            </ul>
            
            </div>
          </div>
        </div>
      </nav>
      </div>
);
  }
}

UserNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  logoutUser,
})(withRouter(UserNavbar));