import React, { Component } from 'react'
import {NavLink} from 'react-router-dom';
import ReactDOM from 'react-dom';
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from 'react-select';
import { Multiselect } from 'multiselect-react-dropdown';

const tradeHours = [
  { value: '24 Hours, 00.00AM to 24.00AM', label: '24 Hours, 00.00AM to 24.00AM' },
  { value: '12 Hours, 00.00AM to 12.00Noon', label: '12 Hours, 00.00AM to 12.00Noon' },
  { value: '12 Hours, 01.00PM to 12.00Mid Night', label: '12 Hours, 01.00PM to 12.00Mid Night' },
];
const TradeTime = [
  { value: 'GMT +2.00 Time Zone', label: 'GMT +2.00 Time Zone' },
  { value: 'GMT +2.00 Time Zone', label: 'GMT +2.00 Time Zone' },
  { value: 'GMT +2.00 Time Zone', label: 'GMT +2.00 Time Zone' },
];

class PostDetails extends Component {
state = {
    selectedTradeHours: null,
    selectedTradeTime: null,
};
handleTradeHours = selectedTradeHours => {
    this.setState({ selectedTradeHours });
};
handleTradeTime = selectedTradeTime => {
    this.setState({ selectedTradeTime });
};

constructor(props) {
    super(props);
    this.state = {
      tradeDay: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      tradeOpen: ["Anybody", "Only Email Verified", "Only Phone Verified"],
    };
  }

	render() {
	const { selectedTradeHours } = this.state;
	const { selectedTradeTime } = this.state;
	const { tradeDay, tradeOpen, selectedValues } = this.state;
		return (
      <div>
      <UserNavbar />
	<section className="userPages">
	   <div className="container">
	      <div className="userCommonCard">
	         <div className="breadCrumb">
	            <ul>
	               <li><a href="/PostTrade">Create New Trade</a></li>
	               <li className="bcArrow"><i class="fas fa-angle-right"></i></li>
	               <li><a href="/OfferCategory">Offer Category</a></li>
	               <li className="bcArrow"><i class="fas fa-angle-right"></i></li>
	               <li>Post Details</li>
	            </ul>
	         </div>
	         <div className="userBoxWhiteBox securityBox">
	            <div className="row">
	               <div className="col-md-12">
	                  <div className="ocTradeDetails">
	                     <h3 class="userBoxUnderlineTitle">Post Details</h3>
	                     <div class="form-group">
	                        <label>What do you want the title of your offer to be?</label>
	                        <input type="text" class="form-control" value="" />
	                     </div>
	                     <div class="form-group">
	                        <label>What do you want to publish in your terms of trade?</label>
	                        <textarea row="3" class="form-control"></textarea>
	                     </div>
	                     <div class="form-group">
	                        <label>Which hours of the day will you be available to trade?</label>
	                        <div className="row">
	                           <div className="col-md-6">
	                              <Multiselect options={tradeDay} isObject={false} />
	                           </div>
	                           <div className="col-md-6">
	                              <Select
	                                 value={selectedTradeHours}
	                                 onChange={this.handleTradeHours}
	                                 options={tradeHours}
	                                 />
	                           </div>
	                           {/* <div className="col-md-4">
	                              <Select
	                                 value={selectedTradeTime}
	                                 onChange={this.handleTradeTime}
	                                 options={TradeTime}
	                                 />
	                           </div> */}
	                        </div>
	                     </div>
	                     <div class="form-group">
	                        <label>Who can open a trade with you?</label>
	                        <Multiselect options={tradeOpen} isObject={false} />
	                     </div>
	                  </div>
	               </div>
	               <div className="col-md-12">
	                  <div className="form-group text-center">
	                  	<button type="button" className="btn btnBlueBordered py-2 mr-3"><i class="fas fa-chevron-left"></i> Back</button>
	                     <button type="button" className="btn btnBlue py-2">Next <i class="fas fa-chevron-right"></i></button>
	                  </div>
	               </div>
	            </div>
	         </div>
	      </div>
	   </div>
	</section>
      <Footer />
</div>
);
	}
}

export default PostDetails