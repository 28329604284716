import React, { Component } from "react";
import ReactDOM from "react-dom";
import googleCaptcha from "../images/googleCaptcha.jpg";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import Select from "react-select";
import { store } from "react-notifications-component";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys, { numberWithCommas } from "../actions/config";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import * as moment from "moment";
import { getCode, getName } from "country-list";
const crypto = require("crypto");

const CryptoJS = require("crypto-js");
const key = CryptoJS.enc.Base64.parse("#base64Key#");
const iv = CryptoJS.enc.Base64.parse("#base64IV#");

const url = keys.baseUrl;

const selectIssue = [
  {
    value: "Seller NOT released as per the contract",
    label: "Seller NOT released as per the contract",
  },
  {
    value: "Seller NOT released as per the contract",
    label: "Seller NOT released as per the contract",
  },
  {
    value: "Seller NOT released as per the contract",
    label: "Seller NOT released as per the contract",
  },
];

class TradeBankRequestSell extends Component {
  //  state = {
  //     selectedIssue: null,
  //   };
  constructor() {
    super();
    this.state = {
      selectedIssue: null,
      errors: {},
      buyadsdetails: {
        price: 0,
        minlimit: 0,
        maxlimit: 0,
        user_info: { icon: "" },
      },
      avg_positive_ratings: 0,
      crypto_amount: "",
      fiat_amount: "",
      showmodel: false,
      message: "",
      terms: false,
      initiate_disable: false,
      _csrf: "",
      curmarketprice: 0,
      currencyselected: "",
      spotpairsdata: [],
      trustStatus: "",
      blockStatus: "No",
      feedback: {},
    };
  }

  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    this.getCSRFtoken();
  }

  getUserdata() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/userget/" + userid).then((res) => {
      this.setState({ kycstatus: res.data.kycstatus });
      if (res.data.kycstatus != "Approved") {
        store.addNotification({
          title: "Error!",
          message:
            "You need to update your verification status to continue using Coinbarter",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });

        this.props.history.push("/MyAccount/");
      }
    });
  }

  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
        this.getbuydetails();
        this.getspotdata();
        this.getUserdata();
      })
      .catch();
  };

  getbuydetails() {
    let id = this.props.match.params.id;
    if (id.trim() == "") {
      this.props.history.push("/dashboard");
    }
    let userid = this.props.auth.user.id;
    let postdata = { _id: id, userid: userid, _csrf: this.state._csrf };
    axios.post(url + "tradeapi/buyads_details", postdata).then((res) => {
      console.log(
        "buyaaaaaaaaaaadddddddddddddddddddd",
        res.data.tradedata[0].userId
      );
      if (res.data.status == false) {
        this.props.history.push("/dashboard");
      } else {
        this.setState({ buyadsdetails: res.data.tradedata[0] });
      }
      var total_ratings =
        this.state.buyadsdetails.user_info.positive_ratings +
        this.state.buyadsdetails.user_info.negative_ratings;
      if (total_ratings > 0) {
        var avg_positive_ratings =
          (this.state.buyadsdetails.user_info.positive_ratings /
            total_ratings) *
          100;
      } else {
        var avg_positive_ratings = 0;
      }
      var negative_ratings = 100 - avg_positive_ratings;
      let userid = this.props.auth.user.id;
      if (
        res.data.tradedata[0].user_info.trustedPeople &&
        res.data.tradedata[0].user_info.trustedPeople.length > 0
      ) {
        var trustindex =
          res.data.tradedata[0].user_info.trustedPeople.findIndex(
            (x) => x.address.toString() === userid.toString()
          );
        if (trustindex != -1) {
          this.setState({ trustStatus: "Yes" });
        } else {
          this.setState({ trustStatus: "No" });
        }
      }
      if (
        res.data.tradedata[0].user_info.blockedPeople &&
        res.data.tradedata[0].user_info.blockedPeople.length > 0
      ) {
        var blockindex =
          res.data.tradedata[0].user_info.blockedPeople.findIndex(
            (x) => x.address.toString() === userid.toString()
          );
        if (blockindex != -1) {
          this.setState({ blockStatus: "Yes" });
        } else {
          this.setState({ blockStatus: "No" });
        }
      }
      axios
        .get(url + "users/getfeedback/" + res.data.tradedata[0].userId)
        .then((res) => {
          console.log("fffffffeeeeeeeeedddddddddddddddd", res.data);
          this.setState({ feedback: res.data.feedbacks });
        });
      this.setState({
        avg_positive_ratings: avg_positive_ratings,
        negative_ratings: negative_ratings,
        currencyselected: this.state.buyadsdetails.firstCurrency,
        posttocurrency: this.state.buyadsdetails.secondCurrency,
      });
    });
    this.getspotdata();
  }

  getspotdata() {
    axios.get(url + "api/spotpair-data").then((res) => {
      if (res.data.status) {
        this.setState({ spotpairsdata: res.data.data });
        var tempdata = res.data.data;
        var pair = this.state.currencyselected + this.state.posttocurrency;

        var indexonchcange = this.state.spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          var onchbaseprice =
            this.state.spotpairsdata[indexonchcange].index_price;
          this.setState({ curmarketprice: onchbaseprice });
        }
      }
    });
  }

  emaildecrypt(value) {
    var decipher = CryptoJS.AES.decrypt(value, key, { iv: iv });
    var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
    return decrypt_val;
  }
  handleIssue = (selectedIssue) => {
    this.setState({ selectedIssue });
  };
  handlecrypto = (e) => {
    var buy_amount = e.currentTarget.value;
    var fiat_price = this.state.buyadsdetails.price;
    var crypto_amount = buy_amount / fiat_price;
    this.setState({ fiat_amount: buy_amount });
    this.setState({ crypto_amount: crypto_amount });
  };
  handlemessage = (e) => {
    var msg = e.currentTarget.value;
    this.setState({ message: msg });
  };

  handleonchange = (e) => {
    this.setState({ terms: e.currentTarget.value });
  };

  getData() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/userget/" + userid).then((res) => {
      if (res.data.kycstatus != "Approved") {
        store.addNotification({
          title: "Error!",
          message:
            "You need to update your verification status to continue using Coinbarter",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });

        this.props.history.push("/TierVerification/");
        return false;
      }
    });
    return true;
  }

  handleTrust() {
    let userid = this.props.auth.user.id;
    let postdata = {
      trusterId: this.state.buyadsdetails.user_info._id,
      userid: userid,
      _csrf: this.state._csrf,
    };
    axios.post(url + "users/trustPeople", postdata).then((res) => {
      if (res.data.message != "") {
        store.addNotification({
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        this.getbuydetails();
      }
    });
  }

  handleBlock() {
    let userid = this.props.auth.user.id;
    let postdata = {
      blockId: this.state.buyadsdetails.user_info._id,
      userid: userid,
      _csrf: this.state._csrf,
    };
    axios.post(url + "users/blockPeople", postdata).then((res) => {
      if (res.data.message != "") {
        store.addNotification({
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        this.getbuydetails();
      }
    });
  }

  handlebuycrypto() {
    let userid = this.props.auth.user.id;

    if (!userid) {
      this.props.history.push("/login");
      return false;
    }
    if (this.getData()) {
      let id = this.props.match.params.id;
      let crypto_amount = this.state.crypto_amount;
      let fiat_amount = this.state.fiat_amount;
      let postdata = {
        _id: id,
        userid: userid,
        crypto_amount: crypto_amount,
        fiat_amount: fiat_amount,
        _csrf: this.state._csrf,
      };
      axios.post(url + "tradeapi/buyorder", postdata).then((res) => {
        if (res.data.message != "") {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          // $("#buyCrypto").modal("hide");
          return false;
        } else {
          this.handle_confirmbuycrypto();
          // alert("test")
          // return true;
        }
      });
    }
  }

  handle_confirmbuycrypto() {
    // if(this.handlebuycrypto()){
    let id = this.props.match.params.id;
    let userid = this.props.auth.user.id;
    let crypto_amount = this.state.crypto_amount;
    let total_fiat = this.state.total_fiat;
    let fiat_amount = this.state.fiat_amount;
    let BuyorSell = "Buy";
    let message = this.state.message;
    let terms = this.state.terms;

    if (terms == false) {
      store.addNotification({
        title: "Error!",
        message: "Please Accept terms and condtions.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      return false;
    }

    this.setState({ initiate_disable: true });
    let postdata = {
      _id: id,
      userid: userid,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
      total_fiat: total_fiat,
      BuyorSell: BuyorSell,
      _csrf: this.state._csrf,
    };
    axios.post(url + "tradeapi/savebuyorder", postdata).then((res) => {
      if (res.data.status == true) {
        let id = res.data._id;
        let userid = this.props.auth.user.id;
        let msg = this.state.message;
        let _csrf = this.state._csrf;
        if (msg != "") {
          let postdata_chat = {
            id: id,
            userid: userid,
            msg: msg,
            _csrf: _csrf,
          };
          axios
            .post(url + "tradeapi/savechat", postdata_chat)
            .then((res) => {});
        }

        store.addNotification({
          title: "Success!",
          message: "Order Placed Successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        // $("#buyCrypto").modal("hide");

        this.props.history.push("/TradeConversation/" + res.data._id);
        return false;
      } else {
        if (res.data.status == false) {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          // $("#buyCrypto").modal("hide");
          return false;
        }
      }
      this.setState({ initiate_disable: false });
    });
    // }
  }

  render() {
    const { selectedIssue } = this.state;
    return (
      <div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <div className="breadCrumb">
              <ul>
                <li>
                  <a href="#">Offers</a>
                </li>
                <li className="bcArrow">
                  <i class="fas fa-angle-right"></i>
                </li>
                <li>
                  Sell {this.state.buyadsdetails.firstCurrency} from{" "}
                  <a href="#">{this.state.buyadsdetails.user_info.username}</a>{" "}
                  with Bank Transfer
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="userBoxWhiteBox createTicket">
                  <h3 class="userBoxTitle">
                    <span>
                      Sell {this.state.buyadsdetails.firstCurrency} from
                    </span>{" "}
                    {this.state.buyadsdetails.user_info.username}
                  </h3>
                  <div class="form-group">
                    <label className="flexLabel">
                      <span>Trade amount</span>
                      <span className="labelBalance">
                        {numberWithCommas(this.state.buyadsdetails.minlimit)} -{" "}
                        {numberWithCommas(this.state.buyadsdetails.maxlimit)}{" "}
                        {this.state.buyadsdetails.secondCurrency}
                      </span>
                    </label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="fiat_amount"
                        name="fiat_amount"
                        onChange={this.handlecrypto}
                      />
                      {/* <input type="text" class="form-control" id="secondamount" value="2500.50" /> */}
                      <div class="input-group-append">
                        <span class="input-group-text">
                          {this.state.buyadsdetails.secondCurrency}
                        </span>
                      </div>
                      {/* <div class="input-group-append"><span class="input-group-text">USDT</span></div> */}
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        name="crypto_amount"
                        id="crypto_amount"
                        value={this.state.crypto_amount}
                        readOnly="true"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          {this.state.buyadsdetails.firstCurrency}
                        </span>
                      </div>
                      {/* <input type="text" class="form-control" id="secondamount" value="0.56898787" />
                        <div class="input-group-append"><span class="input-group-text">BTC</span></div> */}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Send a message</label>
                    <textarea
                      rows="3"
                      id="message"
                      name="message"
                      className="form-control"
                      onChange={this.handlemessage}
                    ></textarea>
                    {/* <textarea rows="3" className="form-control"></textarea> */}
                  </div>
                  {/* <div class="alert alert-primary" role="alert">
                    <div className="aletBoxCard">
                      <div className="alertboxIcon">
                        <img src={require("../images/alertIcon.png")} alt="" className="img-fluid" />
                      </div>
                      <div className="alertboxDescription">
                        <p>pbteja1998 usually replies within 60 minutes</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="form-group remForAction mt-4">
                    <div className="checkbox d-block w-100">
                      <label className="postADAgreement flexJustifyCenter">
                        <input
                          id="terms"
                          type="checkbox"
                          onChange={this.handleonchange}
                        />
                        <span className="cr">
                          <i className="cr-icon fa fa-check"></i>
                        </span>
                        <span className="agreeContent">
                          I agree to the{" "}
                          <a href="/terms">Terms and Conditions</a> and{" "}
                          <a href="/privacy">Privacy Policy</a>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group text-center">
                    <button
                      className="btn btnBlue"
                      onClick={() => this.handlebuycrypto()}
                      buyCrypto
                    >
                      Accept terms and send trade request
                    </button>
                    {/* <button class="btn btnBlue">Accept terms and send trade request</button> */}
                  </div>
                  <div class="alert alert-primary mb-0" role="alert">
                    <div className="aletBoxCard">
                      <div className="alertboxIcon">
                        <img
                          src={require("../images/alertIcon.png")}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="alertboxDescription">
                        <p>
                          Your privacy is protected with end-to-end encrypted
                          messages, and your crypto is protected with
                          non-custodial technology. Our staff cannot read your
                          messages without consent.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="userBoxWhiteBox ticketDetails">
                  <div className="tcTitleFlex">
                    <h5>You are the seller.</h5>
                    {/* <h5>Send the seller Bank Transfer.</h5> */}
                  </div>
                  <div className="BankDetailBox">
                    <div className="currentPrice">
                      <h2>
                        1 {this.state.currencyselected} ={" "}
                        {numberWithCommas(this.state.buyadsdetails.price)}{" "}
                        {this.state.posttocurrency}
                        <small>
                          Refreshing rate in <span>1:00</span> min
                        </small>
                      </h2>
                      {/* {this.state.curmarketprice.toFixed(8)} */}
                      {/* <h2>1 BTC = $54,500.56<small>Refreshing rate in <span>2:25</span> min</small></h2> */}
                    </div>
                  </div>
                  <div className="aboutSeller">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="asLeft">
                          <h4>About the seller</h4>
                          <div className="asLeftProfileDetails">
                            {/* <img
                              src={require("../images/profileIconLarge.png")}
                              alt=""
                              className="img-fluid"
                            /> */}
                            {this.state.buyadsdetails.user_info.icon ==
                            "BTC" ? (
                              <img
                                src={require("../images/dashTabIcon1.png")}
                                class="img-fluid"
                              />
                            ) : this.state.buyadsdetails.user_info.icon ==
                              "ETH" ? (
                              <img
                                src={require("../images/dashTabIcon2.png")}
                                class="img-fluid"
                              />
                            ) : this.state.buyadsdetails.user_info.icon ==
                              "USDT" ? (
                              <img
                                src={require("../images/dashTabIcon3.png")}
                                class="img-fluid"
                              />
                            ) : this.state.buyadsdetails.user_info.icon ==
                              "BCH" ? (
                              <img
                                src={require("../images/dashTabIcon4.png")}
                                class="img-fluid"
                              />
                            ) : this.state.buyadsdetails.user_info.icon ==
                              "XRP" ? (
                              <img
                                src={require("../images/dashTabIcon5.png")}
                                class="img-fluid"
                              />
                            ) : this.state.buyadsdetails.user_info.icon ==
                              "TRX" ? (
                              <img
                                src={require("../images/dashTabIcon6.png")}
                                class="img-fluid"
                              />
                            ) : this.state.buyadsdetails.user_info.icon ==
                              "DASH" ? (
                              <img
                                src={require("../images/dashTabIcon8.png")}
                                class="img-fluid"
                              />
                            ) : this.state.buyadsdetails.user_info.icon ==
                              "CELO" ? (
                              <img
                                src={require("../images/dashTabIcon9.png")}
                                class="img-fluid"
                              />
                            ) : (
                              ""
                            )}
                            <div className="profileSellerText">
                              <h3>
                                {this.state.buyadsdetails.user_info.username}
                              </h3>
                              <p>100% good feedback</p>
                              <p>
                                Registered{" "}
                                {moment(this.state.createdDate).format(
                                  "DD MMMM YYYY"
                                )}
                              </p>
                              <p>
                                Last Seen{" "}
                                <TimeAgo
                                  date={
                                    new Date(
                                      this.state.buyadsdetails.user_info
                                        .loginhistory &&
                                        this.state.buyadsdetails.user_info
                                          .loginhistory[0].createdDate
                                    )
                                  }
                                >
                                  {({ value }) => value}
                                </TimeAgo>
                              </p>
                              {this.state.buyadsdetails.BuyorSell == "Sell" ? (
                                <p>
                                  ~{" "}
                                  {
                                    this.state.buyadsdetails.user_info
                                      .sellCompletedTrade
                                  }{" "}
                                  trades
                                </p>
                              ) : (
                                <p>
                                  ~{" "}
                                  {
                                    this.state.buyadsdetails.user_info
                                      .buyCompletedTrade
                                  }{" "}
                                  trades
                                </p>
                              )}
                              {/* <p>~30 trades</p>
                              <p>~$10,000 volume</p> */}
                            </div>
                          </div>
                          <div className="form-group mt-2">
                            {/* <button class="btn btnBlueBordered w-50"></button> */}
                            <a
                              className="btn btnBlueBordered w-50"
                              href={
                                "/Profile/" +
                                this.state.buyadsdetails.user_info._id
                              }
                            >
                              View Profile
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="asRight">
                          <ul>
                            <li>
                              {this.state.trustStatus == "No" && (
                                <button
                                  onClick={() => this.handleTrust()}
                                  class="btn btn-profile-trust btn-warning smaller-text"
                                >
                                  <i class="fa fa-star"></i> Trust{" "}
                                  {this.state.buyadsdetails.user_info.username}
                                </button>
                              )}
                              {this.state.trustStatus == "Yes" && (
                                <div>
                                  <button
                                    class="btn btn-profile-trust btn-success smaller-text"
                                    disabled="disabled"
                                  >
                                    <i class="fa fa-star"></i> Already trusting{" "}
                                    {
                                      this.state.buyadsdetails.user_info
                                        .username
                                    }
                                  </button>
                                  <button
                                    type="submit"
                                    class="btn btn-danger"
                                    onClick={() => this.handleTrust()}
                                  >
                                    <i class="fa fa-ban"></i> Distrust{" "}
                                    {
                                      this.state.buyadsdetails.user_info
                                        .username
                                    }
                                  </button>
                                </div>
                              )}
                              {this.state.blockStatus == "No" && (
                                <p>
                                  <button>
                                    <i class="fas fa-times-circle redText mr-2"></i>
                                    <a onClick={() => this.handleBlock()}>
                                      {" "}
                                      Block this user
                                    </a>
                                  </button>
                                </p>
                              )}
                              {this.state.blockStatus == "Yes" && (
                                <p>
                                  <button>
                                    <i class="fas fa-check-circle greenText mr-2"></i>
                                    <a onClick={() => this.handleBlock()}>
                                      {" "}
                                      Unblock this user
                                    </a>
                                  </button>
                                </p>
                              )}
                            </li>
                            <li>
                              <h4>Preferred Transfer</h4>
                              {/* {this.state.buyadsdetails.prefcurrencytransfer => map} */}
                              {this.state.buyadsdetails &&
                                this.state.buyadsdetails.prefcurrencytransfer &&
                                this.state.buyadsdetails.prefcurrencytransfer.map(
                                  (number) => <p>{number.bank},</p>
                                )}
                              {/* <p>Bank Transfer</p>
                              <p>Mobile Pay,</p>
                              <p>Paypal payment.</p> */}
                            </li>
                            <li>
                              <h4>Location</h4>
                              <p>
                                {this.state.buyadsdetails &&
                                  this.state.buyadsdetails.country}
                              </p>
                              {/* <p>{getName(this.state.buyadsdetails&&this.state.buyadsdetails.country)}</p> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div >
                    <h4>Feedbacks</h4>
                    <div class="test">

                    {console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa",this.state.feedback.length)}
                    {this.state.feedback&&this.state.feedback.length>0&&this.state.feedback.map((key,item) => {
                    console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa",key)
                    return(
                      <span>
                     

                     <h5>Email</h5>:<h6>{key.email}</h6>
                       <h5>Date</h5>:<h6>{key.created_at}</h6>
                       <h5>Comments</h5>:<h6>{key.comments}</h6>
                  
</span>
                    )
                     
                    
                      
                    })}
                  
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="userBoxWhiteBox">
                        Feedbacks
                        <div class="table-responsive">
                          <table class="table table-striped profileDetailTable mb-0">
                            <tbody>
                              <th>
                                <label>
                                  <h5 className="userBoxTitle">Date</h5>
                                </label>
                              </th>
                              <th>
                                <label>
                                  <h5 className="userBoxTitle">Email</h5>
                                </label>
                              </th>
                              <th>
                                <label>
                                  <h5 className="userBoxTitle">Comments</h5>
                                </label>
                              </th>

                              {this.state.feedback &&
                                this.state.feedback.length > 0 &&
                                this.state.feedback.map((key, item) => {
                                  console.log(
                                    "aaaaaaaaaaaaaaaaaaaaaaaaaaa",
                                    key
                                  );

                                  return (
                                    <tr>
                                      <td>
                                        <label>
                                          <h6>
                                            {moment(key.created_at).format(
                                              "DD MMMM YYYY"
                                            )}
                                          </h6>
                                        </label>
                                      </td>
                                      <td>
                                        <label>
                                          <h6>
                                            {this.emaildecrypt(key.email)}
                                          </h6>
                                        </label>
                                      </td>
                                      <td>
                                        <label>
                                          <h6>{key.comments}</h6>
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

TradeBankRequestSell.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(TradeBankRequestSell));
