import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from 'react-select';
import { Modal, Button } from "react-bootstrap/";
import { store } from "react-notifications-component";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys, { numberWithCommas } from "../actions/config";
import { io } from "socket.io-client";
import CountdownTimer from "timer-countdown";
import * as moment from "moment";
import $ from "jquery";




const url = keys.baseUrl;



const selectIssue = [
  { value: 'Seller NOT released as per the contract', label: 'Seller NOT released as per the contract' },
  { value: 'Seller NOT released as per the contract', label: 'Seller NOT released as per the contract' },
  { value: 'Seller NOT released as per the contract', label: 'Seller NOT released as per the contract' },
];


function NewlineText(props) {
  const text = props.text;
  if (text.split("\n").length > 1) {
    return text.split("\n").map((str) => <p>{str}</p>);
  } else {
    return text;
  }
}
class TradeConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NoFeedback :  true,
      YesFeedback : false,
      errors: {},
      buyadsdetails: {
        user_info: {},
      },
      tradername: "",
      chats: [],
      message: "",
      attachment_flag: false,
      attachment: null,
      crypto_amount: "",
      fiat_amount: "",
      showmodel: false,
      timeleft: "",
      timecomplete: "No",
      trade_status: "",
      trade_status_num:0,
      _id: "",
      from_userId: "",
      send_disable:false,
      release_disable:false,
      cancel_disable:false,
      confirmpay_disable:false,
      twofa_status:false,
      tfacode:"",
      twofabtn_disable:false,
      _csrf:'',
      cancelReason:'',
      satisfy:'',
      likes:'',
      ratings:'',
      comments:'',
    };

    this.socket = io(keys.socketUrl, { transports: ["polling"] });
    // this.socket = io('http://localhost:2053', { secure: true, transports: ['polling'], jsonp: false, rejectUnauthorized: false }); //live

    let id = this.props.match.params.id;
    this.socket.on("p2pchat-" + id, function (data) {
      fetchGetChatData(data);
    });

    const fetchGetChatData = (data) => {
   
      
      if(data.trade_status=='completed'){
      var postdata = {};
      postdata.id = id;
      axios.post(url + "tradeapi/gettradestatus", postdata).then((res) => {
        if (res.data.status == false) {
          this.props.history.push("/dashboard");
        } else {
        
          if(res.data.trade_status==4){
            this.setState({
              timecomplete: "Yes",
            });
          

          store.addNotification({
            title: "Wonderful!",
            message: 'Trade Completed Successfully.',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });

          window.location.href = "/OrderHistory/completed";
        }
        }
      });
    }

      let chatData = [...this.state.chats, ...data]; // append array
      this.setState({ chats: chatData });
    };
  }

  

  getchatdata = (id) => {
    var postdata = {};
    postdata.id = id;
    postdata._csrf = this.state._csrf;
    axios.post(url + "tradeapi/getchats", postdata).then((res) => {
      if (res.data.status == false) {
        this.props.history.push("/dashboard");
      } else {
        this.setState({ chats: res.data.chats });
        if(res.data.trade_status==4){
         
          this.setState({
            trade_status_num: 4
          });
        }
      }
    });
  };

  handle_attach = (e) => {
    this.setState({ attachment_flag: true });
  };

  handleChangeefile = (event) => {
    if (event.target.files[0]) {
      var filesize = event.target.files[0].size;

      this.setState({
        curimage: URL.createObjectURL(event.target.files[0]),
        attachment: event.target.files[0],
      });
    }
  };

  Handleclose = (e) => {
    this.setState({ twofa_status: false});
   
  };

  RadiobuttonChange = (e) => {
    this.setState({
      [e.target.id]: e.currentTarget.value,
    });
  };

  handletextarea = (e) => {
    this.setState({
      [e.target.id]: e.currentTarget.value,
    });
  };

  
  submitfeedback = () => {
  
    let id = this.props.match.params.id;
    let userid = this.props.auth.user.id;

    var passData = {};
    passData.id = id;
    passData.userId = userid;
    passData.satisfy = this.state.satisfy;
    passData.likes = this.state.likes;
    passData.ratings = this.state.ratings;
    passData.comments = this.state.comments;
    // return false;

    axios.post(url + "tradeapi/savefeedback", passData).then((res) => {
      if (res.data.status) {
        store.addNotification({
          title: "Wonderful!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      
        window.location.href = "/Dashboard";
      } else {
        store.addNotification({
          title: "Error!",
          message: res.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      }
    });
  };


  


  

  completed = () => {
    this.setState({
      timecomplete: "Yes",
    });
  };
  tick = (leftTime) => {
    console.log(leftTime);
  };

  getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
          this.setState({_csrf:res.data._csrf});
          let id = this.props.match.params.id;
          this.getuserdata(id);
          this.getchatdata(id);
          this.updatechat(id);
        })
        .catch();
  }

  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    let id = this.props.match.params.id;
    let userid = this.props.auth.user.id;

    var passData = {};
    passData.id = id;
    passData.userid = userid;
    
     axios.post(url + "tradeapi/p2pFeedback", passData).then((res) => {
      this.setState({ userdetails: res.data.userdetails });
    });
}

 

  
 state = {
    selectedIssue: null,
  };
  

  // constructor(props) {
  //       super(props);
  //       this.state = { 
  //           NoFeedback :  true,
  //           YesFeedback : false,
  //       }; 
  //     }
 
  
  render() {
    const { errors } = this.state;
    const { selectedIssue } = this.state;
    const { chats } = this.state;
    return (
      <div>
          <UserNavbar />
      <section className="userPages">
          <div className="container">
          <div className="text-center">
                            <a href="/"><img src={require("../images/cfBuySell.png")} alt="" className="img-fluid" /></a>
                        </div>
             <div className="successBox">
                    <h3 className="greenNewText">Congratulations</h3>
                    <h3>Your Trade Completed Successfully!</h3>
                    <h4><small>Trade Transaction ID is</small> {this.props.match.params.id}</h4>
                    <p>Please share your experience</p>
             </div>
            <div className="pageBox">
                 <div class="form-group textGrey">
                   <label>Do you satisfy this trade?</label>
                   <div className="p2pRadio">
                   <div class="radio mr-5">
                                                    <label>
                                                        <input type="radio" name="satisfy" value="1" id="satisfy"  onChange={this.RadiobuttonChange} />
                                                        <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                          Yes
                                                    </label>
                                                </div>

                  <div class="radio mr-5">
                                                  <label>
                                                      <input type="radio" name="satisfy" id="satisfy" value="2" onChange={this.RadiobuttonChange} />
                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                        No
                                                  </label>
                                              </div>
                  <div class="radio">
                                                  <label>
                                                      <input type="radio" name="satisfy" id="satisfy" value="3" onChange={this.RadiobuttonChange} />
                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                        No Comments
                                                  </label>
                                              </div>
                                           </div>
            </div> 
             <div class="form-group textGrey">
                                            <label>Do you like this user <a href="#">{this.state && this.state.userdetails && this.state.userdetails.username}</a> to trade again?</label>
                                            <div className="p2pRadio">
                                                <div class="radio mr-5">
                                                    <label>
                                                        <input type="radio" name="likes" id="likes" value="1" onChange={this.RadiobuttonChange} />
                                                        <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                          Yes
                                                    </label>
                                                </div>
                                                <div class="radio mr-5">
                                                  <label>
                                                      <input type="radio" name="likes" id="likes"  value="2" onChange={this.RadiobuttonChange} />
                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                        No
                                                  </label>
                                              </div>
                                               <div class="radio">
                                                  <label>
                                                      <input type="radio" name="likes" id="likes" value="3" onChange={this.RadiobuttonChange} />
                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                        Not Sure
                                                  </label>
                                              </div>
                                             </div>
                                          </div>                                                               
                <div class="form-group textGrey">
                                            <label>Ratings with this user <a href="#">{this.state && this.state.userdetails && this.state.userdetails.username}</a></label>
                                            <div className="p2pRadio">
                                                <div class="radio mr-5">
                                                    <label>
                                                        <input type="radio" name="ratings" id="ratings" value="1" onChange={this.RadiobuttonChange} />
                                                        <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                          Positive
                                                    </label>
                                                </div>
                                                <div class="radio mr-5">
                                                  <label>
                                                      <input type="radio" name="ratings" id="ratings" name="satisfyWithTrade" value="2" onChange={this.RadiobuttonChange}  />
                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                        Negative
                                                  </label>
                                              </div>
                                               <div class="radio">
                                                  <label>
                                                      <input type="radio" name="ratings" id="ratings"  value="3" onChange={this.RadiobuttonChange} />
                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                        No Comments
                                                  </label>
                                              </div>
                                             </div>
                                          </div>
            </div>  <div className="form-group textGrey">
                                            <label>Give your comments</label>
                                            <textarea className="form-control" name="comments" id="comments" rows="5"  onChange={this.handletextarea}></textarea>
                                          </div>
                                          <div className="form-group text-center">
                                            <button className="btn btnDefaultRounded mb-2" data-toggle="modal" data-target="#addBankDetails"   onClick={this.submitfeedback}>Submit</button>
                                          </div>
            
          </div>
        </section>

        <Footer />
</div>
);
  }
}

TradeConversation.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(TradeConversation));