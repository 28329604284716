import React, { Component } from 'react'
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import keys, { numberWithCommas } from "../actions/config";
import {
  saveuserNewsletter,
} from "../actions/authActions";

const url = keys.baseUrl;



class Footer extends Component{

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      email_id:'',
      _csrf:'',
    }
  }

  getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
          this.setState({_csrf:res.data._csrf});
        })
        .catch();
  }

  componentDidMount() {
    this.getCSRFtoken();
    let theme=localStorage.getItem("xavitheme");
    if(theme==null){
      localStorage.setItem('xavitheme','dark_theme')
    }{
      if(theme=='dark_theme'){
        document.body.classList.remove('light_theme' );
        document.body.classList.add('dark_theme');
        this.setState({appliedtheme:'dark_theme',isButtonActive:true})
      }else{
        document.body.classList.remove('dark_theme' );
        document.body.classList.add('light_theme');
        this.setState({appliedtheme:'light_theme',isButtonActive:false})
      }
     
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  savenewsletter = (e) => {
    e.preventDefault();
    const postdata = {
     email: this.state.email_id,
     _csrf: this.state._csrf,
    };
    this.props.saveuserNewsletter(postdata);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.forgotuser && nextProps.auth.forgotuser.data) {
      if(nextProps.auth.forgotuser.data.message){
        store.addNotification({
          title: "Error!",
          message: nextProps.auth.forgotuser.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        nextProps.auth.forgotuser = undefined;
      }else{
        store.addNotification({
          title: "Wonderful!",
          message: nextProps.auth.forgotuser.data.successMessage,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        nextProps.auth.forgotuser = undefined;
        this.setState({email_id:''});
      }
    }
  }


	render(){
      const { errors } = this.state;
			return (<div>
       <footer className="mainFooter">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4">
                  <div className="footerLogo wow fadeInUp">
                    <a href="#"><img src={require("../images/landingLogo.png")} className="img-fluid" alt="" /></a>
                  </div>
                </div>
                <div className="col-lg-5 col-md-8">
                  <div className="middLinkFooter wow fadeInUp">
                    <div className="row">
                      <div className="col-md-7 col-sm-6 mb-4 mb-sm-0">
                        <h3>Platform</h3>
                        <ul>
                          <li><a href="/PaymentMethod">Payment Methods</a></li>
                          <li><a href="/Buy">Trade Bitcoin(BTC)</a></li>
                          <li><a href="/Buy">Trade Ether(ETH)</a></li>
                          <li><a href="/Buy">Trade Ripple(USDT)</a></li>
                        </ul>
                      </div>
                      <div className="col-md-5 col-sm-6">
                        <h3>Resources</h3>
                        <ul>
                          <li><a href="/Faq">FAQ</a></li>
                          <li><a href="/Settings">Referral</a></li>
                          <li><a href="/SupportTicket">Support</a></li>
                          <li><a href="/News">News</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="footerSubscribe wow fadeInUp">
                    <h3>Get the latest news and updates</h3>
                    <form className="subscribForm" onSubmit={this.savenewsletter}>
                      <div className="input-group subscribeForm">
                        <input type="text" name="email_id" id="email_id" value={this.state.email_id}  onChange={this.onChange} className="form-control" placeholder="Enter your Email" />
                        <div className="input-group-append">
                          <span className="input-group-text p-0"><button  className="btn btnBlue">Submit</button></span>
                        </div>
                      </div>
                    </form>
                    <ul className="soacialFooterLinks">
                      <li><a href="https://www.facebook.com/profile.php?id=100073459562883" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                      <li><a href="https://twitter.com/RealCoinBarter" target="_blank"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://www.linkedin.com/feed/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                      <li><a href="https://www.instagram.com/coin_barter/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                      <li><a href="https://web.telegram.org/k/" target="_blank"><i className="fab fa-telegram"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerBottom">
              <div className="container">
                <div className="footerBottomBox wow fadeInUp">
                  <p>© Copyright  2022, All Rights Reserved</p>
                   <ul className="footerBottomLinks">
                   <li><a href="terms">Terms and Conditions</a></li>
                    <li><a href="privacy">Privacy Policy</a></li> 
                    <li><a href="cookies_policy">Cookies Policy</a></li> 

                  </ul>
                </div>
                </div>
            </div>
          </footer>
        </div>
);
	};
}

Footer.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {saveuserNewsletter})(withRouter(Footer));