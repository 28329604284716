import React, {Fragment,Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/animate.css';
import './css/style.css';
import "react-notifications-component/dist/theme.css";

import store from "./store";
import {Provider} from "react-redux"
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import ReactNotification from "react-notifications-component";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PrivateRoute from "./components/private-route/PrivateRoute";
import Landing from './components/Landing'
import Login from './components/Login'
import Register from './components/Register'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from "./components/resetpassword"
import Dashboard from './components/Dashboard'
import SupportTicket from './components/SupportTicket'
import Settings from './components/Settings'
import Buy from './components/Buy'
import ProfileDetail from './components/ProfileDetail'
import FAQ from './components/Faq'
import PaymentMethod from './components/PaymentMethod'
import Wallet from './components/Wallet'
import FiatWallet from './components/FiatWallet'
import WalletDepositWithdraw from './components/WalletDepositWithdraw'
import WalletDepositWithdrawCrypto from './components/WalletDepositWithdrawCrypto'
import TwoFactor from './components/TwoFactor';
import OfferCategory from './components/OfferCategory'
import PostDetails from './components/PostDetails'
import PostSummary from './components/PostSummary'
import PostTrade from './components/PostTrade'
import TradeConversation from './components/TradeConversation'
import TradeBankRequest from './components/TradeBankRequest'
import TradeBankRequestSell from './components/TradeBankRequestSell'
import Sell from './components/Sell'
import MyAccount from './components/MyAccount'
import Profile from './components/Profile'
import MoreBuysell from './components/MoreBuysell'
import News from './components/News'
import NewsDetail from './components/NewsDetail'
import Notifications from './components/Notifications'
import Myactivity from './components/Myactivity'
import P2pFeedback from './components/P2pFeedback'
import Notification from './components/Notification'
import Terms from './components/terms'
import Privacy from './components/privacy';
import CookiesPolicy from './components/cookiePolicy';

if (localStorage.jwtToken) {
	const token = localStorage.jwtToken;
	setAuthToken(token);
	const decoded = jwt_decode(token);
	store.dispatch(setCurrentUser(decoded));
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		store.dispatch(logoutUser());
		window.location.href = "./Login";
	}
}


class App extends Component {
	render() {
  return (
	<Provider store={store}>
		<ReactNotification />
			<Router basename={'/'}>
				<Route exact path='/' component={Landing} />
					<Switch>
						<Route exact path='/Notifications' component={Notifications} />
						<Route exact path='/NewsDetail/:id' component={NewsDetail} />
						<Route exact path='/News' component={News} />
			      		<Route exact path='/PostTrade' component={PostTrade} />
			      		<Route exact path='/PostSummary' component={PostSummary} />
			      		<Route exact path='/PostDetails' component={PostDetails} />
			      		<Route exact path='/OfferCategory' component={OfferCategory} />
						<Route exact path="/Activate/:id" component={Login} />
						<Route exact path='/Login' component={Login} />
						<Route exact path='/Signup' component={Register} />
						<Route exact path='/Signup/:id' component={Register} />
						<Route exact path='/ForgotPassword' component={ForgotPassword} />
						<Route exact path="/resetpassword/:id" component={ResetPassword}/>
						<Route exact path="/TwoFactor/:id" component={TwoFactor} />
						<Route exact path='/FiatWithdraw/:id' component={Landing} />
						<Route exact path='/Withdraw/:id' component={Landing} />
						<Route exact path='/MoreBuy' component={MoreBuysell} />
						<Route exact path='/terms' component={Terms} />
						<Route exact path='/cookies_policy' component={CookiesPolicy} />
						<Route exact path='/privacy' component={Privacy} />
						<Route exact path='/MoreSell' component={MoreBuysell} />
						<PrivateRoute exact path='/Wallet' component={Wallet} />
						<PrivateRoute exact path='/FiatWallet' component={FiatWallet} />
	      				<PrivateRoute exact path='/WalletDepositWithdraw' component={WalletDepositWithdraw} />
						  <PrivateRoute exact path='/WalletDepositWithdraw/:id' component={WalletDepositWithdraw} />
	      				<PrivateRoute exact path='/WalletDepositWithdrawCrypto' component={WalletDepositWithdrawCrypto} />
						<PrivateRoute exact path='/Dashboard' component={Dashboard} />
						<PrivateRoute exact path='/SupportTicket' component={SupportTicket} />
						<PrivateRoute exact path='/Settings' component={Settings} />
			      		<PrivateRoute exact path='/MyAccount' component={MyAccount} />
						<PrivateRoute exact path='/Buy' component={Buy} />
			      		<PrivateRoute exact path='/Sell' component={Sell} />
						<PrivateRoute exact path='/ProfileDetail' component={ProfileDetail} />
						<Route exact path='/Faq' component={FAQ} />
						<PrivateRoute exact path='/PaymentMethod' component={PaymentMethod} />
						<PrivateRoute exact path='/Profile/:id' component={Profile} />
						<PrivateRoute exact path='/TradeBankRequest/:id' component={TradeBankRequest} />
						<PrivateRoute exact path='/TradeBankRequestSell/:id' component={TradeBankRequestSell} />
			      		<PrivateRoute exact path='/TradeConversation/:id' component={TradeConversation} />
			      		<PrivateRoute exact path='/Myactivity' component={Myactivity} />
			      		<PrivateRoute exact path='/P2pfeedback/:id' component={P2pFeedback} />
			      		<PrivateRoute exact path='/Notification' component={Notification} />
					</Switch>
				</Router>
	</Provider>
  );
	}
}

export default App;

