import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from 'react-select';
import axios from "axios";
import { store } from "react-notifications-component";
import keys, { numberWithCommas } from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap/";
import * as moment from "moment";
import queryString from "query-string";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import config from "../actions/config"




const url = keys.baseUrl;

const preferredCoin = [
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'XRP', label: 'XRP' },
  { value: 'DASH', label: 'DASH' },
  { value: 'LTC', label: 'LTC' },
];

class ProfileDetail extends Component {
  constructor() {
    super();
    this.state = {
      icon: "",
      selectedPhoneCode: null,
      selectedbankName: null,
      selectedDepositFrom: null,
      selectedWithdrawTo: null,
      selectedCurrencyType: null,
      preferredtransfer: {
				label: "Select",
				value: "",
			},
      selectedcountry: { label: "Nigeria", value: "Nigeria" },
      firstname: "",
      lastname: "",
      email: "",
      _id: "",
      emailotp: "",
      mobileotp: "",
      errors: {},
      phone: "",
      birthDate: "",
      address: "",
      city: "",
      postalcode: "",
      country: "",
      usernameold: "",
      totallikes: 0,
      kycstatus: "",
      createdDate: new Date(),
      ratings: 0,
      emailverified: false,
      mobilenumber: "",
      accountno: "",
      ifsccode: "",
      bankname: "",
      bankdetails: [],
      ViewBankAccount: false,
      currencydetails: [],
      bankdboptions: "",
      adminbankdetails: [],
      tocurrency: "",
      selectedadmin: [],
      toifsccode: "",
      tobankaccountno: "",
      tobankname: "",
      bankselected: "",
      depositamount: "",
      history: [],
      withdrawcurrency: "",
      walletbalance: 0,
      amount: 0,
      tfacode: "",
      balancedetails: [],
      googlestatus: "Disabled",
      withdrawtobank: "",
      history: [],
      date: "",
      deposithistory: [],
      activetab: "",
      Skeletonwith: 0,
      completed_trades: 0,
      kycstatus: "Not Verified",
      last_seen:"",
      createdDate:"",
      myadshistory: [],
      offset_myads: 0,
      offset_searchads: 0,
      editadshow: false,
      editableboxshow: false,
      cancelpopupshow: false,
      userbalance: 0,
      trustedCount: 0,
      blockCount: 0,
      buyCompletedTrade:0,
      sellCompletedTrade:0,
    };
  }

  onChange = (e) => {
    if (e.target.id == "terms" || e.target.id == "editterms") {
      if (e.target.value.length > 1000) {
        store.addNotification({
          title: "Error!",
          message: "Only Allowed 1000 Character",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        return false;
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  onChangedate = (e) => {
    this.setState({ date: e });
  };

  getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
          this.setState({_csrf:res.data._csrf});
          this.getmyads();
          // var csrftoken=res.data._csrf
        })
        .catch();
  }
  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    this.getCSRFtoken();
    let id = this.props.auth.user.id;
    this.getuserdetails(id);
    this.getfeedback(id);
    this.getpreferred_transfer();

  }

  getpreferred_transfer() {
		axios.get(url + "tradeapi/transfer_options").then((res) => {
		  if (res.data.status) {
			var pref = [];
			res.data.preferredtransfer.map((details, index) => {
				console.log(details.BankName,"------------>details123")
			  pref.push({
				value: details.BankName,
				label: (
				  <span>
					{" "}
					<img
					  width="20"
					  height="20"
					  src={config.imageUrl+"/banklogo/"+details.banklogo}
					  class="css-pjaw30"
					/>{" "}
					{details.BankName}
				  </span>
				),
			  });
			});
	
			this.setState({ preferredtransfer: pref });
		  }
		});
  }

  getuserdetails(id) {
      let userid = this.props.auth.user.id;
  
      var ctab = this.props.match.params.id;
  
      if (ctab == "deposit" || ctab == "withdraw") {
        let userid = this.props.auth.user.id;
        axios.get(url + "users/userget/" + userid).then((res) => {
          if (res.data.kycstatus != "Approved") {
            this.setState({ kycstatus: res.data.kycstatus });
            store.addNotification({
              title: "Error!",
              message:
                "You need to update your verification status to continue using Aya",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
  
         this.props.history.push("/TierVerification/");
          }
        });
      }
  
      axios
        .get(url + "users/userget/" + userid)
        .then((res) => {
          if (res.status == 200) {
            // .splice(-1)[0]
            var last_login= res.data.loginhistory.splice(-1)[0]
            if(last_login){
              this.setState({last_seen:last_login.createdDate}) 
            }
            var total_ratings =
              res.data.positive_ratings + res.data.negative_ratings;
            if (total_ratings > 0) {
              var avg_positive_ratings =
                (res.data.positive_ratings / total_ratings) * 100;
            } else {
              var avg_positive_ratings = 0;
            }
            var negative_ratings = 100 - avg_positive_ratings;
            this.setState({
              avg_positive_ratings: avg_positive_ratings,
              negative_ratings: negative_ratings,
            });
            
            var kyc =res.data.kyc
            if(kyc.length==0)
            {
              this.setState({kycstatus : "Not updated" })
            }
            else{
              var updatedKyc = kyc[kyc.length -1];
              if(updatedKyc.status == 0){
                console.log("updatedKyc.statusupdatedKyc.statusupdatedKyc.status",updatedKyc.status)
                this.setState({kycstatus : "Pending" })
              }
              else if(updatedKyc.status == 2){
                this.setState({kycstatus : "Approved" })
              }
              else{
                this.setState({kycstatus : "Rejected" })
              }
            }

            this.setState({
              userid: res.data.userid,
              name: res.data.name,
              otp: res.data.otp,
              firstname: res.data.firstname,
              middlename: res.data.middlename,
              lastname: res.data.lastname,
              phone: res.data.phonenumber,
              email: res.data.email,
              emailverified: res.data.emailverified,
              username: res.data.username,
              usernameold: res.data.username,
              mobilenumber: res.data.phonenumber,
              createdDate: res.data.date,
              googlestatus: res.data.google,
              totallikes: res.data.totallikes,
              icon: res.data.icon,
              trustedCount: res.data.trustedCount,
              blockCount: res.data.blockCount,
              buyCompletedTrade: res.data.buyCompletedTrade,
				      sellCompletedTrade: res.data.sellCompletedTrade,
              kycstatus: res.data.kycstatus,
              createdDate: res.data.date,
              // IdentityVerification:res.data.kycstatus=="Approved"?"KYC Verified":res.data.kycstatus=="Rejected"?"Declined":"None"
            });
  
            this.props.auth.user.name = res.data.name;
          }
        })
        .catch();
  }

  getfeedback(id) {
    let data = { userid: id,"_csrf":this.state._csrf };
    axios.get(url + "users/getfeedback/" + id).then((res) => {
      if (res.data.status) {
        this.setState({ feedbacks: res.data.feedbacks });

      }
    });
  }


 
  handlePreferredCoin = selectedPreferredCoin => {
    this.setState({ selectedPreferredCoin });
  };
  // Add related
  searchmyads() {
    this.getmyads();
  }

  getBalance() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      this.setState({ balancedetails: res.data.data });
      var tempdata = res.data.data;
      var index = tempdata.findIndex(
        (x) => x.currencySymbol === this.state.currencyselected
      );
      if (index != -1) {
        this.setState({ userbalance: tempdata[index].balance });
      }
    });
  }
  
  getmyads() {
    let userid = this.props.auth.user.id;
    var date = new Date(this.state.date);
    var year = date.getFullYear();

    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }

    var datenew = year + "-" + month + "-" + dt;

    var postdata = {
      userid: userid,
      limit: this.props.perPage_myads,
      offset: this.state.offset_myads,
      myaddate: datenew,
      myadname: this.state.myadname,
      _csrf: this.state._csrf,
    };
    axios.post(url + "tradeapi/usermyads", postdata).then((res) => {
      if (res.data.status) {
        this.setState({
          myadshistory: res.data.tradedata,
          pageCount_myads: Math.ceil(res.data.total_count / res.data.limit),
        });
      }
    });
  }

  // Page handling
  handlePageClick_myads = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage_myads);

    this.setState({ offset_myads: offset }, () => {
      this.getmyads();
    });
  };

  // Cancel add
  showcancelPopup = (id) => {
    this.setState({
      postadid: id,
      cancelpopupshow: !this.state.cancelpopupshow,
    });
  };

  CancelAd = (e) => {
    let userid = this.props.auth.user.id;
    var passData = {};
    passData.userId = userid;
    passData.adid = this.state.postadid;
    passData._csrf = this.state._csrf;
    axios.post(url + "tradeapi/cancelmyad", passData).then((res) => {
      if (res.data.status) {
        store.addNotification({
          title: "Wonderful!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        this.getBalance();
        this.getmyads();
        this.setState({ cancelpopupshow: !this.state.cancelpopupshow });
      } else {
        store.addNotification({
          title: "Error!",
          message: res.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      }
    });
  };

  // Edit add

  EditMyAdPopupSHow = (addetails) => {
    var bankarr = [];
    if (
      addetails.prefcurrencytransfer.length > 0 &&
      Array.isArray(addetails.prefcurrencytransfer)
    ) {
      addetails.prefcurrencytransfer.map((details, index) => {
        console.log(details,"------------>detailsdetails")
        bankarr.push({
          value: details.bank,
          label: (
            <span>
              {" "}
              <img
                width="20"
                height="20"
                src={config.imageUrl+"/banklogo/"+details.banklogo}
                class="css-pjaw30"
              />{" "}
              {details.bank}
            </span>
          ),
          banklogo:details.banklogo
        });
      });
    }

    this.setState({
      editadshow: true,
      postminlimit: addetails.minlimit,
      postmaxlimit: addetails.maxlimit,
      posttradeprice: addetails.price,
      postprefcurrency: addetails.prefcurrencytransfer,
      postprefoption: bankarr,
      postadid: addetails._id,
      editadstatus: addetails.status,
      editterms: addetails.terms,
    });
  }; 

  Makeeditable = (e) => {
    this.setState({ editableboxshow: !this.state.editableboxshow });
  };
  handleclosemodal = (e) => {
    this.setState({ cancelpopupshow: !this.state.cancelpopupshow });
  };

  HandleEditAd = (e) => {
    this.setState({ editadshow: !this.state.editadshow });
    this.setState({ editableboxshow: !this.state.editableboxshow });
  };

  UpdateEditMyAd = (e) => {
    let userid = this.props.auth.user.id;
    var passData = {};
    passData.userId = userid;
    passData.postadid = this.state.postadid;
    passData.postminlimit = this.state.postminlimit;
    passData.postmaxlimit = this.state.postmaxlimit;
    passData.posttradeprice = this.state.posttradeprice;
    passData.postprefcurrency = this.state.postprefoption;
    passData.editterms = this.state.editterms;
    passData._csrf = this.state._csrf;
		var message = "";
    if (this.state.postprefoption.length > 2) {
		  message = "Please select maximum 2 Preferred Transfer";
		}
    if (message != "") {
		  store.addNotification({
			title: "Error!",
			message: message,
			type: "danger",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
			  duration: 1500,
			  onScreen: true,
			},
		  });
		  return false;
		} else {
      axios.post(url + "tradeapi/editpostedad", passData).then((res) => {
        if (res.data.status) {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          this.getmyads();
          this.setState({ editadshow: !this.state.editadshow });
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      });
    }
  };

  handlepostpreferrededitable = (postprefoption) => {
    this.setState({ postprefoption });
    this.setState({ postprefcurrency: postprefoption.value });
  };
  


	render() {
   const { selectedPreferredCoin,postprefoption } = this.state;
   const { errors } = this.state;
   const {} = this.state;
		return (
      <div>
      <UserNavbar />
      <Modal
          show={this.state.cancelpopupshow}
          onHide={() => {
            this.handleclosemodal();
          }}
          centered
        >
          <Modal.Header closeButton>
            <h4>Cancel Trade</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="modal-body">
                  <p>Are you sure you want to cancel the Ad?</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnType1 smallFont py-2 px-3 mr-2"
              onClick={this.handleclosemodal}
            >
              Cancel
            </button>

            <button
              className="btn btnType1 smallFont py-2 px-3 mr-2"
              onClick={this.CancelAd}
            >
              Yes,Cancel Ad
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.editadshow}
          onHide={() => {
            this.HandleEditAd();
          }}
          centered
        >
          <Modal.Header closeButton>
            <h4> Details</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="modal-body">
                  {this.state.editableboxshow ? (
                    <ul className="overviewList">
                      <li>
                        <h5>Price</h5>
                        <p>
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.posttradeprice}
                            error={errors.posttradeprice}
                            id="posttradeprice"
                          />
                        </p>
                      </li>
                      <li>
                        <h5>Min Limit</h5>
                        <p>
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.postminlimit}
                            error={errors.postminlimit}
                            id="postminlimit"
                          />
                        </p>
                      </li>
                      <li>
                        <h5>Max Limit</h5>
                        <p>
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.postmaxlimit}
                            error={errors.postmaxlimit}
                            id="postmaxlimit"
                          />
                        </p>
                      </li>

                      <li>
                        <h5>Payment Type</h5>

                        <div className="form-group">
                          <label>Preferred fiat currency transfer</label>
                          <Select
                            isMulti
                            value={postprefoption}
                            onChange={this.handlepostpreferrededitable}
                            options={this.state.preferredtransfer}
                          />
                        </div>

                      
                      </li>

                      <li>
                        <div className="form-group">
                          <label>Terms of Trade</label>
                          <textarea
                            rows="8"
                            id="editterms"
                            name="editterms"
                            className="form-control"
                            value={this.state.editterms}
                            onChange={this.onChange}
                          ></textarea>
                        </div>
                        <p>
                          <span class="agreeContent">Notes :</span> Only Allowed
                          1000 Character
                        </p>
                      </li>
                    </ul>
                  ) : (
                    <ul className="overviewListView">
                      <li>
                        <h5>Price</h5>
                        <p>{this.state.posttradeprice}</p>
                      </li>
                      <li>
                        <h5>Min Limit</h5>
                        <p>{this.state.postminlimit}</p>
                      </li>
                      <li>
                        <h5>Max Limit</h5>
                        <p>{this.state.postmaxlimit}</p>
                      </li>

                      <li>
                        <h5>Payment Type</h5>
                        <p>
                          {this.state.postprefcurrency &&
                            this.state.postprefcurrency &&
                            this.state.postprefcurrency.length > 0 &&
                            Array.isArray(this.state.postprefcurrency) &&
                            this.state.postprefcurrency.map((transfer, i) => {
                              return <div> {transfer.bank}</div>;
                            })}
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnType1 smallFont py-2 px-3 mr-2"
              onClick={this.HandleEditAd}
            >
              Cancel
            </button>

            {this.state.editadstatus == "0" ? (
              <div>
                {this.state.editableboxshow ? (
                  <button
                    className="btn btnType1 smallFont py-2 px-3 mr-2"
                    onClick={this.UpdateEditMyAd}
                  >
                    Update Order
                  </button>
                ) : (
                  <button
                    className="btn btnType1 smallFont py-2 px-3 mr-2"
                    onClick={this.Makeeditable}
                  >
                    Edit Order
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
      <section className="userPages">
            <div className="container">
                <div className="ProfileDetailNameTitle">
                  <a href="/MyAccount">
                 {
                                      this.state.icon == "BTC"  ?
                                      <img src={require("../images/dashTabIcon1.png")} class="img-fluid" />
                                      : this.state.icon == "ETH" ?
                                      <img src={require("../images/dashTabIcon2.png")} class="img-fluid" />
                                      : this.state.icon == "USDT" ?
                                      <img src={require("../images/dashTabIcon3.png")} class="img-fluid" />
                                      : this.state.icon == "BCH" ?
                                      <img src={require("../images/dashTabIcon4.png")} class="img-fluid" />
                                      : this.state.icon == "XRP" ?
                                      <img src={require("../images/dashTabIcon5.png")} class="img-fluid" />
                                      : this.state.icon == "TRX" ?
                                      <img src={require("../images/dashTabIcon6.png")} class="img-fluid" />
                                      : this.state.icon == "DASH" ?
                                      <img src={require("../images/dashTabIcon8.png")} class="img-fluid" />
                                      : this.state.icon == "CELO" ?
                                      <img src={require("../images/dashTabIcon9.png")} class="img-fluid" />
                                      : ''
                                    }
                    {/* <img src={require("../images/profileIcon.jpg")} alt="" className="img-fluid" /> */}
                    {/* <img src={require("../images/dashTabIcon1.png")} class="img-fluid" /> */}
                    {this.state.username} <span>Profile</span>
                  </a>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                      <div className="userBoxWhiteBox">
                        <h3 className="userBoxTitle">{this.state.username} Trade Post</h3>
                        <div className="input-group flexInputGroup">
                          {/* <div className="activityFilterHead"> */}
                              <label className="mr-2 mb-0">Filter</label>
                              <div className="form-group w-100">
                                <DatePicker
                                className="form-control"
                                  selected={this.state.date}
                                  onChange={this.onChangedate} //only when value has changed
                                  placeholder="Date"
                                />
                              </div>
                              <div className="form-group w-100">
                                <input
                                  type="text"
                                  name=""
                                  id="myadname"
                                  onChange={this.onChange}
                                  className="form-control"
                                  placeholder="Search by Post Type or Pair"
                                />
                              </div>
                              <div class="form-group mb-0">
                                <button
                                  class="btn btnType1 roundedCorner py-1 ml-2"
                                  onClick={() => {
                                    this.searchmyads();
                                  }}
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                            {/* <label className="mb-0 mr-2">Buy/Sell</label>
                            <Select
                                value={selectedPreferredCoin}
                                onChange={this.handlePreferredCoin}
                                options={preferredCoin}
                            />
                            <label className="mb-0 ml-2">from <b>{this.state.username}</b></label> */}
                          {/* </div> */}
                        {/* <div className="profileDetailBuySellRow">
                          <div class="pdBsCard">
                            <h2>$47,878.25 <small>- Bank Transfer</small><span>1.256985 - 0.002547</span></h2>
                            <button className="btn greenBorderButton">Buy</button>
                          </div>
                          <button className="btn greenBorderButton">Buy</button>
                          <button className="btn greenBorderButton">Buy</button>
                          <div class="pdBsCard">
                            <h2>$47,878.25 <small>- Bank Transfer</small><span>1.256985 - 0.002547</span></h2>
                            <button className="btn greenBorderButton">Buy</button>
                          </div>
                        </div>
                        <div className="profileDetailBuySellRow">
                          <div class="pdBsCard">
                            <h2>$47,878.25 <small>- Bank Transfer</small><span>1.256985 - 0.002547</span></h2>
                            <button className="btn redBorderButton">Sell</button>
                          </div>
                          <div class="pdBsCard">
                            <h2>$47,878.25 <small>- Bank Transfer</small><span>1.256985 - 0.002547</span></h2>
                            <button className="btn redBorderButton">Sell</button>
                          </div>
                        </div> */}
                        {this.state.myadshistory.length > 0 ? (
                              <div>
                                {this.state.myadshistory.map((item, i) => {
                                  var price = item.price.toFixed(2);
                                  if (item.status == "5") {
                                    var status = "Completed";
                                    var statusclass = "orangeText";
                                  } else {
                                    var status =
                                      item.status == "0"
                                        ? "Open"
                                        : item.status == "1"
                                        ? "Completed"
                                        : "Cancelled";

                                    var statusclass =
                                      item.status == "0"
                                        ? "greenText"
                                        : "orangeText";
                                  }

                                  return (
                                    // <div className="profileDetailBuySellRow">
                                    //   <div class="pdBsCard">
                                    //     <h2>{item.secondCurrency}{" "}{numberWithCommas(price)} <small>- Price Set</small><span className={statusclass}>{status}</span></h2>
                                    //     {item.BuyorSell == "Buy" 
                                    //       ? <button className="btn greenBorderButton">{item.BuyorSell}</button>
                                    //       : <button className="btn redBorderButton">{item.BuyorSell}</button>
                                    //     }
                                    //   </div>
                                    //     <p>
                                    //       {/* <small>Digital Asset</small>1.52365878 BTC */}
                                    //       <small>Pair</small>
                                    //       {item.firstCurrency}-
                                    //       {item.secondCurrency}
                                    //     </p>
                                    //   {
                                    //         item.flag_edit == "0" &&
                                    //         item.status != 3 ? (
                                    //             <button
                                    //               class="btn btnType4 roundedCorner py-1 ml-2"
                                    //               onClick={() => {
                                    //                 this.EditMyAdPopupSHow(
                                    //                   item
                                    //                 );
                                    //               }}
                                    //             >
                                    //               View/Edit Ad
                                    //             </button>
                                    //         ) : (
                                    //           ""
                                    //         )
                                    //         // (
                                    //         //Cancelled   <p>
                                    //         //     <button
                                    //         //       class="btn btnType4 roundedCorner py-1 ml-2"
                                    //         //       onClick={() => {
                                    //         //         this.EditMyAdPopupSHow(item);
                                    //         //       }}
                                    //         //     >
                                    //         //       View Ad
                                    //         //     </button>
                                    //         //   </p>
                                    //         // )
                                    //       }

                                    //       {item.flag_cancel == "0" &&
                                    //       item.status != 3 ? (
                                    //           <button
                                    //             class="btn btnType5 roundedCorner py-1 ml-2"
                                    //             onClick={() => {
                                    //               this.showcancelPopup(
                                    //                 item._id
                                    //               );
                                    //             }}
                                    //           >
                                    //             Cancel Ad
                                    //           </button>
                                    //       ) : (
                                    //         ""
                                    //       )}
                                    //   {/* <button className="btn greenBorderButton">Buy</button>
                                    //   <button className="btn greenBorderButton">Buy</button> */}
                                    // </div>
                                    <div className="row mx-0">
                                      <div className="col-md-4">
                                        <div className="myAdListCard">
                                          <p>
                                            <small>Post Created on</small>
                                            {moment(item.orderDate).format(
                                              "DD-MM-YYYY  k:mm:s "
                                            )}
                                          </p>
                                          <p>
                                            {/* <small>Digital Asset</small>1.52365878 BTC */}
                                            <small>Pair</small>
                                            {item.firstCurrency}-
                                            {item.secondCurrency}
                                          </p>
                                          {/* <p>
                                            <small>Payment Preference</small>
                                            {item.prefcurrencytransfer}
                                          </p> */}
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="myAdListCard">
                                          <p>
                                            <small>Post Type</small>
                                            {item.BuyorSell}
                                          </p>
                                          <p>
                                            <small>Price Set</small>
                                            {(price)}{" "}
                                            {item.secondCurrency}
                                          </p>

                                          {
                                            item.flag_edit == "0" &&
                                            item.status == 0 ? (
                                              <p>
                                                {console.log(item,"------------>itemitemitem")}
                                                <button
                                                  class="btn btnType4 roundedCorner py-1 ml-2"
                                                  onClick={() => {
                                                    this.EditMyAdPopupSHow(
                                                      item
                                                    );
                                                  }}
                                                >
                                                  View/Edit Ad
                                                </button>
                                              </p>
                                            ) : (
                                              ""
                                            )
                                            // (
                                            //Cancelled   <p>
                                            //     <button
                                            //       class="btn btnType4 roundedCorner py-1 ml-2"
                                            //       onClick={() => {
                                            //         this.EditMyAdPopupSHow(item);
                                            //       }}
                                            //     >
                                            //       View Ad
                                            //     </button>
                                            //   </p>
                                            // )
                                          }

                                          {item.flag_cancel == "0" &&
                                          item.status == 0 ? (
                                            <p>
                                              <button
                                                class="btn btnType5 roundedCorner py-1 ml-2"
                                                onClick={() => {
                                                  this.showcancelPopup(
                                                    item._id
                                                  );
                                                }}
                                              >
                                                Cancel Ad
                                              </button>
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="myAdListCard">
                                          <p>
                                            <small>Post ID</small>
                                            <span className="post_id">{item._id}</span>
                                          </p>
                                          <p>
                                            <small>Trade Status</small>
                                            <span className={statusclass}>
                                              {status}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="row mx-0">
                                <div className="col-md-4">
                                  <div className="myAdListCard"></div>
                                </div>
                                <div className="col-md-4">
                                  <div className="myAdListCard">
                                    <p>Oops No Ads Found!</p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="myAdListCard"></div>
                                </div>
                              </div>
                            )}
                            {this.state.myadshistory.length > 0 && (
                              <div id="react-paginate">
                                <ReactPaginate
                                  previousLabel={"« Previous »»»"}
                                  breakLabel={"..."}
                                  nextLabel={"««« Next »"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={this.state.pageCount_myads}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={this.handlePageClick_myads}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                />
                              </div>
                            )}
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="userBoxWhiteBox">
                        <h3 className="userBoxUnderlineTitle">Profile Details</h3>
                        <div class="table-responsive">
                          <table class="table table-striped profileDetailTable mb-0">
                            <tbody>
                              <tr>
                                <td><label>Username</label></td>
                                <td><p>{this.state.username}  <small className="ml-2 greenText"><i class="fas fa-circle"></i></small></p></td>
                                {/* <i class="far fa-star ml-2 yellowText"></i> */}
                              </tr>
                              {/* <tr>
                                <td><label>Trade volume</label></td>
                                <td><p>2.5 BTC, 10.5 ETH, 1500 XRP</p></td>
                              </tr> */}
                              <tr>
                                <td><label>Number of confirmed trades</label></td>
                                <td><p className="blueText">{this.state.buyCompletedTrade+this.state.sellCompletedTrade}+</p></td>
                              </tr>
                              <tr>
                                <td><label>Feedback score</label></td>
                                <td><p className="greenText">{parseFloat(
                                        this.state.avg_positive_ratings
                                      ).toFixed(2)}</p></td>
                              </tr>
                              {/* <tr>
                                <td><label>Real name verification </label></td>
                                <td><p>1  trusted verifications, 1 verifications, 0 rejected</p></td>
                              </tr>
                              <tr>
                                <td><label>First purchase</label></td>
                                <td><p>1 year, 1 month ago</p></td>
                              </tr> */}
                              <tr>
                                <td><label>Account created</label></td>
                                <td><p><TimeAgo
                                                date={new Date(this.state.createdDate)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              <tr>
                                <td><label>Last seen</label></td>
                                
                                <td><p><TimeAgo
                                                date={new Date(this.state.last_seen)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              <tr>
                                <td><label>Language</label></td>
                                <td><p>English</p></td>
                              </tr>
                              <tr>
                                <td><label>Username</label></td>
                                <td><p><i class="fas fa-check-circle greenText mr-2"></i> Verified <TimeAgo
                                                date={new Date(this.state.createdDate)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              <tr>
                                <td><label>Email</label></td>
                                <td><p><i class="fas fa-check-circle greenText mr-2"></i> Verified <TimeAgo
                                                date={new Date(this.state.createdDate)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              {/* <tr>
                                <td><label>Phone number</label></td>
                                <td><p><i class="fas fa-check-circle greenText mr-2"></i> Verified 1 year, 2 months ago</p></td>
                              </tr> */}
                              <tr>
                                <td><label>kyc status</label></td>
                                {this.state.kycstatus == "Approved" ?
                                   <td><p><i class="fas fa-check-circle greenText mr-2"></i> {this.state.kycstatus}</p></td> 
                                   : this.state.kycstatus == "Pending" ?
                                    <td><p><i class="fas fa-times-circle yellowText mr-2"></i> {this.state.kycstatus}</p></td>
                                    : this.state.kycstatus == "Rejected" ?
                                    <td><p><i class="fas fa-times-circle redText mr-2"></i> {this.state.kycstatus}</p></td>
                                    :
                                  <td><p><i class="fas fa-times-circle redText mr-2"></i> {this.state.kycstatus}</p></td>
                                 } 
                              </tr>
                              {/* <tr>
                                <td><label>Trust</label></td>
                                <td><p>Trusted by {this.state.trustedCount} people</p></td>
                              </tr> */}
                              <tr>
                                <td><label>Blocks</label></td>
                                <td><p>Blocked by {this.state.blockCount} person</p></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
</div>
);
	}
}

ProfileDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  perPage: 5,
  perPage_myads: 5,
});

export default connect(mapStateToProps, {})(withRouter(ProfileDetail));