import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import googleCaptcha from "../images/googleCaptcha.jpg";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import Select from "react-select";
import { store } from "react-notifications-component";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys from "../actions/config";
import ReactPaginate from "react-paginate";
import ReactFlagsSelect from "react-flags-select";
import config from "../actions/config";

const url = keys.baseUrl;

const coinPair = [
  { value: "BTC - Bitcoin", label: "BTC - Bitcoin" },
  { value: "XRP- Ripple", label: "XRP- Ripple" },
  { value: "ETH - Ethereum", label: "ETH - Ethereum" },
];

const paymentType = [
  { value: "Bank transfer", label: "Bank transfer" },
  { value: "Online Payment", label: "Online Payment" },
  { value: "Wallet Service", label: "Wallet Service" },
];
const TradersCompleted = [
  { value: 1, label: "1+" },
  { value: 10, label: "10+" },
  { value: 100, label: "100+" },
  { value: 500, label: "500+" },
];

class Buy extends Component {
  //  state = {
  //     selectedCoinPair: null,
  //     selectedpaymentType: null,
  //     selectedTradersCompleted: null,
  //   };
  constructor(props) {
    super(props);
    this.state = {
      selectedCoinPair: null,
      selectedpaymentType: null,
      selectedTradersCompleted: null,
      errors: {},
      buyads: [],
      sellads: [],
      buytab: "",
      selltab: "",
      findadstab: "active",
      buycheckbox: true,
      sellcheckbox: false,
      buytradecheckbox: true,
      selltradecheckbox: false,
      Skeletonbuy: 1,
      Skeletonsell: 0,
      // selectedCurrencyType: {
      //   label: "BTC",
      //   value: "BTC",
      // },

      // selectedtocpostcurrency: {
      //   label: "NGN",
      //   value: "NGN",
      // },
      selectedpreferred: {
        label: "Select",
        value: "",
      },
      selectedDepositFrom: {
        label: null,
        value: null,
      },
      preferredtransfer: {
        label: "Select",
        value: "",
      },
      curmarketprice: 0,
      BuyorSell: "Sell",
      BuyorSell_find: "Sell",
      tradetype: "Sell",
      balancedetails: [],
      IdentityVerification: "",
      email: "",
      username: "",
      currencydetails: [],
      userbalance: 0,
      currencyselected: "",
      posttocurrency: "",
      postprefcurrency: "",
      spotpairsdata: [],
      posttradeprice: "",
      postminlimit: "",
      postmaxlimit: "",
      posttooptions: [],
      postcheckboxaccept: false,
      margin: 0,
      myadshistory: [],
      submitreview: false,
      bankdetails: [],
      bankdboptions: "",
      bankselected: "",
      editadshow: false,
      editableboxshow: false,
      cancelpopupshow: false,
      myactivities: [],
      data: [],
      offset_myads: 0,
      offset_searchads: 0,
      activetab: "",
      myadname: "",
      myaddate: "",
      date: "",
      terms: "",
      editterms: "",
      kycstatus: "Not Verified",
      placorder_disable: false,
      _csrf: "",
      showTrade: 0,
      viewAllUser: false,
      onlyOnlineUser: false,
      buyCompletedTrade: 0,
      sellCompletedTrade: 0,
      country: "",
    };
  }

  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
        this.getmyads();
        this.getbuyads();
        this.searchfind_ads("Sell", 0);
        // var csrftoken=res.data._csrf
      })
      .catch();
  };
  // Did mount
  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    this.getCSRFtoken();
    this.getUserdata();
    this.getBalance();
    this.getcurrencydetails();

    this.getspotdata();
    // this.getbankdetails();
    // this.getmyads();
    // this.getmyactivity();
    this.getpreferred_transfer();
  }

  getbuyads() {
    let userid = this.props.auth.user.id;

    var postdata = {
      userid: userid,
      limit: this.props.perPage,
      offset: this.state.offset_buyads,
      _csrf: this.state._csrf,
    };
    axios.post(url + "tradeapi/getbuyads", postdata).then((res) => {
      this.setState({
        buyads: res.data.tradedata,
        buytab: "on",
        pageCount: Math.ceil(res.data.total_count / res.data.limit),
      });
      this.setState({ sellads: [], buytab: "off" });
    });
  }

  getUserdata() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/userget/" + userid).then((res) => {
      this.setState({ kycstatus: res.data.kycstatus });
      if (res.data.kycstatus != "Approved") {
        store.addNotification({
          title: "Error!",
          message:
            "You need to update your verification status to continue using Coinbarter",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });

        this.props.history.push("/MyAccount/");
      }
    });
  }
  getBalance() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      this.setState({ balancedetails: res.data.data });
      var tempdata = res.data.data;
      var index = tempdata.findIndex(
        (x) => x.currencySymbol === this.state.currencyselected
      );
      if (index != -1) {
        this.setState({ userbalance: tempdata[index].balance });
      }
    });
  }
  getspotdata() {
    axios.get(url + "api/spotpair-data").then((res) => {
      if (res.data.status) {
        this.setState({ spotpairsdata: res.data.data });
        var tempdata = res.data.data;
        var pair = this.state.currencyselected + this.state.posttocurrency;
        var indexonchcange = this.state.spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          var onchbaseprice =
            this.state.spotpairsdata[indexonchcange].index_price;
          this.setState({ curmarketprice: onchbaseprice });
        }
      }
    });
  }

  // Get currency details
  getcurrencydetails() {
    axios.get(url + "api/currency").then((res) => {
      this.setState({ currencydetails: res.data });
      var firstcur = res.data;
      var newcurar = [];
      firstcur.map((details, index) => {
        if (details.type == "Crypto") {
          if (details.p2p == "On") {
            newcurar.push({
              value: details.currencySymbol,
              label: details.currencySymbol,
            });
          }
        }
      });
      var fiatcur = [];
      firstcur.map((details, index) => {
        if (details.type == "Fiat") {
          if (details.p2p == "On") {
            fiatcur.push({
              value: details.currencySymbol,
              label: details.currencySymbol,
            });
          }
        }
      });
      var tocurrecur = [];
      firstcur.map((details, index) => {
        if (details.currencySymbol != this.state.currencyselected) {
          tocurrecur.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });
      this.setState({ posttooptions: tocurrecur });
      this.setState({ currencyoptions: newcurar, fiatcuroption: fiatcur });
    });
  }

  // getpreferred_transfer() {
  //   axios.get(url + "tradeapi/preferred_transfer").then((res) => {
  //     if (res.data.status) {
  //       var pref = [];
  //       res.data.preferredtransfer.map((details, index) => {
  //         pref.push({
  //           value: details.bank,
  //           label: (
  //             <span>
  //               {" "}
  //               <img
  //                 width="20"
  //                 height="20"
  //                 src={require("../images/payment/" +
  //                   details.bank.replace(/[ )(]/g, "") +
  //                   ".png")}
  //                 class="css-pjaw30"
  //               />{" "}
  //               {details.bank}
  //             </span>
  //           ),
  //         });
  //       });

  //       this.setState({ preferredtransfer: pref });
  //     }
  //   });
  // }

  getpreferred_transfer() {
    axios.get(url + "tradeapi/transfer_options").then((res) => {
      if (res.data.status) {
        var pref = [];
        res.data.preferredtransfer.map((details, index) => {
          // console.log(details.BankName,"------------>details123")
          pref.push({
            value: details.BankName,
            label: (
              <span>
                {" "}
                <img
                  width="20"
                  height="20"
                  src={config.imageUrl + "/banklogo/" + details.banklogo}
                  class="css-pjaw30"
                />{" "}
                {details.BankName}
              </span>
            ),
          });
        });

        this.setState({ preferredtransfer: pref });
      }
    });
  }

  // Add related

  buttonChange_buy = (e) => {
    this.setState({
      // tradetype: "Buy"
      tradetype: "Buy",
      buytab: "on",
      selltab: "off",
    });
    // this.setState({ buycheckbox: true });
    // this.setState({ sellcheckbox: false });

    // this.setState({ Skeletonbuy: 1, Skeletonsell: 0 });

    this.searchfind_ads("Buy", 0);
  };

  buttonChange_sell = (e) => {
    this.setState({
      // tradetype: "Sell"
      tradetype: "Sell",
      buytab: "off",
      selltab: "on",
    });
    // this.setState({ buycheckbox: false });
    // this.setState({ sellcheckbox: true });
    // this.setState({ Skeletonbuy: 0, Skeletonsell: 1 });
    this.searchfind_ads("Sell", 0);
  };

  searchfind_ads = (tradetype, offset) => {
    if (offset == 0) {
      this.setState({ buyads: [], sellads: [] });
    }
    const fc = this.state.currencyselected;
    const sc = this.state.posttocurrency;
    const adstype = tradetype;
    const fc_amount = this.state.firstamount;
    const sc_amount = this.state.secondamount;
    const transfer = this.state.postprefcurrency_find;
    const postprefcurrency = this.state.postprefcurrency;
    let userid = this.props.auth.user.id;
    const country = this.state.country;

    var data_find = {
      fc: fc,
      sc: sc,
      adstype: adstype,
      fc_amount: fc_amount,
      sc_amount: sc_amount,
      transfer: postprefcurrency,
      userid: userid,
      limit: this.props.perPage,
      offset: offset,
      buyCompletedTrade: this.state.buyCompletedTrade,
      sellCompletedTrade: this.state.sellCompletedTrade,
      onlineStatus: this.state.showTrade,
      country: country,
      _csrf: this.state._csrf,
    };

    if (adstype == "Buy") {
      axios.post(url + "tradeapi/search_find_ads", data_find).then((res) => {
        this.setState({
          buyads: res.data.tradedata,
          pageCount: Math.ceil(res.data.total_count / res.data.limit),
        });
        this.setState({ sellads: [] });
        this.setState({ Skeletonbuy: 0, Skeletonsell: 0 });
      });
    }

    if (adstype == "Sell") {
      axios.post(url + "tradeapi/search_find_ads", data_find).then((res) => {
        this.setState({
          sellads: res.data.tradedata,
          pageCount: Math.ceil(res.data.total_count / res.data.limit),
        });
        this.setState({ buyads: [] });
        this.setState({ Skeletonbuy: 0, Skeletonsell: 0 });
      });
    }
  };

  searchmyads() {
    this.getmyads();
  }

  getmyads() {
    let userid = this.props.auth.user.id;
    var date = new Date(this.state.date);
    var year = date.getFullYear();

    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }

    var datenew = year + "-" + month + "-" + dt;

    var postdata = {
      userid: userid,
      limit: this.props.perPage_myads,
      offset: this.state.offset_myads,
      myaddate: datenew,
      myadname: this.state.myadname,
      _csrf: this.state._csrf,
    };
    axios.post(url + "tradeapi/usermyads", postdata).then((res) => {
      if (res.data.status) {
        this.setState({
          myadshistory: res.data.tradedata,
          pageCount_myads: Math.ceil(res.data.total_count / res.data.limit),
        });
      }
    });
  }

  handlePageClick = (data) => {
    if (this.state.tradetype == "Sell") {
      this.setState({ Skeletonbuy: 1, Skeletonsell: 0 });
    } else {
      this.setState({ Skeletonbuy: 0, Skeletonsell: 1 });
    }
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage);

    this.setState({ offset_searchads: offset }, () => {
      this.searchfind_ads(this.state.tradetype, offset);
    });
  };

  handleCoinPair = (selectedCoinPair) => {
    this.setState({ selectedCoinPair });
  };
  handlepaymentType = (selectedpaymentType) => {
    this.setState({ selectedpaymentType });
  };
  handleTradersCompleted = (selectedTradersCompleted) => {
    if (this.state.tradetype == "Buy") {
      this.setState({
        selectedTradersCompleted,
        buyCompletedTrade: selectedTradersCompleted.value,
      });
    } else {
      this.setState({
        selectedTradersCompleted,
        sellCompletedTrade: selectedTradersCompleted.value,
      });
    }
  };

  handleCurrencyType = (selectedCurrencyType) => {
    this.setState({ selectedCurrencyType });
    this.setState({ currencyselected: selectedCurrencyType.value });
    var pair = selectedCurrencyType.value + this.state.posttocurrency;
    var tempprice = 0;
    var curindex = this.state.spotpairsdata.findIndex(
      (x) => x.tiker_root === pair
    );
    if (curindex != -1) {
      tempprice = this.state.spotpairsdata[curindex].index_price;
      this.setState({
        curmarketprice: this.state.spotpairsdata[curindex].index_price,
      });
    }
    var index = this.state.balancedetails.findIndex(
      (x) => x.currencySymbol === selectedCurrencyType.value
    );
    if (index != -1) {
      this.setState({ userbalance: this.state.balancedetails[index].balance });
    }

    var fiatcur = [];
    this.state.currencydetails.map((details, index) => {
      if (details.currencySymbol != selectedCurrencyType.value) {
        fiatcur.push({
          value: details.currencySymbol,
          label: details.currencySymbol,
        });
      }
    });

    this.setState({ posttooptions: fiatcur });
  };

  handleposttocurrency = (selectedtocpostcurrency) => {
    this.setState({ selectedtocpostcurrency });
    this.setState({ posttocurrency: selectedtocpostcurrency.value });

    var pair = this.state.currencyselected + selectedtocpostcurrency.value;
    var tempprice = 0;
    var curindex = this.state.spotpairsdata.findIndex(
      (x) => x.tiker_root === pair
    );
    if (curindex != -1) {
      tempprice = this.state.spotpairsdata[curindex].index_price;
      this.setState({
        curmarketprice: this.state.spotpairsdata[curindex].index_price,
      });
    }
  };

  handlepostpreferred = (selectedpreferred) => {
    this.setState({ selectedpreferred });
    this.setState({ postprefcurrency: selectedpreferred.value });
  };

  handlesecondamount = (e) => {
    this.setState({
      [e.target.id]: e.currentTarget.value,
    });
  };

  handleGeneral = (e) => {
    if (e.target.name == "showTrade") {
      if (e.target.name == "showTrade" && e.target.value == 1) {
        this.setState({ onlyOnlineUser: false, viewAllUser: true });
      } else if (e.target.name == "showTrade" && e.target.value == 0) {
        this.setState({ onlyOnlineUser: true, viewAllUser: false });
      }
      this.setState({ showTrade: e.target.value });
    }
  };

  handlebuy = (id, type) => {
    if (type == "buy") {
      this.props.history.push("/TradeBankRequest/" + id);
    }
    if (type == "sell") {
      this.props.history.push("/TradeBankRequestSell/" + id);
    }
  };

  onSelectCountry = (countryCode) => {
    this.setState({ country: countryCode });
  };
  render() {
    const { selectedCoinPair, selectedCurrencyType, selectedtocpostcurrency } =
      this.state;
    const { selectedpaymentType } = this.state;
    const { selectedTradersCompleted } = this.state;
    return (
      <div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="userBoxWhiteBox BuySellCryptoForm">
                  <div className="input-group flexInputGroup">
                    <label className="mb-0 mr-3">I want to</label>
                    <button
                      className={
                        this.state.tradetype === "Buy"
                          ? "btn greenBorderButton px-4 mr-3 active"
                          : "btn greenBorderButton px-4 mr-3"
                      }
                      onClick={this.buttonChange_buy}
                    >
                      Buy
                    </button>
                    <button
                      className={
                        this.state.tradetype === "Sell"
                          ? "btn redBorderButton px-4  active"
                          : "btn redBorderButton px-4 "
                      }
                      onClick={this.buttonChange_sell}
                    >
                      Sell
                    </button>
                  </div>
                  <div className="form-group">
                    <label>Select Crypto</label>
                    <Select
                      value={selectedCurrencyType}
                      onChange={this.handleCurrencyType}
                      options={this.state.currencyoptions}
                    />
                    {/* <Select
                            value={selectedCoinPair}
                            onChange={this.handleCoinPair}
                            options={coinPair}
                         /> */}
                  </div>
                  {/* <div className="form-group">
                      <label>Amount</label>
                      <div class="input-group">
                        <input type="text" class="form-control" id="secondamount" />
                        <div class="input-group-append"><span class="input-group-text">BTC</span></div>
                      </div>
                    </div> */}
                  <div className="form-group">
                    <label>Price</label>
                    <div className="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="secondamount"
                        onChange={this.handlesecondamount}
                      />
                      <div class="input-group-append">
                        {/* <Select
                                        value={selectedCurrencyType}
                                        onChange={this.handleCurrencyType}
                                        options={currencyType}
                                      /> */}
                        <Select
                          value={selectedtocpostcurrency}
                          onChange={this.handleposttocurrency}
                          options={this.state.fiatcuroption}
                        />
                      </div>
                    </div>
                    {this.state.posttocurrency && this.state.currencyselected && (
                      <h2 className="balanceTitle">
                        1 {this.state.currencyselected} ={" "}
                        {this.state.curmarketprice} {this.state.posttocurrency}
                      </h2>
                    )}
                    {/* <div class="input-group">
                        <input type="text" class="form-control" id="secondamount" />
                        <div class="input-group-append"><span class="input-group-text">USDT</span></div>
                      </div> */}
                  </div>
                  <div className="form-group">
                    <label>Preferred Transfer </label>
                    <Select
                      onChange={this.handlepostpreferred}
                      options={this.state.preferredtransfer}
                    />
                    {/* <label>Payment Type</label>
                      <Select
                            value={selectedpaymentType}
                            onChange={this.handlepaymentType}
                            options={paymentType}
                         /> */}
                  </div>
                  <div className="HistoryList">
                    <div class="historyAccordian wow fadeIn">
                      <div id="accordion">
                        <div class="card">
                          <div class="card-header wow flipInX" id="headingOne">
                            <h5 class="mb-0">
                              <button
                                class="btn btn-link"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <span class="question">Advanced Search</span>{" "}
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </button>
                            </h5>
                          </div>
                          <div
                            id="collapseOne"
                            class="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div class="card-body">
                              <div className="form-group">
                                <label>Traders online status</label>
                                <div className="dashRadio">
                                  <div class="radio mr-4">
                                    <label>
                                      <input
                                        type="radio"
                                        name="showTrade"
                                        value={1}
                                        onChange={this.handleGeneral}
                                        checked={this.state.viewAllUser}
                                      />
                                      {/* <input type="radio" name="onLineStatus" value="" /> */}
                                      <span class="cr">
                                        <i class="cr-icon fa fa-circle"></i>
                                      </span>
                                      All
                                    </label>
                                  </div>
                                  <div class="radio">
                                    <label>
                                      <input
                                        type="radio"
                                        name="showTrade"
                                        value={0}
                                        onChange={this.handleGeneral}
                                        checked={this.state.onlyOnlineUser}
                                      />
                                      {/* <input type="radio" name="onLineStatus" value="" /> */}
                                      <span class="cr">
                                        <i class="cr-icon fa fa-circle"></i>
                                      </span>
                                      Online Only
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="form-group">
                                                            <label>Favourite trader status</label>
                                                            <div className="dashRadio">
                                                                <div class="radio mr-4">
                                                                  <label>
                                                                      <input type="radio" name="favouriteStatus" value="" />
                                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                                        All
                                                                  </label>
                                                                </div>
                                                                <div class="radio">
                                                                  <label>
                                                                      <input type="radio" name="favouriteStatus" value="" />
                                                                      <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                                                        Online Only
                                                                  </label>
                                                              </div>
                                                            </div>
                                                         </div> */}
                              <div className="form-group">
                                <label>Trader country (optional)</label>
                                <ReactFlagsSelect
                                  id="country"
                                  searchable
                                  onSelect={this.onSelectCountry}
                                  placeholder="Select a country"
                                  selected={this.state.country}
                                />
                                {/* <input type="text" value="" className="form-control" name="" /> */}
                              </div>
                              <div className="form-group">
                                <label>Trades completed</label>
                                <Select
                                  value={selectedTradersCompleted}
                                  onChange={this.handleTradersCompleted}
                                  options={TradersCompleted}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group text-center">
                    <input
                      type="submit"
                      className="roundedCorner d-block w-100 py-2"
                      name=""
                      value="Search"
                      onClick={() => {
                        this.searchfind_ads(this.state.tradetype, 0);
                      }}
                    />
                    {/* <input type="submit" name="" class="roundedCorner d-block w-100 py-2" value="Search" /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="userBoxWhiteBox buySellCryptoBox">
                  <h3 class="userBoxUnderlineTitle">Posted ad</h3>
                  {this.state.buyads &&
                    this.state.buyads.length > 0 &&
                    this.state.buyads.map((item, i) => {
                      var total_ratings =
                        item.user_info.positive_ratings +
                        item.user_info.negative_ratings;
                      if (total_ratings > 0) {
                        var avg_positive_ratings =
                          (item.user_info.positive_ratings / total_ratings) *
                          100;
                      } else {
                        var avg_positive_ratings = 0;
                      }
                      return (
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                {/* <td><i className="far fa-star"></i></td> */}
                                <td>
                                  <label>
                                    {item.user_info.username}
                                    <small>
                                      {item.user_info.buyCompletedTrade}+ Trades{" "}
                                      <small
                                        class={
                                          item.user_info.is_online == 1
                                            ? "greenText"
                                            : "greyText"
                                        }
                                      >
                                        <i class="fas fa-circle"></i>
                                      </small>
                                    </small>
                                  </label>
                                </td>
                                {/* <td><label>Bank Transfer <small>India, Delhi</small></label></td> */}
                                <td>
                                  <label>
                                    {item.secondCurrency}/{item.firstCurrency}{" "}
                                    {item.price}
                                    <small>
                                      {item.minlimit} - {item.maxlimit}{" "}
                                      {item.secondCurrency}
                                    </small>
                                  </label>
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0);"
                                    id={item._id}
                                    onClick={() =>
                                      this.handlebuy(item._id, "buy")
                                    }
                                    className="btn greenBorderButton"
                                  >
                                    Buy
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                  {this.state.buyads && this.state.buyads.length > 0 && (
                    <div id="react-paginate">
                      <ReactPaginate
                        previousLabel={"« Previous"}
                        nextLabel={"» Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}

                  {this.state.buyads &&
                    this.state.buyads.length == 0 &&
                    this.state.buytab == "on" && (
                      <div style={{ textAlign: "center" }}>
                        Oops No Result Found
                      </div>
                    )}
                  {this.state.sellads.length > 0 &&
                    this.state.sellads.map((item, i) => {
                      var total_ratings =
                        item.user_info.positive_ratings +
                        item.user_info.negative_ratings;
                      if (total_ratings > 0) {
                        var avg_positive_ratings =
                          (item.user_info.positive_ratings / total_ratings) *
                          100;
                      } else {
                        var avg_positive_ratings = 0;
                      }
                      return (
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                {/* <td><i className="far fa-star"></i></td> */}
                                <td>
                                  <label>
                                    {item.user_info.username}
                                    <small>
                                      {item.user_info.sellCompletedTrade}+
                                      Trades{" "}
                                      <small
                                        class={
                                          item.user_info.is_online == 1
                                            ? "greenText"
                                            : "greyText"
                                        }
                                      >
                                        <i class="fas fa-circle"></i>
                                      </small>
                                    </small>
                                  </label>
                                </td>
                                {/* <td><label>Bank Transfer <small>India, Delhi</small></label></td> */}
                                <td>
                                  <label>
                                    {item.secondCurrency}/{item.firstCurrency}{" "}
                                    {item.price}
                                    <small>
                                      {item.minlimit} - {item.maxlimit}{" "}
                                      {item.secondCurrency}
                                    </small>
                                  </label>
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0);"
                                    id={item._id}
                                    onClick={() =>
                                      this.handlebuy(item._id, "sell")
                                    }
                                    className="btn redBorderButton"
                                  >
                                    Sell
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                  {this.state.sellads.length > 0 && (
                    <div id="react-paginate">
                      <ReactPaginate
                        previousLabel={"« Previous"}
                        nextLabel={"» Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                  {this.state.sellads &&
                    this.state.sellads.length == 0 &&
                    this.state.selltab == "on" && (
                      <div style={{ textAlign: "center" }}>
                        Oops No Result Found
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Buy.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  perPage: 5,
  perPage_myads: 5,
});

export default connect(mapStateToProps, {})(withRouter(Buy));
