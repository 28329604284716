import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from 'react-select';
import axios from "axios";
import { store } from "react-notifications-component";
import keys, { numberWithCommas } from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap/";
import * as moment from "moment";
import queryString from "query-string";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";




const url = keys.baseUrl;

const preferredCoin = [
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'XRP', label: 'XRP' },
  { value: 'DASH', label: 'DASH' },
  { value: 'LTC', label: 'LTC' },
];

class Profile extends Component {
    constructor() {
        super();
        this.state = {
          errors: {},
          userdetails: {},
          avg_positive_ratings:0,
          negative_ratings:0,
          feedbacks:[],
          _csrf:'',
        };
      }
      getCSRFtoken =async () => {
        await axios
            .get(url + "getCSRF")
            .then((res) => {
              this.setState({_csrf:res.data._csrf});
              let id = this.props.match.params.id;
              this.getuserdetails(id);
              this.getfeedback(id);
            })
            .catch();
      }
    
      componentDidMount() {
        let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
        this.getCSRFtoken();
      }
    
      getuserdetails(id) {
        let data = { userid: id,_csrf:this.state._csrf };
        axios.post(url + "tradeapi/userbyid/", data).then((res) => {
          if (res.data.status) {
            this.setState({ userdetails: res.data.userdetails });
           var total_ratings =  res.data.userdetails.positive_ratings+ res.data.userdetails.negative_ratings;
            if(total_ratings>0){
            var avg_positive_ratings = (res.data.userdetails.positive_ratings/total_ratings)*100;
            } else {
              var avg_positive_ratings =0;
            }
            var negative_ratings = 100-avg_positive_ratings;
            this.setState({ avg_positive_ratings: avg_positive_ratings,negative_ratings:negative_ratings });
    
          }
        });
      }
    
      getfeedback(id) {
        let data = { userid: id };
        axios.post(url + "tradeapi/getfeedback/", data).then((res) => {
          if (res.data.status) {
            this.setState({ feedbacks: res.data.feedbacks });
          }
        });
      }

	render() {
   const { selectedPreferredCoin,postprefoption } = this.state;
   const { errors } = this.state;
   const {} = this.state;
		return (
      <div>
      <UserNavbar />
      <Modal
          show={this.state.cancelpopupshow}
          onHide={() => {
            this.handleclosemodal();
          }}
          centered
        >
          <Modal.Header closeButton>
            <h4>Cancel Trade</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="modal-body">
                  <p>Are you sure you want to cancel the Ad?</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnType1 smallFont py-2 px-3 mr-2"
              onClick={this.handleclosemodal}
            >
              Cancel
            </button>

            <button
              className="btn btnType1 smallFont py-2 px-3 mr-2"
              onClick={this.CancelAd}
            >
              Yes,Cancel Ad
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.editadshow}
          onHide={() => {
            this.HandleEditAd();
          }}
          centered
        >
          <Modal.Header closeButton>
            <h4> Details</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="modal-body">
                  {this.state.editableboxshow ? (
                    <ul className="overviewList">
                      <li>
                        <h5>Price</h5>
                        <p>
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.posttradeprice}
                            error={errors.posttradeprice}
                            id="posttradeprice"
                          />
                        </p>
                      </li>
                      <li>
                        <h5>Min Limit</h5>
                        <p>
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.postminlimit}
                            error={errors.postminlimit}
                            id="postminlimit"
                          />
                        </p>
                      </li>
                      <li>
                        <h5>Max Limit</h5>
                        <p>
                          <input
                            className="form-control"
                            type="number"
                            onChange={this.onChange}
                            value={this.state.postmaxlimit}
                            error={errors.postmaxlimit}
                            id="postmaxlimit"
                          />
                        </p>
                      </li>

                      <li>
                        <h5>Payment Type</h5>

                        <div className="form-group">
                          <label>Preferred fiat currency transfer</label>
                          <Select
                            isMulti
                            value={postprefoption}
                            onChange={this.handlepostpreferrededitable}
                            options={this.state.preferredtransfer}
                          />
                        </div>

                      
                      </li>

                      <li>
                        <div className="form-group">
                          <label>Terms of Trade</label>
                          <textarea
                            rows="8"
                            id="editterms"
                            name="editterms"
                            className="form-control"
                            value={this.state.editterms}
                            onChange={this.onChange}
                          ></textarea>
                        </div>
                        <p>
                          <span class="agreeContent">Notes :</span> Only Allowed
                          1000 Character
                        </p>
                      </li>
                    </ul>
                  ) : (
                    <ul className="overviewListView">
                      <li>
                        <h5>Price</h5>
                        <p>{this.state.posttradeprice}</p>
                      </li>
                      <li>
                        <h5>Min Limit</h5>
                        <p>{this.state.postminlimit}</p>
                      </li>
                      <li>
                        <h5>Max Limit</h5>
                        <p>{this.state.postmaxlimit}</p>
                      </li>

                      <li>
                        <h5>Payment Type</h5>
                        <p>
                          {this.state.postprefcurrency &&
                            this.state.postprefcurrency &&
                            this.state.postprefcurrency.length > 0 &&
                            Array.isArray(this.state.postprefcurrency) &&
                            this.state.postprefcurrency.map((transfer, i) => {
                              return <div> {transfer.bank}</div>;
                            })}
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnType1 smallFont py-2 px-3 mr-2"
              onClick={this.HandleEditAd}
            >
              Cancel
            </button>

            {this.state.editadstatus == "0" ? (
              <div>
                {this.state.editableboxshow ? (
                  <button
                    className="btn btnType1 smallFont py-2 px-3 mr-2"
                    onClick={this.UpdateEditMyAd}
                  >
                    Update Order
                  </button>
                ) : (
                  <button
                    className="btn btnType1 smallFont py-2 px-3 mr-2"
                    onClick={this.Makeeditable}
                  >
                    Edit Order
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
      <section className="userPages">
            <div className="container">
                <div className="ProfileDetailNameTitle">
                  <a href="/MyAccount">
                  {
                            this.state.icon == "BTC"  ?
                            <img src={require("../images/dashTabIcon1.png")} class="img-fluid" />
                            : this.state.icon == "ETH" ?
                            <img src={require("../images/dashTabIcon2.png")} class="img-fluid" />
                            : this.state.icon == "USDT" ?
                            <img src={require("../images/dashTabIcon3.png")} class="img-fluid" />
                            : this.state.icon == "BCH" ?
                            <img src={require("../images/dashTabIcon4.png")} class="img-fluid" />
                            : this.state.icon == "XRP" ?
                            <img src={require("../images/dashTabIcon5.png")} class="img-fluid" />
                            : this.state.icon == "TRX" ?
                            <img src={require("../images/dashTabIcon6.png")} class="img-fluid" />
                            : this.state.icon == "DASH" ?
                            <img src={require("../images/dashTabIcon8.png")} class="img-fluid" />
                            : this.state.icon == "CELO" ?
                            <img src={require("../images/dashTabIcon9.png")} class="img-fluid" />
                            : ''
                          }
                    {/* <img src={require("../images/profileIcon.jpg")} alt="" className="img-fluid" /> */}
                    {this.state.username} <span>Profile</span>
                  </a>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                      <div className="userBoxWhiteBox">
                      <h3 className="backPageTitle font-weight-bold mb-0 border-bottom-zero">
                          <a href="/Sell">
                            <i class="fas fa-chevron-left"></i>
                          </a>{" "}
                          Profile Details
 
                        </h3>
                        <h3 className="userBoxUnderlineTitle"></h3>
                        <div class="table-responsive">
                          <table class="table table-striped profileDetailTable mb-0">
                            <tbody>
                              <tr>
                                <td><label>Username</label></td>
                                <td><p>{this.state.userdetails.username} <i class="far fa-star ml-2 yellowText"></i> <small className="ml-2 greenText"><i class="fas fa-circle"></i></small></p></td>
                              </tr>
                              {/* <tr>
                                <td><label>Trade volume</label></td>
                                <td><p>2.5 BTC, 10.5 ETH, 1500 XRP</p></td>
                              </tr> */}
                              <tr>
                                <td><label>Number of confirmed trades</label></td>
                                <td><p className="blueText">{this.state.userdetails.buyCompletedTrade+this.state.userdetails.sellCompletedTrade}+</p></td>
                              </tr>
                              <tr>
                                <td><label>Feedback score</label></td>
                                <td><p className="greenText">{parseFloat(
                                        this.state.avg_positive_ratings
                                      ).toFixed(2)}</p></td>
                              </tr>
                              {/* <tr>
                                <td><label>Real name verification </label></td>
                                <td><p>1  trusted verifications, 1 verifications, 0 rejected</p></td>
                              </tr>
                              <tr>
                                <td><label>First purchase</label></td>
                                <td><p>1 year, 1 month ago</p></td>
                              </tr> */}
                              <tr>
                                <td><label>Account created</label></td>
                                <td><p><TimeAgo
                                                date={new Date(this.state.userdetails.date)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              <tr>
                                <td><label>Last seen</label></td>
                                
                                <td><p><TimeAgo
                                                date={new Date(this.state.userdetails.logintime)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              <tr>
                                <td><label>Language</label></td>
                                <td><p>English</p></td>
                              </tr>
                              <tr>
                                <td><label>Username</label></td>
                                <td><p><i class="fas fa-check-circle greenText mr-2"></i> Verified <TimeAgo
                                                date={new Date(this.state.userdetails.date)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              <tr>
                                <td><label>Email</label></td>
                                <td><p><i class="fas fa-check-circle greenText mr-2"></i> Verified <TimeAgo
                                                date={new Date(this.state.userdetails.date)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo></p></td>
                              </tr>
                              {/* <tr>
                                <td><label>Phone number</label></td>
                                <td><p><i class="fas fa-check-circle greenText mr-2"></i> Verified 1 year, 2 months ago</p></td>
                              </tr> */}
                              <tr>
                                <td><label>KYC Status</label></td>
                                <td><p>{this.state.userdetails.kycstatus}</p></td>
                              </tr>
                              {/* <tr>
                                <td><label>Trust</label></td>
                                <td><p>Trusted by {this.state.userdetails.trustedCount} people</p></td>
                              </tr> */}
                              <tr>
                                <td><label>Blocks</label></td>
                                <td><p>Blocked by {this.state.userdetails.blockCount} person</p></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
</div>
);
	}
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  perPage: 5,
  perPage_myads: 5,
});

export default connect(mapStateToProps, {})(withRouter(Profile));