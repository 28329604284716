import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import Navbar from './Navbar'
import Footer from './Footer'
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  registerUser,
  mobregisterUser,
  otptoUser,
  socaillogin,
} from "../actions/authActions";
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();
const grecaptchaObject = window.grecaptcha;
const url = keys.baseUrl;




class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username : "",
            email: "",
            password: "",
            password2: "",
            referalcode: "",
            errors: {},
            emailerrors: {},
            phone: "",
            accept: "",
            phone: "",
            mobilepassword: "",
            mobilereferalcode: "",
            refUserName:"",
            recaptcha: "",
            mobrecaptcha: "",
            mobileaccept: "",
            otpver: "",
            readOnly: false,
            disable: false,
            _csrf:'',
        };
      }

      getCSRFtoken =async () => {
        await axios
            .get(url + "getCSRF")
            .then((res) => {
              this.setState({_csrf:res.data._csrf});
            })
            .catch();
      }

      componentDidMount() {
        let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
        this.getCSRFtoken();
        if (this.props.auth.isAuthenticated) {
          this.props.history.push("/dashboard");
        }

        if (this.props.match.params.id!="undefined" && this.props.match.params.id!="") {
          this.setState({refUserName:this.props.match.params.id})
        }
        const values = queryString.parse(this.props.location.search);
        if (typeof values.ref != "undefined") {
          this.setState({
            referalcode: values.ref,
            mobilereferalcode: values.ref,
            readOnly: true,
          });
        }
      }
    
      componentWillReceiveProps(nextProps) {

        if (nextProps.errors) {
          this.setState({
            errors: nextProps.errors,
            disable: false,
          });
        }


        if (nextProps.auth.newuser !== undefined && nextProps.auth.newuser.data !== undefined && nextProps.auth.newuser.data.message !== undefined
        ) {
    
    
          store.addNotification({
            // title: "Wonderful!",
            message: nextProps.auth.newuser.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.setState({
            email: "",
            password: "",
            mobilepassword: "",
            referalcode: "",
            mobilereferalcode: "",
            errors: {},
            accept: "",
            mobileaccept: "",
            disable: false,
            otpver: this.state.phone != "" ? "true" : "",
          });
          if (
            nextProps.auth.newuser.data.message ==
            "OTP verification successfully completed, You can login now"
          ) {
            this.props.history.push("/Login");
            nextProps.auth.newuser = "";
          }
          else if (
            nextProps.auth.newuser.data.message ==
            "OTP sent to your number."
          ) {
    
            var _id = nextProps.auth.newuser.data.newUser._id
            this.props.history.push("/Otp/" + _id);
            nextProps.auth.newuser = "";
          }
          else if (
            nextProps.auth.newuser.data.message ==
            "OTP sent successfully, It is only valid for 2 minutes"
          ) {
            this.props.history.push("/Otp");
            nextProps.auth.newuser = "";
          }
    
          else {
            this.props.history.push("/Login");
            nextProps.auth.newuser = "";
          }
        }
      }

      onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
      };
    
      captchaChange = (e) => {
        this.setState({ recaptcha: e });
      };
    
      captchaChange1 = (e) => {
        this.setState({ mobrecaptcha: e });
      };
    
      onChangeCheckbox = (e) => {
        this.setState({ [e.target.id]: e.target.checked });
      };
    
      onSubmit = (e) => {
        this.setState({ disable: true });
        e.preventDefault();
        const newUser = {
          username: this.state.username,
          email: this.state.email,
          password: this.state.password,
          password2: this.state.password2,
          referalcode: this.state.referalcode,
          recaptcha: this.state.recaptcha,
          accept: this.state.accept.toString(),
          refUserName: this.state.refUserName,

          _csrf: this.state._csrf,
        };
        this.props.registerUser(newUser, this.props.history);
      };

	render() {
        const { errors } = this.state;
		return (
            <div className="login_nav">
                <Navbar />
                <section className="crediantialsBox">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-10 col-sm-12 mx-auto">
                                <div className="wow flipInY" data-wow-delay=".15s;">
                                <div className="formBox mb-5 mb-md-0">
                                    <div className="tab-content py-0 px-3 px-sm-0" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-signup" role="tabpanel" aria-labelledby="nav-signup-tab">
                                            <h2 className="crediantialTitle">Create an Account</h2>
                                            <form id="regfrm" name="regfrm" className="stoForm" method="" onSubmit={this.onSubmit}>
                                                <div className="form-group">
                                                    <label>Username (visible to everyone)</label>
                                                    <input
                                                        id="username"
                                                        type="text"
                                                        onChange={this.onChange}
                                                        value={this.state.username}
                                                        error={errors.username}
                                                        className="form-control"
                                                    />
                                                    <span className="text-danger">
                                                      {errors.username}</span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Referral User Name (Optional)</label>
                                                    <input
                                                        id="refUserName"
                                                        type="text"
                                                        onChange={this.onChange}
                                                        value={this.state.refUserName}
                                                        // error={errors.refUserName}
                                                        className="form-control"
                                                    />
                                                    <span className="text-danger">
                                                      {errors.refUserName}</span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Email Address</label>
                                                    <input
                                                        id="email"
                                                        type="text"
                                                        onChange={this.onChange}
                                                        value={this.state.email}
                                                        error={errors.email}
                                                        className="form-control"
                                                        
                                                    />
                                                  <span className="text-danger">{errors.email}</span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        onChange={this.onChange}
                                                        value={this.state.password}
                                                        className="form-control"
                                                        error={errors.password}
                                                    />
                                                    <span className="text-danger">
                                                      {errors.password}</span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <input
                                                        onChange={this.onChange}
                                                        value={this.state.password2}
                                                        error={errors.password2}
                                                        id="password2"
                                                        type="password"
                                                        className={classnames("form-control", {
                                                                invalid: errors.password2
                                                        })}
                                                    />
                                                    <span className="text-danger">{errors.password2}</span>
                                                </div>
                                                <div className="form-group remForAction my-4">
                                                    <div className="checkbox d-flex justify-content-center w-100">
                                                        <label>
                                                            <input
                                                                onChange={this.onChangeCheckbox}
                                                                value={this.state.accept}
                                                                id="accept"
                                                                type="checkbox"
                                                                className="form-control"
                                                            />
                                                            <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                                                            <span>I agree to the <a href="/terms">Terms</a> and <a href="/privacy">Policy</a></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <span className="text-danger">
                                                  {errors.accept}
                                                </span>
                                                <div className="form-group">
                                                    <ReCAPTCHA
                                                        className="g-recaptcha"
                                                        ref={(r) => (this.recaptcha = r)}
                                                        sitekey={Recaptchakey}
                                                        grecaptcha={grecaptchaObject}
                                                        onChange={this.captchaChange}
                                                    />
                                                    <span className="text-danger">
                                                      {errors.recaptcha}
                                                    </span>
                                                    {/* <img src={googleCaptcha} className="img-fluid d-block mx-auto" /> */}
                                                </div>
                                                <div className="form-group text-center">
                                            <input type="submit" className="btnType1" value="Sign up" />

                                                    {/* <input id="regfrmbtn" type="submit" className="btnType1" value="Sign up" /> */}
                                                </div>
                                            </form>
                                            <div className="formFooter">
                                                <h6 className="text-center">Already have an account? <a href="/Login">Login</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </div>
        );
	}
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    mobregisterUser: PropTypes.func.isRequired,
    otptoUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
  });
  
  export default connect(mapStateToProps, {
    registerUser,
    mobregisterUser,
    socaillogin,
    otptoUser,
  })(withRouter(Register));