import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import Select from 'react-select';
import ScrollspyNav from "react-scrollspy-nav";
import ReactTooltip from 'react-tooltip';
import Switch from "react-switch";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap/";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import config from "../actions/config"
import { tfaFormsubmit } from "../actions/authActions";
import setAuthToken from "../utils/setAuthToken";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import * as moment from "moment";

const url = keys.baseUrl;


const currencyType = [
  { value: 'NGN', label: 'NGN' },
  { value: 'BTC', label: 'BTC' },
  { value: 'USDT', label: 'USDT' },
  { value: 'BCH', label: 'BCH' },
  { value: 'XRP', label: 'XRP' },
  { value: 'TRON', label: 'TRON' },
  { value: 'DASH', label: 'DASH' },
  { value: 'LTC', label: 'LTC' },
];
const buySell = [
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];
const transferType = [
  { value: 'Bank Transfer', label: 'Bank Transfer' },
  { value: 'Fiat Transfer', label: 'Fiat Transfer' },
  { value: 'Instant Transfer', label: 'Instant Transfer' },
];

class NewsDetail extends Component {
  constructor() {
		super();
		this.state = { 
		  selectedPreferredTime: null,
		  selectedPreferredLanguage: null,
		  checked: false ,
		  selectedCoinPair: null,
		  selectedbankName: null,
		  oldpassword: "",
		  password: "",
		  password2: "",
		  loginpassword: "",
		  googleauth: "",
		  onecode: "",
		  tfacode: "",
		  applicationName: "",
		  readOnly: "",
		  twofactorkey: "",
		  notifications: {},
		  errors: {},
		  accepterror: "",
		  onecodeerror: "",
		  twofastatus:"",
		  accept: "false",
		  _csrf:'',
		  googlestatus:'',
		  isTop: true,
		  selectedBuySell: null,
		  selectedTransferType: null,
		  selectedCurrencyType: null,
		  newsDetailSingle:[],
		  newsDetails:[],
      categoryList:[],
		};
	  }
// state = {
//     isTop: true,
//   };
//  state = {
//     selectedBuySell: null,
//     selectedTransferType: null,
//     selectedCurrencyType: null,
//     };

  getNewsDetailSingle =async (id) => {
    await axios
        .get(url + "users/getNewsDetailSingle/"+id)
        .then((res) => {
          if(res.data.newsDetails){
            this.setState({newsDetailSingle:res.data.newsDetails});
          }
        })
        .catch();
  }

  getNewsDetails =async () => {
    await axios
      .get(url + "users/getNewsDetails")
      .then((res) => {
        if(res.data.newsDetails){
          this.setState({newsDetails:res.data.newsDetails});
        }
      })
      .catch();
  }

  getCategoryList =async () => {
    await axios
      .get(url + "users/getCategoryList")
      .then((res) => {
        if(res.data.categoryList){
          this.setState({categoryList:res.data.categoryList});
        }
      })
      .catch();
  }
  

  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    let id = this.props.match.params.id;
	  this.getNewsDetailSingle(id);
    this.getNewsDetails();
    this.getCategoryList();
  }

  handleBuySell = selectedBuySell => {
    this.setState({ selectedBuySell });
  };
  handleTransferType = selectedTransferType => {
    this.setState({ selectedTransferType });
  };
  handleCurrencyType = selectedCurrencyType => {
    this.setState({ selectedCurrencyType });
  };
	render() {
	const { selectedBuySell } = this.state;
	const { selectedTransferType } = this.state;
	const { selectedCurrencyType } = this.state;
		return (
      <div className="login_nav"> 
        <Navbar />
        <section className="secFAQ cmsPages">
          <div className="container">            
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div className="cmsBoxWhiteBox pb-0">
                  <div className="faqCont">
                    <div className="homeAccordian wow fadeIn">
                      <h2>News</h2>
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.newsDetailSingle&& this.state.newsDetailSingle.length > 0 &&
                              this.state.newsDetailSingle.map((item, i) => {
                                return(
                                  <article className="newsCardLanding">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="newsContentLand">
                                          <img src={`${config.imageUrl}/blogimage/${item.image}`} width="100%" height="auto" alt="" class="img-fluid mb-2" />
                                          <h6><span><a href="#">{item.author}</a> - {moment(item.created_date).format(
                                              "DD-MM-YYYY  k:mm"
                                            )}</span> <label> {item.blogCategory.category_name}</label></h6>
                                          <p>{ReactHtmlParser(item.content)}</p>
                                        </div>
                                      </div>
                                    </div>    
                                  </article>     
                                );
                              })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-4 col-sm-12">
                <div className="blogWhiteCard p-0">
                  <h3 class="blogSidebarTitle">Trending Posts</h3>
                  <ul class="blogSideBarLinks">
                    {this.state.newsDetails&& this.state.newsDetails.length > 0 &&
                      this.state.newsDetails.map((item, i) => {
                        if(item.trendingPost==1){
                        return(
                          <li><a href={"/NewsDetail/"+item._id}>{item.meta_description}</a></li>

                        );
                        }
                      })
                    }
                  </ul>
                </div>
                <div className="blogWhiteCard p-0">
                  <h3 class="blogSidebarTitle">Categories</h3>
                  <ul class="blogSideBarLinks">
                    {this.state.categoryList&& this.state.categoryList.length > 0 &&
                      this.state.categoryList.map((item, i) => {
                        return(
                          <li><a href={"/News"} >{item.category_name}</a></li>
                        );
                      })
                    }                     
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
	  </div>
    );
	}
}

NewsDetail.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	logoutUser: PropTypes.func.isRequired,
  
  };
  
  const mapStateToProps = (state) => ({
   auth: state.auth,
   errors: state.errors,
  });
  
export default connect(mapStateToProps,{tfaFormsubmit})(NewsDetail)