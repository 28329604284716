import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import Select from 'react-select';
import ScrollspyNav from "react-scrollspy-nav";
import ReactTooltip from 'react-tooltip';
import Switch from "react-switch";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap/";
import { store } from "react-notifications-component";
import keys, {  }from "../actions/config";
import { tfaFormsubmit } from "../actions/authActions";
import setAuthToken from "../utils/setAuthToken";
import { CopyToClipboard } from "react-copy-to-clipboard";
import queryString from "query-string";
import ReactPaginate from "react-paginate";



const url = keys.baseUrl;

const currencyType = [
  { value: 'NGN', label: 'NGN' },
  { value: 'BTC', label: 'BTC' },
  { value: 'USDT', label: 'USDT' },
  { value: 'BCH', label: 'BCH' },
  { value: 'XRP', label: 'XRP' },
  { value: 'TRON', label: 'TRON' },
  { value: 'DASH', label: 'DASH' },
  { value: 'LTC', label: 'LTC' },
];
const buySell = [
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];
const transferType = [
  { value: 'Bank Transfer', label: 'Bank Transfer' },
  { value: 'Fiat Transfer', label: 'Fiat Transfer' },
  { value: 'Instant Transfer', label: 'Instant Transfer' },
];

class Landing extends Component {
	static propTypes = {
        url: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
        perPage: PropTypes.number.isRequired,
      };
  constructor(props) {
    super(props);

    this.state = {
      allrecords: [],
      marketlist: [],
      currencylist: [],
      currencyoptions: [],
      sellads: [],
      buyads: [],
      firstamount:"",
      secondamount:"",
      postprefcurrency_find:"",
      firstcurrency: "",
      buyselected: "BTC",
      currencysymbol: "",
      records: [],
      marketselected: "AUD",
      spotpairlength: 0,
      cryptolength: 0,
      Fiatlength: 0,
      currencyDetails: [],
      selectedBuySell: "",
      selectedTransferType: null,
      curmarketprice: 0,
      offset_searchads:0,
      cryptocurrency:"BTC",
     fiatcurrency : "USD",
      selectedpreferred: {
        label: "Select",
        value: "",
      },

      fiatoptions: {
        label: "USD",
        value: "USD",
      },
      posttocurrency:"USD",
      balancedetails:[],
      _csrf:'',
      faqDetails:[],

    };
  }

  //   = (x)=>{
  //     let pricevalue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     return pricevalue;

  // }


  handleposttocurrency = (selectedtocpostcurrency) => {

    this.setState({fiatoptions:selectedtocpostcurrency} );
    this.setState({ fiatcurrency: selectedtocpostcurrency.value });
    this.setState({ posttocurrency: selectedtocpostcurrency.value });

    var pair = this.state.cryptocurrency + selectedtocpostcurrency.value;
    var tempprice = 0;
    var curindex = this.state.spotpairsdata.findIndex(
      (x) => x.tiker_root === pair
    );
    if (curindex != -1) {
      tempprice = this.state.spotpairsdata[curindex].index_price;
      this.setState({
        curmarketprice: this.state.spotpairsdata[curindex].index_price,
      });
    }
    this.searchfind_ads(this.state.tradetype,0,this.state.cryptocurrency,selectedtocpostcurrency.value)

    this.getspotdata(this.state.cryptocurrency, selectedtocpostcurrency.value);

    this.props.history.push(this.props.match.path+'?coin='+this.state.cryptocurrency+"-"+selectedtocpostcurrency.value)
    
  };


  searchfind_ads = (tradetype,offset,crypto,fiat) => { 

    if(offset==0) {
        this.setState({ buyads: [],
          sellads: []})
        }
        
    const fc = crypto;
    const sc = fiat;
    const adstype = tradetype
    const fc_amount = this.state.firstamount;
    const sc_amount = this.state.secondamount;

    const transfer = this.state.postprefcurrency_find;
    const postprefcurrency = this.state.postprefcurrency;
    let userid = this.props.auth.user.id;
    var data_find = {
      fc: fc,
      sc: sc,
      adstype: adstype,
      fc_amount: fc_amount,
      sc_amount: sc_amount,
      transfer: postprefcurrency,
      userid: userid,
      limit: this.props.perPage,
      offset:this.state.offset_searchads,
      _csrf: this.state._csrf,
    };

    if (adstype == "" || adstype == undefined) {
      store.addNotification({
        title: "Error!",
        message: "Please Choose Trade Type",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      return false;
    }

    if (adstype == "Buy") {
      this.setState({ buy_search_fc: fc });

      axios.post(url + "tradeapi/search_find_ads", data_find).then((res) => {
        this.setState({ buyads: res.data.tradedata,pageCount: Math.ceil(res.data.total_count / res.data.limit), });
      });
    }

    if (adstype == "Sell") {
      this.setState({ sell_search_fc: fc });
      axios.post(url + "tradeapi/search_find_ads", data_find).then((res) => {
        this.setState({ sellads: res.data.tradedata,pageCount: Math.ceil(res.data.total_count / res.data.limit), });
      });
    }
  };

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage);

    this.setState({ offset_searchads: offset }, () => {
      this.searchfind_ads(this.state.tradetype,offset,this.state.cryptocurrency,this.state.fiatcurrency)
   
    });
  };



  handlefirstamount = (e) => {
    this.setState({
      [e.target.id]: e.currentTarget.value,
    });
  };

  handlesecondamount = (e) => {
    this.setState({
      [e.target.id]: e.currentTarget.value,
    });
  };

  handlecurrenttab = async (answer) => {
  
    this.setState({ cryptocurrency: answer });
  
    
    var curindex = this.state.currencydetails.findIndex(
      (x) => x.currencySymbol === answer
    );
    if (curindex != -1) {
      this.setState({ maxbuy: this.state.currencydetails[curindex].maxbuy });
    }

    //   var curindex = this.state.balancedetails.findIndex(
    //     (x) => x.currencySymbol === answer );
    //     if(curindex!=-1){
    //       this.setState({buywallet:this.state.balancedetails[curindex].balance})

    //     }

    if (this.state.buytocurrency != "") {
      var pair = answer + this.state.buytocurrency;
      var curindex = this.state.spotpairsdata.findIndex(
        (x) => x.tiker_root === pair
      );
      if (curindex != -1) {
        this.setState({
          curmarketprice: this.state.spotpairsdata[curindex].index_price,
        });
      }
    }

    if (this.state.fromamount != "") {
      var pair = answer + this.state.buytocurrency;
      var curindex = this.state.spotpairsdata.findIndex(
        (x) => x.tiker_root === pair
      );
      if (curindex != -1) {
        var tempprice = this.state.spotpairsdata[curindex].index_price;
        var totalprice =
          parseFloat(tempprice) * parseFloat(this.state.fromamount);
        this.setState({ toamount: totalprice });
        // this.setState({curmarketprice:this.state.spotpairsdata[curindex].index_price})
      }
    }
    this.searchfind_ads(this.state.tradetype,0,answer,this.state.posttocurrency)
    this.getspotdata(answer,this.state.fiatcurrency)
   this.props.history.push(this.props.match.path+'?coin='+answer+"-"+this.state.posttocurrency)


  };


  getcurrencydetails() {
    axios.get(url + "api/currency").then((res) => {
      this.setState({ currencydetails: res.data });
      var firstcur = res.data;
      var newcurar = [];
      firstcur.map((details, index) => {
        if (details.p2p == "On") {
          newcurar.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
            Name: details.currencyName,
          });
        }
      });
      var fiatcur = [];
      firstcur.map((details, index) => {
        if (details.type == "Fiat") {
          fiatcur.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });
      var tocurrecur = [];
      firstcur.map((details, index) => {
        if (details.currencySymbol != this.state.currencyselected) {
          tocurrecur.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });

      this.setState({ posttooptions: tocurrecur });
      this.setState({ currencyoptions: newcurar, fiatcuroption: fiatcur });
    });
  }

  getCurrency() {
    axios.get(url + "api/currency").then((res) => {
      this.setState({ currencydetails: res.data });
      var firstcur = res.data;
      var newcurar = [];
      var cryptarray = [];
      var curindex = firstcur.findIndex((x) => x.currencySymbol === "BTC");
      if (curindex != -1) {
        this.setState({ maxbuy: firstcur[curindex].maxbuy });
      }
      firstcur.map((details, index) => {
        if (details.type == "Fiat") {
          newcurar.push({
            value: details.currencySymbol,
            label: details.currencyName,
          });
        }

        //  if (details.currencySymbol == "BTC"||details.currencySymbol == "USDT") {
        if (details.type == "Crypto") {
          cryptarray.push({
            value: details.currencySymbol,
            label: details.currencyName,
          });
        }
      });

      this.setState({
        Fiatcurrencyoptions: newcurar,
        cryptocurrencyoptions: cryptarray,
        currencylist: cryptarray,
      });
    });
  }

  getAssetData() {
    let userid = this.props.auth.user.id;
    if(userid) {
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      this.setState({ balancedetails: res.data.data });
      var curindex = this.state.balancedetails.findIndex(
        (x) => x.currencySymbol === "BTC"
      );
      if (curindex != -1) {
        this.setState({
          buywallet: this.state.balancedetails[curindex].balance,
        });
      }
    });
  }
  }

  getspotdata(crypto,fiat) {
    axios.get(url + "api/spotpair-data").then((res) => {
      if (res.data.status) {
        this.setState({ spotpairsdata: res.data.data });
        var tempdata = res.data.data;
        var pair = crypto + fiat;

        var indexonchcange = this.state.spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          var onchbaseprice = this.state.spotpairsdata[indexonchcange]
            .index_price;
          this.setState({ curmarketprice: onchbaseprice });
        }
      }
    });
  }

  getbuyads() {
    let userid = this.props.auth.user.id;
   
    var postdata = {
      userid: userid,
      limit: 20,
      offset: 0,
      _csrf: this.state._csrf,

    };
    axios.post(url + "tradeapi/getbuyads", postdata).then((res) => {
      this.setState({ buyads: res.data.tradedata });
    });
  }

  getsellads() {
    let userid = this.props.auth.user.id;
    var postdata = {
      userid: userid,
      limit: 20,
      offset: 0,
      _csrf: this.state._csrf,
    };
    axios.post(url + "tradeapi/getsellads", postdata).then((res) => {
      this.setState({ sellads: res.data.tradedata });
    });
  }
 
componentDidMount() {
  let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    if (this.props.auth.isAuthenticated) {
      //this.props.history.push("/Dashboard");
    }
    this.getCSRFtoken();
    this.getCurrency();
    this.getcurrencydetails();
    this.getpreferred_transfer();
    this.getFaqDetails();
    // var qstr = queryString.parse(this.props.location.search);

    // if (qstr.coin != "" && qstr.coin != undefined) {
    //   var coinarr = qstr.coin.split("-");
    //   this.getAssetData(coinarr[0]);
    //   this.getspotdata(coinarr[0],coinarr[1]);
    //   this.setState({fiatoptions:{value: coinarr[1], label: coinarr[1]}} );
    //     if(this.props.match.path=='/MoreSell'){
    //         this.searchfind_ads('Sell',0,coinarr[0],coinarr[1])
    //         this.setState({'tradetype':'Sell'})
    //     }
    //     if(this.props.match.path=='/MoreBuy'){
    //         this.searchfind_ads('Buy',0,coinarr[0],coinarr[1])
    //         this.setState({'tradetype':'Buy'})
    //     }
    //   this.setState({cryptocurrency:coinarr[0]})
    // }else{
    //   this.getAssetData("BTC");
    //   this.getspotdata("BTC", "USD");
    //     if(this.props.match.path=='/MoreSell'){
    //         this.searchfind_ads('Sell',0,'BTC','USD')
    //         this.setState({'tradetype':'Sell'})
    //     }
    //     if(this.props.match.path=='/MoreBuy'){
    //         this.searchfind_ads('Buy',0,'BTC','USD')
    //         this.setState({'tradetype':'Buy'})
    //     }
    // }


  
    let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "cryptoapi/withdrawrequest", passdata)
        .then((res) => {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
          this.props.history.push("/Login");
        })
        .catch();
    }
}
getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
            this.setState({_csrf:res.data._csrf});
            // this.getbuyads("BTC", "USD");
            //   this.getsellads("BTC", "USD");
            var qstr = queryString.parse(this.props.location.search);
            if (qstr.coin != "" && qstr.coin != undefined) {
            var coinarr = qstr.coin.split("-");
            this.getAssetData(coinarr[0]);
            this.getspotdata(coinarr[0],coinarr[1]);
            this.setState({fiatoptions:{value: coinarr[1], label: coinarr[1]}} );
                if(this.props.match.path=='/MoreSell'){
                    this.searchfind_ads('Sell',0,coinarr[0],coinarr[1])
                    // this.searchfind_ads('Buy',0,coinarr[0],coinarr[1])
                    this.setState({'tradetype':'Sell'})
                }
                if(this.props.match.path=='/MoreBuy'){
                    this.searchfind_ads('Buy',0,coinarr[0],coinarr[1])
                    // this.searchfind_ads('Sell',0,coinarr[0],coinarr[1])
                    this.setState({'tradetype':'Buy'})
                }
            this.setState({cryptocurrency:coinarr[0]})
            }else{
            this.getAssetData("BTC");
            this.getspotdata("BTC", "USD");
                if(this.props.match.path=='/MoreSell'){
                    this.searchfind_ads('Sell',0,'BTC','USD')
                    this.setState({'tradetype':'Sell'})
                }
                if(this.props.match.path=='/MoreBuy'){
                    this.searchfind_ads('Buy',0,'BTC','USD')
                    this.setState({'tradetype':'Buy'})
                }
            }
    })
    .catch();
}
getFaqDetails =async () => {
    await axios
        .get(url + "users/getFaqDetails")
        .then((res) => {
            if(res.data.history){
                    this.setState({faqDetails:res.data.history});
            }
        })
        .catch();
}

  getpreferred_transfer() {
    axios.get(url + "tradeapi/preferred_transfer").then((res) => {
      if (res.data.status) {
        var pref = [];
        res.data.preferredtransfer.map((details, index) => {
          pref.push({
            value: details.bank,
            label: (
              <span>
                {" "}
                <img
                  width="20"
                  height="20"
                  src={require("../images/payment/" +
                    details.bank.replace(/[ )(]/g, "") +
                    ".png")}
                  class="css-pjaw30"
                />{" "}
                {details.bank}
              </span>
            ),
          });
        });

        this.setState({ preferredtransfer: pref });
      }
    });
  }

  handlepostpreferred = (selectedpreferred) => {
    this.setState({ selectedpreferred });
    this.setState({ postprefcurrency: selectedpreferred.value });
  };

	
// state = {
//     isTop: true,
//   };
//  state = {
//     selectedBuySell: null,
//     selectedTransferType: null,
//     selectedCurrencyType: null,
//     };


  handleBuySell = selectedBuySell => {
    this.setState({ selectedBuySell });
  };
  handleTransferType = selectedTransferType => {
    this.setState({ selectedTransferType });
  };
  handleCurrencyType = selectedCurrencyType => {
    this.setState({ selectedCurrencyType });
  };
	render() {
	const { selectedBuySell } = this.state;
	const { selectedTransferType } = this.state;
	const { selectedCurrencyType } = this.state;
	const {
		firstcurrency,
		currencylist,
		selectedtocpostcurrency,
		currencyoptions,
	  } = this.state;
		return (
      <div>
      <Navbar />
<header className="landingBanner">
  <div id="landingSlider" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <div className="container">
          <div className="row">
          	<div className="col-md-12">
          		<div className="landingBannerContent wow fadeIn">
          			<img src={require("../images/cfBuySell.png")} alt="" className="img-fluid d-block mx-auto" />
          			<h2>CryptoCurrency  Everywhere</h2>
          			<h6>Access the Fastest Growing P2P Marketplace<br />Where people buy and sell crypto on their own terms, using any payment method.</h6>
          		</div>
          	</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<section className="currencyCalc">
	<div className="container">
		<div className="landingTable wow fadeIn">
			<nav>
				<div className="nav nav-tabs" id="nav-tab" role="tablist">
				{currencyoptions.length > 0 &&
                    currencyoptions.map((item, i) => {
                      var tempclass =
                        this.state.cryptocurrency == item.value
                          ? "nav-item nav-link active"
                          : "nav-item nav-link";

                      return (
                        <a
                          onClick={() => {
                            this.handlecurrenttab(item.value);
                          }}
                          class={tempclass}
                          id={item.value}
                          data-toggle="tab"
                          href={`#nav-dash${item.value}`}
                          role="tab"
                          aria-controls={`nav-dash${item.value}`}
                          aria-selected="false"
                        >
                          <img
                            src={require("../images/coinsymbols/dashTabIcon" +
                              item.value.toUpperCase() +
                              ".png")}
                            alt=""
                            className="img-fluid"
                          />
                          {item.Name}
                        </a>
                      );
                    })}
					{/* <a className="nav-item nav-link active" id="nav-coinType1-tab" data-toggle="tab" href="#nav-coinType1" role="tab" aria-controls="nav-coinType1" aria-selected="true"><img src={require("../images/dashTabIcon1.png")} alt="" className="img-fluid" /> Bitcoin</a>
					<a className="nav-item nav-link" id="nav-coinType2-tab" data-toggle="tab" href="#nav-coinType2" role="tab" aria-controls="nav-coinType2" aria-selected="false"><img src={require("../images/dashTabIcon2.png")} alt="" className="img-fluid" /> Ethereum</a>
					<a className="nav-item nav-link" id="nav-coinType5-tab" data-toggle="tab" href="#nav-coinType5" role="tab" aria-controls="nav-coinType5" aria-selected="false"><img src={require("../images/dashTabIcon5.png")} alt="" className="img-fluid" /> Ripple</a>
					<a className="nav-item nav-link" id="nav-coinType4-tab" data-toggle="tab" href="#nav-coinType4" role="tab" aria-controls="nav-coinType4" aria-selected="false"><img src={require("../images/dashTabIcon4.png")} alt="" className="img-fluid" /> Bitcoin Cash</a> */}
				</div>
			</nav>
			<div className="tab-content" id="nav-tabContent">
				<div className="tab-pane fade show active" id="nav-coinType1" role="tabpanel" aria-labelledby="nav-coinType1-tab">
					<div className="currencyCalcCard">
						<div className="currencyStatus">
						<h2>
                        1 {this.state.cryptocurrency}={" "}
                        {(this.state.curmarketprice)}{" "}
                      </h2>
                      <div class="select-coinType">
                        <Select
                          value={this.state.fiatoptions}
                          onChange={this.handleposttocurrency}
                          options={this.state.fiatcuroption}
                        />
                      </div>
							{/* <h2>1 Bitcoin = 4197317.86</h2>
							<div className="select-coinType">
								<Select
                                   value={selectedCurrencyType}
                                   onChange={this.handleCurrencyType}
                                   options={currencyType}
                                 />
							</div> */}
						</div>
						<div className="currencyInputGroup">
                      {/* <label>I want to </label>
                      <div className="input-group-append">
                          <span className="input-group-text">
                            {this.state.cryptocurrency}
                          </span>
                        </div>
                      <label>&nbsp;&nbsp;for</label> */}
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="secondamount"
                          placeholder="Amount"
                          onChange={this.handlesecondamount}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            {this.state.posttocurrency}
                          </span>
                        </div>
                      </div>
                      <label>using</label>
                      <div className="select-group-transfer">
                      <Select
                          onChange={this.handlepostpreferred}
                          options={this.state.preferredtransfer}
                        />

                      </div>
                      <button
                        onClick={() => {
                          this.searchfind_ads(this.state.tradetype,0,this.state.cryptocurrency,this.state.fiatcurrency);
                        }}
                        className="btn btnBlue"
                      >
                        Search
                      </button>
                    </div>
					</div>
				</div>
				<div className="tab-pane fade" id="nav-coinType2" role="tabpanel" aria-labelledby="nav-coinType2-tab">
					<div className="currencyCalcCard">
						<div className="currencyStatus">
							<h2>1 Ethereum = 4197317.86</h2>
							<div className="select-coinType">
								<Select
                                   value={selectedCurrencyType}
                                   onChange={this.handleCurrencyType}
                                   options={currencyType}
                                 />
							</div>
						</div>
						<div className="currencyInputGroup">
							<label>I want to </label>
							<div className="select-group-buySell">
								<Select
                                   value={selectedBuySell}
                                   onChange={this.handleBuySell}
                                   options={buySell}
                                 />
							</div>
							<div className="input-group">
							  <input type="text" className="form-control" />
							  <div className="input-group-append">
							    <span className="input-group-text">BTC</span>
							  </div>
							</div>
							<label>for</label>
							<div className="input-group">
							  <input type="text" className="form-control" />
							  <div className="input-group-append">
							    <span className="input-group-text">NGN</span>
							  </div>
							</div>
							<label>using</label>
							<div className="select-group-transfer">
								<Select
                                   value={selectedTransferType}
                                   onChange={this.handleTransferType}
                                   options={transferType}
                                 />
							</div>
							<button className="btn btnBlue">Search</button>
						</div>
					</div>
				</div>
				<div className="tab-pane fade" id="nav-coinType5" role="tabpanel" aria-labelledby="nav-coinType5-tab">
					<div className="currencyCalcCard">
						<div className="currencyStatus">
							<h2>1 Ripple = 4197317.86</h2>
							<div className="select-coinType">
								<Select
                                   value={selectedCurrencyType}
                                   onChange={this.handleCurrencyType}
                                   options={currencyType}
                                 />
							</div>
						</div>
						<div className="currencyInputGroup">
							<label>I want to </label>
							<div className="select-group-buySell">
								<Select
                                   value={selectedBuySell}
                                   onChange={this.handleBuySell}
                                   options={buySell}
                                 />
							</div>
							<div className="input-group">
							  <input type="text" className="form-control" />
							  <div className="input-group-append">
							    <span className="input-group-text">BTC</span>
							  </div>
							</div>
							<label>for</label>
							<div className="input-group">
							  <input type="text" className="form-control" />
							  <div className="input-group-append">
							    <span className="input-group-text">NGN</span>
							  </div>
							</div>
							<label>using</label>
							<div className="select-group-transfer">
								<Select
                                   value={selectedTransferType}
                                   onChange={this.handleTransferType}
                                   options={transferType}
                                 />
							</div>
							<button className="btn btnBlue">Search</button>
						</div>
					</div>
				</div>
				<div className="tab-pane fade" id="nav-coinType4" role="tabpanel" aria-labelledby="nav-coinType4-tab">
					<div className="currencyCalcCard">
						<div className="currencyStatus">
							<h2>1 Bitcoin Cash = 4197317.86</h2>
							<div className="select-coinType">
								<Select
                                   value={selectedCurrencyType}
                                   onChange={this.handleCurrencyType}
                                   options={currencyType}
                                 />
							</div>
						</div>
						<div className="currencyInputGroup">
							<label>I want to </label>
							<div className="select-group-buySell">
								<Select
                                   value={selectedBuySell}
                                   onChange={this.handleBuySell}
                                   options={buySell}
                                 />
							</div>
							<div className="input-group">
							  <input type="text" className="form-control" />
							  <div className="input-group-append">
							    <span className="input-group-text">BTC</span>
							  </div>
							</div>
							<label>for</label>
							<div className="input-group">
							  <input type="text" className="form-control" />
							  <div className="input-group-append">
							    <span className="input-group-text">NGN</span>
							  </div>
							</div>
							<label>using</label>
							<div className="select-group-transfer">
								<Select
                                   value={selectedTransferType}
                                   onChange={this.handleTransferType}
                                   options={transferType}
                                 />
							</div>
							<button className="btn btnBlue">Search</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section className="BuySellTableSection">
	<div className="container">
		<div className="row">
			<div className="col-md-6">
				<div className="landingTable buyTable">
					<div className="landingTableTitle">
						<h3>Buy {this.state.cryptocurrency}</h3>
						{/* <a href="#" className="tableMoreLink">More Buy </a> */}
					</div>
					<div className="table-responsive">
						<table className="table">
							<tbody>
							{this.state.buyads&& this.state.buyads.length > 0 &&
								this.state.buyads.map((item, i) => {
								return (
									<tr>
									<td>
										<label>{item.user_info.username}<small>{item &&
										item.prefcurrencytransfer &&
										item.prefcurrencytransfer.length > 0 &&
										Array.isArray(item.prefcurrencytransfer) &&
										item.prefcurrencytransfer.map((transfer, i) => {
											return <span> {transfer.bank}</span>;
										})}</small>
										</label>
									</td>
									<td>
										<label>{(item.price)}{" "}
											{item.secondCurrency}<small>{item.minlimit} -{" "}
											{(item.maxlimit)}{" "}
											{(item.secondCurrency)}</small>
										</label>
									</td>
									<td>
										<a
										href={"/TradeBankRequest/" + item._id}
										className="btn greenBorderButton"
										>
										Buy
										</a>
									</td>
									</tr>
								);
								})
							}
							{this.state.buyads && this.state.buyads.length == 0 && (
								<tr style={{ textAlign: "center" }}>
									<td colspan="5">Oops No Result Found</td>
								</tr>
							)}
							</tbody>
						</table>
					</div>
				</div>
                {this.state.buyads && this.state.buyads.length > 0 &&
                    <div id="react-paginate" style={{ float: "Right" }}>
                        <ReactPaginate
                            previousLabel={"« Previous"}
                            nextLabel={"» Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            
                        />
                    </div>
                }
			</div>
			<div className="col-md-6">
				<div className="landingTable sellTable">
					<div className="landingTableTitle">
						<h3>Sell {this.state.cryptocurrency}</h3>
						{/* <a href="#" className="tableMoreLink">More Sell </a> */}
					</div>
					<div className="table-responsive">
						<table className="table">
							<tbody>
							{this.state.sellads.length > 0 &&
								this.state.sellads.map((item, i) => {
								return (
									<tr>
									<td>
										<label>
											{item.user_info.username}<small>{item &&
											item.prefcurrencytransfer &&
											item.prefcurrencytransfer.length > 0 &&
											Array.isArray(item.prefcurrencytransfer) &&
											item.prefcurrencytransfer.map((transfer, i) => {
												return <div> {transfer.bank}</div>;
											})}</small>
										</label>
									</td>
									<td>
										<label>
											{(item.price)}{" "}
											{item.secondCurrency}<small>{(item.minlimit)} -{" "}
											{(item.maxlimit)}{" "}
											{item.secondCurrency}</small>
										</label>
									</td>
									<td>
										<a 
                    href={"/TradeBankRequestSell/" + item._id}
										className="btn redBorderButton"
										>
										Sell
										</a>
									</td>
									</tr>
								);
								})
							}
							{this.state.sellads.length == 0 && (
								<tr style={{ textAlign: "center" }}>
									<td colspan="5">Oops No Result Found</td>
								</tr>
							)}
							
						</tbody>
						</table>
					</div>
				</div>
                {this.state.sellads.length > 0 && (
                    <div id="react-paginate" style={{ float: "Right" }}>
                        <ReactPaginate
                        previousLabel={"« Previous"}
                        nextLabel={"» Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        />
                    </div>
                )}
			</div>
		</div>
	</div>
</section>
{/* <section className="coinFeatures">
	<div className="container">
		<h2>Coinfriends <span>Features</span></h2>
		<div className="row">
			<div className="col-md-6">
				<div className="landFeatureCard featureBgColor1">
					<div className="row">
						<div className="col-lg-7 flexColumn">
							<div className="lfcContent">
							<h3>10+ Payments</h3>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
						</div>
						</div>
						<div className="col-lg-5 flexColumn">
							<div className="lfcImg">
							<img src={require("../images/featureimg1.jpg")} alt="" className="img-fluid" />
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="landFeatureCard featureBgColor2">
					<div className="row">
						<div className="col-lg-7 flexColumn">
							<div className="lfcContent">
							<h3>Instant Trade</h3>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
						</div>
						</div>
						<div className="col-lg-5 flexColumn">
							<div className="lfcImg">
							<img src={require("../images/featureimg2.jpg")} alt="" className="img-fluid" />
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="landFeatureCard featureBgColor3">
					<div className="row">
						<div className="col-lg-7 flexColumn">
							<div className="lfcContent">
							<h3>Secured Escrow Service</h3>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
						</div>
						</div>
						<div className="col-lg-5 flexColumn">
							<div className="lfcImg">
							<img src={require("../images/featureimg3.jpg")} alt="" className="img-fluid" />
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="landFeatureCard featureBgColor4">
					<div className="row">
						<div className="col-lg-7 flexColumn">
							<div className="lfcContent">
							<h3>Free Hot Wallet</h3>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
						</div>
						</div>
						<div className="col-lg-5 flexColumn">
							<div className="lfcImg">
							<img src={require("../images/featureimg4.jpg")} alt="" className="img-fluid" />
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="landFeatureCard featureBgColor5">
					<div className="row">
						<div className="col-lg-7 flexColumn">
							<div className="lfcContent">
							<h3>Worldwide Service</h3>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
						</div>
						</div>
						<div className="col-lg-5 flexColumn">
							<div className="lfcImg">
							<img src={require("../images/featureimg5.jpg")} alt="" className="img-fluid" />
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="landFeatureCard featureBgColor6">
					<div className="row">
						<div className="col-lg-7 flexColumn">
							<div className="lfcContent">
							<h3>24/7 Customer Service</h3>
							<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
						</div>
						</div>
						<div className="col-lg-5 flexColumn">
							<div className="lfcImg">
							<img src={require("../images/featureimg6.jpg")} alt="" className="img-fluid" />
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section className="processSteps">
	<div className="container">
		<div className="row">
			<div className="col-md-7 flexColumn">
				<div className="verifyContentLeft">
					<h2>How to Trade</h2>
					<h3>Peer-to-Peer Trading Very Easier</h3>
						<ul>
							{!this.props.auth.user.id &&
								<li><a href="/Signup">Create Account</a></li>
							}
							{this.props.auth.user.id && 
								<li><a href="/dashboard">My Account</a></li>
							}
							{this.props.auth.user.id && 
								<li><a href="/MyAccount">Verify your Identity</a></li>
							}
							<li><a href="/">Search best Buy and Sell Price</a></li>
					    </ul>
				</div>
			</div>
			<div className="col-md-5 flexColumn">
				<div class="w-100">
				<div className="landingVerifyCard wow flipInY" id="verify1">
					<div className="verifyNumberCard">
						<span className="blueBg">1.</span>
					</div>
					<div className="verifyContentLanding">
						<img src={require("../images/signupStep.png")} alt="" className="img-fluid" />
						<h3>Create an Account</h3>
						<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
					</div>
				</div>
				<div className="landingVerifyCard wow flipInY" id="verify2">
					<div className="verifyNumberCard">
						<span className="greyBg">2.</span>
					</div>
					<div className="verifyContentLanding">
						<img src={require("../images/verifyStep.png")} alt="" className="img-fluid" />
						<h3>Verify Your Identity</h3>
						<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
					</div>
				</div>
				<div className="landingVerifyCard wow flipInY" id="verify3">
					<div className="verifyNumberCard">
						<span className="orangeBg">3.</span>
					</div>
					<div className="verifyContentLanding">
						<img src={require("../images/buysellStep.png")} alt="" className="img-fluid" />
						<h3>Buy or Sell Crypto</h3>
						<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
					</div>
				</div>
				<div className="landingVerifyCard wow flipInY" id="verify4">
					<div className="verifyNumberCard">
						<span className="greenBg">4.</span>
					</div>
					<div className="verifyContentLanding">
						<img src={require("../images/step4.png")} alt="" className="img-fluid" />
						<h3>Admin Release Escrow</h3>
						<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>
</section>
<section className="ayaReferral">
	<div className="container">
		<div className="col-md-5 flexColumn">
			<div className="referralImg wow flipInY">
				<img src={require("../images/takeControlImg.jpg")} alt="" className="img-fluid" />
			</div>
		</div>
		<div className="col-md-7 flexColumn">
			<div className="ayaReferralContent wow flipInY">
				<h2>Take Control <span>Today</span></h2>
				<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
				{!this.props.auth.user.id &&<>
					<a className="btn btnPurple" href="/Signup">Create Account</a>
					</>
              	}
				{this.props.auth.user.id &&  <>
					<a className="btn btnPurple" href="/dashboard">My Account</a>
				</>
              	}
			</div>
		</div>
	</div>
</section>
<section className="paymentMethods">
	<div className="container">
		<div className="row">
			<div className="col-xl-9 col-lg-9 col-sm-12 ml-auto mr-auto">
		<h2 className="wow fadeInDown">Accepted <span>Payments</span></h2>
		<div className="row">
			<div className="col-md-4">
				<div className="landPMCard wow flipInY">
					<img src={require("../images/bankTransfer.png")} alt="" className="img-fluid" />
					<h3>Bank Transfer</h3>
				</div>
			</div>
			<div className="col-md-4">
				<div className="landPMCard wow flipInY">
					<img src={require("../images/mobileMoney.png")} alt="" className="img-fluid" />
					<h3>Mobile Money</h3>
				</div>
			</div>
			<div className="col-md-4">
				<div className="landPMCard wow flipInY">
					<img src={require("../images/chipperCash.png")} alt="" className="img-fluid" />
					<h3>Paypal</h3>
				</div>
			</div>
		</div>
	</div>
	</div>
	</div>
</section>
<section className="secFAQ">
      <div className="container">
      	<div className="row">
			<div className="col-xl-10 col-lg-10 col-sm-12 ml-auto mr-auto">
        <div className="faqCont">
          <div className="homeAccordian wow fadeIn">
              <h2>FAQ</h2>
              <div id="accordion">
			  {
				this.state.faqDetails && this.state.faqDetails.map((data, i) => {
					if(data.category == "General"){
						return (
							<div className="card">
								<div className="card-header wow flipInX" id={data._id}>
									<h5 className="mb-0">
									<button className="btn btn-link collapsed" data-toggle="collapse" data-target={"#"+i} aria-expanded="true" aria-controls={i}><span className="question">{data.Articlename} </span> <i className="fas fa-chevron-down"></i></button>
									</h5>
								</div>
								<div id={i} className="collapse" aria-labelledby={data._id} data-parent="#accordion">
									<div className="card-body">
										<p>{data.content}</p>
									</div>
								</div>
							</div>
						)
					}
				})
			  }
              </div>
            </div>
        </div>
    </div>
</div>
      </div>
    </section>
    <section className="landingNews">
        <div className="container" id="news">
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-sm-12 ml-auto mr-auto">
            <h2>News</h2>
            <article className="newsCardLanding">
                <div className="row">
                    <div className="col-md-8">
                        <div className="newsContentLand">
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate feugiat nisi ut tempor <a href="#"><i className="fas fa-external-link-alt"></i></a></h3>
                            <p>Morbi faucibus erat ac mauris pharetra, id tincidunt libero posuere. Proin congue urna at malesuada vehicula. Proin congue urna at malesuada vehicula. Etiam nec massa ac tortor feugiat luctus eu vitae massa.</p>
                            <h6><span><a href="#">Beln Crypto</a> - 56 minutes ago</span> <label><img src={require("../images/dashTabIcon1.png")} alt="" className="img-fluid" /> Bitcoin</label></h6>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="newsImgLand"><a href="#"><img src={require("../images/takeControlImg.jpg")} alt="" className="img-fluid" /></a></div>
                    </div>
                </div>
            </article>
            <article className="newsCardLanding">
                <div className="row">
                    <div className="col-md-8">
                        <div className="newsContentLand">
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate feugiat nisi ut tempor <a href="#"><i className="fas fa-external-link-alt"></i></a></h3>
                            <p>Morbi faucibus erat ac mauris pharetra, id tincidunt libero posuere. Proin congue urna at malesuada vehicula. Proin congue urna at malesuada vehicula. Etiam nec massa ac tortor feugiat luctus eu vitae massa.</p>
                            <h6><span><a href="#">Finbold</a> - 56 minutes ago</span> <label><img src={require("../images/dashTabIcon1.png")} alt="" className="img-fluid" /> Bitcoin</label></h6>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="newsImgLand"><a href="#"><img src={require("../images/takeControlImg.jpg")} alt="" className="img-fluid" /></a></div>
                    </div>
                </div>
            </article>
        </div>
    </div>
        </div>
    </section> */}
    <Footer />
	  </div>
);
	}
}



Landing.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    perPage: 20,
});
export default connect(mapStateToProps, {})(withRouter(Landing));