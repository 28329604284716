import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import {NavLink} from 'react-router-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import Navbar from './Navbar'
import Footer from './Footer'

import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import {
  forgotUser,
} from "../actions/authActions";
import { otptoUser,sendotp ,twofachecking,emailotpchecking,mobileotpcheck} from "../actions/authActions";
import browser from "browser-detect";
import axios from "axios";
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import keys from "../actions/config";
const url = keys.baseUrl;
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();

const grecaptchaObject = window.grecaptcha;



class TwoFactor extends Component {
    constructor() {
        super();
        this.state = {
            _id: "",
      password: "",
      password2: "",
      errors: {},
      twofashow:true,
      mobileotpshow:false,
      emailotpshow:false,
      email:"",
      mobilenumber:"",
      emailverified:false,
      mobileverified:false,
      _csrf:'',
      countryName:"",
      countryCode: "",
      ipaddress: "",
      region: "",
        };
      }

      getCSRFtoken =async () => {
        await axios
            .get(url + "getCSRF")
            .then((res) => {
              this.setState({_csrf:res.data._csrf});
            })
            .catch();
      }

      getGeoInfo = () => {
        axios
          .get("https://ipapi.co/json/")
          .then((response) => {
            let data = response.data;
            this.setState({
              countryName: data.country_name,
              countryCode: data.country_calling_code,
              ipaddress: data.ip,
              region: data.region,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      };

      componentDidMount() {
        let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
          this.getCSRFtoken();
          this.getGeoInfo();
        let userid = this.props.match.params.id;
    axios
       .get(url + "cryptoapi/userget/" + userid)
       .then((res) => {
         this.setState({email:res.data.email,
                        mobilenumber:res.data.phonenumber,
                      mobileverified:res.data.mobileverified,
                      emailverified:res.data.emailverified,
                    })
       })

      }
    
      captchaChange = (e) => {
        this.setState({ recaptcha: e });
      };
    
      captchaChange1 = (e) => {
        this.setState({ mobrecaptcha: e });
      };
      componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
          this.setState({
            errors: nextProps.errors,
          });
    
        }
        if (
          typeof nextProps.errors.notify != "undefined" &&
          nextProps.errors.notify != "" &&
          nextProps.errors.notify != "2fa Enabled" &&
          nextProps.errors.notify != "2fa Activated" &&
          nextProps.errors.notify != "blocking"
        ) {
          store.addNotification({
            // title: "Error!",
            message: nextProps.errors.notify,
            type: "danger", // ip = ip.replace('::ffff:', '');
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
        if (nextProps.auth.isAuthenticated) {
          store.addNotification({
            title: "Wonderful!",
            message: "Loggedin Successfully. Welcome to Coinbarter",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
          // this.props.history.push("/Trade/" + localStorage.getItem("curpair"));
          // this.props.history.push("/Exchange/" + localStorage.getItem("curpair"));
          this.props.history.push("/Dashboard");
        }
    
      }
      onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
      };
    
      onChangeCheckbox = (e) => {
        this.setState({ [e.target.id]: e.target.checked });
      };
    
      tfachecking = (e) => {

        e.preventDefault();
        const result = browser();
        const loginhistory = {};
    
        if (this.state.tfacode != "") {
          loginhistory.countryCode = this.state.countryCode;
          loginhistory.countryName = this.state.countryName;
          loginhistory.ipaddress = this.state.ipaddress;
          loginhistory.regionName = this.state.region;
          loginhistory.broswername = result.name;
          loginhistory.ismobile = result.mobile;
          loginhistory.os = result.os;
    
            var userData = {
              userid: this.props.match.params.id,
              tfacode: this.state.tfacode,
              logininfo: loginhistory,
              _csrf:this.state._csrf,
            };
          this.props.twofachecking(userData);
    
        } else {
          store.addNotification({
            title: "Error!",
            message: "Please enter 2FA Code",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      };
    
    
	render() {
        const { errors } = this.state;

		return (
      <div>
      <Navbar />
     <section className="crediantialsBox">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-10 col-sm-12 mx-auto">
                        <div className="wow flipInY" data-wow-delay=".15s;">
                        {/* <div className="text-center">
                            <a href="/"><img src={require("../images/headLogo.png")} alt="" className="img-fluid" /></a>
                        </div> */}
                        <div className="formBox mb-5 mb-md-0">
                            <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-login" role="tabpanel" aria-labelledby="nav-login-tab">
                                    <h2 className="text-left credintialTitle"><NavLink to="/Login" className="mr-2"><i class="fas fa-angle-left"></i></NavLink> 2FA Code</h2>
                                    <form id="forgotfrm" name="forgotfrm" noValidate onSubmit={this.tfachecking}  className="stoForm" method="">
                                        <div className="form-group mobNumber">
                                            <label>Enter Your Google 2FA Code</label>

                                            <input
                                  className="quantity"

                                  name="tfacode"
                                  id="tfacode"
                                  placeholder="Enter the 2FA Code"
                                  onChange={this.onChange}
                                  value={this.state.tfacode}
                                  type="number"
                                />
                                <span className="text-danger">
                                  {errors.tfacode}
                                </span>                                       </div>
                                        
                                        
                                        
                                        <div className="form-group text-center">
                                            <input  name="frmbtn" id="frmbtn" type="submit" className="btnType1" value="Submit" />
                                        </div>
                                    </form>
                                    {/* <div className="formFooter">
                                        <p className="text-center"><span>Notes: Please check your email, we’ll send you password recovery email link
which valid</span> one hour only</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
</div>
);
	}
}



TwoFactor.propTypes = {
    forgotUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    twofachecking: PropTypes.func.isRequired,

  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
  });
  
  export default connect(mapStateToProps, {
    forgotUser,twofachecking
  })(withRouter(TwoFactor));