import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    USER_REGISTER,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_FORGOT,
    USER_UPDATE,
    OTP_REGISTER,
     TRADE,
     SUPPORT_REPLY,
     SUPPORT,
     WALLET
} from "./types";
import keys from "./config";
const url = keys.baseUrl;


axios.defaults.withCredentials = true;

export const support = (supportdata) => dispatch => {
    axios
        .post(url+"users/supportticketadd", supportdata)
        .then(res =>
          {
            dispatch({
              type: GET_ERRORS,
              payload: ""
          })
            dispatch({
              type: SUPPORT,
               payload: res,
          })
         
          }
          

        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );


   };


   export const supportreply = (supportreplydata) => dispatch => {
    axios
        .post(url+"users/support_reply_user", supportreplydata)
        .then(res => dispatch({
            type: SUPPORT,
            payload: res,
        })

        )
        .catch(err =>{
          console.log("errerrerrerrerrerrerr",err.response.data)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
          }
        );


   };



export const registerUser = (userData, history) => dispatch => {
    axios
        .post(url+"users/userAdd", userData)
        .then(res => dispatch({
            type: USER_REGISTER,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const kycAdd = (kycData) => dispatch => {
      axios
        .post(url + "users/kycupdate", kycData)
        .then(res =>
          dispatch({
            type: USER_UPDATE,
            payload: res,
          })
        )
        .catch(err =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        );
  };
  
  export const profileAdd = profileData => dispatch => {
    axios
      .post(url + "users/profileupdate", profileData)
      .then(res =>
        dispatch({
          type: USER_UPDATE,
          payload: res,
        })
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };


 export const profileUser = (profileData,history) => dispatch => {
  axios
      .post(url+"cryptoapi/userupdate", profileData)
      .then(res => dispatch({
          type: USER_UPDATE,
          payload: res,
      })
      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
 };

 export const dd_submit = Data => dispatch => {
  axios
      .post(url+"walletapi/deposit", Data)
      .then(res =>  dispatch({
        type: WALLET,
        payload: res,
    })
    )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
};



export const mobregisterUser = (userData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/mobuser-add", userData)
        .then(res => dispatch({
            type: USER_REGISTER,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const otptoUser = (otpData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/otp-submit", otpData)
        .then(res => dispatch({
            type: USER_REGISTER,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const sendotp = (otpData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/sendotp", otpData)
        .then(res => dispatch({
            type: USER_REGISTER,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const loginUser = userData => dispatch => {
    axios
        .post(url+"users/logincheck", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        );
};

export const tfachecking = userData => dispatch => {
    axios
        .post(url+"cryptoapi/tfachecking", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const twofachecking = userData => dispatch => {
    axios
        .post(url+"users/twofachecking", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const emailotpchecking = userData => dispatch => {
    axios
        .post(url+"cryptoapi/verifyemailotplogin", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const mobileotpcheck = userData => dispatch => {
    axios
        .post(url+"cryptoapi/verifymobileotplogin", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};




export const confirmaionchecking = userData => dispatch => {
    axios
        .post(url+"cryptoapi/confirmaionchecking", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const mobloginUser = userData => dispatch => {
    axios
        .post(url+"cryptoapi/moblogincheck", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const forgotUser = (forgotData,history) => dispatch => {
  axios
      .post(url+"users/forgot", forgotData)
      .then(res => dispatch({
          type: USER_FORGOT,
          payload: res,
          //
      })
      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
 };

 export const mobforgotUser = (forgotData,history) => dispatch => {
  axios
      .post(url+"cryptoapi/forgotformob", forgotData)
      .then(res => dispatch({
          type: USER_FORGOT,
          payload: res,
      })
      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
 };

 export const resetUser = (resetData) => dispatch => {
   axios
       .post(url+"users/reset", resetData)
       .then(res => dispatch({
           type: USER_FORGOT,
           payload: res,
       })
       )
       .catch(err =>
           dispatch({
               type: GET_ERRORS,
               payload: err.response.data
           })
       );
  };

  export const saveuserNewsletter = (newsData) => dispatch => {
    axios
        .post(url+"users/savenewsletter", newsData)
        .then(res => dispatch({
            type: USER_FORGOT,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
   };

  export const mobresetPassword = (resetData) => dispatch => {
   axios
       .post(url+"cryptoapi/mobresetPassword", resetData)
       .then(res => dispatch({
           type: USER_FORGOT,
           payload: res,
       })
       )
       .catch(err =>
           dispatch({
               type: GET_ERRORS,
               payload: err.response.data
           })
       );
  };

   export const tfaFormsubmit = (otpData, history) => dispatch => {
    axios
        .post(url+"cryptoapi/tfa-enable", otpData)
        .then(res => dispatch({
            type: USER_UPDATE,
            payload: res,
        })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

export const getTableDataDynamic = (dynobj) => dispatch => {
    axios
      .post(url+"cryptoapi/getTableDataDynamic", dynobj)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };

  export const cancelTrade = (pass_data) => dispatch => {
    axios
      .post(url+"cryptoapi/cancelTrade", pass_data)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };
  export const changeopenpositions = (leveragedata) => dispatch => {
    axios
      .post(url+"cryptoapi/changeopenpositions", leveragedata)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };

  export const getPertual = (contactdata) => dispatch => {
    axios
      .post(url+"tradeapi/perpetual-data", contactdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
   };

   export const getCurrencydetails = (contactdata) => dispatch => {
    axios
      .get(url+"tradeapi/curreDetails")
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
   };

   export const getBalancedetails = (contactdata) => dispatch => {
    axios
      .post(url+"tradeapi/balanceDetails",contactdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
   };

   export const getPricevalue = (pairdata) => dispatch => {
    axios
      .post(url+"tradeapi/getPricevalue", pairdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
   };
   export const getTradeData = (pass_data) => dispatch => {
    axios
      .post(url+"tradeapi/getTradeData", pass_data)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };

  //Spot start
  export const getspotPertual = (contactdata) => dispatch => {
    axios
      .post(url+"tradeapi/spotpair-data", contactdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
   };
  export const getspotTradeData = (pass_data) => dispatch => {
    axios
      .post(url+"tradeapi/getspotTradeData", pass_data)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };
  export const getspotuserTradeData = (userdetails) => dispatch => {
    axios
      .post(url+"tradeapi/getspotuserTradeData", userdetails)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };
  export const getspotPricevalue = (pairdata) => dispatch => {
    axios
      .post(url+"tradeapi/getspotPricevalue", pairdata)
      .then(res => dispatch({
          type: TRADE,
          payload: res,
      })

      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
   };
   export const spotcancelTrade = (pass_data) => dispatch => {
    axios
      .post(url+"tradeapi/spotcancelTrade", pass_data)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };
  export const spotorderPlacing = (orderdata) => dispatch => {
    axios
      .post(url+"tradeapi/spotorderPlacing", orderdata)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };
//Spot end

  export const getuserTradeData = (userdetails) => dispatch => {
    axios
      .post(url+"tradeapi/getuserTradeData", userdetails)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };
  export const orderPlacing = (orderdata) => dispatch => {
    axios
      .post(url+"tradeapi/orderPlacing", orderdata)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };

  export const triggerstop = (orderdata) => dispatch => {
    axios
      .post(url+"tradeapi/triggerstop", orderdata)
      .then(res => dispatch({
        type: TRADE,
        payload: res,
      }))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  };


export const logoutUser = () => dispatch => {
  
    axios
    .get(url+"users/logoutuser")
    .then({
    })
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    window.location.href = "/Login";

};

export const socaillogin = userData => dispatch => {
  axios
      .post(url+"cryptoapi/socaillogin", userData)
      .then(res => {
          const { token } = res.data;
          localStorage.setItem("jwtToken", token);
          setAuthToken(token);
          const decoded = jwt_decode(token);
          dispatch(setCurrentUser(decoded));
      })
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
};

export const clearErrorfromredux = async() => dispatch => {
  dispatch({
    type: GET_ERRORS,
    payload: '',
  })
 };
