import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import {NavLink} from 'react-router-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import Navbar from './Navbar'
import Footer from './Footer'
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import {
  forgotUser,
} from "../actions/authActions";
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import keys from "../actions/config";
import axios from "axios";
const url = keys.baseUrl;
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();

const grecaptchaObject = window.grecaptcha;

class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
          email: "",
          phone: "",
          otpver: "",
          timer: "",
          resend: "",
          recaptcha: "",
          mobrecaptcha: "",
          errors: {},
          time: {},
          seconds: 120,
          _csrf:'',
        };
      }

      getCSRFtoken =async () => {
        await axios
            .get(url + "getCSRF")
            .then((res) => {
              this.setState({_csrf:res.data._csrf});
            })
            .catch();
      }

      componentDidMount() {
        this.getCSRFtoken();
        if (this.props.auth.isAuthenticated) {
          this.props.history.push("/Account");
        }
        let theme=localStorage.getItem("xavitheme");
        if(theme==null){
          localStorage.setItem('xavitheme','dark_theme')
        }{
          if(theme=='dark_theme'){
            document.body.classList.remove('light_theme' );
            document.body.classList.add('dark_theme');
            this.setState({appliedtheme:'dark_theme',isButtonActive:true})
          }else{
            document.body.classList.remove('dark_theme' );
            document.body.classList.add('light_theme');
            this.setState({appliedtheme:'light_theme',isButtonActive:false})
          }
         
        }

      }
    
      captchaChange = (e) => {
        this.setState({ recaptcha: e });
      };
    
      captchaChange1 = (e) => {
        this.setState({ mobrecaptcha: e });
      };
    
      componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
          this.setState({
            errors: nextProps.errors,
          });
        }
    
        if (
          nextProps.auth.forgotuser !== undefined &&
          nextProps.auth.forgotuser.data !== undefined &&
          nextProps.auth.forgotuser.data.message !== undefined
        ) {
          store.addNotification({
            title: "Wonderful!",
            message: nextProps.auth.forgotuser.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.setState({
            otpver: this.state.phone != "" ? "true" : "",
          });
          if ( 
            nextProps.auth.forgotuser.data.message ==
            "Your password has been reset successfully"
          ) {
            nextProps.auth.forgotuser = "";
            this.props.history.push("/Login");
          } 
         else if (
            nextProps.auth.forgotuser.data.message ==
            "OTP sent successfully, It is only valid for 2 minutes"
          ) {
            nextProps.auth.forgotuser = "";
            this.props.history.push("/otp");
          } 
          else {
            if (this.state.phone != "") {
              this.setState({ timer: "true", seconds: 120 });
              this.state.seconds = 120;
              this.timer = 0;
              this.startTimer();
              nextProps.auth.forgotuser = "";
            } else {
              nextProps.auth.forgotuser = "";
              this.props.history.push("/Login");
            }
          }
        }
      }
    
      onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
      };
    
      onChangeCheckbox = (e) => {
        this.setState({ [e.target.id]: e.target.checked });
      };
    
      onSubmit = (e) => {
        e.preventDefault();
        const forgotUser = {
          email: this.state.email,
          recaptcha: this.state.recaptcha,
          _csrf: this.state._csrf,
        };
        this.props.forgotUser(forgotUser, this.props.history);
      };
	render() {
        const { errors } = this.state;

		return (
      <div className="login_nav">
        <Navbar />
     <section className="crediantialsBox">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-10 col-sm-12 mx-auto">
                        <div className="wow flipInY" data-wow-delay=".15s;">
                        <div className="formBox mb-5 mb-md-0">
                            <div className="tab-content py-0 px-3 px-sm-0" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-login" role="tabpanel" aria-labelledby="nav-login-tab">
                                    <h2 className="crediantialTitle">Forgot Password</h2>
                                    <p>Enter your registered email address, we’ll send you password reset link to your email address within two minutes.</p>
                                    <form id="forgotfrm" name="forgotfrm" noValidate onSubmit={this.onSubmit}  className="stoForm" method="">
                                        <div className="form-group mobNumber">
                                            <label>Your Registered Email Address</label>
                                            <input type="text" onChange={this.onChange} value={this.state.email} error={errors.email} className="form-control" id="email" required />
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                        <div className="form-group">
                                            <ReCAPTCHA className="g-recaptcha" ref={(r) => (this.recaptcha = r)} sitekey={Recaptchakey} grecaptcha={grecaptchaObject} onChange={this.captchaChange} />
                                            <span className="text-danger">{errors.recaptcha}</span>
                                        </div>
                                        <div className="form-group text-center">
                                            <input name="frmbtn" id="frmbtn" type="submit" className="btnType1" value="Submit" />
                                        </div>
                                    </form>
                                    <div className="formFooter">
                                        <h6 className="text-center"><a href="/">Home</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
</div>
);
	}
}

ForgotPassword.propTypes = {
    forgotUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
  });
  
  export default connect(mapStateToProps, {
    forgotUser,
  })(withRouter(ForgotPassword));