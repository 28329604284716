import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import Select from 'react-select';
import ScrollspyNav from "react-scrollspy-nav";
import ReactTooltip from 'react-tooltip';
import Switch from "react-switch";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap/";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import { tfaFormsubmit } from "../actions/authActions";
import setAuthToken from "../utils/setAuthToken";
import { CopyToClipboard } from "react-copy-to-clipboard";
const url = keys.baseUrl;


const currencyType = [
  { value: 'NGN', label: 'NGN' },
  { value: 'BTC', label: 'BTC' },
  { value: 'USDT', label: 'USDT' },
  { value: 'BCH', label: 'BCH' },
  { value: 'XRP', label: 'XRP' },
  { value: 'TRON', label: 'TRON' },
  { value: 'DASH', label: 'DASH' },
  { value: 'LTC', label: 'LTC' },
];
const buySell = [
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];
const transferType = [
  { value: 'Bank Transfer', label: 'Bank Transfer' },
  { value: 'Fiat Transfer', label: 'Fiat Transfer' },
  { value: 'Instant Transfer', label: 'Instant Transfer' },
];

class Notifications extends Component {
  constructor() {
		super();
		this.state = { 
		  selectedPreferredTime: null,
		  selectedPreferredLanguage: null,
		  checked: false ,
		  selectedCoinPair: null,
		  selectedbankName: null,
		  oldpassword: "",
		  password: "",
		  password2: "",
		  loginpassword: "",
		  googleauth: "",
		  onecode: "",
		  tfacode: "",
		  applicationName: "",
		  readOnly: "",
		  twofactorkey: "",
		  notifications: {},
		  errors: {},
		  accepterror: "",
		  onecodeerror: "",
		  twofastatus:"",
		  accept: "false",
		  _csrf:'',
		  googlestatus:'',
		  isTop: true,
		  selectedBuySell: null,
		  selectedTransferType: null,
		  selectedCurrencyType: null,
		  faqDetails:[],
		};
	  }
// state = {
//     isTop: true,
//   };
//  state = {
//     selectedBuySell: null,
//     selectedTransferType: null,
//     selectedCurrencyType: null,
//     };

 getFaqDetails =async () => {
	await axios
		.get(url + "users/getFaqDetails")
		.then((res) => {
      if(res.data.history){
		    this.setState({faqDetails:res.data.history});
      }
		})
		.catch();
  }

  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
	this.getFaqDetails();
  }

  handleBuySell = selectedBuySell => {
    this.setState({ selectedBuySell });
  };
  handleTransferType = selectedTransferType => {
    this.setState({ selectedTransferType });
  };
  handleCurrencyType = selectedCurrencyType => {
    this.setState({ selectedCurrencyType });
  };
	render() {
	const { selectedBuySell } = this.state;
	const { selectedTransferType } = this.state;
	const { selectedCurrencyType } = this.state;
		return (
      <div>
        <Navbar />
        <section className="secFAQ cmsPages">
          <div className="container">            
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12 m-auto">
                <div className="cmsBoxWhiteBox pb-0">
                  <div className="faqCont">
                    <div className="homeAccordian wow fadeIn">
                      <h2 className="mb-3">Notifications</h2>
                      <div className="row">
                        <div className="col-md-12">
                          <article className="newsCardLanding">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="newsContentLand">
                                <div id="accordion">
                                  <div class="card">
                                    <div class="card-header pointer" data-toggle="collapse" data-target="#collapse-1">
                                      <h5>Eiusmod high life accusamu</h5>
                                    </div>
                                    <div id="collapse-1" class="collapse show" data-parent="#accordion">
                                      <div class="card-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute.
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div class="card">
                                    <div class="card-header pointer" data-toggle="collapse" data-target="#collapse-2">
                                      <h5>Eiusmod high life accusamu</h5>
                                    </div>
                                    <div id="collapse-2" class="collapse" data-parent="#accordion">
                                      <div class="card-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute.
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div class="card">
                                    <div class="card-header pointer" data-toggle="collapse" data-target="#collapse-3">
                                      <h5>Eiusmod high life accusamu</h5>
                                    </div>
                                    <div id="collapse-3" class="collapse" data-parent="#accordion">
                                      <div class="card-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute.
                                      </div>
                                    </div>
                                  </div>

                                </div>                                  
                                </div>
                              </div>
                            </div>    
                          </article>                            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </section>
      <Footer />
	  </div>
    );
	}
}

Notifications.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	logoutUser: PropTypes.func.isRequired,
  
  };
  
  const mapStateToProps = (state) => ({
   auth: state.auth,
   errors: state.errors,
  });
  
export default connect(mapStateToProps,{tfaFormsubmit})(Notifications)