import React, { Component } from 'react'
import {NavLink} from 'react-router-dom';
import ReactDOM from 'react-dom';
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from 'react-select';
import { Multiselect } from 'multiselect-react-dropdown';
import axios from "axios";
import keys, { numberWithCommas } from "../actions/config";
import queryString from "query-string";
import { store } from "react-notifications-component";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";



const url = keys.baseUrl;


const coinPair = [
  { value: 'BTC', label: 'BTC - Bitcoin' },
  { value: 'XRP', label: 'XRP- Ripple' },
  { value: 'ETH', label: 'ETH - Ethereum' },
];
const fiatType = [
  { value: 'US - Dollar', label: 'US - Dollar' },
  { value: 'INR - Rupees', label: 'INR - Rupees' },
  { value: 'AUD - Australian Dollar', label: 'AUD - Australian Dollar' },
];

class OfferCategory extends Component {
state = {
    selectedCoinPair: null,
    selectedFiatType: null,
};
constructor(props) {
    super(props);

    this.state = {
		plainArray: ["New Delhi, India", "Tokyo, Japan", "Newyork, USA", "London, UK"],
		transferType: ["Bank Transfer", "Paypal", "Crypto Wallet", "Gif Certificate"],
		errors: {},
		buyads: [],
		sellads: [],
		buytab: "",
		selltab: "",
		findadstab: "active",
		buycheckbox: true,
		sellcheckbox: false,
		buytradecheckbox: true,
		selltradecheckbox: false,
		Skeletonbuy: 1,
		Skeletonsell: 0,
		// selectedCurrencyType: {
		//   label: "BTC",
		//   value: "BTC",
		// },

		// selectedtocpostcurrency: {
		//   label: "NGN",
		//   value: "NGN",
		// },
		selectedpreferred: {
			label: "Select",
			value: "",
		},
		selectedDepositFrom: {
			label: null,
			value: null,
		},
		preferredtransfer: {
			label: "Select",
			value: "",
		},
		curmarketprice: 0,
		BuyorSell: "Buy",
		BuyorSell_find: "Buy",
		tradetype: "Buy",
		balancedetails: [],
		IdentityVerification: "",
		email: "",
		username: "",
		currencydetails: [],
		userbalance: 0,
		currencyselected: "",
		posttocurrency: "",
		postprefcurrency: "",
		spotpairsdata: [],
		posttradeprice: "",
		postminlimit: "",
		postmaxlimit: "",
		posttooptions: [],
		postcheckboxaccept: false,
		margin: 0,
		myadshistory: [],
		submitreview: false,
		bankdetails: [],
		bankdboptions: "",
		bankselected: "",
		editadshow: false,
		editableboxshow: false,
		cancelpopupshow: false,
		myactivities: [],
		data: [],
		offset_myads: 0,
		offset_searchads: 0,
		activetab: "",
		myadname: "",
		myaddate: "",
		date: "",
		terms: "",
		editterms: "",
		kycstatus:"Not Verified",
		placorder_disable:false,
    percentagetype: "",
    Skeletonabove: 1,
    Skeletonbelow: 0,
    aboveCheckbox: false,
    belowCheckbox: false,
    marginOffer:'',
    };
  }

  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    this.getUserdata();
    this.getBalance();
    this.getcurrencydetails();
   
    this.getspotdata();
    // this.getbankdetails();
    // this.getmyads();
    // this.getmyactivity();
    this.getpreferred_transfer();
    // this.getbuyads();
    // this.searchfind_ads("Buy", 0);
    let params = queryString.parse(this.props.location.search);
    if (params.tab == "postads") {
      this.setState({ postadstab: "active" });
      this.setState({ findadstab: "" });
    }

    if (params.tab == "myads") {
      this.setState({ myadstab: "active" });
      this.setState({ postadstab: "" });
      this.setState({ findadstab: "" });
    }
    var ctab = this.props.match.params.id;

    if (ctab == "myads" || ctab == "myactivity") {
      let userid = this.props.auth.user.id;
      if (userid == "" || userid == undefined) {
        this.props.history.push("/login");
      }
    }

    this.setState({ activetab: ctab });
  }


handleCoinPair = selectedCoinPair => {
    this.setState({ selectedCoinPair });
};
handleCoinPair = selectedFiatType => {
    this.setState({ selectedFiatType });
};

getUserdata() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/userget/" + userid).then((res) => {
        this.setState({kycstatus:res.data.kycstatus});
      if(res.data.kycstatus!='Approved'){
        store.addNotification({
          title: "Error!",
          message: "You need to update your verification status to continue using Aya",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });

     	this.props.history.push("/MyAccount/");
      }
    });
  }
  getBalance() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      this.setState({ balancedetails: res.data.data });
      var tempdata = res.data.data;
      var index = tempdata.findIndex(
        (x) => x.currencySymbol === this.state.currencyselected
      );
      if (index != -1) {
        this.setState({ userbalance: tempdata[index].balance });
      }
    });
  }
  getspotdata() {
    axios.get(url + "api/spotpair-data").then((res) => {
      if (res.data.status) {
        this.setState({ spotpairsdata: res.data.data });
        var tempdata = res.data.data;
        var pair = this.state.currencyselected + this.state.posttocurrency;
        var indexonchcange = this.state.spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          var onchbaseprice =
            this.state.spotpairsdata[indexonchcange].index_price;
            this.setState({ curmarketprice: onchbaseprice });
        }
      }
    });
  }


// Get currency details
getcurrencydetails() {
    axios.get(url + "api/currency").then((res) => {
      this.setState({ currencydetails: res.data });
      var firstcur = res.data;
      var newcurar = [];
      firstcur.map((details, index) => {
        if (details.p2p == "On") {
          newcurar.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });
      var fiatcur = [];
      firstcur.map((details, index) => {
        if (details.type == "Fiat") {
          fiatcur.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });
      var tocurrecur = [];
      firstcur.map((details, index) => {
        if (details.currencySymbol != this.state.currencyselected) {
          tocurrecur.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });
      this.setState({ posttooptions: tocurrecur });
      this.setState({ currencyoptions: newcurar, fiatcuroption: fiatcur });
    });
  }

  getpreferred_transfer() {
    axios.get(url + "tradeapi/preferred_transfer").then((res) => {
      if (res.data.status) {
        var pref = [];
        res.data.preferredtransfer.map((details, index) => {
          pref.push({
            value: details.bank,
            label: (
              <span>
                {" "}
                <img
                  width="20"
                  height="20"
                  src={require("../images/payment/" +
                    details.bank.replace(/[ )(]/g, "") +
                    ".png")}
                  class="css-pjaw30"
                />{" "}
                {details.bank}
              </span>
            ),
          });
        });

        this.setState({ preferredtransfer: pref });
      }
    });
  }
// Radio box change
RadiobuttonChange_buy = (e) => {
    this.setState({
      tradetype: e.currentTarget.value,
    });
    this.setState({ buycheckbox: true });
    this.setState({ sellcheckbox: false });

    this.setState({ Skeletonbuy: 1, Skeletonsell: 0 });

    // this.searchfind_ads(e.currentTarget.value, 0);
  };

  RadiobuttonChange_sell = (e) => {
    this.setState({
      tradetype: e.currentTarget.value,
    });
    this.setState({ buycheckbox: false });
    this.setState({ sellcheckbox: true });
    this.setState({ Skeletonbuy: 0, Skeletonsell: 1 });
    // this.searchfind_ads(e.currentTarget.value, 0);
  };

  RadiobuttonChange_above = (e) => {
    this.setState({
      percentagetype: e.currentTarget.value,
    });
    this.setState({ aboveCheckbox: true });
    this.setState({ belowCheckbox: false });

    this.setState({ Skeletonabove: 1, Skeletonbelow: 0 });
  };

  RadiobuttonChange_below = (e) => {
    this.setState({
      percentagetype: e.currentTarget.value,
    });
    this.setState({ aboveCheckbox: false });
    this.setState({ belowCheckbox: true });
    this.setState({ Skeletonabove: 0, Skeletonbelow: 1 });
    // this.searchfind_ads(e.currentTarget.value, 0);
  };

  onChangeCheckbox = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
  };
//   Handle currencty
handleCurrencyType = (selectedCurrencyType) => {
    this.setState({ selectedCurrencyType });
    this.setState({ currencyselected: selectedCurrencyType.value });
    var pair = selectedCurrencyType.value + this.state.posttocurrency;
    var tempprice = 0;
    var curindex = this.state.spotpairsdata.findIndex(
      (x) => x.tiker_root === pair
    );
    if (curindex != -1) {
      tempprice = this.state.spotpairsdata[curindex].index_price;
      this.setState({
        curmarketprice: this.state.spotpairsdata[curindex].index_price,
      });
    }
    var index = this.state.balancedetails.findIndex(
      (x) => x.currencySymbol === selectedCurrencyType.value
    );
    if (index != -1) {
      this.setState({ userbalance: this.state.balancedetails[index].balance });
    }

    var fiatcur = [];
    this.state.currencydetails.map((details, index) => {
      if (details.currencySymbol != selectedCurrencyType.value) {
        fiatcur.push({
          value: details.currencySymbol,
          label: details.currencySymbol,
        });
      }
    });

    this.setState({ posttooptions: fiatcur });
  };

  handleposttocurrency = (selectedtocpostcurrency) => {
    this.setState({ selectedtocpostcurrency });
    this.setState({ posttocurrency: selectedtocpostcurrency.value });

    var pair = this.state.currencyselected + selectedtocpostcurrency.value;
    var tempprice = 0;
    var curindex = this.state.spotpairsdata.findIndex(
      (x) => x.tiker_root === pair
    );
    if (curindex != -1) {
      tempprice = this.state.spotpairsdata[curindex].index_price;
      this.setState({
        curmarketprice: this.state.spotpairsdata[curindex].index_price,
      });
    }
  };

  onChange = (e) => {
    if (e.target.id == "terms" || e.target.id == "editterms") {
      if (e.target.value.length > 1000) {
        store.addNotification({
          title: "Error!",
          message: "Only Allowed 1000 Character",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        return false;
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  // Post forward to next step
  PostTrade = () => {
    let userid = this.props.auth.user.id;
    var message = "";

    if (userid == "" || userid == undefined) {
      message = "Please Login to post ads";
    }else if (this.state.currencyselected == "") {
      message = "Please Select a Cyprocurreny would like to trade";
    } else if (this.state.posttocurrency == "") {
      message = "Please Select a local currency";
    } else if (this.state.postminlimit == "") {
      message = "Please Enter the Min Limit";
    } else if (isNaN(this.state.postminlimit) == true) {
      message = "Please Enter the Min Limit number only";
    } else if (this.state.postmaxlimit == "") {
      message = "Please Enter the Max Limit";
    } else if (isNaN(this.state.postmaxlimit) == true) {
      message = "Please Enter the Max Limit number only";
    }   else if (this.state.marginOffer == "") {
      message = "Please Enter the Margin Limit";
    } else if (isNaN(this.state.marginOffer) == true) {
      message = "Please Enter the Margin Limit number only";
    } else if (this.state.percentagetype == "") {
      message = "Please Select Above or Below";
    } else if (this.state.postminlimit != "" && this.state.postmaxlimit != "") {
      if (
        parseFloat(this.state.postminlimit) >
        parseFloat(this.state.postmaxlimit)
      ) {
        message = "Please Enter the correct Minimum Value";
      }
    }
    if (this.state.selectedpreferred.length > 2) {
      message = "Please select maximum 2 Preferred Transfer";
    }

    this.setState({
      postprefcurrency:  this.state.selectedpreferred,
    });
    if (message != "") {
      store.addNotification({
        title: "Error!",
        message: message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      return false;
    } else {
      this.setState({ submitreview: true });
      const data= this.state;
      // this.props.history.push("/PostDetails");
      // this.props.history.push({
      //   pathname: '/PostDetails',
      //     state: data // your data array of objects
      // })

      // this.props.history.push({
      //   '/postDetails'+
      //      data // your data array of objects
      // })
    }
  };

	render() {
	const { selectedCoinPair } = this.state;
	const { selectedFiatType } = this.state;
	const { plainArray, transferType, selectedValues } = this.state;
	const { errors } = this.state;

    const {
      selectedCurrencyType,
      selectedtocpostcurrency,
      selectedpreferred,
      selectedDepositFrom,
      postprefoption,
      postprefoption_find,
    } = this.state;
		return (
      <div>
      <UserNavbar />
      <section className="userPages">
          <div className="container">
          	<div className="userCommonCard">
                  <div className="breadCrumb">
                  	<ul>
                  		<li><a href="/PostTrade">Create New Trade</a></li>
                  		<li className="bcArrow"><i class="fas fa-angle-right"></i></li>
                  		<li>Offer Category</li>
                  	</ul>
                  </div>
                  <div className="userBoxWhiteBox securityBox">
                  	<div className="row">
                  		<div className="col-md-6">
                  			<div className="ocTradeDetails">
                  				<h3 class="userBoxUnderlineTitle">Trade Details</h3>
                  				<div className="form-group">
			                        <label>What type of offer do you want to create?</label>
			                        <div className="dashRadio">
			                            <div class="radio mr-4">
			                              <label>
											<input
											type="radio"
											name="o2"
											value="Buy"
											checked={this.state.buycheckbox}
											id="BuyorSell_find"
											onChange={this.RadiobuttonChange_buy}
											/>
			                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
			                                    Buy Crypto
			                              </label>
			                            </div>
			                            <div class="radio">
			                              <label>
												<input
												type="radio"
												name="o2"
												value="Sell"
												checked={this.state.sellcheckbox}
												id="BuyorSell_find"
												onChange={this.RadiobuttonChange_sell}
												/>
			                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
			                                    Sell Crypto
			                              </label>
			                          </div>
			                        </div>
			                     </div>
			                     <div class="form-group">
			                      <label>What Cyprocurreny would like to trade?</label>
			                      <Select
		                            // value={selectedCoinPair}
		                            // onChange={this.handleCoinPair}
		                            options={coinPair}
										value={selectedCurrencyType}
                                        onChange={this.handleCurrencyType}
		                         />
			                     </div>
			                     {/* <div class="form-group">
			                      <label>Location to display the post</label>
			                      <Multiselect options={plainArray} isObject={false} />
			                     </div> */}
			                     <div class="form-group">
			                      <label>Currency would like to trade?</label>
			                      {/* <Select
		                            value={selectedFiatType}
		                            onChange={this.handleFiatType}
		                            options={fiatType}
		                         /> */}
								 <Select
                                        value={selectedtocpostcurrency}
                                        onChange={this.handleposttocurrency}
                                        options={this.state.fiatcuroption}
                                      />
			                     </div>
                  			</div>
                  		</div>
                  		<div className="col-md-6">
                  			<div className="ocPaymentDetails">
                  				<h3 class="userBoxUnderlineTitle">Payment Details</h3>
                  				<div class="row">
                  					<div class="col-lg-6">
                  						<div className="form-group">
					                      <label>Minimum</label>
					                      <div class="input-group">
                                <input
                                          className="form-control"
                                          type="tex"
                                          onChange={this.onChange}
                                          value={this.state.postminlimit}
                                          error={errors.postminlimit}
                                          id="postminlimit"
                                        />
                                        <span className="text-danger">
                                          {errors.postminlimit}
                                        </span>
                                        <div class="input-group-append">
                                          <span class="input-group-text">
                                            {this.state.posttocurrency}
                                          </span>
                                        </div>
					                        {/* <input type="text" class="form-control" value="50.00" id="secondamount" />
					                        <div class="input-group-append"><span class="input-group-text">USD</span></div> */}
					                      </div>
					                    </div>
                  					</div>
                  					<div class="col-lg-6">
                  						<div className="form-group">
					                      <label>Maximum</label>
					                      <div class="input-group">
                                <input
                                          className="form-control"
                                          type="tex"
                                          onChange={this.onChange}
                                          value={this.state.postmaxlimit}
                                          error={errors.postmaxlimit}
                                          id="postmaxlimit"
                                        />
                                        <span className="text-danger">
                                          {errors.postmaxlimit}
                                        </span>
                                        <div class="input-group-append">
                                          <span class="input-group-text">
                                            {this.state.posttocurrency}
                                          </span>
                                        </div>
					                        {/* <input type="text" class="form-control" value="10000.00" id="secondamount" />
					                        <div class="input-group-append"><span class="input-group-text">USD</span></div> */}
					                      </div>
					                    </div>
                  					</div>
                  				</div>
                  				<div class="form-group">
			                      <label>Which payment methods do you want to accept?</label>
                            <Select
                                    isMulti
                                    onChange={this.handlepostpreferred}
                                    options={this.state.preferredtransfer}
                                  />
			                      {/* <Multiselect options={transferType} isObject={false} /> */}
			                     </div>
			                     <div className="form-group">
			                      <label>What margin do you want to offer?</label>
			                      <div class="input-group">
                                <input
                                  className="form-control"
                                  type="tex"
                                  onChange={this.onChange}
                                  value={this.state.marginOffer}
                                  error={errors.marginOffer}
                                  id="marginOffer"
                                />
			                        {/* <input type="text" class="form-control" value="2.5" id="secondamount" /> */}
			                        <div class="input-group-append"><span class="input-group-text">%</span></div>
			                      </div>
			                      <div className="dashRadio mt-3 pl-1">
			                            <div class="radio mr-4">
			                              <label>
                                      <input
                                        type="radio"
                                        name="percent"
                                        value="Above"
                                        checked={this.state.aboveCheckbox}
                                        id="BuyorSell_find"
                                        onChange={this.RadiobuttonChange_above}
                                      />
			                                  {/* <input type="radio" name="marginPercent" value="" /> */}
			                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
			                                    Above
			                              </label>
			                            </div>
			                            <div class="radio">
			                              <label>
                                        <input
                                          type="radio"
                                          name="percent"
                                          value="Below"
                                          checked={this.state.belowCheckbox}
                                          id="BuyorSell_find"
                                          onChange={this.RadiobuttonChange_below}
                                        />
			                                  {/* <input type="radio" name="marginPercent" value="" /> */}
			                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
			                                    Below
			                              </label>
			                          </div>
			                        </div>
			                    </div>
								{this.state.posttocurrency &&
                                  	this.state.currencyselected && (
			                    		<h5>Current Market Price is <span>{(this.state.curmarketprice)}</span> {this.state.posttocurrency} / per {this.state.currencyselected}</h5>
									)
								}
                  			</div>
                  		</div>
                  		<div className="col-md-12">
                  			<div className="form-group text-center">
                          {/* <a className="btn btnBlue py-2" href="/PostDetails">Next <i class="fas fa-chevron-right"></i></a> */}
		                      <button type="button" className="btn btnBlue py-2" onClick={this.PostTrade}>Next <i class="fas fa-chevron-right"></i></button>
		                    </div>
                  		</div>
                  	</div>
                </div>
            </div>
          </div>
       </section>
      <Footer />
</div>
);
	}
}

OfferCategory.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
  });
  
export default connect(mapStateToProps, {})(withRouter(OfferCategory));