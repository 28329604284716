import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from "react-select";
import * as moment from "moment";
import axios from "axios";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from 'jquery';
import {NavLink} from 'react-router-dom';



const url = keys.baseUrl;



class Wallet extends Component {
  constructor() {
    super();
    this.state = {
      icon: "",
      selectedPhoneCode: null,
      selectedbankName: null,
      selectedDepositFrom: null,
      selectedWithdrawTo: null,
      selectedCurrencyType: null,
      selectedcountry: { label: "Nigeria", value: "Nigeria" },
      firstname: "",
      lastname: "",
      email: "",
      _id: "",
      emailotp: "",
      mobileotp: "",
      errors: {},
      phone: "",
      birthDate: "",
      address: "",
      city: "",
      postalcode: "",
      country: "",
      usernameold: "",
      totallikes: 0,
      kycstatus: "",
      createdDate: new Date(),
      ratings: 0,
      emailverified: false,
      mobilenumber: "",
      accountno: "",
      ifsccode: "",
      bankname: "",
      bankdetails: [],
      ViewBankAccount: false,
      currencydetails: [],
      bankdboptions: "",
      adminbankdetails: [],
      tocurrency: "",
      selectedadmin: [],
      toifsccode: "",
      tobankaccountno: "",
      tobankname: "",
      bankselected: "",
      depositamount: "",
      history: [],
      withdrawcurrency: "",
      walletbalance: 0,
      amount: 0,
      tfacode: "",
      balancedetails: [],
      googlestatus: "Disabled",
      withdrawtobank: "",
      history: [],
      deposithistory: [],
      activetab: "",
      Skeletonwith: 0,
      completed_trades: 0,
      kycstatus: "Not Verified",
      _csrf:"",
    };
  }


  // Initial values getting
  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
		this.getCSRFtoken();
    this.getData();
    // this.gettrade();
    this.getAdminbank();
    this.getbankdetails();
    this.getCurrency();
    this.getdeposithistory();
    this.getAssetData();
    this.getWithdrawhistory();
    var ctab = this.props.match.params.id;
    this.setState({ activetab: ctab });
    
    this.updateasset();

  }
  getCSRFtoken =async () => {
		await axios
			.get(url + "getCSRF")
			.then((res) => {
			  this.setState({_csrf:res.data._csrf});
			})
			.catch();
	  }
    updateasset =async () => {
      let userid = this.props.auth.user.id;
      await axios
        .get(url + "users/createAdminAddedAsset/"+userid)
        .then((res) => {
        })
        .catch();
      }
  getData() {
    let userid = this.props.auth.user.id;

    var ctab = this.props.match.params.id;

    if (ctab == "deposit" || ctab == "withdraw") {
      let userid = this.props.auth.user.id;
      axios.get(url + "cryptoapi/userget/" + userid).then((res) => {
        if (res.data.kycstatus != "Approved") {
          this.setState({ kycstatus: res.data.kycstatus });
          store.addNotification({
            title: "Error!",
            message:
              "You need to update your verification status to continue using Aya",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });

       this.props.history.push("/TierVerification/");
        }
      });
    }

    axios
      .get(url + "cryptoapi/userget/" + userid)
      .then((res) => {
        if (res.status == 200) {
          var total_ratings =
            res.data.positive_ratings + res.data.negative_ratings;
          if (total_ratings > 0) {
            var avg_positive_ratings =
              (res.data.positive_ratings / total_ratings) * 100;
          } else {
            var avg_positive_ratings = 0;
          }
          var negative_ratings = 100 - avg_positive_ratings;
          this.setState({
            avg_positive_ratings: avg_positive_ratings,
            negative_ratings: negative_ratings,
          });

          this.setState({
            userid: res.data.userid,
            name: res.data.name,
            otp: res.data.otp,
            firstname: res.data.firstname,
            middlename: res.data.middlename,
            lastname: res.data.lastname,
            phone: res.data.phonenumber,
            email: res.data.email,
            emailverified: res.data.emailverified,
            username: res.data.username,
            usernameold: res.data.username,
            mobilenumber: res.data.phonenumber,
            createdDate: res.data.date,
            googlestatus: res.data.google,
            totallikes: res.data.totallikes,
            icon: res.data.icon,
            kycstatus: res.data.kycstatus,
            // IdentityVerification:res.data.kycstatus=="Approved"?"KYC Verified":res.data.kycstatus=="Rejected"?"Declined":"None"
          });

          //this.setState(res.data);

          this.props.auth.user.name = res.data.name;
        }
      })
      .catch();
  }



  getAssetData() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      this.setState({ balancedetails: res.data.data });
    });
  }

  getAdminbank() {
    axios.post(url + "api/adminbank-data").then((res) => {
      if (res.data != undefined) {
        this.state.adminbankdetails.push(res.data);
      }
    });
  }
  getCurrency() {
    const data = {
			_csrf:this.state._csrf,
		  };
    axios.get(url + "api/currency").then((res) => {
      this.setState({ currencydetails: res.data });
      var firstcur = res.data;
      var newcurar = [];
      firstcur.map((details, index) => {
        if (details.type != "Fiat") {
          newcurar.push({
            value: details.currencySymbol,
            label: details.currencySymbol,
          });
        }
      });

      this.setState({ currencyoptions: newcurar });
    });
  }
  getbankdetails() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getbankdetails/" + userid).then((res) => {
      this.setState({ bankdetails: res.data });
      var tempbank = res.data;
      var addressselect = [];

      for (var i = 0; i < tempbank.length; i++) {
        var from_val = {
          value:
            tempbank[i].Accountno +
            "-" +
            tempbank[i].country +
            "-" +
            tempbank[i]._id +
            "-" +
            tempbank[i].BankName,
          label: tempbank[i].Accountno + " - " + tempbank[i].BankName,
        };
        addressselect.push(from_val);
      }
      this.setState({ bankdboptions: addressselect });
    });
  }

  // History details
  getdeposithistory() {
    // var postData = {};
    let userid = this.props.auth.user.id;
    var postdata = {
      userid: this.props.auth.user.id,
     };
      axios.get(url + "cryptoapi/getUserDepositData/" + userid).then((res) => {
        if (typeof res.data.history != "undefined") {
          var history = res.data.history;
          this.setState({ deposithistory: history, duplicatehistory: history });
        }
      })
      .catch();
  }

  getWithdrawhistory() {
    const id = this.props.auth.user.id;

    this.setState({ Skeletonwith: 1 });
    axios.get(url + "cryptoapi/fiatdeposithistory/" + id).then((res) => {
      this.setState({ history: res.data, duplicatehistory: res.data });
      this.setState({ Skeletonwith: 0 });
    });
  }


  // Deposit selection changes
  handleDepositFrom = (selectedDepositFrom) => {

    this.setState({ selectedDepositFrom });
    this.setState({ bankselected: selectedDepositFrom.value });
    var tempcountry = selectedDepositFrom.value;
    const totvalue = tempcountry.split("-");
    const country = totvalue[1];
    // adminbankdetails

    var bankindex = this.state.adminbankdetails.findIndex(
      (x) => x.Country === country
    );
    if (bankindex != -1) {
      this.setState({
        tocurrency:
          this.state.adminbankdetails[bankindex].currencyId.currencySymbol,
        toifsccode: this.state.adminbankdetails[bankindex].IFSCcode,
        tobankaccountno: this.state.adminbankdetails[bankindex].Accountno,
        tobankname: this.state.adminbankdetails[bankindex].BankName,
      });
    }
  };

  handleWithdrawTo = (selectedWithdrawTo) => {
    this.setState({ selectedWithdrawTo });
    this.setState({ withdrawtobank: selectedWithdrawTo.value });
  };
  handleCurrencyType = (selectedCurrencyType) => {
    this.setState({ selectedCurrencyType });
    this.setState({ withdrawcurrency: selectedCurrencyType.value });
    var withdrawcurrency = selectedCurrencyType.value;
    var index = this.state.balancedetails.findIndex(
      (x) => x.currencySymbol === withdrawcurrency
    );
    if (index != -1) {
      this.setState({
        walletbalance: this.state.balancedetails[index].balance,
      });
    }
    // walletbalance
  };

  // Deposit form submit
  Depositnow = (e) => {
    const bankdata = {
      id: this.props.auth.user.id,
      bankselected: this.state.bankselected,
      depositamount: this.state.depositamount,
      tobankaccountno: this.state.tobankaccountno,
      tocurrency: this.state.tocurrency,
      _csrf:this.state._csrf,
    };
    axios
      .post(url + "cryptoapi/fiatdeposit", bankdata)
      .then((res) => {
        if (res.data.status) {
          this.getData();
          this.getdeposithistory();
          this.setState({
            accountno: " ",
            depositamount: " ",
          });
          $('#deposit-add-modal').modal('hide');
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      })
      .catch();
  };


  // Withdraw form submit
  Withdraw() {
    if (this.state.googlestatus == "Enabled") {
      if (this.validateForm()) {
        // if (this.state.twofaactivated) {
        if (
          this.state.walletbalance > this.state.amount &&
          this.state.amount > 0
        ) {
          // if (this.validateForm()) {

          let fields = {};
          fields["amount"] = "";
          fields["withdrawtobank"] = "";
          fields["tfacode"] = "";
          this.setState({ validation: fields });
          const withdrawdata = {
            id: this.props.auth.user.id,
            amount: this.state.amount,
            tfacode: this.state.tfacode,
            withdrawtobank: this.state.withdrawtobank,
            currency: this.state.withdrawcurrency,
            _csrf: this.state._csrf,
          };
          axios
            .post(url + "cryptoapi/fiatwithdraw", withdrawdata)
            .then((res) => {
              if (res.data.status) {
                store.addNotification({
                  title: "Wonderful!",
                  message: res.data.message,
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
                this.getAssetData();
                this.getWithdrawhistory();
                this.setState({
                  amount: "",
                  tfacode: "",
                });
              } else {
                store.addNotification({
                  title: "Error!",
                  message: res.data.message,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 1500,
                    onScreen: true,
                  },
                });
              }
            })
            .catch();
        } else {
          store.addNotification({
            message: "Balance is low",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      }
    } else {
      store.addNotification({
        title: "Error!",
        message: "Please Enable 2FA for FIAT Withdraw",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["amount"] = "";
    errors["tfacode"] = "";
    errors["withdrawcurrency"] = "";
    let formIsValid = true;
    if (this.state.amount == "") {
      formIsValid = false;
      errors["amount"] = "*Please enter the  Amount";
    }

    if (this.state.withdrawcurrency == "") {
      formIsValid = false;
      errors["withdrawcurrency"] = "*Please Select the  Currency";
    }

    if (this.state.withdrawtobank == "") {
      formIsValid = false;
      errors["withdrawtobank"] = "*Please select the  Bank";
    }

    if (this.state.tfacode == "") {
      formIsValid = false;
      errors["tfacode"] = "*Please enter the  2FA code";
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }


  // Onchange for all text field
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
	render() {
    const { selectedDepositFrom } = this.state;
    const { selectedWithdrawTo } = this.state;
    const { selectedCurrencyType } = this.state;
    const { errors } = this.state;
		return (
      <div>
      <UserNavbar />
      <section className="userPages">
          <div className="container">
            <div className="userCommonCard">
                 <h2 className="userPageTitle">Crypto Wallet</h2>
                 <div className="row">
                 {this.state.currencyoptions &&this.state.currencyoptions.length>0&&this.state.currencyoptions.map((coincurrency, i) => {
                   
                   return(
                   <div className="col-md-6">
                     <div className="WalletCard bgOne">
                       <div className="walletCardTitle">
                         <h4>{coincurrency.value}</h4>
                         {
                           this.state.balancedetails.filter(wallet => wallet.currencySymbol == coincurrency.value).map(filteredWallet=> (
                            filteredWallet.currency&&filteredWallet.currency.currencyimage?   <img src={url+"currency/"+filteredWallet.currency.currencyimage} alt="" className="img-fluid" />:""
                           ))
                         }
                       </div>
                       {
                          this.state.balancedetails.filter(wallet => wallet.currencySymbol == coincurrency.value).map(filteredWallet=> (
                            <h2>{(filteredWallet.balance.toFixed(8))} <span>{filteredWallet.currencySymbol}</span></h2>
                          ))
                        }
                        {
                          this.state.balancedetails.filter(wallet => wallet.currencySymbol == coincurrency.value).map(filteredWallet=> (
                           
                        <ul>
                          <li><a href={"/WalletDepositWithdrawCrypto?coinsend="+filteredWallet.currencySymbol}>Deposit</a></li>
                          <li>|</li>
                          <li><a href={"/WalletDepositWithdrawCrypto?coinsend="+filteredWallet.currencySymbol}>Withdraw</a></li>
                          <li>|</li>
                          <li><a href="/WalletDepositWithdrawCrypto">History</a></li>
                        </ul>
                          ))}
                     </div>
                   </div>
                   )})}
                   {/* <div className="col-md-6">
                     <div className="WalletCard bgTwo">
                        <div className="walletCardTitle">
                         <h4>Ethereum</h4>
                         <img src={require("../images/wallet-eth.png")} alt="" className="img-fluid" />
                       </div>
                       {
                          this.state.balancedetails.filter(wallet => wallet.currencySymbol == "ETH").map(filteredWallet=> (
                            <h2>{(filteredWallet.balance.toFixed(8))} <span>{filteredWallet.currencySymbol}</span></h2>
                          ))
                        }
                        <ul>
                          <li><a href="/WalletDepositWithdrawCrypto?coinreceive=ETH">Deposit</a></li>
                          <li>|</li>
                          <li><a href="/WalletDepositWithdrawCrypto?coinsend=ETH">Withdraw</a></li>
                          <li>|</li>
                          <li><a href="/WalletDepositWithdrawCrypto">History</a></li>
                        </ul>
                     </div>
                   </div>
                   <div className="col-md-6">
                     <div className="WalletCard bgTwo">
                        <div className="walletCardTitle">
                         <h4>Litecoin</h4>
                         <img src={require("../images/ltcicon.png")} alt="" className="img-fluid" />
                       </div>
                       {
                          this.state.balancedetails.filter(wallet => wallet.currencySymbol == "LTC").map(filteredWallet=> (
                            <h2>{(filteredWallet.balance.toFixed(8))} <span>{filteredWallet.currencySymbol}</span></h2>
                          ))
                        }
                        <ul>
                          <li><a href="/WalletDepositWithdrawCrypto?coinreceive=ETH">Deposit</a></li>
                          <li>|</li>
                          <li><a href="/WalletDepositWithdrawCrypto?coinsend=ETH">Withdraw</a></li>
                          <li>|</li>
                          <li><a href="/WalletDepositWithdrawCrypto">History</a></li>
                        </ul>
                     </div>
                   </div>
                   <div className="col-md-6">
                      <div className="WalletCard bgFour">
                        <div className="walletCardTitle">
                         <h4>Tether</h4>
                         <img src={require("../images/wallet-usdt.png")} alt="" className="img-fluid" />
                       </div>
                        {
                          this.state.balancedetails.filter(wallet => wallet.currencySymbol == "USDT").map(filteredWallet=> (
                            <h2>{(filteredWallet.balance.toFixed(8))} <span>{filteredWallet.currencySymbol}</span></h2>
                          ))
                        }
                       <ul>
                          <li><a href="/WalletDepositWithdrawCrypto?coinreceive=USDT">Deposit</a></li>
                          <li>|</li>
                          <li><a href="/WalletDepositWithdrawCrypto?coinsend=USDT">Withdraw</a></li>
                          <li>|</li>
                          <li><a href="/WalletDepositWithdrawCrypto">History</a></li>
                        </ul>
                      </div>
                 
                   </div> */}
                 </div>
            </div>
          </div>
        </section>
      <Footer />
</div>
);
	}
}

Wallet.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
  });
  
export default connect(mapStateToProps, {})(withRouter(Wallet));