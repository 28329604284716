import React, { Component } from "react";
import ReactDOM from "react-dom";
import googleCaptcha from "../images/googleCaptcha.jpg";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import Select from "react-select";
import PropTypes from "prop-types";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import classnames from "classnames";
import keys from "../actions/config";
import axios from "axios";
import * as moment from "moment";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import { support, supportreply } from "../actions/authActions";
import { NavLink, withRouter, useParams } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import { Button } from "@material-ui/core";
const url = keys.baseUrl;

const selectIssue = [
  {
    value: "Withdraw  - Didn’t receive my fund",
    label: "Withdraw  - Didn’t receive my fund",
  },
  {
    value: "Deposit  - Didn’t receive my fund",
    label: "Deposit  - Didn’t receive my fund",
  },
  {
    value: "Transfer  - Didn’t receive my fund",
    label: "Transfer  - Didn’t receive my fund",
  },
  {
    value: "Login  - Didn’t receive my fund",
    label: "Login  - Didn’t receive my fund",
  },
  {
    value: "Signup  - Didn’t receive my fund",
    label: "Signup  - Didn’t receive my fund",
  },
];

class SupportTicket extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      email_add: "",
      altemail: "",
      subject: "",
      description: "",
      replystatus: "",
      closedstatus: "",
      ticketno: "",
      created_date: "",
      supportarray: [],
      finalsort: [],
      validation: {},
      _id: "",
      reply: [],
      message_query: "",
      selectedIssue: null,
      errors: {},
      _csrf: "",
      supportProofType: "",
      supportProoferror: "",
      supportProoffilename: "",
      supportProofSize: "",
      supportProof: "",
      viewIdProof: "",
      checked: 0,
      messagemodal:false,
      premiumdata:{}
    };
    this.initialState = { ...this.state };
    // this.clearErrorfromredux = this.clearErrorfromredux.bind(this);
  }

  //checkbox
  selectShortlistedApplicant = (e) => {
    // this.setState({ messagemodal: true });
    // const checked = e.target.checked;
    // if (checked) {
    //   this.setState({ checked: 1 });
    //   // if (window.confirm("Are you sure to paid this support ticket ?")) {
    //   //   this.setState({ checked: 1 });
    //   // } else {
    //   //   this.setState({ checked: 0 });
    //   // }
    // } else {
    //   this.setState({ checked: 0 });
    // }
  };
  handletrueset = () => {
    // this.setState({setpremium:true});
    this.setState({ checked: 1 });
    this.setState({ messagemodal: false });
  };
  handlesetfalse = () => {
    this.setState({ checked: 0 });
    this.setState({ messagemodal: false });
  };

  handleclosemessage = async () => {
    this.setState({ messagemodal: false });
    this.setState({ checked: 0 });
  };

  handleChangesupportproof = (e) => {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e.target.files[0]);
    if (e.target.files[0]) {
      if (e.target.files[0].type == "application/pdf") {
        this.setState({
          supporttype: "pdf",
        });
      } else {
        this.setState({
          supporttype: "img",
        });
      }

      var filesize = e.target.files[0].size;
      this.setState({ supportProofSize: filesize });
      if (filesize > 3145728) {
        this.setState({ idProoferror: "*Image is Above 3MB" });
      } else {
        this.setState({
          supportProoffilename: e.target.files[0].name,
          supportProof: e.target.files[0],
          viewIdProof: URL.createObjectURL(e.target.files[0]),
        });
      }
    }
  };
  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
      })
      .catch();
  };

  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    this.getData();
    this.getCSRFtoken();
    this.getPremium();
  }
  getPremium(){
    axios
    .get(url + "users/getpremiumdata")
    .then((res) => {
      // console.log(res,"---------------->resresresres")
      this.setState({ premiumdata: res.data.data });
    })
    .catch();
  }
  getData() {
    if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
      const id = this.props.auth.user.id;
      axios
        .get(url + "users/supportget/" + id)
        .then((res) => {
          this.setState({ supportarray: res.data });
        })
        .catch();
    }
    // this.setState({
    //           supportProofType:'',
    //     supportProoferror:'',
    //     supportProoffilename:'',
    //     supportProofSize:'',
    //     supportProof:"",
    //     viewIdProof:"",
    //   })
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "componentWillReceivePropscomponentWillReceivePropscomponentWillReceivePropscomponentWillReceiveProps",
      nextProps
    );
    console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa", nextProps.errors);
    if (
      nextProps.errors &&
      nextProps.errors !== undefined &&
      nextProps.errors.message !== undefined
    ) {
      store.addNotification({
        title: "Warning!",
        message: nextProps.errors.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      // this.props.clearErrorfromredux();
      this.setState({
        errors: {}, //nextProps.errors
      });
    } else {
      this.setState({ errors: "" });
    }
    this.setState({ errors: "" });

    if (
      nextProps.auth !== undefined &&
      nextProps.auth.support !== undefined &&
      nextProps.auth.support.data !== undefined &&
      nextProps.auth.support.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.support.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      this.setState({
        subject: "",
        altemail: "",
        description: "",
        message_query: "",
        viewIdProof: "",
        supportProoffilename: "",
        supportProofSize: "",
        supportProof: "",
      });
      nextProps.auth.support = undefined;
      this.props.history.push("/SupportTicket");
      this.getData();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ errors: "" });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["subject"] = "";
      fields["description"] = "";
      this.setState({ validation: fields });
      const Newsupport = {
        id: this.props.auth.user.id,
        email_add: this.props.auth.user.email,
        subject: this.state.subject,
        altemail: this.state.altemail,
        description: this.state.description,
        _csrf: this.state._csrf,
        checked: this.state.checked,
      };
      this.props.support(Newsupport);
      this.setState({ checked: 0 });
    }
  };

  Submitreply(answer_id) {
    // console.log("eeeeerrrrrrrrrr", this.state.message_query,answer_id,this.state.idProoffilename,this.state.idProof)

    if (
      (this.state.message_query == "" ||
        this.state.message_query == undefined) &&
      (this.state.supportProoffilename == "" ||
        this.state.supportProoffilename == undefined)
    ) {
      let err = {
        message_query: "Please Enter Message",
      };
      console.log(
        "errerrerr",
        err,
        this.state.supportProoffilename,
        this.state.message_query
      );
      this.setState({ errors: err });
      console.log("statettte", this.state.errors);
      return;
    }
    var formData = new FormData();
    formData.append("message_query", this.state.message_query);
    formData.append("_id", answer_id);

    if (this.state.supportProof) {
      formData.append("supportProoffilename", this.state.supportProoffilename);
      formData.append("supportProofSize", this.state.supportProofSize);
      formData.append("supportProof", this.state.supportProof);
    }

    formData.append("_csrf", this.state._csrf);

    const Newsupportreply = {
      _id: answer_id,
      message_query: this.state.message_query,
      _csrf: this.state._csrf,
    };
    this.props.supportreply(formData);
    // this.setState(this.initialState);
    // this.setState({
    //         supportProofType:'',
    //   supportProoferror:'',
    //   supportProoffilename:'',
    //   supportProofSize:'',
    //   supportProof:"",
    // })
  }

  closeticket(ticketno) {
    const closeticket = {
      ticketnumber: ticketno,
      _csrf: this.state._csrf,
    };
    axios
      .post(url + "users/ticketclose", closeticket)
      .then((res) => {
        if (res.status === 200) {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          this.getData();
        }
      })
      .catch();
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["subject"] = "";
    errors["description"] = "";
    let formIsValid = true;

    if (this.state.subject == "") {
      formIsValid = false;
      errors["subject"] = "*Please enter the issue";
    }
    if (this.state.description == "") {
      formIsValid = false;
      errors["description"] = "*Please enter your Description";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleIssue = (selectedIssue) => {
    this.setState({ selectedIssue });
    this.setState({ subject: selectedIssue.value });
  };

  render() {
    const { selectedIssue, checked } = this.state;
    const { user } = this.props.auth;
    const { errors } = this.state;
    console.log(checked, "----------->checkedcheckedchecked");
    return (
      <div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <h2 className="userPageTitle">Support Ticket</h2>
            <div className="row">
              <div className="col-lg-4">
                <form name="supportTicket" onSubmit={this.onSubmit}>
                  <div className="userBoxWhiteBox createTicket">
                    <h3 class="userBoxUnderlineTitle">Create Support Ticket</h3>
                    {/* <div className="form-group">
                      <label>Enter your alternative email</label>
                      <input type="text" name="" className="form-control" />
                    </div> */}
                    <div className="form-group">
                      <label>Enter Subject</label>
                      <input
                        name="subject"
                        onChange={this.onChange}
                        value={this.state.subject}
                        error={errors.subject}
                        id="subject"
                        type="text"
                        className="form-control"
                      />
                      <span className="text-danger">{errors.subject}</span>{" "}
                      {/* <Select
                              value={selectedIssue}
                              onChange={this.handleIssue}
                              options={selectIssue}
                          /> */}
                    </div>
                    <div className="form-group">
                      <label>Send a Message to Support Team</label>
                      <textarea
                        name="description"
                        onChange={this.onChange}
                        value={this.state.description}
                        error={errors.description}
                        id="description"
                        type="text"
                        className="form-control"
                        rows="5"
                      />
                      <span className="text-danger">{errors.description}</span>{" "}
                    </div>

                    <div class="form-check">
                      {/* <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      /> */}
                      {/* <input
                        type="checkbox"
                        className="checkbox disable-team team_values"
                        value="0"
                        onClick={(e) => {
                          this.selectShortlistedApplicant(e);
                        }}
                      />{" "} */}
                      <div className="d-flex p-2">
                      <Form.Check
                        label="Premium"
                        checked={this.state.checked == 1 ? true : false}
                        onClick={(e) => {
                          this.setState({ messagemodal: true });
                        }}
                      />
                      &nbsp;<a href="/Faq">more about</a>
                      </div>

                      {/* <label class="form-check-label" for="defaultCheck1"> */}
                        {/* Premium */}
                      {/* </label> */}
                      
                    </div>

                    <div class="form-group text-center">
                      <input
                        type="submit"
                        name=""
                        class="roundedCorner d-block w-100 py-2"
                        value="Create Ticket"
                      />
                    </div>
                    <div class="alert alert-primary mb-0" role="alert">
                      <div className="aletBoxCard">
                        <div className="alertboxIcon">
                          <img
                            src={require("../images/alertIcon.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="alertboxDescription">
                          <p>
                            We’ll review your ticket details and resolve it
                            ASAP.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-8">
                <div className="userBoxWhiteBox ticketDetails">
                  {/* <h3 class="userBoxUnderlineTitle">Ticket History</h3>
                  <div className="table-responsive">
                    <table class="table referralIncomeTable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Ticket ID</th>
                          <th>Reason</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>02 May 2021 15;15</td>
                          <td><a href="#">#003</a></td>
                          <td>Withdraw - Didn’t receive...</td>
                          <td className="orangeText">Open</td>
                        </tr>
                        <tr>
                          <td>02 May 2021 15;15</td>
                          <td><a href="#">#002</a></td>
                          <td>Deposit - address not show... </td>
                          <td className="greenText">Close</td>
                        </tr>
                        <tr>
                          <td>02 May 2021 15;15</td>
                          <td><a href="#">#001</a></td>
                          <td>  Login issue  </td>
                          <td className="greenText">Close</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                  <div className="tdMain">
                    <h3>Ticket Details</h3>
                    <div className="supporTicketAccordian wow fadeIn">
                      <div id="accordion">
                        {this.state.supportarray.map((answer, i) => {
                          // console.log("answeransweranswer"  ,this.state.supportarray)
                          var statusclass =
                            answer.closedstatus == "Open"
                              ? "greenText"
                              : "orangeText";
                          return (
                            <div className="card">
                              <div
                                className="card-header wow flipInX"
                                id="headingOne"
                              >
                                <h5 className="mb-0">
                                  <button
                                    className="btn btn-link"
                                    data-toggle="collapse"
                                    data-target={"#collapsetest" + i}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    <span className="stHeadText dateWidth">
                                      <small>Created Date</small>{" "}
                                      {moment(answer.created_date).format(
                                        "DD/MM/YYYY  h:mm a "
                                      )}
                                    </span>
                                    <span className="stHeadText ticketIdWidth">
                                      <small>Ticket ID</small>
                                      {answer.ticketno}
                                    </span>
                                    <span className="stHeadText ticketIdWidth">
                                      <small>Ticket Type</small>
                                      {answer.ticket_type}
                                    </span>
                                    <span className="stHeadText statusWidth">
                                      <small>Status</small>
                                      <span className={statusclass}>
                                        {answer.closedstatus}
                                      </span>
                                    </span>
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </h5>
                              </div>
                              <div
                                id={"collapsetest" + i}
                                className="collapse"
                                aria-labelledby="headingOne"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <ul className="ticketComments">
                                        <li>
                                          <div className="supportSubject">
                                            <h4>
                                              <span>Sub:</span> {answer.subject}
                                            </h4>
                                          </div>
                                          <div className="ticketDetails">
                                            {/* <p>Hello Admin,</p>
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p> */}
                                            <p>{answer.description}</p>
                                          </div>
                                        </li>
                                        {answer.reply.map((array, i) => {
                                          return (
                                            <li>
                                              <div className="ticketDetails">
                                                <p className="metaChatDetails">
                                                  {" "}
                                                  {array.replytype == "user"
                                                    ? user.name
                                                    : array.replytype}{" "}
                                                  <TimeAgo
                                                    date={
                                                      new Date(array.replydate)
                                                    }
                                                  >
                                                    {({ value }) => value}
                                                  </TimeAgo>
                                                </p>
                                                <p>{array.message_query}</p>
                                                {array.query_image ? (
                                                  <a
                                                    href={
                                                      keys.baseUrl +
                                                      "supportticket/" +
                                                      array.query_image
                                                    }
                                                    target="_blank"
                                                  >
                                                    {" "}
                                                    <img
                                                      style={{
                                                        width: "25%",
                                                        margin: "25px 0",
                                                      }}
                                                      src={
                                                        keys.baseUrl +
                                                        "supportticket/" +
                                                        array.query_image
                                                      }
                                                    ></img>
                                                  </a>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                      {answer.closedstatus == "Closed" ? (
                                        ""
                                      ) : (
                                        <div className="messageTypeBox">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="form-group">
                                                <label className="d-block">
                                                  Reply to Support Team
                                                </label>
                                                <textarea
                                                  name="message_query"
                                                  onChange={this.onChange}
                                                  value={
                                                    this.state.message_query
                                                  }
                                                  error={errors.message_query}
                                                  id="message_query"
                                                  type="text"
                                                  className={classnames(
                                                    "form-control",
                                                    {
                                                      invalid:
                                                        errors.message_query,
                                                    }
                                                  )}
                                                />
                                                <span className="text-danger">
                                                  {errors.message_query}
                                                </span>{" "}
                                              </div>
                                            </div>

                                            <div className="form-group">
                                              <label>
                                                Upload Proof (name with photo
                                                and address page)
                                              </label>
                                              <div className="custom-file">
                                                <input
                                                  type="file"
                                                  name="supportProof"
                                                  onChange={
                                                    this
                                                      .handleChangesupportproof
                                                  }
                                                  accept="image/x-png,image/gif,image/jpeg,application/pdf"
                                                  className="custom-file-input"
                                                  aria-describedby="inputGroupFileAddon01"
                                                />
                                                <label className="custom-file-label">
                                                  {this.state.supporttype !=
                                                    "pdf" ||
                                                  this.state.supporttype ==
                                                    "pdf"
                                                    ? "Choose file"
                                                    : this.state
                                                        .supportProoffilename}
                                                </label>
                                                {this.state.supporttype ==
                                                  "pdf" ||
                                                this.state.supporttype !=
                                                  "pdf" ? (
                                                  <a
                                                    className="d-block"
                                                    style={{
                                                      marginTop: "15px",
                                                    }}
                                                    href={
                                                      this.state.viewIdProof
                                                    }
                                                    download
                                                  >
                                                    {
                                                      this.state
                                                        .supportProoffilename
                                                    }
                                                  </a>
                                                ) : null}
                                              </div>
                                              <span className="text-danger">
                                                {errors.supportProoferror}
                                              </span>
                                            </div>
                                            <div className="col-md-12">
                                              <p className="submitChat">
                                                <input
                                                  onClick={() =>
                                                    this.Submitreply(answer._id)
                                                  }
                                                  type="submit"
                                                  name=""
                                                  value="Reply"
                                                  className="btn btnTypeTrade2"
                                                />
                                                <a
                                                  href="#"
                                                  onClick={() =>
                                                    this.closeticket(
                                                      answer.ticketno
                                                    )
                                                  }
                                                  className="ml-0 ml-sm-3"
                                                >
                                                  Satisfied, Close this ticket
                                                </a>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <h3 class="userBoxUnderlineTitle mt-4">Ticket Details</h3>
                     <div class="riTotal border-bottom-0 pb-3">
                        <p>Ticket ID: <span>#003</span></p>
                        <p>Created on: <span>02 May 2021 15:15</span></p>
                      </div>
                      <div class="riTotal mb-3">
                        <p>Subject: <span>Withdraw - Didn’t receive my fund</span></p>
                        <button class="btn greenBorderButton">Close This Ticket</button>
                      </div>
                     <ul className="ticketComments">
                         <li>
                          <div className="ticketImg">
                            <img src={require("../images/profileIcon.jpg")} alt="" className="img-fluid" />
                            <h6>pbteja1998</h6>
                          </div>
                           <div className="ticketDetails">
                             <p class="metaChatDetails">Hello Admin,</p>
                             <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                           </div>
                         </li>
                         <li>
                          <div className="ticketImg">
                            <img src={require("../images/adminProfileIcon.jpg")} alt="" className="img-fluid" />
                            <h6>admin</h6>
                          </div>
                           <div className="ticketDetails">
                             <p className="metaChatDetails">Hi pbteja1998, <small className="float-right">02 May 2021 15:15</small></p>
                             <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                             <p>Thanks,<br />Admin team</p>
                           </div>
                         </li>
                       </ul>
                       <div className="messageTypeBox">
                         <div className="row">
                           <div className="col-md-12">
                              <div className="form-group">
                                <label className="d-block">Reply to admin</label>
                                <textarea rows="2" className="form-control"></textarea>
                              </div>
                           </div>
                           <div className="col-md-12">
                             <p className="submitChat">
                               <input type="submit" name="" value="Reply" className="btn btnTypeTrade2" />
                             </p>
                           </div>
                         </div>
                       </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Modal show={this.state.messagemodal} onHide={this.handleclosemessage} centered={true}>
          <Modal.Header closeButton>
            <Modal.Title>Premium Support Ticket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure to make this payment for ticket raising in {this.state.premiumdata.supportcurrency} of
              amount {this.state.premiumdata.supportvalue} ?{" "}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button class="btn btn-secondary" onClick={this.handlesetfalse}>
              Close
            </Button>
            <Button class="btn btn-success" onClick={this.handletrueset}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

SupportTicket.propTypes = {
  support: PropTypes.func.isRequired,
  supportreply: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { support, supportreply })(
  withRouter(SupportTicket)
);
