import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import Select from "react-select";
import axios from "axios";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap/";
import * as moment from "moment";
import queryString from "query-string";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import ReactDatatable from "@ashvin27/react-datatable";
import ChartistGraph from "react-chartist";

const url = keys.baseUrl;

const countrylist = [
  { value: "US", label: "US" },
  { value: "England", label: "England" },
  { value: "Europe", label: "Europe" },
];

const NigerianbankName = [
  { value: "Access Bank Plc", label: "Access Bank Plc" },
  { value: "Fidelity Bank Plc", label: "Fidelity Bank Plc" },
  {
    value: "First City Monument Bank Limited",
    label: "First City Monument Bank Limited",
  },
  {
    value: "First Bank of Nigeria Limited",
    label: "First Bank of Nigeria Limited",
  },
  { value: "Guaranty Trust Bank Plc", label: "Guaranty Trust Bank Plc" },
  { value: "Union Bank of Nigeria Plc", label: "Union Bank of Nigeria Plc" },
  { value: "United Bank for Africa Plc", label: "United Bank for Africa Plc" },
  { value: "Zenith Bank Plc", label: "Zenith Bank Plc" },
  { value: "Citibank Nigeria Limited", label: "Citibank Nigeria Limited" },
  { value: "Ecobank Nigeria Plc", label: "Ecobank Nigeria Plc" },
  { value: "Keystone Bank Limited", label: "Keystone Bank Limited" },
  { value: "Polaris Bank Limited", label: "Polaris Bank Limited" },
  { value: "Stanbic IBTC Bank Plc", label: "Stanbic IBTC Bank Plc" },
  { value: "Standard Chartered", label: "Standard Chartered" },
  { value: "Sterling Bank Plc", label: "Sterling Bank Plc" },
  { value: "Unity Bank Plc", label: "Unity Bank Plc" },
  { value: "Wema Bank Plc", label: "Wema Bank Plc" },
  { value: "Providus Bank Limited", label: "Providus Bank Limited" },
];

const ghanaianbankName = [
  { value: "GCB", label: "GCB" },
  { value: "Fidelity Bank", label: "Fidelity Bank" },
  {
    value: "Consolidated Bank Ghana (CBG)",
    label: "Consolidated Bank Ghana (CBG)",
  },
  { value: "ABSA Ghana ", label: "ABSA Ghana" },
  { value: "Republic Bank", label: "Republic Bank" },
  { value: "GTBANK Ghana", label: "GTBANK Ghana" },
  { value: "Zenith Bank Ghana", label: "Zenith Bank Ghana" },
  { value: "Access Bank Ghana", label: "Access Bank Ghana" },
  { value: "UBA Ghana", label: "UBA Ghana" },
  { value: "Societe General Bank", label: "Societe General Bank " },
  { value: "Standard Chartered", label: "Standard Chartered" },
];
class Dashboard extends Component {
  constructor() {
    super();
    this.columns = [
      {
        key: "loginhistory",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>
              {moment(record.loginhistory.createdDate).format(
                "DD-MM-YYYY  k:mm:s "
              )}
            </p>
          );
        },
      },
      {
        key: "loginhistory",
        text: "IP",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record.loginhistory.ipaddress}</p>;
        },
      },
      {
        key: "loginhistory",
        text: "Location",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record.loginhistory.countryName}</p>;
        },
      },
      {
        key: "loginhistory",
        text: "Device",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>
              {record.loginhistory.os}, {record.loginhistory.broswername}
            </p>
          );
        },
      },
      {
        key: "loginhistory",
        text: "Status",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record.loginhistory.status}</p>;
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };

    this.openColumns = [
      {
        key: "_id",
        text: "Id #",
        className: "Id",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record._id}</p>;
        },
      },
      {
        key: "BuyorSell",
        text: "Trading partner",
        className: "BuyorSell",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <div>
              <p>{record.senderdetails.username}</p>
              <p>{record.BuyorSell}</p>
            </div>
          );
        },
      },
      {
        key: "status",
        text: "Status",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          const tradeStatus = record.status;
          if (tradeStatus == 0 || tradeStatus == 1) {
            var statusVal = "Open";
          } else if (tradeStatus == 3) {
            var statusVal = "Disputed";
          }
          return <p>{statusVal}</p>;
        },
      },
      {
        key: "BuyorSell",
        text: "Info",
        className: "BuyorSell",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>
              {record.to_userId == this.props.auth.user.id ? (
                <p> Buy {record.firstCurrency}</p>
              ) : (
                <p> Sell {record.firstCurrency}</p>
              )}
            </p>
          );
        },
      },
      {
        key: "crypto_amount",
        text: "Price",
        className: "crypto_amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>
              {record.crypto_amount.toFixed(8)} {record.firstCurrency}
            </p>
          );
        },
      },
      {
        key: "trade_fee",
        text: "Trading fee",
        className: "trade_fee",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record.trade_fee}</p>;
        },
      },
      {
        key: "created_date",
        text: "Created at",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{moment(record.created_date).format("DD MMMM YYYY")}</p>;
        },
      },
      {
        key: "record",
        text: "Action",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          var messages = 0;
          if (this.state.chatdata.length > 0) {
            for (var j = 0; j < this.state.chatdata.length; j++) {
              if (this.state.chatdata[j].orderbookId === record._id) {
                messages = messages + 1;
              }
            }
          }
          return (
            <Fragment>
              <Link to={"/TradeConversation/" + record._id}>
                <p className="buttonSup">
                  <div className="dash_msg_counter_panel">
                    <button
                      className="btn btn-primary btn-sm mr-1"
                      title="view deposits"
                    >
                      View/Messages
                    </button>
                    {messages > 0 ? (
                      <sup className="dash_msg_counter">{messages}</sup>
                    ) : (
                      ""
                    )}
                  </div>
                </p>
              </Link>
            </Fragment>
          );
        },
      },
    ];
    this.openConfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };
    this.completedColumns = [
      {
        key: "_id",
        text: "Id #",
        className: "Id",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record._id}</p>;
        },
      },
      {
        key: "BuyorSell",
        text: "Trading partner",
        className: "BuyorSell",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <div>
              <p>{record.senderdetails.username}</p>
              <p>{record.BuyorSell}</p>
            </div>
          );
        },
      },
      {
        key: "status",
        text: "Status",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          const tradeStatus = record.status;
          if (tradeStatus == 4) {
            var statusVal = "Completed";
          } else if (tradeStatus == 3) {
            var statusVal = "Disputed";
          }
          return <p>{statusVal}</p>;
        },
      },
      {
        key: "BuyorSell",
        text: "Info",
        className: "BuyorSell",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>
              {record.to_userId == this.props.auth.user.id ? (
                <p> Buy {record.firstCurrency}</p>
              ) : (
                <p> Sell {record.firstCurrency}</p>
              )}
            </p>
          );
        },
      },
      {
        key: "crypto_amount",
        text: "Price",
        className: "crypto_amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>
              {record.crypto_amount.toFixed(8)} {record.firstCurrency}
            </p>
          );
        },
      },
      {
        key: "trade_fee",
        text: "Trading fee",
        className: "trade_fee",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record.trade_fee}</p>;
        },
      },
      {
        key: "Created at",
        text: "Date",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{moment(record.created_date).format("DD MMMM YYYY")}</p>;
        },
      },
      {
        key: "record",
        text: "Action",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          console.log("fffffffffffffffeeeeeeeeeeeeeee", record);
          return (
            <Fragment>
              <Link to={"/TradeConversation/" + record._id}>
                <button
                  className="btn btn-primary btn-sm mr-1"
                  title="view deposits"
                >
                  View/Messages
                </button>
              </Link>
              {record && record.feedbacks && record.feedbacks.length <= 0 && (
                <Link to={"/P2pfeedback/" + record._id}>
                  <p className="buttonSup">
                    <button
                      className="btn btn-primary btn-sm mr-1"
                      title="view submit"
                    >
                      Submit Feedback
                    </button>
                  </p>
                </Link>
              )}
            </Fragment>
          );
        },
      },
    ];
    this.completedConfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };

    this.closedColumns = [
      {
        key: "_id",
        text: "Id # & Created",
        className: "Id",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <div>
              <p>{record._id}</p>
              <p>{moment(record.created_date).format("DD MM YYYY")}</p>
            </div>
          );
        },
      },
      {
        key: "BuyorSell",
        text: "Info",
        className: "BuyorSell",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <div>
              <p>
                {record.to_userId == this.props.auth.user.id ? (
                  <p> Buy {record.firstCurrency}</p>
                ) : (
                  <p> Sell {record.firstCurrency}</p>
                )}
              </p>
            </div>
          );
        },
      },
      {
        key: "BuyorSell",
        text: "Trading partner",
        className: "BuyorSell",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <div>
              <p>{record.senderdetails.username}</p>
              <p>{record.BuyorSell}</p>
            </div>
          );
        },
      },
      {
        key: "status",
        text: "Status",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          const tradeStatus = record.status;
          if (tradeStatus == 5) {
            var statusVal = "Closed";
          } else if (tradeStatus == 2 || tradeStatus == 2) {
            var statusVal = "Cancelled";
          }
          return <p>{statusVal}</p>;
        },
      },
      {
        key: "crypto_amount",
        text: "Price",
        className: "BuyorSell",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <div>
              <p>{record.crypto_amount.toFixed(8)} </p>
              <p>{record.firstCurrency}</p>
            </div>
            // <p>{record.BuyorSell}{" "}{record.firstCurrency}</p>
          );
        },
      },
      {
        key: "trade_fee",
        text: "Trading fee",
        className: "trade_fee",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record.trade_fee}</p>;
        },
      },
      {
        key: "fiat_amount",
        text: "Total",
        className: "fiat_amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>
              {record.secondCurrency} {record.fiat_amount}
            </p>
            // <p>{moment(record.created_date).format('DD MMMM YYYY')}</p>
          );
        },
      },
      {
        key: "record",
        text: "Action",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <Link to={"/TradeConversation/" + record._id}>
                <button
                  className="btn btn-primary btn-sm mr-1"
                  title="view deposits"
                >
                  View/Messages
                </button>
              </Link>
            </Fragment>
          );
        },
      },
    ];
    this.closedConfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };

    this.state = {
      icon: "",
      selectedPhoneCode: null,
      selectedbankName: null,
      selectedDepositFrom: null,
      selectedWithdrawTo: null,
      selectedCurrencyType: null,
      selectedcountry: { label: "US", value: "US" },
      firstname: "",
      lastname: "",
      email: "",
      _id: "",
      emailotp: "",
      mobileotp: "",
      errors: {},
      phone: "",
      birthDate: "",
      address: "",
      city: "",
      postalcode: "",
      country: "",
      usernameold: "",
      totallikes: 0,
      kycstatus: "",
      createdDate: new Date(),
      ratings: 0,
      emailverified: false,
      mobilenumber: "",
      accountno: "",
      ifsccode: "",
      bankname: "",
      bankdetails: [],
      ViewBankAccount: false,
      currencydetails: [],
      bankdboptions: "",
      adminbankdetails: [],
      tocurrency: "",
      selectedadmin: [],
      toifsccode: "",
      tobankaccountno: "",
      tobankname: "",
      bankselected: "",
      depositamount: "",
      history: [],
      withdrawcurrency: "",
      walletbalance: 0,
      amount: 0,
      tfacode: "",
      balancedetails: [],
      googlestatus: "Disabled",
      withdrawtobank: "",
      history: [],
      deposithistory: [],
      activetab: "",
      Skeletonwith: 0,
      completed_trades: 0,
      kycstatus: "Not Verified",
      last_seen: "",
      createdDate: "",
      historylist: [],
      responsive: true,
      selectedcountry: { label: "US", value: "US" },
      ViewBankAccount: false,
      accountno: "",
      ifsccode: "",
      bankname: "",
      bankdetails: [],
      buyCompletedTrade: 0,
      sellCompletedTrade: 0,
      myadshistory: [],
      openadsHistory: [],
      completedadsHistory: [],
      closedadsHistory: [],
      balancedetails: [],
      chatdata: [],
      currencyList: [],
      currencyBalance: [],
      btcbalance: 0,
      usdtbalance: 0,
      ethbalance: 0,
      usdEstimatedValue: 0,
    };
  }

  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
        var csrftoken = res.data._csrf;
        var tab = this.props.match.params.id;
        this.setState({ activetab: tab });
        var ids = "0,1";
        // var ids='2,3,5';
        // var ids='4';
        this.gettrade(ids, tab);
        this.getmessages();
      })
      .catch();
  };
  componentDidMount() {
    this.getCSRFtoken();
    let id = this.props.auth.user.id;
    this.getuserdetails(id);
    this.getloginhistory();
    this.getbankdetails();
    this.getAssetData();
    this.generateAddress();
    this.getspotdata();
    this.gettrade("0,1,3");
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
  }

  getmessages() {
    let userid = this.props.auth.user.id;
    let data = { userid: userid, _csrf: this.state._csrf };
    axios.post(url + "tradeapi/chatlisthistory/", data).then((res) => {
      if (res.data.status) {
        this.setState({ chatdata: res.data.chatdata });
      }
    });
  }

  getAssetData() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      var currencyList = [];
      var currencyBalance = [];

      res.data.data.map((Currencydetails, i) => {
        if (Currencydetails.currencySymbol == "BTC") {
          currencyBalance.push({
            meta: Currencydetails.currencySymbol,
            value: Currencydetails.balance,
          });
          currencyList.push(Currencydetails.currencySymbol);
        }
        if (Currencydetails.currencySymbol == "ETH") {
          currencyBalance.push({
            meta: Currencydetails.currencySymbol,
            value: Currencydetails.balance,
          });
          currencyList.push(Currencydetails.currencySymbol);
        }
        if (Currencydetails.currencySymbol == "USDT") {
          currencyBalance.push({
            meta: Currencydetails.currencySymbol,
            value: Currencydetails.balance,
          });
          currencyList.push(Currencydetails.currencySymbol);
        }
      });
      this.setState({
        currencyList: currencyList,
        currencyBalance: currencyBalance,
      });

      res.data.data.map((Currencydetails, i) => {
        if (
          Currencydetails.currency &&
          Currencydetails.currency.type == "Crypto"
        ) {
          currencyList.push(Currencydetails.currency.currencySymbol);
        }
      });
      this.setState({ balancedetails: res.data.data });
    });
  }

  gettrade(type) {
    let userid = this.props.auth.user.id;
    let data = { type: type, userid: userid, _csrf: this.state._csrf };
    axios.post(url + "tradeapi/tradelist/", data).then((res) => {
      if (res.data.status) {
        if (type == "0,1,3") {
          this.setState({ openadsHistory: res.data.tradedata });
        } else if (type == "2,5") {
          this.setState({ closedadsHistory: res.data.tradedata });
        } else {
          this.setState({ completedadsHistory: res.data.tradedata });
        }
      }
    });
  }

  getspotdata() {
    axios.get(url + "api/spotpair-data").then((res) => {
      if (res.data.status) {
        res.data.data.map((details, index) => {
          if (
            details.first_currency == "BTC" &&
            details.second_currency == "USD"
          ) {
            this.setState({ usdEstimatedValue: details.index_price });
          }
        });
      }
    });
  }

  getuserdetails(id) {
    let userid = this.props.auth.user.id;

    var ctab = this.props.match.params.id;

    if (ctab == "deposit" || ctab == "withdraw") {
      let userid = this.props.auth.user.id;
      axios.get(url + "users/userget/" + userid).then((res) => {
        if (res.data.kycstatus != "Approved") {
          this.setState({ kycstatus: res.data.kycstatus });
          store.addNotification({
            title: "Error!",
            message:
              "You need to update your verification status to continue using Aya",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          this.props.history.push("/TierVerification/");
        }
      });
    }

    axios
      .get(url + "users/userget/" + userid)
      .then((res) => {
        if (res.status == 200) {
          // .splice(-1)[0]
          var last_login = res.data.loginhistory.splice(-1)[0];
          if (last_login) {
            this.setState({ last_seen: last_login.createdDate });
          }
          var total_ratings =
            res.data.positive_ratings + res.data.negative_ratings;
          if (total_ratings > 0) {
            var avg_positive_ratings =
              (res.data.positive_ratings / total_ratings) * 100;
          } else {
            var avg_positive_ratings = 0;
          }
          var negative_ratings = 100 - avg_positive_ratings;
          this.setState({
            avg_positive_ratings: avg_positive_ratings,
            negative_ratings: negative_ratings,
          });

          this.setState({
            userid: res.data.userid,
            name: res.data.name,
            otp: res.data.otp,
            firstname: res.data.firstname,
            middlename: res.data.middlename,
            lastname: res.data.lastname,
            phone: res.data.phonenumber,
            email: res.data.email,
            emailverified: res.data.emailverified,
            username: res.data.username,
            usernameold: res.data.username,
            mobilenumber: res.data.phonenumber,
            createdDate: res.data.date,
            googlestatus: res.data.google,
            totallikes: res.data.totallikes,
            icon: res.data.icon,
            kycstatus: res.data.kycstatus,
            buyCompletedTrade: res.data.buyCompletedTrade,
            sellCompletedTrade: res.data.sellCompletedTrade,
          });
          this.props.auth.user.name = res.data.name;
        }
      })
      .catch();
  }

  //   Login History
  getloginhistory() {
    let id = this.props.auth.user.id;
    axios.get(url + "users/loginhistory/" + id).then((res) => {
      this.setState({ historylist: res.data.history });
    });
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  // Bank details data add
  AddBankAccount() {
    if (this.validateBankForm()) {
      let fields = {};
      fields["accountno"] = "";
      fields["bankname"] = "";
      this.setState({ validation: fields });

      const bankdata = {
        id: this.props.auth.user.id,
        accountno: this.state.accountno,
        ifsccode: this.state.ifsccode,
        bankname: this.state.bankname,
        country: this.state.selectedcountry.value,
        _csrf: this.state._csrf,
      };
      axios
        .post(url + "cryptoapi/addbankdetails", bankdata)
        .then((res) => {
          if (res.data.status) {
            this.getbankdetails();
            this.getuserdetails(this.props.auth.user.id);
            this.setState({
              accountno: " ",
              ifsccode: " ",
              selectedbankName: null,
            });
            this.setState({ ViewBankAccount: !this.state.ViewBankAccount });
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error!",
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
        })
        .catch();
    }
  }
  getbankdetails() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getbankdetails/" + userid).then((res) => {
      this.setState({ bankdetails: res.data });
      var tempbank = res.data;
      var addressselect = [];

      for (var i = 0; i < tempbank.length; i++) {
        var from_val = {
          value:
            tempbank[i].Accountno +
            "-" +
            tempbank[i].country +
            "-" +
            tempbank[i]._id +
            "-" +
            tempbank[i].BankName,
          label: tempbank[i].Accountno + " - " + tempbank[i].BankName,
        };
        addressselect.push(from_val);
      }
      this.setState({ bankdboptions: addressselect });
    });
  }
  Deletebankaccount = (answer) => {
    let data = { id: answer._id, _csrf: this.state._csrf };
    const id = answer._id;
    axios.post(url + "cryptoapi/bankaccountdelete", data).then((res) => {
      if (res.data.status) {
        store.addNotification({
          title: "Wonderful!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          tradeliquidationIn: ["animated", "fadeIn"],
          tradeliquidationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        this.getbankdetails();
      } else {
        store.addNotification({
          title: "Error!",
          message: res.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      }
    });
  };
  validateBankForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["accountno"] = "";
    errors["bankname"] = "";
    // errors["ifsccode"] = "";
    let formIsValid = true;
    var ifscvalid = "^[A-Z]{4}[0][A-Z0-9]{6}$";
    // var benvalid = "^[A-Za-z]+$";

    // var benvalid = "/[^a-zA-Z, ]/";
    var benvalid = /^[a-zA-Z ]*$/;

    // var benvalid = "[a-zA-Z][a-zA-Z ]+";

    if (this.state.accountno == "") {
      formIsValid = false;
      errors["accountno"] = "*Check the  Account No";
    }
    if (this.state.accountno != "") {
      if (this.state.accountno.length < 9 || this.state.accountno.length > 18) {
        formIsValid = false;
        errors["accountno"] = "*Enter the correct Account No";
      }
    }
    // if (this.state.ifsccode == "") {
    //   formIsValid = false;
    //   errors["ifsccode"] = "*Please  enter the IFSC Code";
    // }
    if (this.state.ifsccode != "") {
      var resultifsc = this.state.ifsccode.match(ifscvalid);
      if (this.state.ifsccode.length > 11) {
        formIsValid = false;
        errors["ifsccode"] = "*Please  enter the correct IFSC Code";
      }
      // if (resultifsc == null) {
      //   formIsValid = false;
      //   errors["ifsccode"] = "*IFSC code is Invalid";
      // }
    }

    if (this.state.bankname == "") {
      formIsValid = false;
      errors["bankname"] = "*Please Select the Bank";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handlebankName = (selectedbankName) => {
    this.setState({ selectedbankName });
    this.setState({ bankname: selectedbankName.value });
  };

  handlecountry = (selectedcountry) => {
    this.setState({ selectedcountry });
    this.setState({ country: selectedcountry.value });
  };
  handlebankModal = (e) => {
    this.setState({ ViewBankAccount: !this.state.ViewBankAccount });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  generateAddress() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/addresscreate/" + userid).then((res) => {});
  }

  render() {
    var data1 = {
      labels: this.state.currencyList,
      series: this.state.currencyBalance,
    };

    let pieChart = {
      labels: ["A", "B", "C"],
      series: this.state.currencyBalance,
    };

    var options1 = {
      labelInterpolationFnc: function (value) {
        return value[0];
      },
    };

    let pieOptions = {
      showLabel: false,
      ignoreEmptyValues: false,
    };

    var responsiveOptions = [
      [
        "screen and (min-width: 1200px)",
        {
          chartPadding: 40,
          labelOffset: 100,
          labelDirection: "explode",
          labelInterpolationFnc: function (value) {
            return value;
          },
        },
      ],
      [
        "screen and (min-width: 1024px)",
        {
          labelOffset: 30,
          chartPadding: 20,
        },
      ],
    ];
    var type1 = "Pie";
    const { selectedbankName } = this.state;
    const { selectedcountry } = this.state;
    const { errors } = this.state;
    return (
      <div>
        <UserNavbar />
        <section className="userPages">
          <Modal
            show={this.state.ViewBankAccount}
            onHide={() => {
              this.handlebankModal();
            }}
            centered
          >
            <Modal.Header closeButton>
              <h4>Add Bank Account</h4>
            </Modal.Header>
            <Modal.Body>
              <div class="modal-body accountForm border-top-zero">
                <div className="form-group">
                  <label>Account Number</label>
                  <input
                    onChange={this.onChange}
                    id="accountno"
                    type="number"
                    className="form-control"
                    value={this.state.accountno}
                    maxlength="18"
                  />
                  <span className="text-danger">{errors.accountno}</span>
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <Select
                    value={selectedcountry}
                    onChange={this.handlecountry}
                    options={countrylist}
                  />
                </div>
                {this.state.selectedcountry.value == "Nigeria" ? (
                  <div className="form-group">
                    <label>Bank Name</label>
                    <Select
                      value={selectedbankName}
                      onChange={this.handlebankName}
                      options={NigerianbankName}
                    />
                    <span className="text-danger">{errors.bankname}</span>
                  </div>
                ) : (
                  <div className="form-group">
                    <label>Bank Name</label>
                    <Select
                      value={selectedbankName}
                      onChange={this.handlebankName}
                      options={ghanaianbankName}
                    />
                    <span className="text-danger">{errors.bankname}</span>
                  </div>
                )}

                <div class="form-group text-center">
                  <button
                    className="btn btnDefaultRounded"
                    data-toggle="modal"
                    onClick={() => this.AddBankAccount()}
                  >
                    Save Account
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className="container">
            <div className="userCommonCard">
              <h2 className="userPageTitle">Dashboard</h2>
              <div className="userBoxWhiteBox securityBox">
                <h3 class="userBoxUnderlineTitle">Balance</h3>
                <div className="dashTotalBalance">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="dashBalanceCard">
                        {this.state.balancedetails
                          .filter((wallet) => wallet.currencySymbol == "BTC")
                          .map((filteredWallet) => (
                            <div>
                              <h2>
                                <small>Total Account Balance</small>
                                {filteredWallet.balance.toFixed(8)}
                                <span>{filteredWallet.currencySymbol}</span>
                              </h2>
                              <h3>
                                <small>Estimated Value</small>$
                                {(
                                  filteredWallet.balance *
                                  this.state.usdEstimatedValue
                                ).toFixed(8)}
                              </h3>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="dashChartCard">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="dashChartImg">
                              <ChartistGraph
                                data={pieChart}
                                options={pieOptions}
                                type={"Pie"}
                              />
                              {/* <ChartistGraph data={data1} options={options1} responsiveOptions={responsiveOptions} type={type1} /> */}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <ul>
                              {this.state.balancedetails
                                .filter(
                                  (wallet) => wallet.currencySymbol == "BTC"
                                )
                                .map((filteredWallet) => (
                                  <li>
                                    <label>
                                      <i class="fas fa-square-full redSquare"></i>
                                      {filteredWallet.currencySymbol}
                                    </label>
                                    <span>
                                      {filteredWallet.balance.toFixed(8)}
                                    </span>
                                  </li>
                                ))}
                              {this.state.balancedetails
                                .filter(
                                  (wallet) => wallet.currencySymbol == "ETH"
                                )
                                .map((filteredWallet) => (
                                  <li>
                                    <label>
                                      <i class="fas fa-square-full orangeSquare"></i>
                                      {filteredWallet.currencySymbol}
                                    </label>
                                    <span>
                                      {filteredWallet.balance.toFixed(8)}
                                    </span>
                                  </li>
                                ))}
                              {this.state.balancedetails
                                .filter(
                                  (wallet) => wallet.currencySymbol == "USDT"
                                )
                                .map((filteredWallet) => (
                                  <li>
                                    <label>
                                      <i class="fas fa-square-full yelllowSquare"></i>
                                      {filteredWallet.currencySymbol}
                                    </label>
                                    <span>
                                      {filteredWallet.balance.toFixed(8)}
                                    </span>
                                  </li>
                                ))}
                              {/* <li>
                    							<label><i class="fas fa-square-full blueSquare"></i> XYM</label>
                    							<span>108.24008070</span>
                    						</li>
                    						<li>
                    							<label><i class="fas fa-square-full yelllowSquare"></i> TRX</label>
                    							<span>283.17498070</span>
                    						</li>
                    						<li>
                    							<label><i class="fas fa-square-full greenSquare"></i> XRP</label>
                    							<span>16.31582070</span>
                    						</li>
                    						<li>
                    							<label><i class="fas fa-square-full"></i> Other</label>
                    						</li> */}
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <div className="userPageContent">
                              <div className="pageBox">
                                <h3 className="subPageTitle">My Bank</h3>
                                <div className="table-responsive">
                                  {this.state.bankdetails.length > 0 ? (
                                    <table className="table bankList">
                                      <thead>
                                        <tr>
                                          <th>Account Number</th>
                                          <th>Bank</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>

                                      {this.state.bankdetails.map(
                                        (answer, i) => {
                                          var primary =
                                            answer.primary == true
                                              ? "- Primary Account"
                                              : " ";
                                          var button =
                                            answer.primary == true ? (
                                              ""
                                            ) : (
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  this.Deletebankaccount(
                                                    answer
                                                  );
                                                }}
                                              >
                                                Delete
                                              </a>
                                            );
                                          return (
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {answer.Accountno}
                                                  <small>{primary}</small>
                                                </td>
                                                <td>{answer.BankName}</td>
                                                <td>{button}</td>
                                              </tr>
                                            </tbody>
                                          );
                                        }
                                      )}
                                    </table>
                                  ) : (
                                    <table className="table bankList">
                                      <thead>
                                        <tr>
                                          <th>Account Number</th>
                                          <th>Bank</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          <td>
                                            <small>No Banks Added</small>
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                                <div className="buttonNote">
                                  <button
                                    className="btn btnDefaultRounded mt-3 mb-4"
                                    data-toggle="modal"
                                    // data-target="#addBankDetails"
                                    onClick={() => {
                                      this.handlebankModal();
                                    }}
                                  >
                                    <i class="fas fa-plus"></i>
                                    Add Bank
                                  </button>
                                  <p>
                                    <span>Notes:</span> You can add more than 5
                                    bank accounts, primary account is your first
                                    kyc verified account which cannot be deleted
                                  </p>
                                </div>
                                <div class="modal fade" id="addBankDetails">
                                  <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                      <div class="modalHead">
                                        <h4>Add a Bank Account</h4>
                                        <p className="mb-0">
                                          Please only add a bank account that
                                          you own. Do not add accounts generated
                                          from third party wallets/platforms.
                                        </p>
                                        <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                        >
                                          &times;
                                        </button>
                                      </div>
                                      <div class="modal-body accountForm border-top-zero">
                                        <div className="form-group">
                                          <label>Account Number</label>
                                          <input
                                            onChange={this.onChange}
                                            id="accountno"
                                            type="number"
                                            className="form-control"
                                            value={this.state.accountno}
                                            maxlength="18"
                                          />
                                          <span className="text-danger">
                                            {errors.accountno}
                                          </span>
                                        </div>

                                        <div className="form-group">
                                          <label>IFSC Code</label>
                                          <input
                                            onChange={this.onChange}
                                            id="ifsccode"
                                            type="text"
                                            className="form-control"
                                            maxlength="11"
                                            value={this.state.ifsccode}
                                          />
                                          <span className="text-danger">
                                            {errors.ifsccode}
                                          </span>{" "}
                                        </div>
                                        <div className="form-group">
                                          <label>Bank Name</label>
                                          <Select
                                            value={selectedbankName}
                                            onChange={this.handlebankName}
                                            options={NigerianbankName}
                                          />
                                          {errors.bankname}
                                        </div>
                                        <div class="form-group text-center">
                                          <button
                                            className="btn btnDefaultRounded"
                                            data-toggle="modal"
                                            onClick={() =>
                                              this.AddBankAccount()
                                            }
                                          >
                                            Save Account
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashAccountTrade">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 class="userBoxUnderlineTitle">Account Details</h3>
                      <ul>
                        <li>
                          <label>Username</label>
                          <span>{this.state.username}</span>
                        </li>
                        <li>
                          <label>Account Since</label>
                          <span>
                            {moment(this.state.createdDate).format(
                              "DD MMMM YYYY"
                            )}
                          </span>
                        </li>
                        <li>
                          <label>Email</label>
                          <span>
                            <i class="fas fa-check-circle greenText mr-2"></i>{" "}
                            Verified on{" "}
                            {moment(this.state.createdDate).format(
                              "DD MMMM YYYY"
                            )}
                          </span>
                        </li>
                        {/* <li>
                    					<label>Phone number</label>
                    					<span><i class="fas fa-check-circle greenText mr-2"></i> Verified on 22 Apr 2021</span>
                    				</li> */}
                        <li>
                          <label>kyc status</label>
                          {this.state.kycstatus == "Approved" ? (
                            <span>
                              <i class="fas fa-check-circle greenText mr-2"></i>{" "}
                              {this.state.kycstatus}
                            </span>
                          ) : this.state.kycstatus == "Pending" ? (
                            <span>
                              <i class="fas fa-times-circle yellowText mr-2"></i>{" "}
                              {this.state.kycstatus}
                            </span>
                          ) : this.state.kycstatus == "Rejected" ? (
                            <span>
                              <i class="fas fa-times-circle redText mr-2"></i>{" "}
                              {this.state.kycstatus}
                            </span>
                          ) : (
                            <span>
                              <i class="fas fa-times-circle redText mr-2"></i>{" "}
                              {this.state.kycstatus}
                            </span>
                          )}{" "}
                        </li>
                        {/* <li>
                    					<label>ID, Passport or Driver’s license</label>
                    					<span><i class="fas fa-check-circle greenText mr-2"></i> Verified on 23 Apr 2021</span>
                    				</li> */}
                        <li>
                          <label>2 Factor Authentication</label>
                          <span>{this.state.googlestatus}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <h3 class="userBoxUnderlineTitle">Trade Details</h3>
                      <ul>
                        <li>
                          <label>Total Buy Completed</label>
                          <span>{this.state.buyCompletedTrade}</span>
                        </li>
                        <li>
                          <label>Total Sell Completed</label>
                          <span>{this.state.sellCompletedTrade}</span>
                        </li>
                        <li>
                          <label>Number of confirmed trades</label>
                          <span className="blueText">
                            {this.state.buyCompletedTrade +
                              this.state.sellCompletedTrade}
                            +
                          </span>
                        </li>
                        <li>
                          <label>Feedback score</label>
                          <span className="greenText">
                            {parseFloat(
                              this.state.avg_positive_ratings
                            ).toFixed(2)}
                            %
                          </span>
                        </li>
                        {/* <li>
                    					<label>Recent Trade</label>
                    					<span>Sell Post, ID <span className="blueText">#125487</span></span>
                    				</li> */}
                        {/* <li>
                    					<label>2 Factor Authentication</label>
                    					<span>{this.state.googlestatus}</span>
                    				</li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="dashTableTabbedContent">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <a
                        class="nav-item nav-link active"
                        id="nav-openTrades-tab"
                        data-toggle="tab"
                        href="#nav-openTrades"
                        role="tab"
                        aria-controls="nav-openTrades"
                        aria-selected="true"
                        onClick={() => {
                          this.gettrade("0,1,3");
                        }}
                      >
                        Open Trades
                      </a>
                      <a
                        class="nav-item nav-link"
                        id="nav-closedTrades-tab"
                        data-toggle="tab"
                        href="#nav-closedTrades"
                        role="tab"
                        aria-controls="nav-closedTrades"
                        aria-selected="false"
                        onClick={() => {
                          this.gettrade("2,5");
                        }}
                      >
                        Closed Trades
                      </a>
                      <a
                        class="nav-item nav-link"
                        id="nav-completedTrades-tab"
                        data-toggle="tab"
                        href="#nav-completedTrades"
                        role="tab"
                        aria-controls="nav-completedTrades"
                        aria-selected="false"
                        onClick={() => {
                          this.gettrade("4");
                        }}
                      >
                        Completed Trades{" "}
                      </a>
                      {/* <a class="nav-item nav-link" id="nav-cancelledTrades-tab" data-toggle="tab" href="#nav-cancelledTrades" role="tab" aria-controls="nav-cancelledTrades" aria-selected="false">Cancelled Trades</a> */}
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-openTrades"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div className="table-responsive">
                        <ReactDatatable
                          responsive={this.state.responsive}
                          config={this.openConfig}
                          records={this.state.openadsHistory}
                          columns={this.openColumns}
                          onPageChange={this.pageChange.bind(this)}
                        />
                        {/* <table className="table tableDashBoardStyle">
										<thead>
											<tr>
												<th>#</th>
												<th>Status</th>
												<th>Info</th>
												<th>Price</th>
												<th>Margin</th>
												<th>Created at</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>23578</td>
												<td>Open</td>
												<td>Sell Bitcoin</td>
												<td>$45,500.5</td>
												<td>+1.5 above</td>
												<td>02 May 2021</td>
											</tr>
											<tr>
												<td>23578</td>
												<td>Open</td>
												<td>Buy Etherum</td>
												<td>$3,250.50</td>
												<td>+2.5 below</td>
												<td>01 May 2021</td>
											</tr>
										</tbody>
									</table> */}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-closedTrades"
                      role="tabpanel"
                      aria-labelledby="nav-closedTrades-tab"
                    >
                      <div className="table-responsive">
                        <ReactDatatable
                          responsive={this.state.responsive}
                          config={this.closedConfig}
                          records={this.state.closedadsHistory}
                          columns={this.closedColumns}
                          onPageChange={this.pageChange.bind(this)}
                        />
                        {/* <table className="table tableDashBoardStyle">
										<thead>
											<tr>
												<th># & Created</th>
												<th>Trade type</th>
												<th>Trading partner</th>
												<th>Transaction status</th>
												<th>Trade amount</th>
												<th>Trading fee</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><span className="blueText">73243</span>05 04 2021</td>
												<td>Bitcoin<span>Selling</span></td>
												<td><span className="blueText">@CryptoTrader</span>is selling</td>
												<td>Completed<span>successfully</span></td>
												<td>0.025478<span>BTC</span></td>
												<td>0.0000547<span>BTC</span></td>
												<td>$35,500.50</td>
											</tr>
											<tr>
												<td><span className="blueText">5243</span>05 04 2021</td>
												<td>Ripple<span>Buying</span></td>
												<td><span className="blueText">@XRPtrade</span>is buying</td>
												<td>Completed<span>successfully</span></td>
												<td>150.025478<span>XRP</span></td>
												<td>1.0000547<span>XRP</span></td>
												<td>$200.50</td>
											</tr>
										</tbody>
									</table> */}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-completedTrades"
                      role="tabpanel"
                      aria-labelledby="nav-completedTrades-tab"
                    >
                      <div className="table-responsive">
                        <ReactDatatable
                          responsive={this.state.responsive}
                          config={this.completedConfig}
                          records={this.state.completedadsHistory}
                          columns={this.completedColumns}
                          onPageChange={this.pageChange.bind(this)}
                        />
                        {/* <table className="table tableDashBoardStyle">
										<thead>
											<tr>
												<th>#</th>
												<th>Status</th>
												<th>Info</th>
												<th>Price</th>
												<th>Margin</th>
												<th>Created at</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>23578</td>
												<td>Open</td>
												<td>Sell Bitcoin</td>
												<td>$45,500.5</td>
												<td>+1.5 above</td>
												<td>02 May 2021</td>
											</tr>
											<tr>
												<td>23578</td>
												<td>Open</td>
												<td>Buy Etherum</td>
												<td>$3,250.50</td>
												<td>+2.5 below</td>
												<td>01 May 2021</td>
											</tr>
										</tbody>
									</table> */}
                      </div>
                    </div>
                    {/* <div class="tab-pane fade" id="nav-cancelledTrades" role="tabpanel" aria-labelledby="nav-cancelledTrades-tab">
								<div className="table-responsive">
									<table className="table tableDashBoardStyle">
										<thead>
											<tr>
												<th>#</th>
												<th>Status</th>
												<th>Info</th>
												<th>Price</th>
												<th>Margin</th>
												<th>Created at</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>23578</td>
												<td>Open</td>
												<td>Sell Bitcoin</td>
												<td>$45,500.5</td>
												<td>+1.5 above</td>
												<td>02 May 2021</td>
											</tr>
											<tr>
												<td>23578</td>
												<td>Open</td>
												<td>Buy Etherum</td>
												<td>$3,250.50</td>
												<td>+2.5 below</td>
												<td>01 May 2021</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div> */}
                  </div>
                </div>
                <h3 class="userBoxUnderlineTitle">Login Details</h3>
                <div className="dashTableTabbedContent">
                  <div className="table-responsive">
                    <ReactDatatable
                      responsive={this.state.responsive}
                      config={this.config}
                      records={this.state.historylist}
                      columns={this.columns}
                      onPageChange={this.pageChange.bind(this)}
                    />
                    {/* <table className="table tableDashBoardStyle">
							<thead>
								<tr>
									<th>Date</th>
									<th>IP Address</th>
									<th>Location</th>
									<th>Device</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>02 May 2021 15;15</td>
									<td>154.254.214</td>
									<td>NewDelhi, India</td>
									<td>Chrome, Win10</td>
									<td>Login</td>
								</tr>
								<tr>
									<td>01 May 2021 15;15</td>
									<td>244.254.214</td>
									<td>Mumbai, India</td>
									<td>Firefox, Win10</td>
									<td>Login</td>
								</tr>
								<tr>
									<td>02 May 2021 15;15</td>
									<td>154.254.214</td>
									<td>NewDelhi, India</td>
									<td>Chrome, Win10</td>
									<td>Login</td>
								</tr>
							</tbody>
						</table> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Dashboard));
