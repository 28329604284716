import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import {NavLink} from 'react-router-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import Navbar from './Navbar'
import Footer from './Footer'
import { resetUser } from "../actions/authActions";

import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import ReCAPTCHA from "react-google-recaptcha";
import keys from "../actions/config";
import axios from "axios";
import classnames from "classnames";

const url = keys.baseUrl;
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();

const grecaptchaObject = window.grecaptcha;



class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            _id: "",
            password: "",
            password2: "",
            errors: {},
            _csrf:'',
        };
      }
  getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
          this.setState({_csrf:res.data._csrf});
        })
        .catch();
  }
  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    this.getCSRFtoken();
    // if (this.props.auth.isAuthenticated) {
    //   this.props.history.push("/Account");
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth.forgotuser !== undefined &&
      nextProps.auth.forgotuser.data !== undefined &&
      nextProps.auth.forgotuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.forgotuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      nextProps.auth.forgotuser = "";
      this.props.history.push("/Login");
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const resetuser = {
      _id: this.props.match.params.id,
      password: this.state.password,
      password2: this.state.password2,
      _csrf: this.state._csrf,
    };
    this.props.resetUser(resetuser);
  };

	render() {
        const { errors } = this.state;

		return (
      <div>
      <Navbar />
     <section className="crediantialsBox">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-10 col-sm-12 mx-auto">
                        <div className="wow flipInY" data-wow-delay=".15s;">
                        <div className="formBox mb-5 mb-md-0">
                            <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-login" role="tabpanel" aria-labelledby="nav-login-tab">
                                    <h2 className="text-left credintialTitle"> Reset Password</h2>
                                    <form id="forgotfrm" name="forgotfrm" noValidate onSubmit={this.onSubmit}  className="stoForm" method="">
                                        <div className="form-group mobNumber">
                                        <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>New Password</label>
                                <input
                              className="form-control"
                              type="password"
                              onChange={this.onChange}
                              value={this.state.password}
                              error={errors.password}
                              id="password"
                              required
                            />
                            <span className="text-danger">{errors.password}</span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Confirm Password</label>
                                <input
                              type="password"
                              onChange={this.onChange}
                              value={this.state.password2}
                              error={errors.password2}
                              id="password2"
                              className="form-control"
                              required
                            />
                            <span className="text-danger">{errors.password2}</span>                             </div>
                            </div>
                          </div>                                 </div>
                                        
                                        
                                        <div className="form-group text-center">
                                        <ReCAPTCHA
                              className="g-recaptcha"
                              ref={(r) => (this.recaptcha = r)}
                              sitekey={Recaptchakey}
                              grecaptcha={grecaptchaObject}
                              onChange={this.captchaChange}
                            />
                            <span className="text-danger">{errors.recaptcha}</span>                                        </div>
                                        <div className="form-group text-center">
                                            <input  name="frmbtn" id="frmbtn" type="submit" className="btnType1" value="Submit" />
                                        </div>
                                    </form>
                                    <div className="formFooter">
                                        <p className="text-center"><span>Notes: Please check your email, we’ll send you password recovery email link
which valid</span> one hour only</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
</div>
);
	}
}




ResetPassword.propTypes = {
    resetUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
  });
  
  export default connect(mapStateToProps, {
    resetUser,
  })(withRouter(ResetPassword));
  