const crypto = require('crypto');

// const algorithm = 'aes-256-ctr';
// const secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3';
// const iv = crypto.randomBytes(16);
const CryptoJS = require('crypto-js');
const key = CryptoJS.enc.Base64.parse("#base64Key#");
const iv = CryptoJS.enc.Base64.parse("#base64IV#");

// const encrypt = (text) => {

//     const cipher = crypto.createCipheriv(algorithm, secretKey, iv);

//     const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

//     return {
//         iv: iv.toString('hex'),
//         content: encrypted.toString('hex')
//     };
// };

// const decrypt = (hash) => {

//     const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));

//     const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);

//     console.log("----decrpyted.toString()",decrpyted.toString())
//     return decrpyted.toString();
// };
let encrypt = exports.encrypt = (value) => {
	var cipher = CryptoJS.AES.encrypt(value, key, {iv: iv}).toString();
	return cipher;
}

let decrypt = exports.decrypt = (value) => {
	var decipher = CryptoJS.AES.decrypt(value, key, {iv: iv})
	var decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
	return decrypt_val;
}

module.exports = {
    encrypt,
    decrypt
};