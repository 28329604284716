import React, { Component } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";
import Switch from "react-switch";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap/";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import { tfaFormsubmit } from "../actions/authActions";
import setAuthToken from "../utils/setAuthToken";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { encrypt, decrypt } from "../actions/crypto";

const url = keys.baseUrl;

const preferredTime = [
  {
    value: "GMT +5.30 - Delhi, Mumbai - India",
    label: "GMT +5.30 - Delhi, Mumbai - India",
  },
  {
    value: "GMT +5.30 - Delhi, Mumbai - India",
    label: "GMT +5.30 - Delhi, Mumbai - India",
  },
  {
    value: "GMT +5.30 - Delhi, Mumbai - India",
    label: "GMT +5.30 - Delhi, Mumbai - India",
  },
  {
    value: "GMT +5.30 - Delhi, Mumbai - India",
    label: "GMT +5.30 - Delhi, Mumbai - India",
  },
  {
    value: "GMT +5.30 - Delhi, Mumbai - India",
    label: "GMT +5.30 - Delhi, Mumbai - India",
  },
];

const preferredLanguage = [
  { value: "English", label: "English" },
  { value: "Tamil", label: "Tamil" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Hindi", label: "Hindi" },
  { value: "Arabic", label: "Arabic" },
];

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      selectedPreferredTime: null,
      selectedPreferredLanguage: null,
      checked: false,
      selectedCoinPair: null,
      selectedbankName: null,
      oldpassword: "",
      password: "",
      password2: "",
      loginpassword: "",
      googleauth: "",
      onecode: "",
      tfacode: "",
      applicationName: "",
      readOnly: "",
      twofactorkey: "",
      notifications: {},
      errors: {},
      accepterror: "",
      onecodeerror: "",
      twofastatus: "",
      accept: "false",
      _csrf: "",
      onetermerr: "",
      googlestatus: "",
      Referralist: [],
      showProfile: 0,
      terms: false,
      postNotification: 0,
      credentialChange: 0,
      promotional: 0,
      onlyVerifiedUser: false,
      viewAllUser: false,
      postNotifyEnable: false,
      postNotifyDisable: false,
      credentialEnable: false,
      credentialDisable: false,
      marketingEnable: false,
      marketingDisable: false,
      userName: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
      })
      .catch();
  };

  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    this.getData();
    this.getReferalData();
    this.getCSRFtoken();
  }

  getReferalData = async () => {
    let userid = this.props.auth.user.id;
    const data = await axios.get(url + "users/getReferalData/" + userid);

    this.setState({
      Referralist: data.data,
    });
  };
  getData() {
    let userid = this.props.auth.user.id;
    axios
      .get(url + "cryptoapi/userget/" + userid)
      .then((res) => {
        console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwww", res.data.username);
        this.props.auth.user.name = res.data.name;
        if (res.data.showProfile == 1) {
          this.setState({ viewAllUser: true, onlyVerifiedUser: false });
        } else {
          this.setState({ onlyVerifiedUser: true, viewAllUser: false });
        }
        if (res.data.postNotification == 1) {
          this.setState({ postNotifyEnable: true, postNotifyDisable: false });
        } else {
          this.setState({ postNotifyDisable: true, postNotifyEnable: false });
        }
        if (res.data.credentialChange == 1) {
          this.setState({ credentialEnable: true, credentialDisable: false });
        } else {
          this.setState({ credentialDisable: true, credentialEnable: false });
        }
        if (res.data.promotional == 1) {
          this.setState({ marketingEnable: true, marketingDisable: false });
        } else {
          this.setState({ marketingDisable: true, marketingEnable: false });
        }
        this.setState({
          userid: res.data.userid,
          name: res.data.username,
          showProfile: res.data.showProfile,
          postNotification: res.data.postNotification,
          credentialChange: res.data.credentialChange,
          promotional: res.data.promotional,
          googlestatus: res.data.google,
          secretcode: res.data.newSecret.secret,
          Factor2AImg:
            "https://chart.googleapis.com/chart?chs=150x150&chld=L|0&cht=qr&chl=otpauth://totp/Coinbarter%3Fsecret=" +
            res.data.newSecret.secret,
          userName: res.data.username,
        });
        if (res.data.google == "Enabled") {
          this.setState({ checked: true, twofastatus: "Enabled" });
        } else this.setState({ checked: false, twofastatus: "Disabled" });
      })
      .catch();
  }

  handlePreferredTime = (selectedPreferredTime) => {
    this.setState({ selectedPreferredTime });
  };
  handlePreferredLanguage = (selectedPreferredLanguage) => {
    this.setState({ selectedPreferredLanguage });
  };

  handleChange(checked) {
    this.setState({ checked });
  }

  Copied() {
    store.addNotification({
      message: "Copied",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 1500,
        onScreen: true,
      },
    });
  }

  tfaFormsubmit = (e) => {
    e.preventDefault();
    if (this.state.twofastatus != "Enabled") {
      if (!this.state.terms) {
        this.setState({ onetermerr: "Please Backup your 32 digit code" });
        return;
      }
    }

    if (this.state.onecode == "") {
      this.setState({ onecodeerror: "Please enter 2FA code" });
      return;
    }
    if (this.state.onecode != "" && this.state.accept.toString() == "true") {
      this.setState({ onecodeerror: "" });
      this.setState({ accepterror: "" });
      this.setState({ onetermerr: "" });
    }

    const userData = {
      secretcode: this.state.secretcode,
      id: this.props.auth.user.id,
      // accept: this.state.accept.toString(),
      onecode: this.state.onecode,
      _csrf: this.state._csrf,
    };
    console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", userData);
    axios
      .post(url + "users/tfa-enable", userData)
      .then((res) => {
        if (res.data.google == "Enabled") {
          this.setState({ googleauth: "Verified" });
        }
        if (res.data.google == "Disabled") {
          this.setState({ googleauth: "" });
        }
        if (res.data.success) {
          // this.setState({ ViewBankAccount: !this.state.ViewBankAccount });
          this.setState({ onecodeerror: "" });
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            tradeliquidationIn: ["animated", "fadeIn"],
            tradeliquidationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          this.getData();
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            tradeliquidationIn: ["animated", "fadeIn"],
            tradeliquidationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
        this.setState({ onecode: "" });
      })

      .catch();
  };

  onChangeCheckbox = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
  };
  handlebankModal = (e) => {
    this.setState({ ViewBankAccount: !this.state.ViewBankAccount });
  };

  Showpopup = (e) => {
    if (this.state.accept != "true") {
      if (this.state.accept.toString() == "true") {
        this.setState({ ViewBankAccount: !this.state.ViewBankAccount });
      } else {
        this.setState({ accepterror: "Please Read and confirm" });
      }
    } else {
      this.setState({ accepterror: "Please Read and confirm" });
    }
  };

  onChange = (e) => {
    // this.setState({ onecodeerror: "" }),
    this.setState({ [e.target.id]: e.target.value, onecodeerror: "" });
  };

  onChangepasswordUpdate = (e) => {
    e.preventDefault();
    if (this.state.password != "") {
      if (this.state.password2 == "") {
        this.setState({ passerror: "Reenter Password" });
      }
    } else {
      this.setState({ passerror: "Enter Password" });
    }
    const updatechangepassword = {
      _id: this.props.auth.user.id,
      oldpassword: this.state.oldpassword,
      password: this.state.password,
      password2: this.state.password2,
      _csrf: this.state._csrf,
    };
    axios
      .post(url + "users/updatepasswordchange", updatechangepassword)
      .then((res) => {
        console.log("resresresresresres", res.data);
        if (res.data.success) {
          this.setState({
            oldpassword: "",
            password: "",
            password2: "",
          });
          store.addNotification({
            title: "Wonderful!",
            message: "Password Changed Successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            tradeliquidationIn: ["animated", "fadeIn"],
            tradeliquidationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          window.location.reload();

          // this.setState({ errors: "" });
        } else {
          if (res.data.success == false) {
            store.addNotification({
              title: "Error!",
              message: res.data.errors.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              tradeliquidationIn: ["animated", "fadeIn"],
              tradeliquidationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
          var err = res.data.errors;
          this.setState({ errors: res.data.errors });
        }
      })
      .catch();
  };
  handleGeneral = (e) => {
    if (e.target.name == "showProfile") {
      if (e.target.name == "showProfile" && e.target.value == 1) {
        this.setState({ onlyVerifiedUser: false, viewAllUser: true });
      } else if (e.target.name == "showProfile" && e.target.value == 0) {
        this.setState({ onlyVerifiedUser: true, viewAllUser: false });
      }
      this.setState({ showProfile: e.target.value });
    } else if (e.target.name == "postNotification") {
      if (e.target.name == "postNotification" && e.target.value == 1) {
        this.setState({ postNotifyEnable: true, postNotifyDisable: false });
      } else if (e.target.name == "postNotification" && e.target.value == 0) {
        this.setState({ postNotifyEnable: false, postNotifyDisable: true });
      }
      this.setState({ postNotification: e.target.value });
    } else if (e.target.name == "credentialChange") {
      if (e.target.name == "credentialChange" && e.target.value == 1) {
        this.setState({ credentialEnable: true, credentialDisable: false });
      } else if (e.target.name == "credentialChange" && e.target.value == 0) {
        this.setState({ credentialEnable: false, credentialDisable: true });
      }
      this.setState({ credentialChange: e.target.value });
    } else if (e.target.name == "promotional") {
      if (e.target.name == "promotional" && e.target.value == 1) {
        this.setState({ marketingEnable: true, marketingDisable: false });
      } else if (e.target.name == "promotional" && e.target.value == 0) {
        this.setState({ marketingEnable: false, marketingDisable: true });
      }
      this.setState({ promotional: e.target.value });
    }
  };

  saveSettingsChanges = async (e) => {
    e.preventDefault();

    const { showProfile, promotional, credentialChange, postNotification } =
      this.state;

    const data = {
      _id: this.props.auth.user.id,
      showProfile: showProfile,
      promotional: promotional,
      credentialChange: credentialChange,
      postNotification: postNotification,
    };
    const saveData = await axios.post(
      url + "users/update_general_settings",
      data
    );
    store.addNotification({
      title: "Wonderful!",
      message: saveData.data.message,
      type: "success",
      insert: "top",
      container: "top-right",
      tradeliquidationIn: ["animated", "fadeIn"],
      tradeliquidationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 1500,
        onScreen: true,
      },
    });
    this.getData();
  };

  settings_change = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleonchange = (e) => {
    this.setState({ terms: !this.state.terms });
    this.setState({ onetermerr: "" });
  };

  render() {
    const { selectedCoinPair, userName } = this.state;
    const { selectedbankName } = this.state;
    const { errors } = this.state;
    const { selectedPreferredTime } = this.state;
    const { selectedPreferredLanguage } = this.state;
    const {
      showProfile,
      only_showProfile,
      promotional,
      promotional_disable,
      credentialChange,
      credentialChange_disable,
      postNotification,
      postNotification_disable,
    } = this.state;
    return (
      <div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="userCommonCard">
                  <h2 className="userPageTitle">Security</h2>
                  <div className="userBoxWhiteBox securityBox">
                    <h3 class="userBoxUnderlineTitle">
                      Two Factor Authentication
                    </h3>
                    <div class="alert alert-primary" role="alert">
                      <div className="aletBoxCard">
                        <div className="alertboxIcon">
                          <img
                            src={require("../images/alertIcon.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="alertboxDescription">
                          <h5>
                            Status{" "}
                            <span className="redText">
                              - {this.state.googlestatus}
                            </span>
                          </h5>
                          <p>
                            Enable 2FA to withdraw your funds, it is additional
                            security to safe your crypto assets
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="qrCodePh text-center">
                      <img
                        src={this.state.Factor2AImg}
                        alt=""
                        className="img-fluid"
                      />
                      {/* <img src={require("../images/qrCode.jpg")} alt="" className="img-fluid" /> */}
                    </div>
                    <div className="form-group">
                      <label>
                        Your 32 digit security code
                        {/* <a data-tip data-for='clickme' data-event='click' className="ml-2"><i class="fas fa-info-circle"></i></a> */}
                        <ReactTooltip
                          id="clickme"
                          place="right"
                          effect="solid"
                          clickable={true}
                        >
                          For your security right down this
                          <br />
                          32 digit code in a paper
                        </ReactTooltip>
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.secretcode}
                          disabled
                        />
                        <div class="input-group-append">
                          <CopyToClipboard
                            text={this.state.secretcode}
                            onCopy={() => this.setState({ copied: true })}
                          >
                            <button
                              className="btn btnType1"
                              onClick={() => this.Copied()}
                            >
                              <i class="fas fa-copy"></i>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Enter 6 digit from Google authenticator</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.onecode}
                        id="onecode"
                        type="number"
                        className=" form-control"
                        placeholder="Enter the 2FA Code"
                      />
                      <span className="text-danger">
                        {this.state.onecodeerror}
                      </span>
                    </div>
                    <div className="form-group text-center">
                      {this.state.twofastatus == "Enabled" ? (
                        <input
                          type="submit"
                          onClick={this.tfaFormsubmit}
                          className="roundedCorner"
                          value="Disable"
                        />
                      ) : (
                        <>
                          <div className="form-group remForAction mt-4">
                            <div className="checkbox d-block w-100">
                              <label className="postADAgreement flexJustifyCenter">
                                <input
                                  id="terms"
                                  type="checkbox"
                                  checked={this.state.terms}
                                  onChange={this.handleonchange}
                                />
                                <span className="cr">
                                  <i className="cr-icon fa fa-check"></i>
                                </span>
                                <span className="agreeContent">
                                  I have copied 32 Security code
                                </span>
                              </label>
                              <span className="text-danger">
                                {this.state.onetermerr}
                              </span>
                            </div>
                          </div>

                          <input
                            type="submit"
                            onClick={this.tfaFormsubmit}
                            className="roundedCorner"
                            value="Enable"
                          />
                        </>
                      )}
                      {/* <input type="submit" className="roundedCorner" value="Enable" /> */}
                    </div>
                    <h3 class="userBoxUnderlineTitle mt-5">
                      Update Login Password
                    </h3>
                    <div className="form-group">
                      <label>Old Password</label>
                      <input
                        type="password"
                        onChange={this.onChange}
                        value={this.state.oldpassword}
                        id="oldpassword"
                        class="form-control"
                        value={this.state.oldpassword}
                      />
                      <span className="text-danger">{errors.oldpassword}</span>
                    </div>
                    <div className="form-group">
                      <label>
                        New Password
                        <a
                          data-tip
                          data-for="clickme"
                          data-event="click"
                          className="ml-2"
                        >
                          <i class="fas fa-info-circle"></i>
                        </a>
                        <ReactTooltip
                          id="clickme"
                          place="right"
                          effect="solid"
                          clickable={true}
                        >
                          Your new password must contain at least
                          <br />
                          one number digit, one special character,
                          <br />
                          one UPPER letter with 12 digit long.
                        </ReactTooltip>
                      </label>
                      <input
                        type="password"
                        onChange={this.onChange}
                        id="password"
                        class="form-control"
                        value={this.state.password}
                      />
                      <span className="text-danger">{errors.password}</span>
                    </div>
                    <div className="form-group">
                      <label>Confirm New Passsword</label>
                      <input
                        type="password"
                        class="form-control"
                        onChange={this.onChange}
                        id="password2"
                        value={this.state.password2}
                      />
                      <span className="text-danger">{errors.password2}</span>
                    </div>
                    <div className="form-group text-center">
                      <input
                        type="submit"
                        id="password2"
                        className="roundedCorner"
                        onClick={this.onChangepasswordUpdate}
                        value="Update"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="userCommonCard">
                  <h2 className="userPageTitle">General Settings</h2>
                  <div className="userBoxWhiteBox generalSettingsBox">
                    <form onSubmit={this.saveSettingsChanges}>
                      {/* <div class="form-group">
                      <label>Time Zone</label>
                      <Select
                         value={selectedPreferredTime}
                         onChange={this.handlePreferredTime}
                         options={preferredTime}
                       />
                     </div> */}

                      <div className="form-group">
                        <label>Show My Profile</label>
                        <div className="dashRadio">
                          <div class="radio mr-4">
                            <label>
                              <input
                                type="radio"
                                name="showProfile"
                                value={1}
                                onChange={this.handleGeneral}
                                checked={this.state.viewAllUser}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test"></i>
                              </span>
                              View All
                            </label>
                          </div>
                          <div class="radio">
                            <label>
                              <input
                                type="radio"
                                name="showProfile"
                                value={0}
                                onChange={this.handleGeneral}
                                checked={this.state.onlyVerifiedUser}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test"></i>
                              </span>
                              Only Verified User
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>When create a post notification to email</label>
                        <div className="dashRadio">
                          <div class="radio mr-4">
                            <label>
                              <input
                                type="radio"
                                name="postNotification"
                                value={1}
                                onChange={this.handleGeneral}
                                checked={this.state.postNotifyEnable}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test1"></i>
                              </span>
                              Enable
                            </label>
                          </div>
                          <div class="radio">
                            <label>
                              <input
                                type="radio"
                                name="postNotification"
                                value={0}
                                onChange={this.handleGeneral}
                                checked={this.state.postNotifyDisable}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test1"></i>
                              </span>
                              Disable
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          When login credential changed notification to email
                        </label>
                        <div className="dashRadio">
                          <div class="radio mr-4">
                            <label>
                              <input
                                type="radio"
                                name="credentialChange"
                                value={1}
                                onChange={this.handleGeneral}
                                checked={this.state.credentialEnable}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test2"></i>
                              </span>
                              Enable
                            </label>
                          </div>
                          <div class="radio">
                            <label>
                              <input
                                type="radio"
                                name="credentialChange"
                                value={0}
                                onChange={this.handleGeneral}
                                checked={this.state.credentialDisable}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test2"></i>
                              </span>
                              Disable
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Coinbarter marketing promotional email</label>
                        <div className="dashRadio">
                          <div class="radio mr-4">
                            <label>
                              <input
                                type="radio"
                                name="promotional"
                                value={1}
                                onChange={this.handleGeneral}
                                checked={this.state.marketingEnable}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test3"></i>
                              </span>
                              Enable
                            </label>
                          </div>
                          <div class="radio">
                            <label>
                              <input
                                type="radio"
                                name="promotional"
                                value={0}
                                onChange={this.handleGeneral}
                                checked={this.state.marketingDisable}
                              />
                              <span class="cr">
                                <i class="cr-icon fa fa-circle test3"></i>
                              </span>
                              Disable
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group text-center">
                        <input
                          type="submit"
                          className="roundedCorner"
                          value="Save"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="userCommonCard mt-5">
                  <h2 className="userPageTitle">Referral</h2>
                  <div className="userBoxWhiteBox referralIncome">
                    <div class="form-group">
                      <label>
                        My Referral ID <span className="reffId"></span>
                      </label>

                      <div class="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name=""
                          value={keys.frontUrl + "Signup/" + userName}
                        />
                        <div class="input-group-append">
                          <CopyToClipboard
                            text={keys.frontUrl + "Signup/" + userName}
                            onCopy={() => this.setState({ copied: true })}
                          >
                            <button
                              className="btn btnType1"
                              onClick={() => this.Copied()}
                            >
                              <i class="fas fa-copy"></i>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                    <div className="riTotal">
                      {/* <p>Total Referral Income <span>$50</span></p>
                       <p>Total Referral Users <span>3</span></p> */}
                    </div>
                    <div className="table-responsive">
                      <table class="table referralIncomeTable">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Ref. Email</th>
                            <th>Reffral Bonus</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.Referralist &&
                            this.state.Referralist.map((data, i) => {
                              if (data.referaluseridStatus == 1) {
                                return (
                                  <tr>
                                    <td>
                                      {moment(data.date).format(
                                        "MMMM,Do YYYY, hh:mm A"
                                      )}
                                    </td>
                                    <td>{decrypt(data.email)}</td>
                                    <td>{data.referalAmount}</td>
                                  </tr>
                                );
                              }
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { tfaFormsubmit })(Settings);
