import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import Select from 'react-select';
import ScrollspyNav from "react-scrollspy-nav";

const currencyType = [
  { value: 'NGN', label: 'NGN' },
  { value: 'BTC', label: 'BTC' },
  { value: 'USDT', label: 'USDT' },
  { value: 'BCH', label: 'BCH' },
  { value: 'XRP', label: 'XRP' },
  { value: 'TRON', label: 'TRON' },
  { value: 'DASH', label: 'DASH' },
  { value: 'LTC', label: 'LTC' },
];
const buySell = [
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
];
const transferType = [
  { value: 'Bank Transfer', label: 'Bank Transfer' },
  { value: 'Fiat Transfer', label: 'Fiat Transfer' },
  { value: 'Instant Transfer', label: 'Instant Transfer' },
];

class PaymentMethod extends Component {
state = {
    isTop: true,
  };
 state = {
    selectedBuySell: null,
    selectedTransferType: null,
    selectedCurrencyType: null,
    };
  handleBuySell = selectedBuySell => {
    this.setState({ selectedBuySell });
  };
  handleTransferType = selectedTransferType => {
    this.setState({ selectedTransferType });
  };
  handleCurrencyType = selectedCurrencyType => {
    this.setState({ selectedCurrencyType });
  };
	render() {
	const { selectedBuySell } = this.state;
	const { selectedTransferType } = this.state;
	const { selectedCurrencyType } = this.state;
		return (
      <div>
      <Navbar />
<section className="secFAQ cmsPages">
      <div className="container">
      	<div className="cmsBoxWhiteBox">
      	 <h2 className="cmsPageTitle">Payment Methods</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="cmsContentImg">
                <img src={require("../images/cmsContentImg1.jpg")} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="cmsContent">
                <h3>How to pay to traders in Coinbarter?</h3>
                <p>Domestic payment methods are the most popular choice for Coinbarter users. Most traders prefer to trade via a standard bank transfer within their country. Alipay and WeChat Pay are also incredibly popular throughout China.</p>
                <p>Some traders prefer to meet face-to-face to exchange cash in person. If you're trading with cash, we recommend meeting in a public place such as a cafe. If safety is a greater concern in your area, try an international airport or bank branch — somewhere with cameras and security to keep you safe.</p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-8 mx-auto">
              <div className="cmsContent">
                <h3 className="text-center">Popular domestic payment methods</h3>
                <div className="row">
                  <div className="col-md-4">
                    <div className="landPMCard wow flipInY">
                      <img src={require("../images/bankTransfer.png")} alt="" className="img-fluid" />
                      <h3>Bank Transfer</h3>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="landPMCard wow flipInY">
                      <img src={require("../images/mobileMoney.png")} alt="" className="img-fluid" />
                      <h3>Mobile Money</h3>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="landPMCard wow flipInY">
                      <img src={require("../images/chipperCash.png")} alt="" className="img-fluid" />
                      <h3>Paypal</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="cmsContent">
                <p>Domestic payment methods are the most popular choice for Coinbarter users. Most traders prefer to trade via a standard bank transfer within their country. Alipay and WeChat Pay are also incredibly popular throughout China.</p>
                <p>Some traders prefer to meet face-to-face to exchange cash in person. If you're trading with cash, we recommend meeting in a public place such as a cafe. If safety is a greater concern in your area, try an international airport or bank branch — somewhere with cameras and security to keep you safe.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
	  </div>
);
	}
}

export default PaymentMethod