import React, { Component } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import UserNavbar from "./UserNavbar";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";
import * as moment from "moment";
import axios from "axios";
import { store } from "react-notifications-component";
import keys, { numberWithCommas } from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "jquery";
import ReactDatatable from "@ashvin27/react-datatable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import queryString from "query-string";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import ReactDatatable from '@ashvin27/react-datatable';

var QRCode = require("qrcode.react");

const url = keys.baseUrl;

const preferredCrypto = [
  { value: "BTC", label: "BTC - Bitcoin" },
  { value: "ETH", label: "ETH - Ethereum" },
  { value: "USDT", label: "USDT - Tether" },
  { value: "LTC", label: "LTC - Litecoin" },
  { value: "BNB", label: "BNB - BNB" },

  //   { value: 'XRP', label: 'XRP - Ripple' },
];
const preferredCryptoShort = [
  { value: "BTC", label: "BTC" },
  { value: "ETH", label: "ETH" },
  { value: "USDT", label: "USDT" },
  { value: "XRP", label: "XRP" },
];
const preferredRecords = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "All", label: "All" },
];

const preferredType = [
  { value: "Deposit", label: "Deposit" },
  { value: "Withdraw", label: "Withdraw" },
  // { value: 'All', label: 'All' },
];

class WalletDepositWithdrawCrypto extends Component {
  constructor() {
    super();
    this.depositColumns = [
      {
        key: "created",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{moment(record.created).format("DD-MM-YYYY  k:mm:s ")}</p>;
        },
      },
      {
        key: "_id",
        text: "Transaction ID",
        className: "Transaction ID",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record._id}</p>;
        },
      },
      {
        key: "currencySymbol",
        text: "Currency",
        className: "currencySymbol",
        align: "left",
        sortable: true,
      },
      {
        key: "Amount",
        text: "Amount",
        className: "Amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          const transferType = record && record.transferType;
          const Amount = record && record.Amount;
          const amount = record && record.amount;
          if (transferType != "Withdraw") {
            var lockVal = amount;
          } else if (transferType == "Withdraw") {
            var lockVal = Amount;
          }
          return <p>{lockVal}</p>;
        },
      },
      {
        key: "depType",
        text: "Type",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          const transferType = record && record.transferType;
          if (transferType != "Withdraw") {
            var lockVal = "Deposit";
          } else if (transferType == "Withdraw") {
            var lockVal = "Withdraw";
          }
          return <p>{lockVal}</p>;
        },
      },
      {
        key: "status",
        text: "Status",
        className: "Ip",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <p>{record.status}</p>;
        },
      },
    ];
    this.depositConfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };
    this.withdrawColumns = [
      {
        key: "created_date",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <p>{moment(record.created_date).format("DD-MM-YYYY  k:mm:s ")}</p>
          );
        },
      },
      {
        key: "transferType",
        text: "Type",
        className: "transferType",
        align: "left",
        sortable: true,
      },
      {
        key: "currency",
        text: "Crypto currency",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "Amount",
        text: "Amount",
        className: "Amount",
        align: "left",
        sortable: true,
      },
      {
        key: "Toaccountno",
        text: "To address",
        className: "Toaccountno",
        align: "left",
        sortable: true,
      },
      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: true,
      },
    ];
    this.withdrawConfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "History",
      no_data_text: "No login details found!",
      sort: { column: "Created date", order: "desc" },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      defaultSortAsc: true,
    };
    this.state = {
      errors: {},
      user: {},
      errors: {},
      spotpairsdata: [],
      balancedetails: [],
      totalholding: 0,
      profitloss: "",
      percentagechange: "",
      currencysymbol: "",
      balancelist: [],
      currencydetails: [],
      identity: "Level 0",
      spotpairsdata: [],
      depositHistory: [],
      withdrawHistory: [],
      copied: false,
      desttag: "",
      currencytype: "Fiat",
      mobilenumber: "",
      accountno: "",
      ifsccode: "",
      bankname: "",
      bankdetails: [],
      xrpaddress: "",
      receiveselected: "BTC",
      sendselected: "BTC",
      cryptoaddress: "",
      kycstatus: "Not Verified",
      cryptobalance: "",
      toaddress: "",
      amount: "",
      fee: 0,
      googlestatus: "Disabled",
      tfacode: "",
      _csrf: "",
      withdrawShowing: false,
      depositShowing: true,
      preferredTypeVal: "Deposit",
      preferredCrypto1: [],
    };
  }

  componentDidMount() {
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }
    const query = new URLSearchParams(this.props.location.search);
    const coinreceive = query.get("coinreceive");
    const coinsend = query.get("coinsend");
    if (coinreceive != null) {
      this.setState({ receiveselected: coinsend });
      this.getAssetData(coinsend);
    } else {
      this.setState({ receiveselected: coinsend });
      this.getAssetData(coinsend);
    }
    if (coinsend != null) {
      this.setState({ sendselected: coinsend });
      this.getAssetData(coinsend);
    } else {
      this.setState({ sendselected: coinsend });
      this.getAssetData(coinsend);
    }

    this.getCSRFtoken();
    this.getData();
    this.getHistory();
    this.getWithdrawHistory();
  }
  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });
      })
      .catch();
  };
  getHistory() {
    const id = this.props.auth.user.id;
    axios.get(url + "cryptoapi/cryptodeposithistory/" + id).then((res) => {
      console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvv", res);
      this.setState({ depositHistory: res.data });
    });
  }
  getWithdrawHistory() {
    const id = this.props.auth.user.id;
    axios.get(url + "cryptoapi/cryptowithdrawhistory/" + id).then((res) => {
      this.setState({ withdrawHistory: res.data });
    });
  }
  getAssetData(coin) {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/getBalance/" + userid).then((res) => {
      // console.log("coincoincoincoincoincoin",res.data)
      if (res.data.status) {
        var curarrya = res.data.data;
        var cryptoarra = [];
        var cryptoaddress = [];
        var desttag = "";
        for (var i = 0; i < curarrya.length; i++) {
          if (curarrya[i].currency != null) {
            if (curarrya[i].currency.type != "Fiat") {
              cryptoarra.push(curarrya[i]);
              let d = {
                value: curarrya[i].currencySymbol,
                label: curarrya[i].currencySymbol,
              };
              console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", d, curarrya[i]);
              cryptoaddress.push(d);
            }
            // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",d,curarrya[i])
            this.setState({ preferredCrypto1: cryptoaddress });
          }
        }
        var tempaddres = "";
        var tempBalance = "";
        if (coin != "XRP") {
          var curindex = cryptoarra.findIndex((x) => x.currencySymbol === coin);
          if (curindex != -1) {
            tempBalance = cryptoarra[curindex].balance;
            tempaddres = cryptoarra[curindex].currencyAddress;
          }

          this.setState({
            balancedetails: cryptoarra,
            xrpaddress: res.data.xrpdetails.address,
            cryptoaddress: tempaddres,
            cryptobalance: tempBalance,
          });
        } else {
          var curindex = cryptoarra.findIndex((x) => x.currencySymbol === coin);
          if (curindex != -1) {
            tempBalance = cryptoarra[curindex].balance;
            tempaddres = cryptoarra[curindex].privateKey;
            desttag = cryptoarra[curindex].currencyAddress;
          }

          this.setState({
            balancedetails: cryptoarra,
            desttag: desttag,
            cryptoaddress: tempaddres,
            cryptobalance: tempBalance,
          });
        }
        if (coin == "XRP") {
          this.setState({
            balancedetails: cryptoarra,
            xrpaddress: res.data.xrpdetails.address,
            cryptoaddress: res.data.xrpdetails.address,
            desttag: tempaddres,
          });
        } else {
          this.setState({
            balancedetails: cryptoarra,
            xrpaddress: res.data.xrpdetails.address,
            cryptoaddress: tempaddres,
          });
        }
      }
      const query = new URLSearchParams(this.props.location.search);
      const coinreceive = query.get("coinreceive");
      const coinsend = query.get("coinsend");
      if (coinsend != null) {
        var curindex = this.state.balancedetails.findIndex(
          (x) => x.currencySymbol === coinsend
        );
        var fee = 0;
        var tempaddres = "";
        if (curindex != -1) {
          tempaddres = this.state.balancedetails[curindex].balance;
          fee = this.state.balancedetails[curindex].currency.fee;
        }
        this.setState({ cryptobalance: tempaddres, fee: fee });
        this.setState({ cryptocurrency: coinsend });
        this.setState({ activetab: "send" });
        this.props.history.push(
          "/WalletDepositWithdrawCrypto?coinsend=" + coinsend
        );
      } else {
        var curindex = this.state.balancedetails.findIndex(
          (x) => x.currencySymbol === "BTC"
        );
        var fee = 0;
        var tempaddres = "";
        if (curindex != -1) {
          tempaddres = this.state.balancedetails[curindex].balance;
          fee = this.state.balancedetails[curindex].currency.fee;
        }
        this.setState({ cryptobalance: tempaddres, fee: fee });
        this.setState({ cryptocurrency: "BTC" });
        this.setState({ activetab: "send" });
        this.props.history.push(
          "/WalletDepositWithdrawCrypto?coinsend=" + "BTC"
        );
      }
    });
  }
  getData() {
    let userid = this.props.auth.user.id;
    axios
      .get(url + "cryptoapi/userget/" + userid)
      .then((res) => {
        var qstr = queryString.parse(this.props.location.search);
        if (qstr.coinreceive != "" && qstr.coinreceive != undefined) {
          if (res.data.kycstatus != "Approved") {
            store.addNotification({
              title: "Error!",
              message:
                "You need to update your verification status to continue using Coinbarter",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });

            this.props.history.push("/MyAccount/");
          }
        }

        if (res.status == 200) {
          this.setState(res.data);
          this.setState({
            userid: res.data.userid,
            name: res.data.name,
            otp: res.data.otp,
            firstname: res.data.firstname,
            middlename: res.data.middlename,
            lastname: res.data.lastname,
            phone: res.data.phonenumber,
            email: res.data.email,
            emailverified: res.data.emailverified,
            username: res.data.username,
            mobilenumber: res.data.phonenumber,
            createdDate: res.data.date,
            googlestatus: res.data.google,
          });

          this.props.auth.user.name = res.data.name;
        }
      })
      .catch();
  }
  Copied = (answer) => {
    store.addNotification({
      message: "Address Copied",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 1500,
        onScreen: true,
      },
    });
  };

  SelectReceiveCurrency = (answer) => {
    this.setState({ receiveselected: answer.value });
    if (answer.value != "XRP") {
      var curindex = this.state.balancedetails.findIndex(
        (x) => x.currencySymbol === answer.value
      );
      var tempaddres = "";
      var tempBalance = "";
      if (curindex != -1) {
        tempaddres = this.state.balancedetails[curindex].currencyAddress;
        tempBalance = this.state.balancedetails[curindex].balance;
      }
      this.setState({ cryptoaddress: tempaddres, cryptobalance: tempBalance });
    } else {
      var curindex = this.state.balancedetails.findIndex(
        (x) => x.currencySymbol === answer.value
      );
      var tempaddres = "";
      var addd = "";

      if (curindex != -1) {
        tempaddres = this.state.balancedetails[curindex].currencyAddress;
        tempBalance = this.state.balancedetails[curindex].balance;
        addd = this.state.balancedetails[curindex].privateKey;
      }
      this.setState({
        desttag: tempaddres,
        cryptobalance: tempBalance,
        // cryptoaddress: addd,
        cryptoaddress: this.state.xrpaddress,
      });
    }
    this.setState({ cryptocurrency: answer.value });
    this.setState({ activetab: "send" });
    this.props.history.push(
      "/WalletDepositWithdrawCrypto?coinreceive=" + answer.value
    );
  };

  SelectSendCurrency = (answer) => {
    this.setState({ sendselected: answer.value });
    var curindex = this.state.balancedetails.findIndex(
      (x) => x.currencySymbol === answer.value
    );
    var fee = 0;
    var tempaddres = "";
    if (curindex != -1) {
      tempaddres = this.state.balancedetails[curindex].balance;
      fee = this.state.balancedetails[curindex].currency.fee;
    }
    this.setState({ cryptobalance: tempaddres, fee: fee });
    this.setState({ cryptocurrency: answer.value });
    this.setState({ activetab: "send" });
    this.props.history.push(
      "/WalletDepositWithdrawCrypto?coinsend=" + answer.value
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  // Table change using type
  handlePreferredType = (selectedPreferredType) => {
    this.setState({
      selectedPreferredType,
      preferredTypeVal: selectedPreferredType.value,
    });
    if (selectedPreferredType && selectedPreferredType.value == "Deposit") {
      this.setState({ withdrawShowing: false, depositShowing: true });
    } else if (
      selectedPreferredType &&
      selectedPreferredType.value == "Withdraw"
    ) {
      this.setState({ depositShowing: false, withdrawShowing: true });
    }
  };
  // Withdraw process
  Withdraw() {
    if (this.state.googlestatus == "Enabled") {
      if (this.validateForm()) {
        // if (this.state.twofaactivated) {
        if (
          parseFloat(this.state.cryptobalance) >
            parseFloat(this.state.amount) +
              (parseFloat(this.state.fee) * parseFloat(this.state.amount)) /
                100 &&
          this.state.amount > 0
        ) {
          // if (this.validateForm()) {

          let fields = {};
          fields["amount"] = "";
          fields["toaddress"] = "";
          fields["tfacode"] = "";
          this.setState({ validation: fields });
          const withdrawdata = {
            id: this.props.auth.user.id,
            amount: this.state.amount,
            tfacode: this.state.tfacode,
            toaddress: this.state.toaddress,
            cryptoType: this.state.sendselected,
            _csrf: this.state._csrf,
          };
          this.setState({ loading: true });

          axios
            .post(url + "cryptoapi/cryptowithdraw", withdrawdata)
            .then((res) => {
              this.setState({ loading: false });

              if (res.data.status) {
                store.addNotification({
                  title: "Wonderful!",
                  message: res.data.message,
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
                this.getAssetData(this.state.sendselected);
                this.getHistory();
                this.setState({
                  amount: "",
                  tfacode: "",
                });
                document.querySelector("#withdraw").dataset.target =
                  "#withdrawModal";
                document.getElementById("withdraw").click();
              } else {
                store.addNotification({
                  title: "Error!",
                  message: res.data.message,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animated", "fadeIn"],
                  animationOut: ["animated", "fadeOut"],
                  dismiss: {
                    duration: 1500,
                    onScreen: true,
                  },
                });
              }
            })
            .catch();
        } else {
          store.addNotification({
            message: "Balance is low",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      }
    } else {
      store.addNotification({
        title: "Error!",
        message: "Please Enable 2FA For Withdraw Currecny",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["amount"] = "";
    errors["toaddress"] = "";
    errors["tfacode"] = "";
    let formIsValid = true;
    if (this.state.amount == "") {
      formIsValid = false;
      errors["amount"] = "*Please enter the  Amount";
      document.querySelector("#withdraw").dataset.target = "#withdrawModal";
      document.getElementById("withdraw").click();
    }
    if (this.state.toaddress == "") {
      formIsValid = false;
      errors["toaddress"] = "*Please enter the  Address";
      document.querySelector("#withdraw").dataset.target = "#withdrawModal";
      document.getElementById("withdraw").click();
    }
    if (this.state.tfacode == "") {
      formIsValid = false;
      errors["tfacode"] = "*Please enter the  2FA code";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  // Pdf generation
  //  Export option functions:
  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    if (this.state.withdrawShowing == true) {
      const title = "Withdraw History";
      const headers = [
        ["Date", "type", "Crypto currency", "Amount", "To address", "status"],
      ];
      const data = this.state.withdrawHistory.map((elt) => [
        moment(elt.created_date).format("DD-MM-YYYY  k:mm:s "),
        elt.transferType,
        elt.currency,
        elt.Amount,
        elt.Toaccountno,
        elt.status,
      ]);
      let content = {
        startY: 50,
        head: headers,
        body: data,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
    } else if (this.state.depositShowing == true) {
      const title = "Deposit History";
      const headers = [["Date", "Address", "type", "Amount", "status"]];
      const data = this.state.depositHistory.map((elt) => [
        elt.created,
        elt._id,
        elt.transferType,
        elt.Amount,
        elt.status,
      ]);
      let content = {
        startY: 50,
        head: headers,
        body: data,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
    }

    // let content = {
    //   startY: 50,
    //   head: headers,
    //   body: data
    // };

    if (this.state.withdrawShowing == true) {
      doc.save("Withdraw-details.pdf");
    } else if (this.state.depositShowing == true) {
      doc.save("Deposit-details.pdf");
    }
  };

  render() {
    var qstr = queryString.parse(this.props.location.search);
    const { selectedPreferredCrypto } = this.state;
    const { selectedPreferredCryptoShort } = this.state;
    const { selectedPreferredRecords } = this.state;
    const { selectedPreferredType } = this.state;
    const { selectedDepositFrom } = this.state;
    const { selectedWithdrawTo } = this.state;
    const { selectedCurrencyType } = this.state;
    const { errors } = this.state;
    return (
      <div>
        <div class="modal fade" id="withdrawModal">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Confirm Withdraw</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body withdrawConfirmDetails px-5 pb-4">
                <h3>
                  <small>Your withdraw is</small>
                  <span className="font-weight-bold">
                    {this.state.amount} {this.state.sendselected}
                  </span>
                </h3>
                <h3>
                  <small>Withdraw address is</small>
                  {this.state.toaddress}
                </h3>
                <div className="form-group">
                  <label>
                    Enter 6 digit 2FA code (<a href="/Settings">Enable</a> if
                    not enable 2FA)
                  </label>
                  {/* <input type="text" class="form-control" />
                  <span className="text-danger"> {errors.amount} </span> */}
                  <input
                    onChange={this.onChange}
                    value={this.state.tfacode}
                    error={errors.tfacode}
                    id="tfacode"
                    type="number"
                    className="form-control"
                    required
                  />
                  <span className="text-danger"> {errors.tfacode} </span>
                </div>
                <div class="form-group text-center">
                  <button class="btn btnBlue" onClick={() => this.Withdraw()}>
                    Confirm Withdraw
                  </button>
                </div>
                <div class="form-group text-center mb-0">
                  <button class="btn btnBlueBordered" data-dismiss="modal">
                    Cancel Withdraw
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserNavbar />
        <section className="userPages">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="userCommonCard">
                  <h2 className="userPageTitle">Deposit</h2>
                  <div className="userBoxWhiteBox depositBox">
                    <div className="form-group">
                      <label>Cryptocurreny</label>
                      {/* {console.log("preferredCryptopreferredCryptopreferredCrypto",preferredCrypto)} */}
                      <Select
                        //  value={selectedPreferredCrypto}
                        value={
                          this.state.preferredCrypto1 &&
                          this.state.preferredCrypto1.filter(
                            (option) =>
                              option.value === this.state.receiveselected
                          )
                        }
                        onChange={this.SelectReceiveCurrency}
                        options={this.state.preferredCrypto1}
                      />
                    </div>
                    <div className="form-group">
                      <label>Your {this.state.receiveselected} Address</label>
                      <div class="input-group">
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          value={this.state.cryptoaddress.replace(
                            "bitcoincash:",
                            ""
                          )}
                        />
                        {/* {console.log("this.state.cryptoaddress",this.state.cryptoaddress)} */}
                        {/* <input type="text" class="form-control" value="12d1iTgXzFjcSzow59E5adSKLS2KGpMdqv" disabled /> */}
                        <div class="input-group-append">
                          <button className="btn btnType1">
                            <CopyToClipboard
                              text={this.state.cryptoaddress}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <a
                                class="float-right"
                                onClick={() => {
                                  this.Copied(this.state.cryptoaddress);
                                }}
                              >
                                <i class="fa fa-copy" aria-hidden="true"></i>
                              </a>
                            </CopyToClipboard>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="qrCodePh text-center">
                      <div className="qrCodeBg">
                        {/* <img
                              src={require("../images/receiveqrCode.jpg")}
                              className="img-fluid"
                              alt=""
                            /> */}
                        <QRCode value={this.state.cryptoaddress} />
                      </div>
                      {/* <img src={require("../images/qrCode.jpg")} alt="" className="img-fluid" /> */}
                    </div>
                    <h6>
                      Send only {this.state.receiveselected} to this deposit
                      address.
                    </h6>
                    <p>
                      Sending coin or token other than{" "}
                      {this.state.receiveselected} to this address may result in
                      the loss of your deposit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="userCommonCard">
                  <h2 className="userPageTitle">Withdraw</h2>
                  <div className="userBoxWhiteBox withdrawBox">
                    <div class="form-group">
                      <label className="flexLabel">
                        <span>Cryptocurreny</span>
                        <span className="labelBalance">
                          <small>Balance</small>{" "}
                          {parseFloat(this.state.cryptobalance).toFixed(8)}{" "}
                          <small> {this.state.sendselected}</small>
                        </span>
                      </label>
                      <Select
                        value={
                          this.state.preferredCrypto1 &&
                          this.state.preferredCrypto1.filter(
                            (option) => option.value === this.state.sendselected
                          )
                        }
                        onChange={this.SelectSendCurrency}
                        options={this.state.preferredCrypto1}
                      />
                    </div>
                    <div className="form-group">
                      <label className="flexLabel">
                        <span>Amount to Withdraw</span>
                        <span className="labelBalance">
                          <small>+ Fee {this.state.fee}%</small>
                        </span>
                      </label>
                      <input
                        onChange={this.onChange}
                        id="amount"
                        type="number"
                        className="form-control"
                      />
                      <span className="text-danger"> {errors.amount} </span>
                    </div>
                    <div className="form-group">
                      <label className="flexLabel">
                        <span>Enter Withdraw Address</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        className="form-control"
                        onChange={this.onChange}
                        id="toaddress"
                      />
                      <span className="text-danger"> {errors.toaddress} </span>
                    </div>
                    <div className="form-group text-center">
                      <button
                        className="btn btnBlue px-4 py-2"
                        id="withdraw"
                        data-toggle="modal"
                        data-target="#withdrawModal"
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="userCommonCard mt-5">
                  <div className="tableFilterHead">
                    {this.state.depositShowing == true ? (
                      <h2 className="userPageTitle mb-0">Deposit History</h2>
                    ) : this.state.withdrawShowing == true ? (
                      <h2 className="userPageTitle mb-0">Withdraw History</h2>
                    ) : null}
                    <div className="tableFilterCard">
                      <div className="filterGroup">
                        <label>Type</label>
                        <Select
                          value={
                            preferredType &&
                            preferredType.filter(
                              (option) =>
                                option.value === this.state.preferredTypeVal
                            )
                          }
                          //  value={selectedPreferredType}
                          onChange={this.handlePreferredType}
                          options={preferredType}
                        />
                      </div>
                      <button
                        className="btnType1 downLoadBtn"
                        onClick={() => this.exportPDF()}
                      >
                        Download PDF
                      </button>
                    </div>
                  </div>
                  <div className="userBoxWhiteBox referralIncome">
                    {this.state.depositShowing == true ? (
                      <div className="table-responsive" id="depositHistory">
                        <ReactDatatable
                          responsive={this.state.responsive}
                          config={this.depositConfig}
                          records={this.state.depositHistory}
                          columns={this.depositColumns}
                          onPageChange={this.pageChange.bind(this)}
                        />
                      </div>
                    ) : null}
                    {this.state.withdrawShowing == true ? (
                      <div className="table-responsive" id="withdrawHistory">
                        <ReactDatatable
                          responsive={this.state.responsive}
                          config={this.withdrawConfig}
                          records={this.state.withdrawHistory}
                          columns={this.withdrawColumns}
                          onPageChange={this.pageChange.bind(this)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

WalletDepositWithdrawCrypto.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(WalletDepositWithdrawCrypto));
