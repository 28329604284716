let keys = {};
if (process.env.REACT_APP_MODE === "production") {
  console.log("\x1b[34m%s\x1b[0m", "Set Production Config");
  keys = {
    //for development server
    socketUrl: "https://coinfriendsapi.wealwin.com/",
    imageUrl: "https://coinfriendsapi.wealwin.com/",
    baseUrl: "https://coinfriendsapi.wealwin.com/",
    frontUrl: "https://coinfriends.wealwin.com/",
    // Recaptchakey: "6Lf9oewaAAAAAKVDTQGCul7_z2Ef36fQvteny_M7", //http://3.23.198.208/ */
    Recaptchakey: "6LdfEEIcAAAAANidC5VzEl3SRkCRChH_Nk3f22wn",

    //for Live server
    // socketUrl: "http://139.162.236.121:2053/",
    // imageUrl: "http://139.162.236.121:2053/",
    // baseUrl: "http://139.162.236.121:2053/",
    // frontUrl: "http://139.162.236.120/",
    // Recaptchakey: "6LdtuKkcAAAAADoy6vMcJAOP_n1Zd8g6NNr4sUYs", //http://50.19.1.66/ && http://aya.cash/
    // domainn
    // socketUrl: "https://api.coin-barter.com/",
    // imageUrl: "https://api.coin-barter.com/",
    // baseUrl: "https://api.coin-barter.com/",
    // frontUrl: "https://coin-barter.com/",
    // // Recaptchakey: "6Lc8wqocAAAAAAd8RY20FTbvqRUoUBCKrATMX0a7", //http://50.19.1.66/ && http://aya.cash/
    // Recaptchakey: "6LdfEEIcAAAAANidC5VzEl3SRkCRChH_Nk3f22wn", //local/Sitekey


  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("\x1b[34m%s\x1b[0m", "Set demo Config");
  keys = {
    //for development server
    socketUrl: "https://coinfriendsapi.wealwin.com/",
    imageUrl: "https://coinfriendsapi.wealwin.com/",
    baseUrl: "https://coinfriendsapi.wealwin.com/",
    frontUrl: "https://coinfriend-frontend.pages.dev/",
    // Recaptchakey: "6Lf9oewaAAAAAKVDTQGCul7_z2Ef36fQvteny_M7", //http://3.23.198.208/ */
    Recaptchakey: "6Lek-yglAAAAANJ1XesZQ9Mj0S2OCDmGUgTUBj1G", //vivake

    //for Live server
    // socketUrl: "http://139.162.236.121:2053/",
    // imageUrl: "http://139.162.236.121:2053/",
    // baseUrl: "http://139.162.236.121:2053/",
    // frontUrl: "http://139.162.236.120/",
    // Recaptchakey: "6LdtuKkcAAAAADoy6vMcJAOP_n1Zd8g6NNr4sUYs", //http://50.19.1.66/ && http://aya.cash/
    // domainn
    // socketUrl: "https://api.coin-barter.com/",
    // imageUrl: "https://api.coin-barter.com/",
    // baseUrl: "https://api.coin-barter.com/",
    // frontUrl: "https://coin-barter.com/",
    // // Recaptchakey: "6Lc8wqocAAAAAAd8RY20FTbvqRUoUBCKrATMX0a7", //http://50.19.1.66/ && http://aya.cash/
    // Recaptchakey: "6LdfEEIcAAAAANidC5VzEl3SRkCRChH_Nk3f22wn", //local/Sitekey


  };
} else {
  console.log("\x1b[34m%s\x1b[0m", "Set Development Config");
  keys = {
    Recaptchakey: "6LdnVsQaAAAAAD0VV-V-c36bCajdQMjum8A-af3f",
    socketUrl: "http://localhost:3023/",
    imageUrl: "http://localhost:3023/",
    baseUrl: "http://localhost:3023/",
    Recaptchakey: "6LdfEEIcAAAAANidC5VzEl3SRkCRChH_Nk3f22wn", //local/Sitekey
    frontUrl: "http://localhost:3000/",
  };
}

function numberWithCommas(x) {
  var pricevalue = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return pricevalue;
}

export default keys;
export { numberWithCommas };
