import React, { Component } from "react";
import ReactDOM from "react-dom";
import googleCaptcha from "../images/googleCaptcha.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import browser from "browser-detect";
import axios from "axios";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, Button } from "react-bootstrap/";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import { GoogleLogin } from "react-google-login";
// import "react-phone-number-input/style.css";

import {
  loginUser,
  mobloginUser,
  socaillogin,
  tfachecking,
  confirmaionchecking,
} from "../actions/authActions";
const url = keys.baseUrl;
const Recaptchakey = keys.Recaptchakey;
const recaptchaRef = React.createRef();
const grecaptchaObject = window.grecaptcha;
class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      countryName: "",
      countryCode: "",
      ipaddress: "",
      broswername: "",
      regionName: "",
      ismobile: "",
      os: "",
      region: "",
      recaptcha: "",
      mobrecaptcha: "",
      notify: "",
      tfacode: "",
      ccode: "",
      show: false,
      show1: false,
      show2: false,
      _csrf: "",
    };
  }
  getCSRFtoken = async () => {
    await axios
      .get(url + "getCSRF")
      .then((res) => {
        this.setState({ _csrf: res.data._csrf });

        var csrftoken = res.data._csrf;

        this.getGeoInfo();
        let activate_id = this.props.match.params.id;
        if (typeof activate_id != "undefined" && activate_id != "") {
          let passdata = { userid: activate_id, _csrf: csrftoken };
          axios
            .post(url + "users/userActivate", passdata)
            .then((res) => {
              store.addNotification({
                title: "Wonderful!",
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true,
                },
              });
              this.props.history.push("/Login");
            })
            .catch();
        }
      })
      .catch();
  };
  componentDidMount() {
    this.getGeoInfo();
    let theme = localStorage.getItem("xavitheme");
    if (theme == null) {
      localStorage.setItem("xavitheme", "dark_theme");
    }
    {
      if (theme == "dark_theme") {
        document.body.classList.remove("light_theme");
        document.body.classList.add("dark_theme");
        this.setState({ appliedtheme: "dark_theme", isButtonActive: true });
      } else {
        document.body.classList.remove("dark_theme");
        document.body.classList.add("light_theme");
        this.setState({ appliedtheme: "light_theme", isButtonActive: false });
      }
    }

    this.getCSRFtoken();
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/Dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      store.addNotification({
        // title: "Wonderful!",
        message: "Loggedin Successfully. Welcome to Coinbarter",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });

      this.props.history.push("/Dashboard");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
      if (
        typeof nextProps.errors.notify != "undefined" &&
        nextProps.errors.notify != ""
      ) {
        if (nextProps.errors.notify == "2fa Enabled") {
          var _id = nextProps.errors.newUser._id;
          this.props.history.push("/TwoFactor/" + _id);
          // this.setState({ show: true });
        }
        if (nextProps.errors.notify == "2fa Activated") {
          var _id = nextProps.errors.newUser._id;
          this.props.history.push("/TwoFactor/" + _id);
          // this.setState({ show1: true });
        }
        if (nextProps.errors.notify == "blocking") {
          this.setState({ show2: true });
          store.addNotification({
            title: "Error!",
            message: "New IP login check you Email for confirmation code",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      }
      setTimeout(function () {
        if (
          typeof nextProps.errors.notify != "undefined" &&
          nextProps.errors.notify != "" &&
          nextProps.errors.notify != "2fa Enabled" &&
          nextProps.errors.notify != "2fa Activated" &&
          nextProps.errors.notify != "blocking"
        ) {
          store.addNotification({
            // title: "Error!",
            message: nextProps.errors.notify,
            type: "danger", // ip = ip.replace('::ffff:', '');
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
        nextProps.errors.notify = "";
      }, 100);
    }
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.auth.isAuthenticated) {
  //     store.addNotification({
  //       // title: "Wonderful!",
  //       message: "Loggedin Successfully. Welcome to Coinfriends",
  //       type: "success",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animated", "fadeIn"],
  //       animationOut: ["animated", "fadeOut"],
  //       dismiss: {
  //         duration: 2000,
  //         onScreen: true,
  //       },
  //     });
  //     // this.props.history.push("/Trade/" + localStorage.getItem("curpair"));
  //     // this.props.history.push("/Exchange/" + localStorage.getItem("curpair"));
  //     this.props.history.push("/Account");
  //   }

  //   if (nextProps.errors) {
  //     this.setState({
  //       errors: nextProps.errors,
  //     });
  //     if (
  //       typeof nextProps.errors.notify != "undefined" &&
  //       nextProps.errors.notify != ""
  //     ) {
  //       console.log("nextProps.errors.nextProps.errors.nextProps.errors.",nextProps.errors);
  //       if (nextProps.errors.notify == "2fa Enabled") {
  //         var _id = nextProps.errors.newUser._id
  //         this.props.history.push("/TwoFactor/" + _id);
  //         // this.setState({ show: true });
  //       }
  //       if (nextProps.errors.notify == "2fa Activated") {
  //         var _id = nextProps.errors.newUser._id
  //         this.props.history.push("/TwoFactor/" + _id);
  //         // this.setState({ show1: true });
  //       }
  //       if (nextProps.errors.notify == "blocking") {
  //         this.setState({ show2: true });
  //         store.addNotification({
  //           // title: "Error!",
  //           message: "New IP login check you Email for confirmation code",
  //           type: "danger",
  //           insert: "top",
  //           container: "top-right",
  //           animationIn: ["animated", "fadeIn"],
  //           animationOut: ["animated", "fadeOut"],
  //           dismiss: {
  //             duration: 1500,
  //             onScreen: true,
  //           },
  //         });
  //       }
  //       // if (nextProps.errors.notify == "IPAddressblocking") {
  //       //   // this.setState({ show2: true });
  //       //   store.addNotification({
  //       //     // title: "Error!",
  //       //     message: "Your IP blocked due to wrong attempts Please Check your email",
  //       //     type: "danger",
  //       //     insert: "top",
  //       //     container: "top-right",
  //       //     animationIn: ["animated", "fadeIn"],
  //       //     animationOut: ["animated", "fadeOut"],
  //       //     dismiss: {
  //       //       duration: 1500,
  //       //       onScreen: true,
  //       //     },
  //       //   });
  //       // }
  //     }
  //     setTimeout(function () {
  //       console.log(nextProps.errors);
  //       if (
  //         typeof nextProps.errors.notify != "undefined" &&
  //         nextProps.errors.notify != "" &&
  //         nextProps.errors.notify != "2fa Enabled" &&
  //         nextProps.errors.notify != "2fa Activated" &&
  //         nextProps.errors.notify != "blocking"
  //       ) {
  //         store.addNotification({
  //           // title: "Error!",
  //           message: nextProps.errors.notify,
  //           type: "danger", // ip = ip.replace('::ffff:', '');
  //           insert: "top",
  //           container: "top-right",
  //           animationIn: ["animated", "fadeIn"],
  //           animationOut: ["animated", "fadeOut"],
  //           dismiss: {
  //             duration: 1500,
  //             onScreen: true,
  //           },
  //         });
  //       }
  //       nextProps.errors.notify = "";
  //     }, 100);
  //   }
  // }

  onChange = (e) => {
    var txt = e.target.value;

    if (txt !== undefined || txt != "")
      this.setState({
        errors: "",
      });
    this.setState({ [e.target.id]: e.target.value });
  };
  tpClose = (e) => {
    this.setState({ show: false });
  };
  tpClose1 = (e) => {
    this.setState({ show1: false });
  };

  tpClose2 = (e) => {
    this.setState({ show2: false });
  };

  captchaChange = (e) => {
    this.setState({ recaptcha: e });
  };

  captchaChange1 = (e) => {
    this.setState({ mobrecaptcha: e });
  };
  getGeoInfo = async () => {
    fetch("https://ipapi.co/json/")
      .then(async (response) => {
        const data = await response.json();

        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
        this.setState({
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          ipaddress: data.ip,
          region: data.region,
        });
      })
      .catch((error) => {
        console.log(error, "errrrrrrrrrrrrrrrrrrrr");
      });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const result = browser();
    const loginhistory = {};
    console.log(result);

    loginhistory.countryCode = this.state.countryCode;
    loginhistory.countryName = this.state.countryName;
    loginhistory.ipaddress = this.state.ipaddress;
    loginhistory.regionName = this.state.region;
    loginhistory.broswername = result.name;
    loginhistory.ismobile = result.mobile;
    loginhistory.os = result.os;
    const userData = {
      email: this.state.email,
      password: this.state.password,
      recaptcha: this.state.recaptcha,
      logininfo: loginhistory,
      _csrf: this.state._csrf,
    };
    console.log("loginhistoryloginhistory", loginhistory);
    this.props.loginUser(userData);
  };
  render() {
    const { errors } = this.state;
    return (
      <div className="login_nav">
        <Navbar />
        <section className="crediantialsBox">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10 col-sm-12 mx-auto">
                <div className="wow flipInY" data-wow-delay=".15s;">
                  <div className="formBox mb-5 mb-md-0">
                    <div
                      className="tab-content py-0 px-3 px-sm-0"
                      id="nav-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="nav-login"
                        role="tabpanel"
                        aria-labelledby="nav-login-tab"
                      >
                        <h2 className="crediantialTitle">Login</h2>
                        <form
                          id="frm"
                          name="frm"
                          noValidate
                          onSubmit={this.onSubmit}
                          className="stoForm"
                          method=""
                        >
                          <div className="form-group mobNumber">
                            <label>Email Address</label>
                            <input
                              className="form-control"
                              type="text"
                              onChange={this.onChange}
                              value={this.state.email}
                              error={errors.email}
                              id="email"
                              required
                            />
                            <span className="text-danger">{errors.email}</span>
                          </div>
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.password}
                              error={errors.password}
                              id="password"
                              type="password"
                              required
                            />
                            <span className="text-danger">
                              {errors.password}
                            </span>{" "}
                          </div>
                          <div className="form-group">
                            <div className="text-center">
                              <a href="/ForgotPassword">Forgot Password?</a>
                            </div>
                          </div>
                          <div className="form-group">
                            <ReCAPTCHA
                              className="g-recaptcha"
                              ref={(r) => (this.recaptcha = r)}
                              sitekey={Recaptchakey}
                              grecaptcha={grecaptchaObject}
                              onChange={this.captchaChange}
                            />
                            <span className="text-danger">
                              {errors.recaptcha}
                            </span>{" "}
                          </div>
                          <div className="form-group text-center">
                            <input
                              type="submit"
                              className="btnType1"
                              value="Login"
                            />
                          </div>
                        </form>
                        <div className="formFooter">
                          <h6 className="text-center">
                            New to Coin Barter?{" "}
                            <a href="/Signup">Sign up Now</a>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Login.propTypes = {
  confirmaionchecking: PropTypes.func.isRequired,
  tfachecking: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
  socaillogin,
  mobloginUser,
  tfachecking,
  confirmaionchecking,
})(withRouter(Login));
