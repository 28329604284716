import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from 'react-select';
import { Modal, Button } from "react-bootstrap/";
import { store } from "react-notifications-component";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys, { numberWithCommas } from "../actions/config";
import { io } from "socket.io-client";
import CountdownTimer from "timer-countdown";
import * as moment from "moment";
import $ from "jquery";




const url = keys.baseUrl;



const selectIssue = [
  { value: 'Seller NOT released as per the contract', label: 'Seller NOT released as per the contract' },
  { value: 'Seller NOT released as per the contract', label: 'Seller NOT released as per the contract' },
  { value: 'Seller NOT released as per the contract', label: 'Seller NOT released as per the contract' },
];


function NewlineText(props) {
  const text = props.text;
  if (text.split("\n").length > 1) {
    return text.split("\n").map((str) => <p>{str}</p>);
  } else {
    return text;
  }
}
class TradeConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NoFeedback :  true,
      YesFeedback : false,
      errors: {},
      buyadsdetails: {
        user_info: {},
      },
      tradername: "",
      chats: [],
      message: "",
      attachment_flag: false,
      attachment: null,
      crypto_amount: "",
      fiat_amount: "",
      showmodel: false,
      timeleft: "",
      timecomplete: "No",
      trade_status: "",
      trade_status_num:0,
      _id: "",
      from_userId: "",
      send_disable:false,
      release_disable:false,
      cancel_disable:false,
      confirmpay_disable:false,
      twofa_status:false,
      tfacode:"",
      twofabtn_disable:false,
      _csrf:'',
      cancelReason:'',
      tradeId:'',
      idProoferror:'',
      idProoffilename:'',
      trade_id:0,
    };
    this.socket = io(keys.socketUrl, { transports: ["polling"] });
    // this.socket = io('http://localhost:2053', { secure: true, transports: ['polling'], jsonp: false, rejectUnauthorized: false }); //live

    let id = this.props.match.params.id;
    this.socket.on("p2pchat-" + id, function (data) {
      fetchGetChatData(data);
    });

    const fetchGetChatData = (data) => {
      let status=data[0]&&data[0].orderboookdetail&&data[0].orderboookdetail.status
      this.setState({ trade_status_num: status });
      console.log("333333333333333333333333333333333333333333",data[0].orderboookdetail.status)
      if(data.trade_status=='completed'){
      var postdata = {};
      postdata.id = id;
      axios.post(url + "tradeapi/gettradestatus", postdata).then((res) => {
        alert()
        if (res.data.status == false) {
          this.props.history.push("/dashboard");
        } else {
      
          if(res.data.trade_status==4){
            this.setState({
              timecomplete: "Yes",
            });
          store.addNotification({
            title: "Wonderful!",
            message: 'Trade Completed Successfully.',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          window.location.href = "/OrderHistory/completed";
        }
        }
      });
    }
    console.log("1111111111111111111111111111111111111111111111111111111111",data.trade_status)
      let chatData = [...this.state.chats, ...data]; 
      console.log("chatDatachatDatachatDatachatData",chatData)
      this.setState({ chats: chatData });
    };
  }

  getuserdata = (id) => {
    var postdata = {};
    postdata.id = id;
    postdata._csrf=this.state._csrf;
    axios.post(url + "tradeapi/userdetails", postdata).then((res) => {
      if (
        res.data.userdetails[0].from_userId == this.props.auth.user.id ||
        res.data.userdetails[0].to_userId == this.props.auth.user.id
      ) {
        //correct user chat
      } else {
        this.props.history.push("/dashboard");
      }

      if (res.data.status == false) {
        this.props.history.push("/dashboard");
      } else {
        if (
          this.props.auth.user.id == res.data.userdetails[0].sellerdetails._id
        ) {
          this.setState({
            tradername: res.data.userdetails[0].buyerdetails.username,
          });
        } else {
          this.setState({
            tradername: res.data.userdetails[0].sellerdetails.username,
          });
        }

        var utcDate = new Date().toISOString();
        var currentTime = new Date(utcDate).getTime();

        var EndTime = new Date(res.data.userdetails[0].end_time).getTime();

        if (res.data.userdetails[0].status == 0) {
          var tr_status = "Waiting for buyer to confirm payment.";
        } else if (res.data.userdetails[0].status == 1) {
          var tr_status = "Payment Confirmed.";
        } else if (res.data.userdetails[0].status == 2) {
          var tr_status = "Cancelled.";
        } else if (res.data.userdetails[0].status == 3) {
          var tr_status = "Disputed.";
        } else if (res.data.userdetails[0].status == 4) {
          var tr_status = "Completed.";
        } else if (res.data.userdetails[0].status == 5) {
          var tr_status = "Closed.";
        }
        this.setState({ trade_status_num: res.data.userdetails[0].status });
        this.setState({ trade_status: tr_status });

        this.setState({ from_userId: res.data.userdetails[0].from_userId });

        this.setState({ _id: res.data.userdetails[0]._id });

        if (EndTime > currentTime) {
          var difference = (EndTime - currentTime) / 1000;
          var timeleft = difference * 1000;
          this.setState({
            timeleft: timeleft,
          });
        } else {
          this.setState({
            timecomplete: "Yes",
          });
        }
      }
    });
  };

  getchatdata = (id) => {
    var postdata = {};
    postdata.id = id;
    postdata._csrf = this.state._csrf;
    axios.post(url + "tradeapi/getchats", postdata).then((res) => {
      if (res.data.status == false) {
        alert("getchatdata")
        console.log("res.data.chatsres.data.chats",res.data.chats)
        this.props.history.push("/dashboard");
      } else {
        console.log("res.data.chatsres.data.chats",res.data.chats)

        this.setState({ chats: res.data.chats });
        if(res.data.trade_status==4){
         
          this.setState({
            trade_status_num: 4
          });
        }
      }
    });
  };

  handle_attach = (e) => {
    this.setState({ attachment_flag: true });
  };

  handleChangeefile = (event) => {

    this.setState({ idProoferror: "" });
    console.log('weweweeeeeeeeeee',event.target.files[0])
    if (event.target.files[0]) {
      // if ((event.target.files[0].type == "application/pdf")&&(event.target.files[0].type == "application/pdf")) {

      // }else{

      // }
      var filesize = event.target.files[0].size;
        if (filesize > 3145728) {
           this.setState({ idProoferror: "*Image is Above 3MB" });
        } else {
      this.setState({
        curimage: URL.createObjectURL(event.target.files[0]),
        attachment: event.target.files[0],
        idProoffilename:event.target.files[0].name,
      });
    }

     
    }
  };

  Handleclose = (e) => {
    this.setState({ twofa_status: false});
   
  };


  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }
  handleconfirm_pay = () => {
    this.setState({confirmpay_disable:true})
    if (this.state.timecomplete == "Yes") {
      store.addNotification({
        title: "Error!",
        message: "Sorry . Time Exceeded",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      return false;
    }
    let id = this.props.match.params.id;
    let userid = this.props.auth.user.id;
    var postdata = {};
    postdata.id = id;
    postdata.userid = userid;
    postdata._csrf = this.state._csrf;

    axios.post(url + "tradeapi/confirmpay", postdata).then((res) => {
      if (res.data.status == false) {
        store.addNotification({
          title: "Error!",
          message: res.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      } else {
        let typee="payment"
        let id = this.props.match.params.id;
        let userid = this.props.auth.user.id;
        const postdata = new FormData();
        postdata.append("id", id);
        postdata.append("userid", userid);
        postdata.append("msg", "Payment Confirmed");
        postdata.append("_csrf", this.state._csrf);
        postdata.append("type", typee);
  
        axios.post(url + "tradeapi/savechat", postdata)
        store.addNotification({
          title: "Wonderful!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        this.setState({ trade_status: 'Payment Confirmed.',trade_status_num:res.data.order_status });
      }
      this.setState({confirmpay_disable:false})
    });
  };

  handleconfirm_cancel = () => {
    if (this.state.timecomplete == "Yes") {
      store.addNotification({
        title: "Error!",
        message: "Sorry . Time Exceeded",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      return false;
    }

    let id = this.props.match.params.id;
    let userid = this.props.auth.user.id;
    var postdata = {};
    postdata.id = id;
    postdata.userid = userid;
    postdata._csrf = this.state._csrf;
    postdata.cancelReason = this.state.cancelReason;
    if(this.state.cancelReason==""){
      store.addNotification({
        title: "Error!",
        message: "Please select any one cancel reason",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
    else{
      this.setState({cancel_disable:true})
      axios.post(url + "tradeapi/cancel_trade", postdata).then((res) => {
        if (res.data.status == false) {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          document.querySelector('#confirm_cancel').dataset.target = "#cancelTradeModal";
          document.getElementById("confirm_cancel").click();
        } else {
          
          let typee="payment"
          const postdata = new FormData();
          postdata.append("id", id);
          postdata.append("userid", userid);
          postdata.append("msg", "Trade Cancelled");
          postdata.append("_csrf", this.state._csrf);
          postdata.append("type", typee);
    
          axios.post(url + "tradeapi/savechat", postdata)
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          document.querySelector('#confirm_cancel').dataset.target = "#cancelTradeModal";
          document.getElementById("confirm_cancel").click();
        this.setState({trade_status_num:res.data.order_status})

          window.location.href = "/dashboard";
        }
        this.setState({cancel_disable:false})
      });
    }
  };

  handle_dispute = () => {
    this.setState({disupte_disabled:true})
    let id = this.props.match.params.id;
    let userid = this.props.auth.user.id;
    var postdata = {};
    postdata.id = id;
    postdata.userid = userid;
    postdata._csrf = this.state._csrf;

    axios.post(url + "tradeapi/dispute_trade", postdata).then((res) => {
      if (res.data.status == false) {
        store.addNotification({
          title: "Error!",
          message: res.data.message+"Please attach the proof for raised dispute..",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      } else {
        store.addNotification({
          title: "Wonderful!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      }
      this.setState({disupte_disabled:false})
    });
  };

                                  
test(id){

  let userid = this.props.auth.user.id;
  let tfacode = this.state.tfacode;
  var postdata = {};
  postdata.id = id;
  postdata.userid = userid;
  postdata.tfacode = tfacode;
  postdata._csrf=this.state._csrf;
  postdata.twofacheck=1;
  axios
    .post(url + "tradeapi/release_cryptocurrency", postdata)
    .then((res) => {
      if (res.data.state=="hide") {
        store.addNotification({
          title: "Error!",
          message: res.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
       
      }else{
        this.setState({ twofa_status:true})
      }
    })
}
  handle_2fa(){

  }


  handlerelease_cryptocurrency = (idvalue) => {
 
    if(this.state.tfacode==""){
      store.addNotification({
        title: "Error!",
        message: "Enter 2FA Code",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      // this.setState({ twofa_status: true});
      document.querySelector('#assetRelease').dataset.target = "#assetRelease";
      document.getElementById("assetRelease").click();
      return false;
    }else{
    // this.setState({twofabtn_disable:true})
    // this.setState({release_disable:true})

    let id = idvalue;
    let userid = this.props.auth.user.id;
    let tfacode = this.state.tfacode;
    var postdata = {};
    postdata.id = id;
    postdata.userid = userid;
    postdata.tfacode = tfacode;
    postdata._csrf=this.state._csrf;

    axios
      .post(url + "tradeapi/release_cryptocurrency", postdata)
      .then((res) => {
        if (res.data.status == false) {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          document.querySelector('#assetRelease').dataset.target = "#assetRelease";
          document.getElementById("assetRelease").click();
          return
        } else {
          let typee="payment"
          let id = this.props.match.params.id;
          let userid = this.props.auth.user.id;
          const postdata = new FormData();
          postdata.append("id", id);
          postdata.append("userid", userid);
          postdata.append("msg", "Asset Released to Your wallet");
          postdata.append("_csrf", this.state._csrf);
          postdata.append("type", typee);
    
          axios.post(url + "tradeapi/savechat", postdata)
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        this.setState({trade_status_num:res.data.order_status})

          window.location.href = "/dashboard";
        }
        // if(this.state.twofa_status){
        //   this.setState({ twofa_status: false});
        // }
       
        // if(this.state.release_disable){
        //   this.setState({release_disable:false})
        // }
       
        
        // this.setState({twofabtn_disable:false})
      });}
  };

  completed = () => {
    this.setState({
      timecomplete: "Yes",
    });
  };
  tick = (leftTime) => {
    console.log(leftTime);
  };

  getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
          let id = this.props.match.params.id;
          this.setState({_csrf:res.data._csrf,trade_id:id});
          this.getuserdata(id);
          this.getchatdata(id);
          this.updatechat(id);
        })
        .catch();
  }

  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    let id = this.props.match.params.id;
    this.setState({tradeId:id})
    if (id.trim() == "") {
      this.props.history.push("/dashboard");
    }
    this.getCSRFtoken();
    
  }

  updatechat = (id) => {
    let userid = this.props.auth.user.id;
    var postdata = {};
    postdata.id = id;
    postdata.userid = userid;
    postdata._csrf= this.state._csrf;

    axios.post(url + "tradeapi/updatechats", postdata).then((res) => {});
  };

  handlemessage = (e) => {
    var msg = e.currentTarget.value;
    this.setState({ message: msg });
  };

  handle_send(type) {
    // if(!this.state.attachment){
    
    //   store.addNotification({
    //     title: "Error!",
    //     message: "Please Submit Document",
    //     type: "success",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animated", "fadeIn"],
    //     animationOut: ["animated", "fadeOut"],
    //     dismiss: {
    //       duration: 1500,
    //       onScreen: true,
    //     },
    //   });
    //   return
    // }
    // if(type=="payment"){
      this.setState({send_disable:true})
      let id = this.props.match.params.id;
      let userid = this.props.auth.user.id;
      // let msg = this.state.message;
      let msg = this.state.message.replace(/^\s+|\s+$/g, '');

      const postdata = new FormData();
      postdata.append("id", id);
      postdata.append("userid", userid);
      postdata.append("msg", msg);
      postdata.append("file", this.state.attachment);
      postdata.append("_csrf", this.state._csrf);
      postdata.append("type", type);

      axios.post(url + "tradeapi/savechat", postdata).then((res) => {
        if (res.data.status) {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });

          this.setState({
            curimage: "",
            attachment: "",
            message: "",
        idProoffilename:"",

          });
        if(type=="dispute"){
          this.setState({trade_status_num:3})
          document.querySelector('#createDisputeModal').dataset.target = "#createDisputeModal";
          document.getElementById("createDisputeModal").click();
        }
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          
          this.setState({
            // curimage: "",
            // attachment: "",
            // message: "",
          });
          if(type=="dispute"){
            document.querySelector('#createDisputeModal').dataset.target = "#createDisputeModal";
            document.getElementById("createDisputeModal").click();
          }
        }
        
        this.setState({send_disable:false})
        // this.handle_dispute();

      });
    // }
    // else{
      
    //     this.handle_dispute();
    // }
   
  }
 state = {
    selectedIssue: null,
  };
  handleIssue = selectedIssue => {
    this.setState({ selectedIssue });
  };

  // constructor(props) {
  //       super(props);
  //       this.state = { 
  //           NoFeedback :  true,
  //           YesFeedback : false,
  //       }; 
  //     }
  onNoFeedbackChanged = (e) => {
    this.setState({
      NoFeedback : true,
      YesFeedback : false
    });
  };
  onYesFeedbackChanged = (e) => {
    this.setState({
      NoFeedback : false,
      YesFeedback : true
    });
  };
  handleReason =(e) =>{
    this.setState({cancelReason:e.target.value})
  }
	render() {
    const { errors } = this.state;
    const { selectedIssue } = this.state;
    const { chats } = this.state;
		return (
      <div>
          <Modal show={this.state.twofa_status} onHide={() => { this.Handleclose();}}centered id="assetRelease">
            <Modal.Header closeButton>
                <h4> 2Fa</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="modal-body">
                            <ul className="overviewList">
                                <li>
                                    <h5>2fa Code</h5>
                                    <p>
                                        <input
                                        className="form-control"
                                        type="number"
                                        onChange={this.onChange}
                                        value={this.state.tfacode}
                                        error={errors.tfacode}
                                        id="tfacode"
                                        />
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btnType1 smallFont py-2 px-3 mr-2"
                    onClick={this.Handleclose}
                >
                    Cancel
                </button>
                <div>
                    <button
                    id="2faSubmitAsset" data-toggle="modal" data-target="#assetRelease"
                    // id="2faSubmitAsset"
                    className="btn btnType1 smallFont py-2 px-3 mr-2"
                    disabled={this.state.twofabtn_disable}
                    onClick={() => this.handlerelease_cryptocurrency(this.state._id)}
                    >
                        Submit
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
        <div class="modal fade" id="cancelTradeModal">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Cancel My Trade</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body tradeCancelDetails px-5 pb-4">
                <h5>Do you want to cancel this contact ID #{this.state._id} with the seller {this.state.tradername}?</h5>
                <div className="form-group">
                  <label>If so, may I know the reason:</label>
                  <div className="dashRadio mb-2">
                      <div class="radio mr-4">
                        <label>
                            <input type="radio" name="cancelTrade" value="I changed my mind" onChange={this.handleReason}/>
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                              I changed my mind
                        </label>
                      </div>
                    </div>
                    <div className="dashRadio mb-2">
                      <div class="radio">
                        <label>
                            <input type="radio" name="cancelTrade" value="I wrongly select the trade" onChange={this.handleReason}/>
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                              I wrongly select the trade
                        </label>
                    </div>
                  </div>
                  <div className="dashRadio mb-2">
                      <div class="radio">
                        <label>
                            <input type="radio" name="cancelTrade" value="I don’t have enough fund" onChange={this.handleReason}/>
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                              I don’t have enough fund
                        </label>
                    </div>
                  </div>
                  <div className="dashRadio mb-2">
                      <div class="radio">
                        <label>
                            <input type="radio" name="cancelTrade" value="No comments, will try again" onChange={this.handleReason}/>
                            <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                              No comments, will try again
                        </label>
                    </div>
                  </div>
                  <span className="text-danger">{errors.cancelReason}</span>
               </div>
                <div class="form-group text-center mb-2 mt-4">
                  <button class="btn btnBlue" disabled={this.state.cancel_disable} onClick={() =>
                                          this.handleconfirm_cancel()
                                        }>Submit</button>
                </div>
                <div class="form-group text-center mb-0">
                  <a href="#" data-dismiss="modal">No</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="createDisputeModal" data-backdrop="static">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Create a Dispute</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body tradeCancelDetails px-5 pb-4">
                <h5>Upload necessary documents to create dispute this trade <span className="font-weight-bold">Contact#7318590</span></h5>
                <p className="disputeDescription">1. Payment details<br />2. Any other documents related to this trade<br />3. Screenshots for Illegal communication</p>
                {/* <div className="form-group">
                  <label>Reason for Dispute</label>
                  <Select
                     value={selectedIssue}
                     onChange={this.handleIssue}
                     options={selectIssue}
                   />
                </div> */}
                <div className="form-group">
                  <label>Message to Support Team</label>
                  <textarea onChange={this.handlemessage} className="form-control" rows="4" value={this.state.message}></textarea>
                </div>
                <div className="form-group">
                  <div className="custom-file">
                    {/* <input
                      type="file"
                      className="custom-file-input"
                      aria-describedby="inputGroupFileAddon01"
                    /> */}
                    <input
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg,image/doc"
                      onChange={this.handleChangeefile}
                      name="file1"
                      id="exampleInputFile"
                      aria-describedby="inputGroupFileAddon01"
                      className="custom-file-input"
                    />
                    <label className="custom-file-label">
                      max 3MB in jpg / pdf / doc
                    </label>
                    <span className="text-bllue">{this.state.idProoffilename}</span>   
                    <span className="text-danger">{this.state.idProoferror}</span>   
                  </div>
                </div>
                <div class="form-group text-center mb-2 mt-4">
                  <button class="btn btnBlue w-50"
                              // type="file"
                              onClick={() => this.handle_send("dispute")} >Submit</button>
                </div>
                <div class="form-group text-center mb-0">
                  <button class="btn btnBlueBordered w-50" data-dismiss="modal">Cancel Dispute</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="feedbackModal">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Confirm Payment & Leave Feedback</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body tradeCancelDetails px-5 pb-4">
                <h5 className="mb-3">Do you fully paid as per the trade terms?</h5>
                <div className="form-group">
	            	<div className="dashRadio mb-2">
	                  <div class="radio mr-4">
	                    <label>
	                        <input type="radio" name="selectFeedback" value="yes" checked={this.state.NoFeedback?("checked"):("")} id="Deposit" onChange={this.onNoFeedbackChanged} />
	                        <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
	                          Yes, paid fully
	                    </label>
	                  </div>
	                </div>
	                <div className="dashRadio mb-2">
	                  <div class="radio">
	                    <label>
	                        <input type="radio" name="selectFeedback" value="no" checked={this.state.YesFeedback?("checked"):("")} id="Deposit" onChange={this.onYesFeedbackChanged} />
	                        <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
	                          Yes, paid fully & Leave feedback
	                    </label>
	                </div>
	              </div>
	              </div>
	              {this.state.NoFeedback?(
	              	<div className="invisible d-none">Not Interested to give feedback</div>
	               ):(
	              <div className="feedBackForm">
	              	<div className="form-group">
                        <label>Do you fully satisfied this trade?</label>
                        <div className="dashRadio">
                            <div class="radio mr-4">
                              <label>
                                  <input type="radio" name="satisfied" value="" />
                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                   Yes
                              </label>
                            </div>
                            <div class="radio">
                              <label>
                                  <input type="radio" name="satisfied" value="" />
                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                    No
                              </label>
                          </div>
                        </div>
                     </div>
                     <div className="form-group">
                        <label>Do you recommend this trader?</label>
                        <div className="dashRadio">
                            <div class="radio mr-4">
                              <label>
                                  <input type="radio" name="recommend" value="" />
                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                   Yes
                              </label>
                            </div>
                            <div class="radio">
                              <label>
                                  <input type="radio" name="recommend" value="" />
                                  <span class="cr"><i class="cr-icon fa fa-circle"></i></span>
                                    No
                              </label>
                          </div>
                        </div>
                     </div>
                     <div className="form-group">
	                  <label>Reason for Dispute</label>
	                  <Select
	                     value={selectedIssue}
	                     onChange={this.handleIssue}
	                     options={selectIssue}
	                   />
	                </div>
	              </div>
	              )}
                <div class="form-group text-center mb-2 mt-4">
                  <button class="btn btnBlue w-50">Submit</button>
                </div>
                <div class="form-group text-center mb-0">
                  <a href="#" data-dismiss="modal">No</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <UserNavbar />
      <section className="userPages">
          <div className="container">
            <div className="breadCrumb">
              <ul>
                 <li><a href="#">Offers</a></li>
                 <li className="bcArrow"><i class="fas fa-angle-right"></i></li>
                 <li><a href="#">Place Order</a></li>
                 <li className="bcArrow"><i class="fas fa-angle-right"></i></li>
                 {/* <li>Contact #73178590: Buying 0.00215661 bitcoins for $5,000 </li> */}
              </ul>
           </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="userBoxWhiteBox createTicket">
                  <h3 class="userBoxUnderlineTitle">Traders Contract</h3>
                  <div className="contractDetails">
                    <h4>Step 1: Pay the seller</h4>
                    <p>Send a message to the {this.state.tradername} to receive help with completing the payment.</p>
                    <h4>Step 2: Confirm the payment</h4>
                    <p>The bitcoins are held in escrow for 64 minutes, during which it is safe to pay. After paying, you need to mark payment complete by pressing the I have paid button or the trade will automatically cancel.</p>
                    <h4>Cancel / Dispute the trade</h4>
                    <p>Never cancel the trade if you have already sent payment to the seller!</p>
                    <p>In case of any problems DO NOT cancel the trade, but start a dispute by clicking on the Disputing the trade button after marking the payment as complete.</p>
                    <p>Disputing the trade button will appear 1 hour after the start of the trade.</p>
                  </div>
                  <div class="form-group text-center">
                 {this.state.trade_status_num==0&&   <button class="btn btnBlueBordered mr-2" id="confirm_cancel" data-toggle="modal" data-target="#cancelTradeModal">Cancel the Trade</button>}
                    {/* <button class="btn btnGrey">Block User</button> */}
                  </div>
                  <div class="form-group text-center mb-0">
                  {this.state.timecomplete == "Yes" && this.state.trade_status_num==1&&(
                    <button  className="btn btnBlueBordered" data-toggle="modal" data-target="#createDisputeModal">Create Dispute</button>
                  )}&nbsp;&nbsp;&nbsp;
                    {this.state.from_userId && this.props.auth.user.id && (this.state.from_userId !=
                      this.props.auth.user.id) && this.state.trade_status_num==0 &&(
                      <button disabled={this.state.confirmpay_disable}
                        className="btn btnBlueBordered"
                        onClick={() => this.handleconfirm_pay()}
                      >
                        Confirm Pay
                      </button>
                    )} 
                    {this.state.from_userId && this.props.auth.user.id && (this.state.from_userId ==
                      this.props.auth.user.id) &&this.state.trade_status_num==1&& (
                      <button disabled={this.state.release_disable}
                        className="btn btnBlueBordered"
                        onClick={() => this.test(this.state._id)
                          // this.handle_2fa(
                          //   this.state._id
                          // )
                        }
                        
                        
                      >
                        Release Asset
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="userBoxWhiteBox ticketDetails">
                  <div className="tcTitleFlex">
                    <h5>Send message to<span className="blueText">{this.state.tradername}</span></h5>
                    <h3>{console.log("1111111111111111111111111111111111111111111111111111111111",this.state.trade_status_num)}
                          {this.state.timeleft != "" && (this.state.trade_status_num==0 || this.state.trade_status_num==1) && (
                            <CountdownTimer
                              timeLeft={this.state.timeleft}
                              completeCallback={this.completed}
                              tickCallback={this.tick}
                            />
                          )}
                        </h3>
                    <h5>Trade Conversation</h5>
                    {/* <h5>You are the buyer <span className="blueText">@myid2021</span></h5> */}
                  </div>
                  <div className="greyChartBox">
                    <ul>
                    {chats.length > 0 &&
                            chats.map((item, i) => {
                              return (
                                <li
                                  className={
                                    item.Sender_userId !=
                                    this.props.auth.user.id
                                      ? "SellerChat"
                                      : "buyerChat"
                                  }
                                >
                                  <h6>
                                    {" "}
                                    {moment(item.created_at).format(
                                      "DD-MMMM-YYYY  k:mm:s "
                                    )}
                                  </h6>
                                  <h5>
                                  <p><span>{item.senderdetails.username}:</span>:
                                    <NewlineText text={item.message} />
                                    {item.attachment != "" && (
                                      <span>  Attachment :
                                        <a
                                          href={
                                            keys.imageUrl +
                                            "chatimg/" +
                                            item.attachment
                                          }
                                          target="_blank"
                                        >
                                          {item.attachment}
                                        </a></span>
                                    )}
                                    </p>
                                  </h5>
                                </li>
                              );
                            })}
                      {/* <li className="buyerChat">
                        <h6>5 min</h6>
                        <p>Hello</p>
                      </li>
                      <li className="SellerChat">
                        <h6>3 min</h6>
                        <p>yes, send the fund to my bank account</p>
                      </li>
                      <li className="buyerChat">
                        <h6>1 min</h6>
                        <p>give me your bank details?</p>
                      </li>
                      <li className="SellerChat">
                        <h6>20 sec</h6>
                        <p>your email address?</p>
                      </li> */}
                    </ul>
                    <div className="form-group">
                      <div class="input-group">
                      <textarea
                        class="form-control"
                        placeholder="Start your conversation"
                        onChange={this.handlemessage}
                        value={this.state.message} 
                      ></textarea>
                        <div class="input-group-append">
                          <button className="btn btnType1 pl-4 pr-3"  
                              onClick={(e) => (e.target.value = null)}
                              type="file"
                              onClick={() => this.handle_send("payment")}>
                                <img src={require("../images/sendImg.png")} alt="" className="img-fluid" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="chatAgreeCheck">
                      <div className="checkbox">
                        <label>
                            <input type="checkbox" value="" />
                            <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                            <span>I agree to the <a href="#">Terms</a> and <a href="#">Policy</a> of CoinFriends and Trader’s <a href="#">Contract</a></span>
                        </label>
                    </div>
                  </div> */}
                  <div className="paidButtons">
                    {/* <button type="button" className="btn btnBlue" data-toggle="modal" data-target="#feedbackModal">I have paid</button> */}
                    <div className="custom-file">
                        <input
                          type="file"
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={this.handleChangeefile}
                          name="file1"
                          id="exampleInputFile"
                          aria-describedby="inputGroupFileAddon01"
                          className="custom-file-input"
                        />
                        <label className="custom-file-label">
                          If paid upload here the payment proof
                        </label>
                    </div>
                  </div>
                  <span className="text-danger">
                    {this.state.imageerror}
                  </span>
                  <img
                    className="SellerChat"
                    src={this.state.curimage}
                  />
                  <div class="alert alert-primary mb-0" role="alert">
                    <div className="aletBoxCard">
                      <div className="alertboxIcon">
                        <img src={require("../images/alertIcon.png")} alt="" className="img-fluid" />
                      </div>
                      <div className="alertboxDescription">
                        <p>If you paid to the seller as per the trade agreement you must upload paid proof to the seller and the admin for verification. If the deal accept by the seller will release Escrow</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
</div>
);
	}
}

TradeConversation.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(TradeConversation));