import React, { Component } from 'react'
import {Link,withRouter} from 'react-router-dom';
import { store } from "react-notifications-component";
import keys from "../actions/config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { logoutUser } from "../actions/authActions";
const url = keys.baseUrl;



class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      errors: {},
      _csrf:'',
      isButtonActive: true,
      appliedtheme:'dark_theme',
      isTop: true,
    };
    this.onClick = this.onClick.bind(this)   
  }

  componentDidMount() {
    let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
    this.getCSRFtoken();
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 30;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
    });
  }

  getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
          this.setState({_csrf:res.data._csrf});
        })
        .catch();
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    let userid = this.props.auth.user.id;
    let postdata={"userId":userid, "_csrf" :this.state._csrf}  
    axios.post(url + "users/logoutuser/",postdata).then((res) => {
      store.addNotification({
        // title: "Wonderful!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 15000,
          onScreen: true,
        },
      });
      this.props.logoutUser();
    });
   
  };

  onClick (e) {
    e.preventDefault();
    localStorage.setItem('xavitheme', this.state.isButtonActive ? 'light_theme' : 'dark_theme');
    this.setState({appliedtheme:this.state.isButtonActive ? 'light_theme' : 'dark_theme'});

    document.body.classList.remove(!this.state.isButtonActive ? 'light_theme' : 'dark_theme');
    this.setState({
      isButtonActive: !this.state.isButtonActive
    })

    document.body.classList.add(this.state.isButtonActive ? 'light_theme' : 'dark_theme');
    // this.props.openPanel(this.props.buttonNumber)
  }

  render() {
    return (<div>
     <nav className ={this.state.isTop?"navbar navbar-expand-lg navbar-light bg-light fixed-top landingNav wow fadeInDown Scroll down":"navbar navbar-expand-lg navbar-light bg-light fixed-top landingNav wow fadeInDown Scroll up"}>
        <div className="container">
          <a className="navbar-brand" href="/"><img src={require("../images/landingLogo.png")} alt="" className="img-fluid" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
          <div className="ml-auto d-flex">
            <ul className="navbar-nav mr-5 linkTabs">
              <li className="nav-item">
                <a className="nav-link" href="/Buy">Buy Crypto</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Sell">Sell Crypto</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Faq">FAQ</a>
              </li>
              <li className="nav-item mb-2 mb-lg-0">
                <div className="light_dark_sectoin">
                  <a  href="#" onClick={this.onClick}> <i className={this.state.isButtonActive ?  'fas fa-moon':'fas fa-sun' } ></i></a>
                      {/* <a><i class="fas fa-sun"></i></a>  
                      <i class="fas fa-moon d-none"></i> */}
                </div>
              
              </li>
            </ul>
            <ul className="navbar-nav">
            {/*<li className="nav-item mb-2 mb-lg-0">
                          <div className="dropdown show">
                <a className="btn btnBorder mr-0 mr-lg-3 dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Learn More
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a className="dropdown-item" href="#">How to Buy</a>
                  <a className="dropdown-item" href="#">How to Sell</a>
                  <a className="dropdown-item" href="#">FAQ</a>
                  <a className="dropdown-item" href="#">How to create an Ad</a>
                  <a className="dropdown-item" href="#">How to add payment options </a>
                  <a className="dropdown-item" href="#">Webinars</a>
                  <a className="dropdown-item" href="#">Contact Support</a>
                  <a className="dropdown-item" href="#">About Us</a>
                </div>
              </div>
              </li>*/}
              {!this.props.auth.user.id &&<>
              <li className="nav-item mb-2 mb-lg-0">
                <a className="nav-link btn btnBlue mr-0 mr-lg-3" href="/Login">Login</a>
              </li>
              <li className="nav-item mb-2 mb-lg-0">
                <a className="nav-link btn btnBlue" href="/Signup">Create Account</a>
              </li></>}
              {this.props.auth.user.id &&  <>
              <li className="nav-item mb-2 mb-lg-0">
                <a className="nav-link btn btnBlue mr-0 mr-lg-3" href="/dashboard">My Account</a>
              </li> 
              </>
              } 
              {this.props.auth.user.id &&  <>
              <li className="nav-item mb-2 mb-lg-0">
                <a className="nav-link btn btnBlue" href="#" onClick={this.onLogoutClick} >Logout</a>
              </li> </>
              } 
            </ul>
            </div>
          </div>
        </div>
      </nav>
      </div>
);
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  logoutUser,
})(withRouter(Navbar));