import React, { Component } from 'react'
import {NavLink} from 'react-router-dom';
import ReactDOM from 'react-dom';
import UserNavbar from './UserNavbar'
import Footer from './Footer'


class PostSummary extends Component {
	render() {
		return (
      <div>
      <UserNavbar />
      <section className="userPages">
          <div className="container">
          	<div className="userCommonCard">
                  <div className="breadCrumb">
		            <ul>
		               <li><a href="/PostTrade">Create New Trade</a></li>
		               <li className="bcArrow"><i class="fas fa-angle-right"></i></li>
		               <li><a href="/OfferCategory">Offer Category</a></li>
		               <li className="bcArrow"><i class="fas fa-angle-right"></i></li>
		               <li><a href="/PostDetails">Post Details</a></li>
		               <li className="bcArrow"><i class="fas fa-angle-right"></i></li>
		               <li>Post Summary</li>
		            </ul>
		         </div>
                  <div className="userBoxWhiteBox securityBox">
                    <h3 class="userBoxUnderlineTitle">Your Trade Post Summary</h3>
                    <div className="dashAccountTrade">
	        			<ul>
	        				<li>
	        					<label>Type</label>
	        					<span>Selling <span className="strong">Bitcoin</span></span>
	        				</li>
	        				<li>
	        					<label>Location</label>
	        					<span>New Delhi ,India;   Tokoya, Japan; Newyork, USA, London, UK</span>
	        				</li>
	        				<li>
	        					<label>Payment Method</label>
	        					<span>US Dollar</span>
	        				</li>
	        				<li>
	        					<label>Rate</label>
	        					<span>Current crypto market rate +2.5% margin</span>
	        				</li>
	        				<li>
	        					<label>Post Title</label>
	        					<span><span className="strong italics">“Only truested buyer can deal”</span></span>
	        				</li>
	        				<li>
	        					<label>Terms of the trade</label>
	        					<span className="longPara">
	        						<span className="mb-3 d-block">If anyone is promising you on Instagram, Facebook or Telegram that if you invest in their company or in trading they will give you very high returns and profit then please don't fall for that trap. They all are scammers and you will lose all your money.</span>
	        						<span className="d-block">If you have not done KYC with localbitcoins, I may ask you for the ID.</span>
	        					</span>
	        				</li>
	        				<li>
	        					<label>Limit  Minimum & Maximum</label>
	        					<span><span className="strong">$5.50 - $100000.50</span></span>
	        				</li>
	        				<li>
	        					<label>Time Duration</label>
	        					<span>Monday - Friday, 10.00AM - 11.00PM, GMT +2.50</span>
	        				</li>
	        				<li>
	        					<label>Trade View</label>
	        					<span>Anybody can view, Only phone verified</span>
	        				</li>
	        			</ul>
	        			<div className="form-group text-center mt-4">
	                  	<button type="button" className="btn btnBlueBordered py-2 mr-3"><i class="fas fa-chevron-left"></i> Edit</button>
	                     <button type="button" className="btn btnBlue py-2">Submit My Post</button>
	                  </div>
                    </div>
                </div>
            </div>
          </div>
       </section>
      <Footer />
</div>
);
	}
}

export default PostSummary