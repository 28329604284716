import {
    SET_CURRENT_USER,
    USER_ADD,
    USER_LOADING,
    USER_UPDATE,
    USER_REGISTER,
    USER_FORGOT,
    TRADE,
    WALLET,
    SUPPORT,
    SUPPORT_REPLY,
} from "../actions/types";
const isEmpty =require("is-empty")
const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false
};
export default function(state = initialState, action) {
    switch (action.type) {
        case USER_REGISTER:
            return {
                ...state,
                newuser: action.payload
            };
        case USER_UPDATE:
            return {
                ...state,
                updateprofile: action.payload,
            };
        case USER_FORGOT:
            return {
                ...state,
                forgotuser: action.payload,
            };


        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };
            case TRADE:
            return {
                ...state,
                trade:action.payload,
            };
            case SUPPORT_REPLY:
           return {
               ...state,
               support_reply: action.payload
           };
           case SUPPORT:
           return {
               ...state,
               support:action.payload,
           };
            case WALLET:
                 return {
                     ...state,
                     wallet:action.payload,
                 };
        default:
            return state;
    }
}
