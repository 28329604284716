import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import googleCaptcha from "../images/googleCaptcha.jpg"
import UserNavbar from './UserNavbar'
import Footer from './Footer'
import Select from 'react-select';
import axios from "axios";
import { store } from "react-notifications-component";
import keys, { numberWithCommas } from "../actions/config";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap/";
import * as moment from "moment";
import queryString from "query-string";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import ReactDatatable from '@ashvin27/react-datatable';

const url = keys.baseUrl;

class Notification extends Component {
    constructor() {
        super();
        this.columns = [
			{
				key: "loginhistory",
				text: "Date & Time",
				className: "Date",
				align: "left",
				sortable: true,
				cell: record => {
                    return (
                        <p>{moment(record.created_date).format(
							"DD-MM-YYYY  k:mm:s "
						  )}</p>
                    )
                }
			},
			{
				key: "Activity",
				text: "Message",
				className: "Ip",
				align: "left",
				sortable: true,
				cell: record => {
                    return (
                        <p>{record.message}</p>
                    )
                }
			},
		]
		this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "History",
            no_data_text: 'No notificaion details found!',
            sort: { column: "Created date", order: "desc" },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
            defaultSortAsc: true,
        };
        this.state = {
          errors: {},
          userdetails: {},
          avg_positive_ratings:0,
          negative_ratings:0,
          feedbacks:[],
          allNotifications: [],
		  responsive:true,
          _csrf:'',
        };
    }
    getCSRFtoken =async () => {
    await axios
        .get(url + "getCSRF")
        .then((res) => {
            this.setState({_csrf:res.data._csrf});
            let id = this.props.match.params.id;
        })
        .catch();
    }
    
    componentDidMount() {
        let theme=localStorage.getItem("xavitheme");
		if(theme==null){
		  localStorage.setItem('xavitheme','dark_theme')
		}{
		  if(theme=='dark_theme'){
			document.body.classList.remove('light_theme' );
			document.body.classList.add('dark_theme');
			this.setState({appliedtheme:'dark_theme',isButtonActive:true})
		  }else{
			document.body.classList.remove('dark_theme' );
			document.body.classList.add('light_theme');
			this.setState({appliedtheme:'light_theme',isButtonActive:false})
		  }
		 
		}
        this.getCSRFtoken();
        this.getMyNotifications();
    }

    getMyNotifications() {
        let userid = this.props.auth.user.id;
        axios.get(url + "users/getMyNotifications/" + userid).then((res) => {
            if (res.data) {
            this.setState({ allNotifications: res.data });
            }
        });
    }
      
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
render() {
   const { selectedPreferredCoin,postprefoption } = this.state;
   const { errors } = this.state;
   const {} = this.state;
	return (
        <div>
            <UserNavbar />
            <section className="userPages">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="userBoxWhiteBox">
                            <h3 className="backPageTitle font-weight-bold mb-0 border-bottom-zero">My notification</h3>
                            <div class="table-responsive">
                                <ReactDatatable
                                    responsive={this.state.responsive}
                                    config={this.config}
                                    records={this.state.allNotifications}
                                    columns={this.columns}
                                    onPageChange={this.pageChange.bind(this)}
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
	}
}

Notification.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  perPage: 5,
  perPage_myads: 5,
});

export default connect(mapStateToProps, {})(withRouter(Notification));